import React from "react";
import { SvgIcon } from "@mui/material";

const MotionCheck = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M16.7394 6.13312L15.635 5.02812L7.90062 12.7619L4.365 9.22625L3.26062 10.3312L7.90062 14.9719L16.7394 6.13312Z" />
    </SvgIcon>
  );
};

export default MotionCheck;
