import { usePermission } from "../hooks/usePermission";
import { useQueryClient } from "@tanstack/react-query";
import UpperCaseText from "../../utils/UpperCaseText";

export function canAcceptPurchaseRequest() {
  return usePermission("acceptPurchaseRequestNA:write");
}

export function canCancelPurchaseRequest() {
  return usePermission("cancelPurchaseRequestNA:write");
}

// check if purchase request exists
export function getPurchaseRequest(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);
  const { purchaseInfo } = customerReservation;
  return purchaseInfo;
}

export function isPurchaseRequestAccepted(purchaseInfo) {
  if (!purchaseInfo) {
    return false;
  }

  const { acceptRejectStatus } = purchaseInfo;
  return UpperCaseText(acceptRejectStatus) === "ACCEPTED";
}

export function isPurchaseRequestCanceled(purchaseInfo) {
  if (!purchaseInfo) {
    return false;
  }

  const { acceptRejectStatus } = purchaseInfo;
  const status = UpperCaseText(acceptRejectStatus);
  return status === "CANCELLED" || status === "CANCELED";
}
