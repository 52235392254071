import React, { useContext, useState, useRef, useEffect } from "react";
import { Typography, Box, Skeleton, Grid } from "@mui/material";
import EMPTabs from "../../shared/components/EMPTabs/EMPTabs";
import EMPBreadcrumbs from "../../shared/components/EMPBreadcrumbs/EMPBreadcrumbs";
import ActionCardGrid from "./ActionCardGrid";
import CustomerInfo from "./CustomerInfo";
import Vehicle from "./Vehicle";
import Pricing from "./Pricing";
import Financing from "./Financing";
import TradeIn from "./TradeIn";
import NewDelivery from "./NewDelivery";
import { useParams } from "react-router-dom";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import EMPCounter from "../../shared/components/EMPCounter/EMPCounter";
import {
  DrawerProvider,
  DrawerContext,
} from "../../shared/components/EMPDrawer/DrawerContext";
import { ModalProvider } from "../../shared/components/EMPModal/ModalContext";
import OrderNotifications from "./OrderNotifications";
import { useQuery } from "@tanstack/react-query";
import NADealerNotificationsClient from "../../clients/NADealerNotificationsClient";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";
import DealerNotes from "./DealerNotes";
import { UserContext } from "../../shared/context/userContext";
import DealerDeliveryToolClient from "../../clients/DealerDeliveryToolClient";
import DealerNotesClient from "../../clients/DealerNotesClient";
import EMPChip from "../../shared/components/EMPChip/EMPChip";
import { getPurchaseRequest } from "../../shared/rules/PurchaseRequestRules";
import { canViewNotifications } from "../../shared/rules/NotificationRules";
import EMPSearchField from "../../shared/components/EMPSearchField/EMPSearchField";
import { useTranslation } from "react-i18next";
import { getJourneyTypeAndVendorCategory } from "../../shared/rules/JourneyTypeRules";
import EMPBlankSection from "../../shared/components/EMPBlankSection/EMPBlankSection";
import {
  trackOrderDetailsPageAction,
  trackOrderDetailsPageLoad,
} from "../../shared/services/analytics/OrderDetailsPageAnalytics";
import { canViewNotes } from "../../shared/rules/DealerNotesRules";

const OrderDetailsSearchBar = () => {
  return (
    <Box sx={{ background: "#172A4B", width: "100%", mb: 2, py: 4 }}>
      <Grid container justifyContent="center">
        <Grid item>
          <EMPSearchField searchQuery="" />
        </Grid>
      </Grid>
    </Box>
  );
};

const OrderDetailsHeader = ({ orderId, delivery }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const { market, dealerId, salesCode, dealerBrand } = user;
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const hasNotesViewPermission = canViewNotes();

  const { data: notifications } = useQuery({
    queryKey: ["notifications", { orderId }],
    queryFn: async () =>
      await NADealerNotificationsClient.getNotifications(
        sessionStorage.getItem("adfs.token"),
        userCommonId.split("|").join("%7C"),
        orderId
      ),
    enabled: canViewNotifications() && !!orderId,
    staleTime: 60000,
    retries: 2,
  });

  const { data: notes } = useQuery({
    queryKey: ["dealerNotes", { orderId }],
    queryFn: async () =>
      await DealerNotesClient.getDealerNotes(
        sessionStorage.getItem("adfs.token"),
        orderId,
        0,
        100
      ),
    enabled: hasNotesViewPermission && !!orderId,
    staleTime: 60000,
    select: ({ data }) => data,
  });

  const { handleDrawer, drawerHeight } = useContext(DrawerContext);
  const totalNotificationsCount = notifications?.data?.pagination?.totalCount;
  const totalNotesCount = notes?.pagination?.totalNotes;
  const isPurchaseRequest = getPurchaseRequest(orderId);
  const journeys = getJourneyTypeAndVendorCategory(orderId);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        alignItems: "center",
        gap: 4,
        mb: 4,
      }}
    >
      <Box gridColumn={{ xs: "span 7" }}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h1" sx={{ color: "text.primary" }}>
              {isPurchaseRequest
                ? `${t("common.orderTypes.purchase")} #${orderId}`
                : `${t("common.orderTypes.order")} #${orderId}`}
            </Typography>
          </Grid>
          <Grid item sx={{ ml: 1 }}>
            {journeys.map((journey) => (
              <Box sx={{ ml: 2 }} key={journey} display="inline">
                <EMPChip variant="outlined" label={journey} />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Box
        gridColumn={{ xs: "span 5" }}
        sx={{ display: "flex", justifyContent: "flex-end", gap: 6 }}
      >
        <EMPButton
          variant="text"
          color="primary"
          onClick={() => {
            handleDrawer(
              totalNotificationsCount
                ? `${t("common.notifications")} (${totalNotificationsCount})`
                : t("common.notifications"),
              canViewNotifications ? (
                <OrderNotifications
                  notifications={notifications}
                  orderId={orderId}
                />
              ) : (
                "No permission"
              )
            );
            trackOrderDetailsPageAction(
              delivery,
              "notifications",
              orderId,
              user
            );
          }}
          sx={{ position: "relative", display: "flex", alignItems: "center" }}
        >
          {t("common.notifications")}
          {totalNotificationsCount > 0 && (
            <Box ml={2} sx={{ display: "inline-block" }}>
              <EMPCounter
                count={totalNotificationsCount}
                testId="notifications-counter"
              />
            </Box>
          )}
        </EMPButton>

        <EMPButton
          variant="text"
          color="secondary"
          disabled={!hasNotesViewPermission}
          onClick={() =>
            handleDrawer(
              totalNotesCount
                ? `${t("common.dealerNotes")} (${totalNotesCount})`
                : t("common.dealerNotes"),
              <DealerNotes orderId={orderId} drawerHeight={drawerHeight} />
            )
          }
        >
          {t("common.dealerNotes")}
          {totalNotesCount > 0 && (
            <Box ml={2} sx={{ display: "inline-block" }}>
              <EMPCounter count={totalNotesCount} testId="notes-counter" />
            </Box>
          )}
        </EMPButton>
        <EMPButton
          variant="outlined"
          color="default"
          onClick={() => {
            trackOrderDetailsPageAction(delivery, "print", orderId, user);
            window.print();
          }}
        >
          {t("common.print")}
        </EMPButton>
      </Box>
    </Box>
  );
};

const OrderDetailsPage = () => {
  const { orderId } = useParams();
  const user = useContext(UserContext);
  const { market, dealerId, salesCode, dealerBrand } = user;
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const { t } = useTranslation();

  function clickToTab(tabNumber, analyticsStr) {
    trackOrderDetailsPageAction(
      deliveryModule.deliveryAppointment,
      analyticsStr,
      orderId,
      user
    );

    if (tabsRef.current) {
      window.scrollTo({
        top: tabsRef.current.offsetTop,
        behavior: "smooth",
      });
    }

    setActiveTab(tabNumber);
  }

  function changeTab(tabValue) {
    setActiveTab(tabValue);
    const tabTitle = tabItems
      .find((tab) => tab.value === tabValue)
      .title.toLowerCase();

    trackOrderDetailsPageAction(
      deliveryModule,
      `tab:${tabTitle}`,
      orderId,
      user
    );
  }

  useEffect(() => {
    const lastTab = sessionStorage.getItem("lastTab");
    const parsedTab = JSON.parse(lastTab);
    if (parsedTab && parsedTab.hasOwnProperty(orderId)) {
      setActiveTab(parseInt(parsedTab[orderId].tabNumber));
    }
  }, [orderId]);

  const {
    isFetching,
    status,
    error,
    data: order,
  } = useQuery({
    queryKey: ["order", { orderId }],
    queryFn: async () => {
      const { data } = await CustomerReservationsClient.fetchOrderDetails(
        sessionStorage.getItem("adfs.token"),
        orderId,
        userCommonId,
        false,
        "en",
        null
      );
      return data;
    },
    enabled: !!orderId,
    staleTime: 60 * 1000,
    retry: 2,
  });

  const { data: deliveryModule } = useQuery({
    queryKey: ["deliveryModule", { orderId }],
    queryFn: () =>
      DealerDeliveryToolClient.getDeliveryModuleInfo(
        sessionStorage.getItem("adfs.token"),
        dealerId,
        orderId
      ),
  });

  useEffect(() => {
    if (deliveryModule) {
      trackOrderDetailsPageLoad(deliveryModule, orderId, user);
    }
  }, [deliveryModule]);

  const tabItems = [
    {
      title: t("common.categories.customer"),
      route: "customer-info",
      content: <CustomerInfo content={order} orderId={orderId} />,
      value: 0,
    },
    {
      title: t("common.categories.vehicle"),
      route: "vehicle",
      content: <Vehicle content={order} orderId={orderId} />,
      value: 1,
    },
    {
      title: t("common.categories.pricing"),
      route: "pricing",
      content: <Pricing content={order} orderId={orderId} />,
      value: 2,
    },
    {
      title: t("common.categories.financing"),
      route: "financing",
      content: <Financing content={order} orderId={orderId} />,
      value: 3,
    },
    {
      title: t("common.categories.tradeIn"),
      route: "trade-in",
      content: <TradeIn content={order} orderId={orderId} />,
      value: 4,
    },
    {
      title: t("common.categories.delivery"),
      route: "delivery",
      content: <NewDelivery orderId={orderId} content={order} />,
      value: 5,
    },
  ];

  if (status === "error") {
    return (
      <>
        <OrderDetailsSearchBar />
        <Box
          component="section"
          sx={{ py: 4, px: { xs: 4, sm: 4, md: 4, lg: 14 } }}
        >
          <Box sx={{ mb: 4 }}>
            <EMPBreadcrumbs></EMPBreadcrumbs>
          </Box>

          <EMPBlankSection
            message1={`Something went wrong while loading ${orderId}`}
            message2={`${error.code}: ${error.response?.data.detail}`}
          />
        </Box>
      </>
    );
  }

  return (
    <DrawerProvider>
      <ModalProvider>
        <OrderDetailsSearchBar />
        <Box
          component="section"
          sx={{ py: 4, px: { xs: 4, sm: 4, md: 4, lg: 14 } }}
        >
          <Box sx={{ mb: 4 }}>
            <EMPBreadcrumbs></EMPBreadcrumbs>
          </Box>
          {status === "loading" && (
            <Skeleton
              variant="rectangular"
              height={44}
              sx={{ mb: 4 }}
            ></Skeleton>
          )}
          {!isFetching && order && (
            <Box>
              <OrderDetailsHeader
                orderId={orderId}
                origin={
                  order?.customerReservation.spotBuy ? "Spot Buy" : "Online"
                }
                delivery={deliveryModule}
              />
            </Box>
          )}

          {isFetching ? (
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={4}
              sx={{ mb: 4 }}
            >
              {[1, 2, 3].map((item) => {
                return (
                  <Box
                    gridColumn={{
                      xs: "span 6",
                      sm: "span 6",
                      md: "span 4",
                      lg: "span 4",
                    }}
                    key={item}
                  >
                    <Skeleton variant="rectangular" height={240}></Skeleton>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <>
              <Box sx={{ mb: 6 }}>
                <ActionCardGrid
                  orderId={orderId}
                  handleTabChange={(num, analyticsStr) =>
                    clickToTab(num, analyticsStr)
                  }
                ></ActionCardGrid>
              </Box>
              <Box ref={tabsRef}>
                <EMPTabs
                  tabItems={tabItems}
                  activeTab={activeTab}
                  onTabChange={changeTab}
                ></EMPTabs>
              </Box>
            </>
          )}
        </Box>
      </ModalProvider>
    </DrawerProvider>
  );
};

export default OrderDetailsPage;
