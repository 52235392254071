import React from "react";
import { SvgIcon } from "@mui/material";

const MotionInfo = React.forwardRef(function MotionInfo(props, ref) {
  return (
    <SvgIcon {...props} ref={ref} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24ZM12 1.71429C17.6571 1.71429 22.2857 6.34286 22.2857 12C22.2857 17.6571 17.6571 22.2857 12 22.2857C6.34286 22.2857 1.71429 17.6571 1.71429 12C1.71429 6.34286 6.34286 1.71429 12 1.71429Z"
        fill="#066FEF"
      />
      <path
        d="M22.2858 12C22.2858 6.34287 17.6572 1.71429 12.0001 1.71429C6.34293 1.71429 1.71436 6.34287 1.71436 12C1.71436 17.6572 6.34293 22.2857 12.0001 22.2857C17.6572 22.2857 22.2858 17.6572 22.2858 12Z"
        fill="white"
      />
      <path
        d="M12.857 16.2857H14.5713V18H9.42847V16.2857H11.1428V11.1429H9.42847V9.42859H12.857V16.2857Z"
        fill="#066FEF"
      />
      <path
        d="M12.0001 7.71428C12.6858 7.71428 13.2858 7.11428 13.2858 6.42857C13.2858 5.74285 12.6858 5.14285 12.0001 5.14285C11.3144 5.14285 10.7144 5.74285 10.7144 6.42857C10.7144 7.11428 11.3144 7.71428 12.0001 7.71428Z"
        fill="#066FEF"
      />
    </SvgIcon>
  );
});

export default MotionInfo;
