const reportPreviewStylesOverride = {
  cell: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1px",
    color: "#102b4e",
  },
  cellGreyBackground: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1px",
    color: "#102b4e",
    backgroundColor: "#f5f5f5",
  },
  cellWhiteBackground: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1px",
    color: "#102b4e",
    backgroundColor: "white",
  },
  cellGreyBackgroundBold: {
    fontSize: "12px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1px",
    color: "#102b4e",
    backgroundColor: "#f5f5f5",
  },
};

export default reportPreviewStylesOverride;
