import React, { Component } from "react";
import { Accordion, AccordionSummary, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UpperCaseText from "../../../utils/UpperCaseText";
import { withTranslation } from "react-i18next";
import { NA_DEFAULT_CURRENCY, PURCHASE_TYPES } from "../../../common/Constants";
import DownPayment from "./downPayment/DownPayment";
import OrderDeposit from "./orderDeposit/OrderDeposit";
import ReservationDeposit from "./reservationDeposit/ReservationDeposit";
import ErrorProcessingDialog from "../../../errors/ErrorProcessingDialog";
import { IS_CANADA_MARKET, IS_FORD_BRAND } from "../../../utils/EmpUtil";
import HasPermissionTo from "../../../common/HasPermissionTo";
import crStylesFord from "../crStyles";
import crStylesLincoln from "../crStylesLincoln";
import fordStyles from "./NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "./NAOrderDetailsNewVersionLincoln.module.scss";
import sharedStylesFord from "./OrderDetailsNewVersion.module.scss";
import sharedStylesLincoln from "./OrderDetailsNewVersionLincoln.module.scss";
import PurchaseDeposit from "./purchaseDeposit/PurchaseDeposit";

class ConsumerDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumerDepositExpansion: false,
      refundErrorOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ consumerDepositExpansion: this.props.expandAccordian });
    }
  }

  flipIcon() {
    this.setState({
      consumerDepositExpansion: !this.state.consumerDepositExpansion,
    });
  }

  getCurrency() {
    return this.props.localizedInfo &&
      this.props.localizedInfo.defaultCurrency &&
      this.props.localizedInfo.defaultCurrency.symbol
      ? this.props.localizedInfo.defaultCurrency.symbol
      : NA_DEFAULT_CURRENCY;
  }

  processFordPayError = (error) => {
    const parsedError = JSON.parse(
      error.response && error.response.data && error.response.data.message
    );
    if (
      parsedError.errors &&
      parsedError.errors[0] &&
      parsedError.errors[0].payload
    ) {
      this.refundError = parsedError.errors[0].payload.code;
    } else {
      this.refundError = null;
    }

    this.setState({ refundErrorOpen: true });
  };

  render() {
    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const sharedStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? sharedStylesFord
        : sharedStylesLincoln;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    const {
      customerReservation,
      isDownPaymentEnabled,
      disableDealerActionsFlagForPurchaseRequests,
    } = this.props;

    const orderDepositAmount =
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.depositAmount;

    const currencySymbol = this.getCurrency();
    return (
      <div>
        <Accordion
          onChange={() => this.flipIcon()}
          expanded={this.state.consumerDepositExpansion}
        >
          <AccordionSummary
            style={crStyles.headerBarBg}
            data-testid="consumerDeposit"
          >
            <div className={sharedStyles.headerBar}>
              <div className={sharedStyles.header}>
                {this.props.t("OrderDetailsConsumerDeposit.title")}
              </div>
              <div>
                {this.state.consumerDepositExpansion ? (
                  <ExpandLessIcon data-testid="lessConsumerDeposit" />
                ) : (
                  <ExpandMoreIcon data-testid="moreConsumerDeposit" />
                )}
              </div>
            </div>
          </AccordionSummary>
          <div className={styles.customerInfoGrid}>
            <Grid container spacing={2}>
              <HasPermissionTo
                perform={["vehicleHold"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={
                  customerReservation &&
                  customerReservation.purchaseInfo &&
                  customerReservation.purchaseDeposit &&
                  customerReservation.purchaseDeposit.depositAmount
                }
                render={() => {
                  return (
                    <Grid item className={styles.cardGridItemWrapper}>
                      <PurchaseDeposit
                        {...this.props}
                        currencySymbol={this.getCurrency()}
                        customerReservation={customerReservation}
                        processFordPayError={this.processFordPayError}
                        disableDealerActionsFlagForPurchaseRequests={
                          disableDealerActionsFlagForPurchaseRequests
                        }
                      />
                    </Grid>
                  );
                }}
              />

              {customerReservation &&
                customerReservation.purchaseDeposit &&
                customerReservation.purchaseInfo &&
                PURCHASE_TYPES.SIGN_ONLINE ===
                  UpperCaseText(
                    customerReservation.purchaseInfo.purchaseType
                  ) &&
                isDownPaymentEnabled && (
                  <Grid item className={styles.cardGridItemWrapper}>
                    <DownPayment
                      {...this.props}
                      currencySymbol={this.getCurrency()}
                      customerReservation={customerReservation}
                      processFordPayError={this.processFordPayError}
                      disableDealerActionsFlagForPurchaseRequests={
                        disableDealerActionsFlagForPurchaseRequests
                      }
                    />
                  </Grid>
                )}
              {customerReservation &&
                UpperCaseText(customerReservation.orderType) === "ORDER" &&
                orderDepositAmount && (
                  <Grid item className={styles.cardGridItemWrapper}>
                    <OrderDeposit
                      {...this.props}
                      currencySymbol={currencySymbol}
                      customerReservation={customerReservation}
                      processFordPayError={this.processFordPayError}
                    />
                  </Grid>
                )}

              {((customerReservation &&
                UpperCaseText(customerReservation.orderType) ===
                  "RESERVATION") ||
                customerReservation.conversionDate) && (
                <Grid item className={styles.cardGridItemWrapper}>
                  <ReservationDeposit
                    {...this.props}
                    currencySymbol={currencySymbol}
                    customerReservation={customerReservation}
                    processFordPayError={this.processFordPayError}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </Accordion>
        {this.state.refundErrorOpen && (
          <ErrorProcessingDialog
            open={this.state.refundErrorOpen}
            close={() => {
              this.setState({ refundErrorOpen: false });
            }}
            title={
              this.refundError
                ? this.props.t(`Error.Title${UpperCaseText(this.refundError)}`)
                : this.props.t("Error.processingErrorTitle")
            }
            body={
              this.refundError
                ? this.props.t(`Error.Body${UpperCaseText(this.refundError)}`)
                : IS_CANADA_MARKET(this.props.user.market)
                ? this.props.t("Error.processingErrorMessageCAN")
                : this.props.t("Error.processingErrorMessage")
            }
            buttonText={this.props.t("Error.goBack")}
          />
        )}
      </div>
    );
  }
}

export default withTranslation("emp")(ConsumerDeposit);
