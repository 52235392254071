import React, { useContext, useState, useEffect } from "react";
import EMPDataCard from "../../shared/components/EMPDataCard/EMPDataCard";
import EMPObjectCard from "../../shared/components/EMPDataCard/EMPObjectCard";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import { Box, Typography, Grid } from "@mui/material";
import { ModalContext } from "../../shared/components/EMPModal/ModalContext";
import EMPBanner from "../../shared/components/EMPBanner/EMPBanner";
import CapitalizeText from "../../utils/CapitalizeText";
import RefundPurchaseDeposit from "../../shared/components/EMPModal/ModalFragments/RefundPurchaseDeposit";
import {
  getRefundStatus,
  isRefundStatusAccepted,
  isPurchaseDepositStatusAccepted,
} from "../../shared/rules/PurchaseDepositRules";
import { useTranslation } from "react-i18next";
import {
  getPaymentType,
  hasCreditApp,
  get3pfCreditAppStatus,
} from "../../shared/rules/FinancingRules";
import {
  PURCHASE_DEPOSIT_STATUS,
  PURCHASE_REFUND_STATUS,
  DOC_UPLOAD_STATUS_COMPLETE,
  DOC_UPLOAD_STATUS_LOCKED,
  DOC_UPLOAD_STATUS_READY,
  PDS_STATUS_ACCEPT,
  PDS_STATUS_READY,
  PDS_STATUS_REJECT,
} from "../../common/Constants";
import UpperCaseText from "../../utils/UpperCaseText";
import { formatPriceValue } from "../../utils/FormatPrice";
import EMPLoader from "../../shared/components/EMPLoader/EMPLoader";
import CreditApplicationTable from "./financing-tables/CreditApplicationTable";
import CashTable from "./financing-tables/CashTable";
import LeaseTable from "./financing-tables/LeaseTable";
import { FINANCE_STATUS } from "../../shared/constants/FinanceConstants";
import { usePurchaseDeposit } from "../../shared/hooks/usePurchaseDeposit";
import { usePurchaseDepositStatus } from "../../shared/hooks/usePurchaseDepositStatus";
import { usePaymentTypeDetails } from "../../shared/hooks/usePaymentTypeDetails";

const extractPurchaseDeposit = (purchaseDeposit) => {
  if (purchaseDeposit) {
    const {
      depositAmount,
      acceptRejectDealerUserId,
      cardLastFourDigits,
      paymentTransactionId,
      acceptRejectStatus,
    } = purchaseDeposit;

    return {
      depositAmount,
      acceptRejectDealerUserId,
      cardLastFourDigits,
      paymentTransactionId,
      acceptRejectStatus,
    };
  } else {
    return {};
  }
};

const extractRefund = (purchaseDeposit) => {
  const refundStatus = getRefundStatus(purchaseDeposit);

  if (refundStatus) {
    const {
      refundStatus,
      refundAmount,
      refundDate,
      refundTransactionId,
      refundDealerUserId,
    } = purchaseDeposit;

    return {
      refundStatus,
      refundAmount,
      refundDate,
      refundTransactionId,
      refundDealerUserId,
    };
  } else {
    return {};
  }
};

const extractCustomerContracts = (content, t) => {
  const { preDeliverySummary } = content;
  const { docUploadDataStatus } = content.customerReservation;

  const result = {};

  if (preDeliverySummary) {
    const { systemStatus } = preDeliverySummary;
    let translatedStatus = "";
    switch (systemStatus) {
      case PDS_STATUS_READY:
        translatedStatus = t("ModelEOrders.pdsReadyStatus");
        break;
      case PDS_STATUS_ACCEPT:
        translatedStatus = t("ModelEOrders.pdsAcceptedStatus");
        break;
      case PDS_STATUS_REJECT:
        translatedStatus = t("ModelEOrders.pdsRejectedStatus");
        break;
      default:
        translatedStatus = null;
    }

    result.preDeliverySummary = translatedStatus;
    result.dealJacketId = preDeliverySummary.dealJacketId;
  }

  if (docUploadDataStatus) {
    let translatedDocStatus = "";
    switch (UpperCaseText(docUploadDataStatus)) {
      case DOC_UPLOAD_STATUS_COMPLETE:
        translatedDocStatus = t("ModelEOrders.complete");
        break;
      case DOC_UPLOAD_STATUS_READY:
        translatedDocStatus = t("ModelEOrders.ready");
        break;
      case DOC_UPLOAD_STATUS_LOCKED:
        translatedDocStatus = t("ModelEOrders.locked");
        break;
      default:
        translatedDocStatus = null;
    }
    result.docUpload = translatedDocStatus;
  }

  return result;
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const Financing = ({ content, orderId }) => {
  const [paymentLoaded, setPaymentLoaded] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const { handleModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const purchaseDepositData = usePurchaseDeposit(orderId);
  const purchaseDepositStatus = usePurchaseDepositStatus(orderId);

  const purchaseDepositDetails = extractPurchaseDeposit(purchaseDepositData);
  const refundDetails = extractRefund(purchaseDepositData);
  const customerContractsDetails = extractCustomerContracts(content, t);
  const customerPaymentDetails = usePaymentTypeDetails(orderId);
  const paymentType = getPaymentType(orderId);

  const userHasCreditApp = hasCreditApp(orderId);

  const getPurchaseDepositStatusKey = (status) => {
    for (const [key, value] of Object.entries(PURCHASE_DEPOSIT_STATUS)) {
      if (value === status) {
        return CapitalizeText(key);
      }
    }
    return null;
  };

  const getRefundStatusKey = (status) => {
    for (const [key, value] of Object.entries(PURCHASE_REFUND_STATUS)) {
      if (value === status) {
        return CapitalizeText(key);
      }
    }
    return null;
  };

  function shortenInvoice(value) {
    return value ? value.substr(value.length - 12, value.length) : null;
  }

  const getPurchaseDepositStatusInfo = (status) => {
    switch (status) {
      case PURCHASE_DEPOSIT_STATUS.ACCEPTED:
        return {
          heading: t("financing.purchaseDeposit.fields.0"),
          content: getPurchaseDepositStatusKey(
            PURCHASE_DEPOSIT_STATUS.ACCEPTED
          ),
          highlight: true,
          color: "success",
        };
      case PURCHASE_DEPOSIT_STATUS.REJECTED:
      case PURCHASE_DEPOSIT_STATUS.DECLINED:
      case PURCHASE_DEPOSIT_STATUS.FAILED:
        return {
          heading: t("financing.purchaseDeposit.fields.0"),
          content: getPurchaseDepositStatusKey(status),
          highlight: true,
          color: "warning",
        };
      default:
        return {
          heading: t("financing.purchaseDeposit.fields.0"),
          content: "Pending",
          highlight: true,
          color: "info",
        };
    }
  };

  const purchaseDepositStatusInfo = getPurchaseDepositStatusInfo(
    purchaseDepositStatus
  );

  const getRefundStatusInfo = () => {
    const status = getRefundStatus(purchaseDepositData);
    switch (status) {
      case PURCHASE_REFUND_STATUS.ACCEPTED:
        return {
          heading: t("financing.refund.fields.0"),
          content: "Refunded",
          highlight: true,
          color: "success",
        };
      case PURCHASE_REFUND_STATUS.REFUNDED_OFFLINE:
        return {
          heading: t("financing.refund.fields.0"),
          content: getRefundStatusKey(PURCHASE_REFUND_STATUS.REFUNDED_OFFLINE),
          highlight: true,
          color: "success",
        };
      case PURCHASE_REFUND_STATUS.DECLINED:
      case PURCHASE_REFUND_STATUS.FAILED:
        return {
          heading: t("financing.refund.fields.0"),
          content: getRefundStatusKey(status),
          highlight: true,
          color: "warning",
        };
      default:
        return {};
    }
  };

  const refundStatusInfo = getRefundStatusInfo();

  function purchaseDepositActionAvailable() {
    const isStatusValid = isPurchaseDepositStatusAccepted(purchaseDepositData);
    const refundStatus = getRefundStatus(purchaseDepositData);
    return (
      isStatusValid &&
      !refundStatus &&
      !Object.values(PURCHASE_REFUND_STATUS).includes(refundStatus)
    );
  }

  const getCustomerPaymentType = async () => {
    const response = await customerPaymentDetails;
    if (response) {
      setPaymentLoaded(true);
      const paymentData = {};

      if (response.spotBuy) {
        paymentData["spotBuy"] = response.spotBuy;
      }

      if (response.financeType) {
        paymentData["financeType"] = {
          heading: "Finance Type",
          content: response.financeType,
          hasTooltip: paymentType === "Finance",
          toolTipMessage: t(
            "OrderDetailsConsumerDeposit.paymentMethodTypeTooltipBody"
          ),
        };
      }

      if (response.totalPriceWithAddOns) {
        paymentData["totalPriceWithAddOns"] = {
          heading: "Estimated cash due at dealer",
          content: formatPriceValue(response.totalPriceWithAddOns),
        };
      }

      if (response.downPayment >= 0) {
        paymentData["downPayment"] = {
          heading: "Down Payment",
          content: response.downPayment
            ? formatPriceValue(response.downPayment)
            : "--",
        };
      }

      if (response.purchaseDepositAmountInt) {
        paymentData["purchaseDepositAmountInt"] = {
          heading: "Purchase Deposit",
          content: formatPriceValue(response.purchaseDepositAmountInt),
        };
      }

      if (response.apr) {
        paymentData["apr"] = {
          heading: "APR",
          content: `${parseFloat(response.apr).toFixed(2)}%`,
        };
      }

      if (response.monthlyPayment >= 0) {
        paymentData["monthlyPayment"] = {
          heading: "Monthly Payment",
          content: response.monthlyPayment
            ? `${formatPriceValue(response.monthlyPayment)} per mo`
            : "--",
        };
      }

      if (response.term) {
        paymentData["term"] = {
          heading: "Term",
          content: `${response.term} months`,
        };
      }

      if (response.totalAmountFinancedStandard) {
        paymentData["totalAmountFinancedStandard"] = {
          heading: "Total Amount Financed",
          content: formatPriceValue(response.totalAmountFinancedStandard),
        };
      }

      if (response.baseMonthlyPayment >= 0) {
        paymentData["baseMonthlyPayment"] = {
          heading: "Base Monthly Payment",
          content: response.baseMonthlyPayment
            ? formatPriceValue(response.baseMonthlyPayment)
            : "--",
        };
      }

      if (response.monthlyUseTax) {
        paymentData["monthlyUseTax"] = {
          heading: "Monthly Use Tax",
          content: formatPriceValue(response.monthlyUseTax),
        };
      }

      if (response.annualMileage) {
        paymentData["annualMileage"] = {
          heading: "Annual Mileage",
          content: Number(parseFloat(response.annualMileage)).toLocaleString(
            "en"
          ),
        };
      }

      if (response.residualData >= 0) {
        paymentData["residualData"] = {
          heading: "Residual Data",
          content: response.residualData ? response.residualData : "--",
        };
      }

      if (response.capitalizedCostReduction >= 0) {
        paymentData["capitalizedCostReduction"] = {
          heading: "Capitalized Cost Reduction",
          content: response.capitalizedCostReduction
            ? formatPriceValue(response.capitalizedCostReduction)
            : "--",
        };
      }

      if (response.availableIncentivesAndNonCashCredits) {
        paymentData["availableIncentivesAndNonCashCredits"] = {
          heading: "Available Incentives And Non Cash Credits",
          content: formatPriceValue(
            response.availableIncentivesAndNonCashCredits
          ),
        };
      }

      if (response.tradeInPrice >= 0) {
        paymentData["tradeInPrice"] = {
          heading: "Trade In Price",
          content: response.tradeInPrice
            ? formatPriceValue(response.tradeInPrice)
            : "--",
        };
      }

      if (response.cashDueAtSigning >= 0) {
        paymentData["cashDueAtSigning"] = {
          heading: "Cash Due At Signing",
          content: response.cashDueAtSigning
            ? formatPriceValue(response.cashDueAtSigning)
            : "--",
        };
      }

      if (response.totalAmountDueAtSigning >= 0) {
        paymentData["totalAmountDueAtSigning"] = {
          heading: "Total Amount Due At Signing",
          content: formatPriceValue(response.totalAmountDueAtSigning),
        };
      }

      // default to this is pipeline = true
      if (response.dataQuoteTotalAmountDueAtSigning >= 0) {
        paymentData["dataQuoteTotalAmountDueAtSigning"] = {
          heading: "Data Quote Total Amount Due At Signing",
          content: formatPriceValue(response.dataQuoteTotalAmountDueAtSigning),
        };
      }

      return setPaymentData(paymentData);
    }
  };

  useEffect(() => {
    getCustomerPaymentType();
  }, []);

  const customerPurchaseDeposit = !isEmptyObject(purchaseDepositDetails)
    ? [
        ...(purchaseDepositStatusInfo ? [purchaseDepositStatusInfo] : []),
        {
          heading: t("financing.purchaseDeposit.fields.1"),
          content: purchaseDepositDetails.depositAmount,
        },
        {
          heading: t("financing.purchaseDeposit.fields.2"),
          content: purchaseDepositDetails.acceptRejectDealerUserId || "--",
        },
        {
          heading: t("financing.purchaseDeposit.fields.3"),
          content:
            shortenInvoice(purchaseDepositDetails.paymentTransactionId) || "--",
        },
        {
          heading: t("financing.purchaseDeposit.fields.4"),
          content: purchaseDepositDetails.cardLastFourDigits || "--",
        },
      ]
    : [];

  const refundPurchaseDeposit = !isEmptyObject(refundDetails)
    ? [
        ...(refundStatusInfo ? [refundStatusInfo] : []),
        {
          heading: t("financing.refund.fields.1"),
          content: refundDetails.refundAmount,
        },
        {
          heading: t("financing.refund.fields.2"),
          content: refundDetails.refundDate,
        },
        {
          heading: t("financing.refund.fields.3"),
          content: shortenInvoice(refundDetails.refundTransactionId),
        },
        {
          heading: t("financing.refund.fields.4"),
          content: refundDetails.refundDealerUserId,
        },
      ]
    : [];

  const customerContractsData = !isEmptyObject(customerContractsDetails)
    ? {
        docUpload: {
          heading: t("financing.customerContracts.fields.0"),
          content: customerContractsDetails.docUpload || "--",
        },
        dealJacketId: {
          heading: t("financing.customerContracts.fields.1"),
          content: customerContractsDetails.dealJacketId || "--",
        },
        preDeliverySummary: {
          heading: t("financing.customerContracts.fields.2"),
          content: customerContractsDetails.preDeliverySummary | "--",
        },
      }
    : {};

  const renderPaymentTable = (paymentType, paymentData, t) => {
    switch (paymentType) {
      case "Cash":
        return <CashTable cashPaymentData={paymentData} />;
      case "Lease":
        return <LeaseTable leasePaymentData={paymentData} t={t} />;
      default:
        return (
          <>
            <Box
              gridColumn={{
                xs: "span 12",
              }}
            >
              <Grid container>
                <Grid item>
                  <Typography variant="h3" sx={{ color: "text.primary" }}>
                    {paymentType}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box
              gridColumn={{
                xs: "span 12",
              }}
            >
              <EMPObjectCard data={paymentData} />
            </Box>
          </>
        );
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      columnGap={4}
      rowGap={4}
      sx={{ mt: 5, mb: 11 }}
    >
      {isRefundStatusAccepted(purchaseDepositData) && (
        <Box
          gridColumn={{
            xs: "span 12",
          }}
        >
          <EMPBanner
            title={t("common.banners.refundedPurchaseDeposit.title")}
            content={t("common.banners.refundedPurchaseDeposit.content")}
          />
        </Box>
      )}

      {paymentType === "Third Party Financing" &&
        get3pfCreditAppStatus(orderId) === FINANCE_STATUS.INITIATED && (
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <EMPBanner
              title={t("common.banners.autofiStatus.title")}
              content={t("common.banners.autofiStatus.content")}
              hasLink
              linkText={t("common.banners.autofiStatus.linkText")}
              href="https://www.routeone.com"
            />
          </Box>
        )}

      {paymentLoaded && customerPurchaseDeposit.length > 0 && (
        <>
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h3" sx={{ color: "text.primary" }}>
                  {t("financing.purchaseDeposit.heading")}
                </Typography>
              </Grid>
              <Grid item>
                {purchaseDepositActionAvailable() && (
                  <Box>
                    <EMPButton
                      onClick={() =>
                        handleModal(
                          t("common.buttonCtas.refundPurchaseDeposit"),
                          <RefundPurchaseDeposit orderId={orderId} />
                        )
                      }
                    >
                      {t("common.buttonCtas.refundPurchaseDeposit")}
                    </EMPButton>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <EMPDataCard
              data={
                getRefundStatus(purchaseDepositData)
                  ? refundPurchaseDeposit.concat(customerPurchaseDeposit)
                  : customerPurchaseDeposit || []
              }
            />
          </Box>
        </>
      )}

      {paymentLoaded ? (
        paymentData && paymentData["spotBuy"] ? (
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <Typography variant="h3" sx={{ color: "text.primary", mb: 7 }}>
              Financing
            </Typography>
            <Typography variant="h5" component="p">
              Financing data is not available for in store purchase requests
            </Typography>
          </Box>
        ) : (
          <>
            {renderPaymentTable(paymentType, paymentData, t)}
            {userHasCreditApp && (
              <CreditApplicationTable
                orderId={orderId}
                paymentType={paymentType}
              />
            )}
          </>
        )
      ) : (
        <Box
          gridColumn={{
            xs: "span 12",
          }}
        >
          <EMPLoader loadingMessage="Loading payment data" />
        </Box>
      )}

      {!isEmptyObject(customerContractsData) && (
        <>
          <Box
            gridColumn={{
              xs: "span 12",
            }}
            sx={{ mt: 2 }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h3" sx={{ color: "text.primary" }}>
                  {t("financing.customerContracts.heading")}
                </Typography>
              </Grid>
              <Grid item>
                {(paymentType === "Ford Credit Financing" ||
                  paymentType === "Third Party Financing") && (
                  <Box>
                    <EMPButton
                      href="https://www.routeone.net"
                      target="_blank"
                      rel="noopener noreferrer"
                      buttonType="link"
                    >
                      {t("common.buttonCtas.routeOne")}
                    </EMPButton>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <EMPObjectCard data={customerContractsData} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Financing;
