import React, { Component } from "react";
import fordStyles from "./FieldView-Ford.module.scss";
import { withTranslation } from "react-i18next";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DealerGroupingClient from "../../clients/DealerGroupingClient";
import {
  IS_CANADA_MARKET,
  IS_FIELD_VIEW_USER,
  IS_USA_MARKET,
} from "../../utils/EmpUtil";
import DealerSearchResults from "./DealerSearchResults";
import { publish } from "../../common/PubSub";
import {
  Box,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from "@mui/material";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";
import { LANGUAGE_MARKET_MAP } from "../../common/Constants";
import FieldViewOrderIdSearchResultsTable from "../search/FieldViewOrderIdSearchResultsTable";
import LocalizedInfoClient from "../../clients/LocalizedInfoClient";
import UpperCaseText from "../../utils/UpperCaseText";
import SharedToolTip from "../../shared/sharedToolTip/SharedToolTip";
import EMPButton from "../../shared/components/EMPButton/EMPButton";

const styles = fordStyles;

class DealerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      orderId: "",
      invalidCodeError: false,
      dealerNotFoundError: false,
      dealers: {},
      showDealerResults: false,
      showOrderResults: false,
      showOrderDetails: false,
      searchMethod: "searchBySalesCodePA",
    };

    this.isNADealer =
      this.props.user.market.toUpperCase() === "CAN" ||
      this.props.user.market.toUpperCase() === "USA";
  }

  componentDidMount() {
    const defaultUserMarket = this.props.user.market.toUpperCase();

    this.isNADealer =
      this.props.user.market.toUpperCase() === "CAN" ||
      this.props.user.market.toUpperCase() === "USA";

    this.fetchLocalizedInfo(defaultUserMarket);
  }

  handleInputChange = (event) => {
    if (this.state.searchMethod === "searchBySalesCodePA") {
      this.setState({
        code: event.target.value.trim(),
        invalidCodeError: false,
        dealerNotFoundError: false,
      });
    } else if (this.state.searchMethod === "searchByOrderId") {
      this.setState({
        orderId: event.target.value.trim(),
      });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    if (this.state.searchMethod === "searchBySalesCodePA") {
      this.handleDealerSearch();
    } else if (this.state.searchMethod === "searchByOrderId") {
      this.handleOrderSearch();
    }
  };

  handleDealerSearch = () => {
    const code = this.state.code;
    const market = this.props.user.market;
    const isValidPAOrSalesCode = this.isValidSearchCode(code, market);

    if (!isValidPAOrSalesCode) {
      this.setState({ invalidCodeError: true, showDealerResults: false });
    } else {
      this.getDealerGroup(this.isValidPACode(code, market));
    }
  };

  handleOrderSearch = () => {
    this.props.hideOrShow(true);
    CustomerReservationsClient.searchCustomerReservations(
      this.state.orderId,
      this.props.user,
      LANGUAGE_MARKET_MAP[this.props.user.market.toUpperCase()],
      null,
      this.props.user.market
    )
      .then((response) => {
        this.parseReservations(response);
      })
      .catch(() => {
        this.setState({ reservations: {}, showOrderResults: true });
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  isValidSearchCode = (code, market) => {
    const regexUSA = /^[FL]?\d{5}$/i;
    const regexCAN = /^[C]?[BA]\d{4}$/i;

    if (IS_USA_MARKET(market)) {
      return regexUSA.test(code);
    } else if (IS_CANADA_MARKET(market)) {
      return regexCAN.test(code);
    } else {
      return false;
    }
  };

  isValidPACode = (code, market) => {
    const regexUSA = /^\d{5}$/;
    const regexCAN = /^[BA]\d{4}$/i;

    if (IS_USA_MARKET(market)) {
      return regexUSA.test(code);
    } else if (IS_CANADA_MARKET(market)) {
      return regexCAN.test(code);
    } else {
      return false;
    }
  };

  fetchLocalizedInfo = (marketID) => {
    LocalizedInfoClient.getLocalizedInfo(this.props.user, marketID).then(() => {
      this.setState({ renderApp: true });
    });
  };

  updateCurrentDealer = (dealer) => {
    this.props.updateCurrentDealer(dealer);
  };

  updateBrand = (brandCode) => {
    this.props.updateBrand(brandCode);
  };

  searchDealerFromOrderId = (code, market) => {
    const isValidSearchCode = this.isValidSearchCode(code, market);
    const isValidPACode = this.isValidPACode(code, market);
    if (isValidSearchCode) {
      DealerGroupingClient.getDealerGroup(
        this.props.user,
        code,
        null,
        IS_FIELD_VIEW_USER(this.props.user.userType),
        isValidPACode && code
      )
        .then((data) => {
          const dealers = data;
          if (!dealers) {
            this.setState({ dealerDataForOrderSearch: null });
          } else if (dealers.length > 0) {
            this.props.user.setDealerGroup(dealers);
            this.setState({ dealerDataForOrderSearch: dealers[0] });
          }
        })
        .catch(() => {
          return null;
        });
    }
  };

  parseReservations(reservations) {
    const tempReservations = {};
    reservations.forEach((reservation) => {
      if (reservation.dealerId in tempReservations) {
        tempReservations[reservation.dealerId].push(reservation);
      } else {
        tempReservations[reservation.dealerId] = [];
        this.searchDealerFromOrderId(
          reservation.dealerId.substring(10, 16),
          this.props.user.market
        );
        tempReservations[reservation.dealerId].push(reservation);
      }
    });

    this.setState({
      reservations: tempReservations,
      showOrderResults: true,
    });

    this.props.hideOrShow(false);
  }

  getDealerGroup = (isValidPACode) => {
    this.props.hideOrShow(true);
    DealerGroupingClient.getDealerGroup(
      this.props.user,
      !isValidPACode && this.state.code,
      null,
      IS_FIELD_VIEW_USER(this.props.user.userType),
      isValidPACode && this.state.code
    )
      .then((data) => {
        let dealers = data;
        if (!isValidPACode) {
          dealers = dealers.filter(
            (dealer) =>
              UpperCaseText(dealer.salesCode) === UpperCaseText(this.state.code)
          );
        }
        if (!dealers) {
          this.setState({
            dealerNotFoundError: true,
            showDealerResults: false,
          });
        } else {
          this.props.user.setDealerGroup(dealers);
          this.props.user.setLanguageCode(this.props.i18n.language);
          this.setState({ dealers: data, showDealerResults: true });
        }
        this.props.hideOrShow(false);
      })
      .catch(() => {
        this.setState({ dealerNotFoundError: true, showDealerResults: false });
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  render() {
    this.props.user.updateBrand(this.props.brand);
    publish("brandChanged", this.props.brand);

    return this.state.renderApp ? (
      !this.state.showOrderDetails ? (
        <section className="pageWrapper">
          <div data-testid="dealerSearch" className={styles.searchIndent}>
            <Typography variant="h1" sx={{ my: 4 }}>
              {this.props.t("fieldView.title")}
            </Typography>
            <Typography variant="body1">
              {IS_USA_MARKET(this.props.user.market)
                ? this.props.t("fieldView.subheader")
                : this.props.t("fieldView.subheaderForCanada")}
            </Typography>
            <RadioGroup
              value={this.state.searchMethod}
              onChange={(e) => {
                e.target.value === "searchByOrderId"
                  ? this.setState({ code: "", searchMethod: e.target.value })
                  : this.setState({
                      orderId: "",
                      searchMethod: e.target.value,
                    });
              }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                className={styles.searchBar}
              >
                <Grid item>
                  <FormControlLabel
                    value="searchBySalesCodePA"
                    control={<Radio />}
                    data-testid="searchBySalesCodePA"
                    label={
                      <Typography variant="body2">
                        {this.props.t("CrcOrderSearch.searchBySalesCode")}
                      </Typography>
                    }
                  />

                  <ValidatorForm
                    id="valuationForm"
                    onSubmit={this.handleSearch}
                  >
                    <div className={styles.validatorForm}>
                      <div className={styles.textBox}>
                        <TextValidator
                          placeholder={
                            IS_USA_MARKET(this.props.user.market)
                              ? this.props.t(
                                  "fieldView.paOrSalesCodeTextInputPlaceholder"
                                )
                              : this.props.t(
                                  "fieldView.paOrSalesCodeTextInputPlaceholderForCanada"
                                )
                          }
                          name="searchBySalesCodePA"
                          autoComplete="off"
                          inputProps={{
                            "data-testid": "dealerSearchTextInput",
                          }}
                          onChange={this.handleInputChange}
                          onFocus={() =>
                            this.setState({
                              searchMethod: "searchBySalesCodePA",
                              orderId: "",
                            })
                          }
                          focused={
                            this.state.searchMethod === "searchBySalesCodePA"
                          }
                          value={this.state.code}
                        />
                      </div>
                      <SearchButton
                        testId="dealerSearchButtonBySalesCode"
                        disabled={this.state.code === ""}
                        {...this.props}
                      />
                    </div>
                  </ValidatorForm>
                </Grid>
                <Grid item>
                  <div className={styles.labelPadding}>
                    {this.props.t("CrcOrderSearch.or")}
                  </div>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value="searchByOrderId"
                    control={<Radio />}
                    data-testid="searchByOrderId"
                    label={
                      <Typography variant="body2">
                        {this.props.t("CrcOrderSearch.searchByOrderId")}
                      </Typography>
                    }
                  />

                  {IS_CANADA_MARKET(this.props.user.market) && (
                    <div style={{ display: "inline-flex", fontSize: "0px" }}>
                      <SharedToolTip
                        className={styles.iBallOffset}
                        placement="top-start"
                        {...this.props}
                        openTestid="recordSearchToolTipOpen"
                        body={this.props.t(
                          "CrcOrderSearch.recordSearchToolTipBody"
                        )}
                      />
                    </div>
                  )}

                  <ValidatorForm
                    id="valuationForm"
                    onSubmit={this.handleSearch}
                  >
                    <div className={styles.validatorForm}>
                      <div className={styles.textBox}>
                        <TextValidator
                          placeholder={this.props.t(
                            "CrcOrderSearch.textInputPlaceholder"
                          )}
                          name="searchByOrderId"
                          autoComplete="off"
                          id="orderSearch"
                          onChange={this.handleInputChange}
                          value={this.state.orderId}
                          focused={
                            this.state.searchMethod === "searchByOrderId"
                          }
                          onFocus={() =>
                            this.setState({
                              searchMethod: "searchByOrderId",
                              code: "",
                            })
                          }
                        />
                      </div>

                      <SearchButton
                        testId="dealerSearchButtonByOrderId"
                        disabled={this.state.orderId === ""}
                        {...this.props}
                      />
                    </div>
                  </ValidatorForm>
                </Grid>
              </Grid>
            </RadioGroup>

            {this.state.searchMethod === "searchBySalesCodePA" &&
              (this.state.invalidCodeError ? (
                <div className={styles.error}>
                  {this.props.t("fieldView.codeError")}
                </div>
              ) : this.state.dealerNotFoundError ? (
                <div className={styles.errorContainer}>
                  <div className={styles.error}>
                    {this.props.t("fieldView.dealerNotFoundError1")}
                  </div>
                  <div className={styles.error}>
                    {this.props.t("fieldView.dealerNotFoundError2")}
                  </div>
                </div>
              ) : this.state.showDealerResults ? (
                <div className="pageWrapper">
                  <DealerSearchResults
                    {...this.props}
                    dealerBrand={this.props.user.dealerBrand}
                    dealers={this.state.dealers}
                    updateCurrentDealer={this.updateCurrentDealer}
                  />
                </div>
              ) : (
                ""
              ))}
            {this.state.searchMethod === "searchByOrderId" &&
              this.state.showOrderResults &&
              (Object.keys(this.state.reservations).length !== 0 ? (
                Object.keys(this.state.reservations).map(
                  (commonDealerId, index) => {
                    return (
                      <FieldViewOrderIdSearchResultsTable
                        {...this.props}
                        key={commonDealerId}
                        isNADealer={this.isNADealer}
                        index={index}
                        fromFieldView={true}
                        commonDealerId={commonDealerId}
                        totalNumberOfResults={
                          this.state.reservations[commonDealerId].length
                        }
                        reservations={this.state.reservations[commonDealerId]}
                        dealerData={this.state.dealerDataForOrderSearch}
                      />
                    );
                  }
                )
              ) : (
                <div id="noOrders" key="none-found" className={styles.error}>
                  {this.props.t("CrcOrderSearch.noOrdersFoundErrorMessage")}
                </div>
              ))}
          </div>
        </section>
      ) : (
        ""
      )
    ) : (
      ""
    );
  }
}

const SearchButton = (props) => {
  return (
    <Box ml={1}>
      <EMPButton
        testId={props.testId}
        submitButton
        variant="contained"
        disabled={props.disabled}
      >
        {props.t("fieldView.search")}
      </EMPButton>
    </Box>
  );
};

export default withTranslation("emp")(DealerSearch);
