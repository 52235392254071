import React from "react";
import { Box, Typography } from "@mui/material";

const EMPCounter = (props) => {
  const { count, testId } = props;

  return (
    <Box
      component="div"
      data-testid={testId}
      sx={(theme) => ({
        display: "inline-block",
        borderRadius: 400,
        minWidth: "28px",
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.primary.contrastText,
      })}
    >
      <Typography variant="body2" component="span">
        {count}
      </Typography>
    </Box>
  );
};

export default EMPCounter;
