import { usePurchaseDeposit } from "./usePurchaseDeposit";
import { PURCHASE_DEPOSIT_STATUS_CREATED } from "../constants/ActionCardConstants";

export function usePurchaseDepositStatus(orderId) {
  const purchaseDeposit = usePurchaseDeposit(orderId);

  if (!purchaseDeposit) {
    return null;
  }

  const { acceptRejectStatus, depositAmount } = purchaseDeposit;

  return depositAmount && acceptRejectStatus !== null
    ? acceptRejectStatus
    : PURCHASE_DEPOSIT_STATUS_CREATED;
}
