const crStyles = {
  headers: {
    height: 40,
  },
  sort: {
    height: "12.5px",
    fill: "#102b4e",
  },
  expansionPanel: {
    height: "40px",
    minHeight: "40px",
  },
  headerBar: {
    color: "#102b4e",
    backgroundColor: "#d8d8d8",
    height: "40px",
    minHeight: "40px",
    padding: 0,
    margin: 0,
  },
  headerBarGrey: {
    backgroundColor: "#f3f3f3",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
    margin: 0,
  },
  headerBarBg: {
    backgroundColor: "#e5e5e5",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
    margin: 0,
  },
  barBg: {
    backgroundColor: "#ffffff",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
  },
  headerBarNeutral: {
    maxHeight: "50px",
    minHeight: "50px",
    padding: 0,
  },
  nestedAccordionHeader: {
    padding: 0,
  },
  accessoriesHeaderBarBg: {
    marginTop: "5px",
    backgroundColor: "#e5e5e5",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
  },
  accessoriesHeaderBarBgLincoln: {
    marginTop: "5px",
    backgroundColor: "#ffffff",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  gridItems: {
    display: "block",
    alignItems: "center",
    marginLeft: "-15px",
  },
  dropdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  priceDropdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  orderTypeHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "left",
    letterSpacing: "1.88px",
  },
  idHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: "15",
    paddingRight: 15,
    paddingTop: 0,
    paddingBottom: 0,
    width: "17%",
    textAlign: "center",
    letterSpacing: "1.88px",
  },
  dateHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: 15,
    paddingRight: 15,
    width: "10%",
    textAlign: "center",
    letterSpacing: "1.88px",
  },
  specsHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: 15,
    paddingRight: 15,
    width: "36%",
    letterSpacing: "1.88px",
  },
  statusHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center",
    letterSpacing: "1.88px",
  },
  feeHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center",
    width: "22%",
    letterSpacing: "1.88px",
  },
  orderType: {
    textAlign: "left",
    textTransform: "uppercase",
  },
  name: {
    textAlign: "left",
    textTransform: "normal",
  },
  pendingActionType: {
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  orderId: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
  },
  date: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
  },
  specs: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "left",
  },
  commonStatusStyle: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",

    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
  },
  confirmedStatus: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",

    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
    color: "#00ad46",
    backgroundColor: "rgba(0, 173, 70, 0.03)",
  },
  cancelledStatus: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",

    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
    color: "#ff0000",
    backgroundColor: "rgba(255, 0, 0, 0.03)",
  },
  amendedStatus: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",

    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
    color: "#4a4a4a",
    backgroundColor: "rgba(0, 0, 0, 0.03)",
  },
  statusRed: {
    color: "#ff0000",
  },
  statusGreen: {
    color: "#00ad46",
  },
  statusGray: {
    paddingLeft: 15,
    paddingRight: 15,
    color: "#4a4a4a",
    textAlign: "center",
  },
  fee: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
  },
  view: {
    paddingLeft: 15,
    paddingRight: "6%",
    textAlign: "right",
  },
  viewButton: {
    fontSize: "12px",
    color: "#102b4e",
    padding: 0,
    textAlign: "right",
    letterSpacing: "0.5px",
  },
  viewIcon: {
    height: "17.5px",
  },
  arrowEnabled: {
    backgroundColor: "#102b4e",
    color: "white",
    height: "40px",
    width: "40px",
    borderRadius: "3px",
  },
  arrowDisabled: {
    backgroundColor: "#8c8c8c",
    color: "white",
    height: "40px",
    width: "40px",
    borderRadius: "3px",
  },
  orderDetailsExpansion: {
    backgroundColor: "#ffffff",
    maxHeight: "60px",
    minHeight: "60px",
    padding: 0,
  },
  summaryTitle: {
    height: "100%",
    fontSize: "14px",
  },
};

export default crStyles;
