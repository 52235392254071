import React, { useContext } from "react";
import { DialogActions, Grid, Typography } from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";
import { ModalContext } from "../ModalContext";

const SimpleErrorMessage = ({ error }) => {
  const { handleModal } = useContext(ModalContext);
  return (
    <div>
      <Typography variant="body1" sx={{ mb: 6 }}>
        {error}
      </Typography>

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() => handleModal(false, false)}
            >
              Close
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

export default SimpleErrorMessage;
