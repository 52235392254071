import axios from "axios";
import { getHeaders } from "../utils/AuthorizationUtil";
import { BASE_URL } from "../utils/EmpUtil";

export default class DealerNotesClient {
  static baseUrl = BASE_URL;

  static saveDealerNotes(token, dealerId, requestBody) {
    const url = `${this.baseUrl}/dealer/dealernotes/${dealerId}/save-dealer-notes`;
    return axios.post(url, requestBody, getHeaders(token));
  }

  static updateDealerNotes(token, requestBody) {
    const url = `${this.baseUrl}/dealer/dealernotes/update-dealer-notes`;
    return axios.put(url, requestBody, getHeaders(token));
  }

  static getDealerNotes(token, orderId, pageNumber = 0, pageSize = 3) {
    const queryParams = `pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const url = `${this.baseUrl}/dealer/dealernotes/${orderId}/get-dealer-notes?${queryParams}`;
    return axios.get(url, getHeaders(token));
  }

  static deleteDealerNotes(token, id) {
    const url = `${this.baseUrl}/dealer/dealernotes/delete-dealer-notes/${id}`;
    return axios.delete(url, getHeaders(token));
  }
}
