import React, { Component } from "react";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import Search from "../components/search/Search";
import { withTranslation } from "react-i18next";
import Footer from "../shared/Footer/Footer";
import Crc from "./pure/Crc";
import withIdleTimer from "../common/WithIdleTimer";
import { Route, Router } from "react-router-dom";
import ErrorScreen from "../errors/ErrorScreen";
import { ENGLISH } from "../common/Constants";
import { UserContext } from "../shared/context/userContext";
import OrderDetailsPage from "../orders/order-details/OrderDetailsPage";
import EMPFieldSupportBar from "../shared/components/EMPFieldSupportBar/EMPFieldSupportBar";

class CRCApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderApp: false,
      userDetails: {},
    };
    this.currentCrc = new Crc(
      this.props.token,
      this.props.userDetails,
      this.props.history
    );

    this.setUserDetails = this.setUserDetails.bind(this);
  }

  componentDidMount() {
    this.props.history.push(`/`);
    this.props.i18n.changeLanguage(ENGLISH);
    this.setState({ renderApp: true });
  }

  setUserDetails(user, id) {
    this.setState({ userDetails: user });
    this.props.history.push("/customer-handling/" + id);
  }

  render() {
    return (
      this.state.renderApp && (
        <UserContext.Provider value={this.state.userDetails}>
          <Router history={this.props.history}>
            <EMPFieldSupportBar />
            <div className="appWrapper">
              <GlobalNavBar
                {...this.props}
                urlPath={window.location.pathname}
                user={this.currentCrc}
              />
              <Route
                exact
                path="/"
                component={withIdleTimer(() => (
                  <Search
                    {...this.props}
                    user={this.currentCrc}
                    setUserDetails={this.setUserDetails}
                  />
                ))}
              />
              <Route path="/customer-handling/:orderId">
                <OrderDetailsPage />
              </Route>

              <Route
                exact
                path="/error"
                render={(props) => <ErrorScreen {...props} />}
              />
              <Footer
                user={this.currentCrc}
                footerTitle={this.props.t("Footer.baseTitle")}
                helpSupportTitle={this.props.t("Footer.help")}
                permissions={this.props.userDetails.permissions}
              />
            </div>
          </Router>
        </UserContext.Provider>
      )
    );
  }
}

export default withTranslation("emp")(CRCApp);
