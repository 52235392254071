const stylesOverride = {
  typeDropDown: {
    height: 50,
    width: 300,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 12,
    lineHeight: 1.43,
    letterSpacing: 1.5,
    color: "#102b4e",
  },
  dealershipDropDown: {
    height: 50,
    width: 320,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  dealershipDropDownDisabled: {
    height: 50,
    width: 320,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "grey",
    borderColor: "grey",
  },
  marketAreaRegionDropDown: {
    height: 50,
    width: 195,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  marketAreaRegionDropDownDisabled: {
    height: 50,
    width: 195,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "grey",
    borderColor: "grey",
  },
  zoneDropDown: {
    height: 50,
    width: 160,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  zoneDropDownDisabled: {
    height: 50,
    width: 160,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "grey",
    borderColor: "grey",
  },
  autoDateDropDown: {
    height: 50,
    width: 200,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  icon: {
    top: "calc(50% - 12px)",
    right: 0,
    color: "#102b4e",
    position: "absolute",
    paddingRight: 7,
    cursor: "pointer",
    pointerEvents: "none",
  },
  orangeIcon: {
    top: "calc(50% - 12px)",
    right: 0,
    color: "#eb6147",
    position: "absolute",
    paddingRight: 10,
    cursor: "pointer",
    pointerEvents: "none",
  },
  item: {
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  reportLocationGridItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    paddingLeft: "0px",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 12,
    letterSpacing: 1.5,
    fontWeight: "bolder",
    color: "#324047",
  },
  dealershipGridItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontSize: "13px",
    letterSpacing: 1,
    fontWeight: ".5",
    color: "#383838",
    paddingRight: "20px",
    paddingLeft: "10px",
  },
  runReportGridItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontSize: "13px",
    letterSpacing: 1,
    fontWeight: ".5",
    color: "#383838",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 12,
    letterSpacing: 1.5,
    fontWeight: "bolder",
    color: "#324047",
  },
};

export default stylesOverride;
