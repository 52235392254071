import { useQueryClient } from "@tanstack/react-query";

export function usePurchaseDeposit(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);
  const { purchaseDeposit } = customerReservation;

  return purchaseDeposit;
}
