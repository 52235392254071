import { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/userContext";

export function useCrcStatus() {
  const [isCrc, setIsCrc] = useState(false);
  const { userType } = useContext(UserContext);

  useEffect(() => {
    setIsCrc(userType === "crc");

    return () => setIsCrc(false);
  });

  return isCrc;
}
