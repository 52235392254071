import React from "react";
import { Grid, Typography } from "@mui/material";
import EMPChip from "../EMPChip/EMPChip";

// Available props:
// headingText: string
// subItems: array
// hasLabel: Boolean
// labelText: string

const EMPGroupedHeading = (props) => {
  const { headingText, subItems, label, hideSubItems } = props;

  return (
    <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Typography variant="h1" align="left" sx={{ mr: 2 }}>
          {headingText}
        </Typography>
      </Grid>
      {label && (
        <Grid item>
          <EMPChip
            label={label.text}
            labelColor={label.color}
            testId="order-label"
          />
        </Grid>
      )}
      {!hideSubItems && (
        <Grid item container spacing={0} alignItems="center">
          {subItems.map(
            (dataItem) =>
              dataItem && (
                <Grid item key={dataItem.toString()}>
                  <Typography
                    variant="body1"
                    align="left"
                    data-testid={dataItem}
                    sx={{ mr: 2 }}
                  >
                    {dataItem}
                  </Typography>
                </Grid>
              )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default EMPGroupedHeading;
