import { Box, Typography, Divider } from "@mui/material";
import React from "react";
import EMPChip from "../EMPChip/EMPChip";

const EMPDataCard = ({ data, hasBackground, twoByTwo, testId }) => {
  const spanValue = twoByTwo ? 6 : 2;
  const columnValue = data.length > 3 ? 12 : 6;

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          border: hasBackground ? "none" : "1px solid #B2B2B2",
          borderRadius: 2,
          background: hasBackground
            ? "linear-gradient(135deg, #F5F6F7 0%, #FFFFFF 100%)"
            : "transparent",
          height: "inherit",
          padding: hasBackground ? "8px 56px" : 0,
        }}
        display="grid"
        gridTemplateColumns={`repeat(${columnValue}, 1fr)`}
        columnGap={4}
        rowGap={0}
        data-testid={testId}
      >
        {data.map((item, index) => {
          const isFirstItem = index === 0 || index === 6;
          return (
            <React.Fragment key={item.heading}>
              <Box
                gridColumn={`span ${spanValue}`}
                sx={{
                  padding: () => {
                    if (isFirstItem) {
                      return "16px 0px 16px 16px";
                    }
                    if (twoByTwo && index % 2 === 0) {
                      return "16px 0px 16px 16px";
                    }
                    if (twoByTwo && index % 2 !== 0) {
                      return "16px 0px 16px 16px";
                    }
                    if (!twoByTwo && !isFirstItem) {
                      return "16px 16px 16px 0px";
                    }
                    return "16px";
                  },
                }}
              >
                <Typography variant="caption">{item.heading}</Typography>

                {item.highlight ? (
                  <Box>
                    <EMPChip label={item.content} labelColor={item.color} />
                  </Box>
                ) : (
                  <Typography variant={hasBackground ? "body1" : "body2"}>
                    {item.content}
                  </Typography>
                )}
              </Box>
              {twoByTwo
                ? (index + 1) % 2 === 0 &&
                  index + 1 < data.length && (
                    <Divider
                      sx={{ gridColumn: `span ${columnValue}`, my: 0 }}
                    />
                  )
                : (index + 1) % 6 === 0 &&
                  index + 1 < data.length && (
                    <Divider
                      sx={{ gridColumn: `span ${columnValue}`, my: 0 }}
                    />
                  )}
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default EMPDataCard;
