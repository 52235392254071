import React from "react";
import { TextValidator } from "react-material-ui-form-validator";

const EMPValidatedInputField = ({
  type,
  placeholder,
  value,
  testId,
  disabled,
  readOnly,
  onChange,
  name,
  multiline,
  helperText,
  startAdornment,
  ref,
  validators,
  errorMessages,
}) => {
  return (
    <TextValidator
      variant="standard"
      fullWidth
      InputProps={{
        readOnly,
        disableUnderline: true,
        startAdornment,
      }}
      inputProps={{
        "data-testid": testId,
      }}
      type={type}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      ref={ref}
      name={name}
      multiline={multiline}
      helperText={helperText}
      validators={validators}
      errorMessages={errorMessages}
    ></TextValidator>
  );
};

export default EMPValidatedInputField;
