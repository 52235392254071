import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import getDeliveryStatus from "../../../utils/DeliveryStatusUtils/GetDeliveryStatus";

function generateStatusMessage(result) {
  return result !== undefined
    ? getDeliveryStatus(result?.deliveryAppointment).message.toLowerCase()
    : "no info available";
}

function trackOrderDetailsPageLoad(result, orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    "fv:emp:customer handling details",
    `emp:customer handling:status:${generateStatusMessage(result)}`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackOrderDetailsPageAction(result, action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details",
    `emp:customer handling:status:${generateStatusMessage(result)}`,
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:content:${action}`,
    "fv:emp:customer handling details:content action",
    user
  );
}

function trackPurchaseRequestDecisionPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    "fv:emp:customer handling details:purchase request popup",
    "emp:customer handling details:purchase request popup",
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackAcceptPurchaseRequestPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    "fv:emp:customer handling details:accept purchase popup",
    "emp:customer handling details:accept purchase popup",
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackAcceptPurchaseRequestPopupAction(
  paymentType,
  orderId,
  orderCreatedDate,
  user
) {
  let paymentTypeTag;
  if (
    paymentType === "ThirdPartyFinancingOptions" ||
    paymentType === "thirdpartyfinancingoptions"
  ) {
    paymentTypeTag = "dealer finance";
  } else {
    paymentTypeTag = paymentType.toLowerCase() || "";
  }

  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:accept purchase popup",
    `emp:customer handling details:accept purchase popup:${paymentTypeTag}:order id:${orderId}`,
    `emp:customer handling:order created:${orderCreatedDate || ""}`,
    orderId,
    "emp:customer handling details:accept purchase popup:content:confirm",
    "fv:emp:customer handling details:accept purchase popup:content action",
    user,
    paymentTypeTag.toLowerCase()
  );
}

function trackCancelPurchaseRequestPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    "fv:emp:customer handling details:cancel purchase popup",
    "emp:customer handling details:cancel purchase popup",
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackCancelPurchaseRequestPopupAction(
  dealerRejectedReason,
  paymentType,
  orderId,
  orderCreatedDate,
  user
) {
  let paymentTypeTag;
  if (
    paymentType === "ThirdPartyFinancingOptions" ||
    paymentType === "thirdpartyfinancingoptions"
  ) {
    paymentTypeTag = "dealer finance";
  } else {
    paymentTypeTag = paymentType.toLowerCase() || "";
  }
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:cancel purchase popup",
    `emp:customer handling details:cancel purchase:${dealerRejectedReason}:${paymentTypeTag}:order id:${orderId}`,
    `emp:customer handling:order created:${orderCreatedDate || ""}`,
    orderId,
    "emp:customer handling details:cancel purchase popup:content:confirm",
    "fv:emp:customer handling details:cancel purchase popup:content action",
    user,
    paymentTypeTag.toLowerCase()
  );
}

function trackDealerNotesDrawerLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    "fv:emp:customer handling details:dealer notes drawer",
    "emp:customer handling details:dealer notes drawer",
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackDealerNotesDrawerAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:dealer notes drawer",
    `emp:customer handling details:dealer notes drawer`,
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:dealer notes drawer:content:${action}`,
    "fv:emp:customer handling details:dealer notes drawer:content action",
    user
  );
}

function trackAcceptPurchaseDepositPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:accept purchase deposit popup`,
    `emp:customer handling details:accept purchase deposit popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackAcceptPurchaseDepositPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:accept purchase deposit popup",
    "emp:customer handling details:accept purchase deposit popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:accept purchase deposit popup:content:${action}`,
    "fv:emp:customer handling details:accept purchase deposit popup:content action",
    user
  );
}

function trackRejectPurchaseDepositPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:reject purchase deposit popup`,
    `emp:customer handling details:reject purchase deposit popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackRejectPurchaseDepositPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:reject purchase deposit popup",
    "emp:customer handling details:reject purchase deposit popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:reject purchase deposit popup:content:${action}`,
    "fv:emp:customer handling details:reject purchase deposit popup:content action",
    user
  );
}

function trackRefundPurchaseDepositPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:refund deposit popup`,
    `emp:customer handling details:refund deposit popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackRefundPurchaseDepositPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:refund deposit popup",
    "emp:customer handling details:refund deposit popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:refund deposit popup:content:${action}`,
    "fv:emp:customer handling details:refund deposit popup:content action",
    user
  );
}

function trackEVPowerPromisePopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:ford power promise popup`,
    `emp:customer handling details:ford power promise popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackEVPowerPromisePopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:ford power promise popup",
    "emp:customer handling details:ford power promise popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:ford power promise popup:content:${action}`,
    "fv:emp:customer handling details:ford power promise popup:content action",
    user
  );
}

function trackTimeOfSaleReportPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:time of sale report popup`,
    `emp:customer handling details:time of sale report popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackTimeOfSaleReportPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:time of sale report popup",
    "emp:customer handling details:time of sale report popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:time of sale report popup:content:${action}`,
    "fv:emp:customer handling details:time of sale report popup:content action",
    user
  );
}

// old
function trackDeliveryPopupLoad(modalHeader, orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    "fv:emp:customer handling details:appt popup",
    `emp:customer handling:appt form:${modalHeader}`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

// old
function trackDeliveryPopupAction(modalHeader, orderId, action, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:appt popup",
    `emp:customer handling:appt form:${modalHeader}`,
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:appt popup:content:${action}`,
    "fv:emp:customer handling details:appt popup:content action",
    user
  );
}

function trackRequestDeliveryApptPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:request delivery appointment popup`,
    `emp:customer handling details:request delivery appointment popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackRequestDeliveryApptPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:request delivery appointment popup",
    "emp:customer handling details:request delivery appointment popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:request delivery appointment popup:content:${action}`,
    "fv:emp:customer handling details:request delivery appointment popup:content action",
    user
  );
}

function trackConfirmDeliveryApptPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:confirm delivery appointment popup`,
    `emp:customer handling details:confirm delivery appointment popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackConfirmDeliveryApptPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:confirm delivery appointment popup",
    "emp:customer handling details:confirm delivery appointment popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:confirm delivery appointment popup:content:${action}`,
    "fv:emp:customer handling details:confirm delivery appointment popup:content action",
    user
  );
}

// step 1
function trackConfirmTradeInDetailsPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:confirm trade in details popup`,
    `emp:customer handling details:confirm trade in details popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackConfirmTradeInDetailsPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:confirm trade in details popup",
    "emp:customer handling details:confirm trade in details popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:confirm trade in details popup:content:${action}`,
    "fv:emp:customer handling details:confirm trade in details popup:content action",
    user
  );
}

// step 2
function trackTradeInDecisionPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:trade in decision popup`,
    `emp:customer handling details:trade in decision popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackTradeInDecisionPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:trade in decision popup",
    "emp:customer handling details:trade in decision popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:trade in decision popup:content:${action}`,
    "fv:emp:customer handling details:trade in decision popup:content action",
    user
  );
}

function trackDecisionAction(action, decision, orderId, user) {
  switch (decision) {
    case "accept-and-keep":
      trackAcceptTradeInAndKeepVehiclePopupAction(action, orderId, user);
      break;
    case "accept-and-send":
      trackAcceptTradeInAndSendToAuctionPopupAction(action, orderId, user);
      break;
    case "reject":
      trackRejectTradeInPopupAction(action, orderId, user);
      break;
    default:
      // eslint-disable-next-line no-console
      console.error("Invalid decision type");
  }
}

// step 3
function trackAcceptTradeInAndKeepVehiclePopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:accept trade in and keep vehicle popup`,
    `emp:customer handling details:accept trade in and keep vehicle popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackAcceptTradeInAndKeepVehiclePopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:accept trade in and keep vehicle popup",
    "emp:customer handling details:accept trade in and keep vehicle popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:accept trade in and keep vehicle popup:content:${action}`,
    "fv:emp:customer handling details:accept trade in and keep vehicle popup:content action",
    user
  );
}

// step 3
function trackAcceptTradeInAndSendToAuctionPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:accept and send to auction:customer info popup`,
    `emp:customer handling details:accept and send to auction:customer info popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackAcceptTradeInAndSendToAuctionPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:accept and send to auction:customer info popup",
    "emp:customer handling details:accept and send to auction:customer info popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:accept and send to auction:customer info popup:content:${action}`,
    "fv:emp:customer handling details:accept and send to auction:customer info popup:content action",
    user
  );
}

// step 3
function trackRejectTradeInPopupLoad(orderId, user) {
  AnalyticsService.trackCustomerHandlingPageLoadDetailEvent(
    `fv:emp:customer handling details:reject trade in popup`,
    `emp:customer handling details:reject trade in popup`,
    `emp:customer handling:order id:${orderId}`,
    user
  );
}

function trackRejectTradeInPopupAction(action, orderId, user) {
  AnalyticsService.trackOnClickDetailEvent(
    "fv:emp:customer handling details:reject trade in popup",
    "emp:customer handling details:reject trade in popup",
    `emp:customer handling:order id:${orderId}`,
    orderId,
    `emp:customer handling details:reject trade in popup:content:${action}`,
    "fv:emp:customer handling details:reject trade in popup:content action",
    user
  );
}

export {
  trackRejectTradeInPopupAction,
  trackRejectTradeInPopupLoad,
  trackAcceptTradeInAndSendToAuctionPopupAction,
  trackAcceptTradeInAndSendToAuctionPopupLoad,
  trackAcceptTradeInAndKeepVehiclePopupAction,
  trackAcceptTradeInAndKeepVehiclePopupLoad,
  trackDecisionAction,
  trackOrderDetailsPageLoad,
  trackOrderDetailsPageAction,
  trackPurchaseRequestDecisionPopupLoad,
  trackAcceptPurchaseRequestPopupLoad,
  trackAcceptPurchaseRequestPopupAction,
  trackCancelPurchaseRequestPopupLoad,
  trackCancelPurchaseRequestPopupAction,
  trackDealerNotesDrawerLoad,
  trackDealerNotesDrawerAction,
  trackAcceptPurchaseDepositPopupLoad,
  trackAcceptPurchaseDepositPopupAction,
  trackRejectPurchaseDepositPopupLoad,
  trackRejectPurchaseDepositPopupAction,
  trackRefundPurchaseDepositPopupLoad,
  trackRefundPurchaseDepositPopupAction,
  trackEVPowerPromisePopupLoad,
  trackEVPowerPromisePopupAction,
  trackTimeOfSaleReportPopupLoad,
  trackTimeOfSaleReportPopupAction,
  trackDeliveryPopupLoad,
  trackDeliveryPopupAction,
  trackRequestDeliveryApptPopupLoad,
  trackRequestDeliveryApptPopupAction,
  trackConfirmDeliveryApptPopupLoad,
  trackConfirmDeliveryApptPopupAction,
  trackConfirmTradeInDetailsPopupLoad,
  trackConfirmTradeInDetailsPopupAction,
  trackTradeInDecisionPopupLoad,
  trackTradeInDecisionPopupAction,
};
