import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useReducer,
} from "react";
import { Collapse, Box, Typography } from "@mui/material";
import EMPActionCard from "../../shared/components/EMPActionCard/EMPActionCard";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import MotionPlus from "../../shared/Icons/MotionPlus";
import MotionMinus from "../../shared/Icons/MotionMinus";
import { useQueryClient } from "@tanstack/react-query";
import { ModalContext } from "../../shared/components/EMPModal/ModalContext";
import EVPowerPromise from "../../shared/components/EMPModal/ModalFragments/EVPowerPromise";
import { doesAttestationExist } from "../../shared/services/localstorage/LocalStorageService";
import {
  ACCEPT_REJECT_STATUS_CREATED,
  ACCEPT_REJECT_STATUS_ACCEPTED,
  ACCEPT_REJECT_STATUS_CANCELLED,
  PURCHASE_DEPOSIT_STATUS_NOT_SCHEDULED,
  PURCHASE_DEPOSIT_STATUS_CREATED,
  PURCHASE_DEPOSIT_STATUS_REFUND,
  PURCHASE_DEPOSIT_STATUS_REJECTED,
  DELIVERY_STATUS_NOT_SCHEDULED,
  DELIVERY_STATUS_REQUESTED,
  DELIVERY_STATUS_CONFIRMED,
  DELIVERY_STATUS_COMPLETED,
  TRADE_IN_STATUS_NOT_SCHEDULED,
  TRADE_IN_STATUS_EVALUATE,
  TRADE_IN_STATUS_COMPLETED,
  EV_POWER_PROMISE_ATTEST,
  EV_POWER_PROMISE_COMPLETED,
  CARD_STATUS_ORDER,
  ACCEPT_REJECT_STATUS_CANCELED,
  PURCHASE_DEPOSIT_STATUS_ACCEPTED,
} from "../../shared/constants/ActionCardConstants";
import getDeliveryStatus from "../../utils/DeliveryStatusUtils/GetDeliveryStatus";
import TradeInDecision from "../../shared/components/EMPModal/ModalFragments/TradeInDecision";
import EMPBlankSection from "../../shared/components/EMPBlankSection/EMPBlankSection";
import { useTranslation } from "react-i18next";
import { trackOrderDetailsPageAction } from "../../shared/services/analytics/OrderDetailsPageAnalytics";
import {
  setCanceledPurchaseRequestDisplay,
  setPurchaseDepositRefundCard,
} from "../../shared/rules/ActionCardRules";
import RefundPurchaseDeposit from "../../shared/components/EMPModal/ModalFragments/RefundPurchaseDeposit";
import PurchaseRequestDecision from "../../shared/components/EMPModal/ModalFragments/PurchaseRequestDecision";
import PurchaseDepositDecision from "../../shared/components/EMPModal/ModalFragments/PurchaseDepositDecision";
import { PURCHASE_DEPOSIT_STATUS } from "../../common/Constants";
import { UserContext } from "../../shared/context/userContext";
import { TRADE_IN_STATUS } from "../../shared/constants/TradeInConstants";
import { usePurchaseDeposit } from "../../shared/hooks/usePurchaseDeposit";

function reducer(state, action) {
  switch (action.type) {
    case "SET_ACTION_CARDS":
      return [...state].filter(
        (directive) =>
          directive.id ===
            `accept-reject-status-${action.payload.statuses[0].acceptRejectStatus}` ||
          directive.id ===
            `purchase-deposit-status-${action.payload.statuses[1].purchaseDepositStatus}` ||
          directive.id ===
            `delivery-status-${action.payload.statuses[2].deliveryStatus}` ||
          directive.id ===
            `trade-in-status-${action.payload.statuses[3].tradeInStatus}` ||
          directive.id ===
            `ev-power-promise-${action.payload.statuses[4].evPowerPromise}`
      );
    case "SORT_ACTION_CARDS":
      return [...state].sort((a, b) => {
        return CARD_STATUS_ORDER[a.status] - CARD_STATUS_ORDER[b.status];
      });
    case "UPDATE_COMPLETED_DATE":
      return state.map((card) => {
        if (
          card.id === "accept-reject-status-accepted" ||
          card.id === "accept-reject-status-canceled" ||
          card.id === "accept-reject-status-cancelled"
        ) {
          return {
            ...card,
            completedDate: action.payload[0].actionDate,
            user: action.payload[0].actionUserId,
            cancellationReason: action.payload[0].cancellationReason,
          };
        }
        if (
          card.id === "purchase-deposit-status-accepted" ||
          card.id === "purchase-deposit-status-rejected"
        ) {
          return {
            ...card,
            completedDate: action.payload[1].purchaseDepositDate,
          };
        }
        if (card.id === "delivery-status-confirmed") {
          return {
            ...card,
            text: `Delivery appointment is confirmed for ${action.payload[2].deliveryDate}`,
          };
        }
        if (card.id === "delivery-status-completed") {
          return {
            ...card,
            text: `This order was delivered to the customer on ${action.payload[2].deliveryDate}`,
            completedDate: action.payload[2].deliveryDate,
          };
        }
        if (card.id === "trade-in-status-completed") {
          return {
            ...card,
            completedDate: action.payload[3].tradeInDate,
          };
        }
        if (card.id === "ev-power-promise-completed") {
          return {
            ...card,
            completedDate: action.payload[4].evPowerPromiseDate,
          };
        }

        return card;
      });
    case "CHECK_FOR_CANCEL": {
      const canceledCard = [...state].find(
        (card) =>
          card.id === "accept-reject-status-canceled" ||
          card.id === "accept-reject-status-cancelled"
      );
      const refundCard = [...state].find(
        (card) => card.id === "purchase-deposit-status-refund"
      );
      if (canceledCard && refundCard) {
        return [canceledCard, refundCard];
      } else if (canceledCard) {
        return [canceledCard];
      } else {
        return [...state];
      }
    }
    default:
      return state;
  }
}

const ActionCardGrid = ({ orderId, handleTabChange }) => {
  const [showSecondRow, setShowSecondRow] = useState(false);
  const [collapsedSize, setCollapsedSize] = useState("240px");
  const boxRef = useRef(null);
  const collapseRef = useRef(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const purchaseDeposit = usePurchaseDeposit(orderId);

  const actionCardDirectives = [
    {
      id: ACCEPT_REJECT_STATUS_CREATED,
      status: "action-required",
      title: t("common.actions.purchaseRequest"),
      text: t("common.actions.reviewPurchaseRequest"),
      hasActionButton: true,
      buttonType: "contained",
      buttonText: t("common.buttonCtas.accept"),
    },
    {
      id: ACCEPT_REJECT_STATUS_ACCEPTED,
      status: "action-completed",
      title: t("common.actions.purchaseRequest"),
      text: t("common.actions.acceptedPurchaseRequest"),
      hasActionButton: true,
      buttonText: t("common.buttonCtas.seeDetails"),
      buttonType: "outlined",
      completedDate: null,
      user: null,
    },
    {
      id: ACCEPT_REJECT_STATUS_CANCELED,
      status: "action-completed",
      title: t("common.actions.purchaseRequest"),
      text: t("common.actions.canceledPurchaseRequest"),
      hasActionButton: false,
      completedDate: null,
      user: null,
      cancellationReason: null,
    },
    {
      id: ACCEPT_REJECT_STATUS_CANCELLED,
      status: "action-completed",
      title: t("common.actions.purchaseRequest"),
      text: t("common.actions.canceledPurchaseRequest"),
      hasActionButton: false,
      completedDate: null,
      user: null,
      cancellationReason: null,
    },
    {
      id: PURCHASE_DEPOSIT_STATUS_NOT_SCHEDULED,
      status: "action-not-available",
      title: t("common.actions.purchaseDeposit"),
      text: t("common.actions.unavailablePurchaseDeposit"),
      hasActionButton: true,
      buttonType: "outlined",
      buttonText: "View Deposit Info",
    },
    {
      id: PURCHASE_DEPOSIT_STATUS_CREATED,
      status: "action-required",
      title: t("common.actions.purchaseDeposit"),
      text: t("common.actions.reviewPurchaseDeposit"),
      hasActionButton: true,
      buttonType: "contained",
      buttonText: t("common.buttonCtas.accept"),
    },
    {
      id: PURCHASE_DEPOSIT_STATUS_ACCEPTED,
      status: "action-completed",
      title: t("common.actions.purchaseDeposit"),
      text: t("common.actions.acceptedPurchaseDeposit"),
      hasActionButton: true,
      buttonType: "outlined",
      buttonText: t("common.buttonCtas.seeDetails"),
      completedDate: null,
    },
    {
      id: PURCHASE_DEPOSIT_STATUS_REJECTED,
      status: "action-completed",
      title: t("common.actions.purchaseDeposit"),
      text: t("common.actions.rejectedPurchaseDeposit"),
      hasActionButton: true,
      buttonType: "outlined",
      buttonText: t("common.buttonCtas.seeDetails"),
      completedDate: null,
    },
    {
      id: PURCHASE_DEPOSIT_STATUS_REFUND,
      status: "action-required",
      title: "Refund Purchase Deposit",
      text: "This Purchase Request has been canceled.  Please refund the purchase deposit.",
      hasActionButton: true,
      buttonType: "contained",
      buttonText: "Refund Deposit",
    },
    {
      id: DELIVERY_STATUS_NOT_SCHEDULED,
      status: "action-not-available",
      title: t("common.actions.deliveryAppointment"),
      text: t("common.actions.visitDeliveryTab"),
      hasActionButton: true,
      buttonType: "outlined",
      buttonText: t("common.actions.seeDeliveryInfo"),
    },
    {
      id: DELIVERY_STATUS_REQUESTED,
      status: "action-required",
      title: t("common.actions.deliveryAppointment"),
      text: t("common.actions.confirmDelivery"),
      hasActionButton: true,
      buttonType: "contained",
      buttonText: t("common.buttonCtas.confirmDelivery"),
    },
    {
      id: DELIVERY_STATUS_CONFIRMED,
      status: "action-completed",
      title: t("common.actions.deliveryAppointment"),
      text: null,
      hasActionButton: true,
      buttonType: "outlined",
      buttonText: t("common.buttonCtas.seeDetails"),
      completedDate: null,
    },
    {
      id: DELIVERY_STATUS_COMPLETED,
      status: "action-completed",
      title: t("common.actions.deliveryAppointment"),
      text: t("common.actions.deliveredAppointment"),
      hasActionButton: true,
      buttonType: "outlined",
      buttonText: t("common.buttonCtas.seeDetails"),
      completedDate: null,
    },
    {
      id: TRADE_IN_STATUS_NOT_SCHEDULED,
      status: "action-not-available",
      title: t("common.actions.tradeIn"),
      text: "You can evaluate the trade-in after accepting the Purchase Request.",
      hasActionButton: true,
      buttonText: "See Trade-In Details",
      buttonType: "outlined",
    },
    {
      id: TRADE_IN_STATUS_EVALUATE,
      status: "action-required",
      title: t("common.actions.tradeIn"),
      text: t("common.actions.evaluateTradeIn"),
      hasActionButton: true,
      buttonText: t("common.buttonCtas.evaluateTradeIn"),
      buttonType: "contained",
    },
    {
      id: TRADE_IN_STATUS_COMPLETED,
      status: "action-completed",
      title: t("common.actions.tradeIn"),
      text: t("common.actions.evaluatedTradeIn"),
      hasActionButton: true,
      buttonText: t("common.buttonCtas.seeDetails"),
      buttonType: "outlined",
      completedDate: null,
    },
    {
      id: EV_POWER_PROMISE_ATTEST,
      status: "action-required",
      title: t("common.actions.powerPromise"),
      text: "You must enroll your customer via SmartVincent.",
      hasActionButton: true,
      buttonText: t("common.buttonCtas.noteAsCompleted"),
      buttonType: "contained",
    },
    {
      id: EV_POWER_PROMISE_COMPLETED,
      status: "action-completed",
      title: t("common.actions.powerPromise"),
      text: t("common.actions.completedPowerPromise"),
      hasActionButton: false,
      buttonText: "",
      completedDate: null,
    },
  ];

  const [state, dispatch] = useReducer(reducer, actionCardDirectives);
  const { customerReservation, tradeIn } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);

  const { deliveryAppointment } = queryClient.getQueryData([
    "deliveryModule",
    { orderId },
  ]);

  const attestationData = localStorage.getItem("attestation");
  const { handleModal } = useContext(ModalContext);

  const toggleSecondRow = () => {
    setShowSecondRow((prev) => !prev);
    trackOrderDetailsPageAction(
      deliveryAppointment,
      showSecondRow ? "hide all action items" : "show all action items",
      orderId,
      user
    );
  };

  useEffect(() => {
    if (boxRef.current) {
      setCollapsedSize(`${boxRef.current.clientHeight}px`);
    }
  }, []);

  useEffect(() => {
    if (!showSecondRow && collapseRef.current) {
      collapseRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [showSecondRow]);

  const isTradeInStatusValid = (tradeIn) => {
    const tradeInOptIn = customerReservation?.dealerTradeInOptIn;
    const validStatuses = [
      TRADE_IN_STATUS.ACCEPT_KEEP,
      TRADE_IN_STATUS.ACCEPT_AUCTION,
      TRADE_IN_STATUS.REJECTED,
    ];
    return tradeIn && tradeInOptIn && validStatuses.includes(tradeIn.status);
  };

  const statuses = [
    {
      acceptRejectStatus:
        customerReservation.purchaseInfo?.acceptRejectStatus || null,
      actionDate: customerReservation.purchaseInfo?.purchaseRequestDealerAction
        ?.dealerActionDate
        ? new Date(
            customerReservation.purchaseInfo.purchaseRequestDealerAction.dealerActionDate
          ).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : null,
      actionUserId:
        customerReservation.purchaseInfo?.purchaseRequestDealerAction
          ?.dealerActionUserId || null,
      cancellationReason:
        customerReservation.purchaseInfo?.purchaseRequestDealerAction
          ?.dealerRejectedReason,
    },
    {
      purchaseDepositStatus:
        customerReservation.purchaseInfo?.acceptRejectStatus !== "accepted" &&
        customerReservation.purchaseInfo?.acceptRejectStatus !== "canceled" &&
        customerReservation.purchaseDeposit &&
        customerReservation.purchaseDeposit?.acceptRejectStatus === null &&
        customerReservation.purchaseDeposit?.depositAmount !== null
          ? "not-scheduled"
          : customerReservation.purchaseDeposit?.acceptRejectStatus ===
              PURCHASE_DEPOSIT_STATUS.ACCEPTED &&
            customerReservation.purchaseDeposit?.refundStatus === null &&
            customerReservation.purchaseInfo?.acceptRejectStatus !== "canceled"
          ? "accepted"
          : customerReservation.purchaseInfo?.acceptRejectStatus ===
              "accepted" &&
            customerReservation.purchaseDeposit &&
            customerReservation.purchaseDeposit?.depositAmount !== null &&
            customerReservation.purchaseDeposit?.refundStatus !==
              PURCHASE_DEPOSIT_STATUS.REFUNDED &&
            ![
              PURCHASE_DEPOSIT_STATUS.DECLINED,
              PURCHASE_DEPOSIT_STATUS.REJECTED,
            ].includes(customerReservation.purchaseDeposit?.acceptRejectStatus)
          ? "created"
          : customerReservation.purchaseDeposit &&
            customerReservation.purchaseDeposit?.acceptRejectStatus ===
              PURCHASE_DEPOSIT_STATUS.DECLINED
          ? null
          : customerReservation.purchaseDeposit &&
            customerReservation.purchaseDeposit?.acceptRejectStatus ===
              PURCHASE_DEPOSIT_STATUS.REJECTED
          ? "rejected"
          : setPurchaseDepositRefundCard(
              purchaseDeposit,
              customerReservation.purchaseInfo
            ),
      purchaseDepositDate: customerReservation.purchaseDeposit?.acceptRejectDate
        ? new Date(
            customerReservation.purchaseDeposit.acceptRejectDate
          ).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : null,
    },
    {
      deliveryStatus: getDeliveryStatus(deliveryAppointment).status,
      deliveryDate: deliveryAppointment?.date
        ? new Date(deliveryAppointment?.date).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : null,
    },
    {
      tradeInStatus:
        tradeIn !== null &&
        !isTradeInStatusValid(tradeIn) &&
        customerReservation.purchaseInfo?.acceptRejectStatus !== "accepted"
          ? "not-scheduled"
          : tradeIn !== null &&
            !isTradeInStatusValid(tradeIn) &&
            customerReservation.purchaseInfo?.acceptRejectStatus === "accepted"
          ? "evaluate"
          : isTradeInStatusValid(tradeIn)
          ? "completed"
          : null,
      tradeInDate: tradeIn?.actionDateNA
        ? new Date(tradeIn.actionDateNA).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : null,
    },
    {
      evPowerPromise: doesAttestationExist(orderId, "ev-power-promise")
        ? "completed"
        : "attest",
      evPowerPromiseDate:
        doesAttestationExist(orderId, "ev-power-promise") && attestationData
          ? JSON.parse(attestationData)[orderId]["ev-power-promise"].date
          : null,
    },
  ];

  const getHandleClickFunction = (status, orderId) => {
    switch (status) {
      case ACCEPT_REJECT_STATUS_ACCEPTED:
        return () => {
          handleTabChange(2, "completed:purchase request accepted:see details");
        };
      case ACCEPT_REJECT_STATUS_CREATED:
        return () => {
          handleModal(
            t("common.actions.purchaseRequest"),
            <PurchaseRequestDecision orderId={orderId} />
          );
        };
      case PURCHASE_DEPOSIT_STATUS_NOT_SCHEDULED:
        return () => {
          handleTabChange(
            3,
            "not available yet:purchase deposit:view deposit info"
          );
        };
      case PURCHASE_DEPOSIT_STATUS_CREATED:
        return () =>
          handleModal(
            t("common.actions.purchaseDeposit"),
            <PurchaseDepositDecision orderId={orderId} />
          );
      case PURCHASE_DEPOSIT_STATUS_REFUND:
        return () =>
          handleModal(
            t("common.buttonCtas.refundPurchaseDeposit"),
            <RefundPurchaseDeposit orderId={orderId} />
          );
      case PURCHASE_DEPOSIT_STATUS_ACCEPTED:
        return () =>
          handleTabChange(3, "completed:purchase deposit accepted:see details");
      case PURCHASE_DEPOSIT_STATUS_REJECTED:
        return () =>
          handleTabChange(3, "completed:purchase deposit rejected:see details");
      case TRADE_IN_STATUS_NOT_SCHEDULED:
        return () =>
          handleTabChange(4, "not available yet:trade in:see details");
      case TRADE_IN_STATUS_EVALUATE:
        return () => {
          handleModal(
            t("common.actions.confirmTradeInDetails"),
            <TradeInDecision orderId={orderId} />
          );
          trackOrderDetailsPageAction(
            deliveryAppointment,
            "review trade in",
            orderId,
            user
          );
        };
      case TRADE_IN_STATUS_COMPLETED:
        return () => handleTabChange(4, "completed:trade in:see details");
      case "ev-power-promise-attest":
        return () =>
          handleModal(
            t("common.actions.powerPromise"),
            <EVPowerPromise orderId={orderId} />
          );
      case "delivery-status-not-scheduled":
      case "delivery-status-requested":
      case "delivery-status-confirmed":
      case "delivery-status-completed":
        return () =>
          handleTabChange(
            5,
            `${status.replace(
              /-/g,
              " "
            )}:delivery appointment:see delivery information`
          );
      default:
        return () => handleModal("Default", "Default");
    }
  };

  useEffect(() => {
    dispatch({ type: "SET_ACTION_CARDS", payload: { statuses } });
    dispatch({ type: "UPDATE_COMPLETED_DATE", payload: statuses });
    dispatch({ type: "CHECK_FOR_CANCEL" });
    dispatch({ type: "SORT_ACTION_CARDS" });
  }, []);

  const getHeightBasedOnViewport = () => {
    const viewportWidth = window.innerWidth;
    return viewportWidth < 1440 ? "228px" : "218px"; // Example height values
  };

  return state && state.length > 0 ? (
    <>
      {setCanceledPurchaseRequestDisplay(state) ? (
        <EMPBlankSection
          message1={t("common.actions.canceledPurchaseRequestMessage", {
            user: state[0].user,
            completedDate: state[0].completedDate,
            interpolation: { escapeValue: false },
          })}
          message2={t("common.actions.dynamicCancellationReason", {
            cancellationReason: state[0].cancellationReason,
          })}
        />
      ) : (
        <Collapse
          in={showSecondRow}
          collapsedSize={collapsedSize}
          sx={{
            height: state.length > 3 ? getHeightBasedOnViewport : "auto",
            overflow: showSecondRow ? "scroll" : "hidden",
          }}
          ref={collapseRef}
        >
          <Box
            ref={boxRef}
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={4}
            sx={{ mb: 4 }}
          >
            {state.slice(0, 3).map((card) => {
              return (
                <Box
                  key={card.id}
                  gridColumn={{
                    xs: "span 6",
                    sm: "span 6",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  id="action-card"
                >
                  <EMPActionCard
                    status={card.status}
                    hasActionButton={card.hasActionButton}
                    title={card.title}
                    text={card.text}
                    buttonText={card.buttonText}
                    buttonType={card.buttonType}
                    onClick={getHandleClickFunction(card.id, orderId)}
                    completedDate={card.completedDate}
                  ></EMPActionCard>
                </Box>
              );
            })}
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={4}
            sx={{ mb: 4 }}
          >
            {state.slice(3).map((card) => (
              <Box
                gridColumn={{
                  xs: "span 6",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                }}
                key={card.id}
                sx={{
                  height: "auto",
                }}
              >
                <EMPActionCard
                  status={card.status}
                  hasActionButton={card.hasActionButton}
                  title={card.title}
                  text={card.text}
                  buttonText={card.buttonText}
                  buttonType={card.buttonType}
                  onClick={getHandleClickFunction(card.id, orderId)}
                  completedDate={card.completedDate}
                ></EMPActionCard>
              </Box>
            ))}
          </Box>
        </Collapse>
      )}

      {state.length > 3 && (
        <Box sx={{ mt: 2 }}>
          <EMPButton
            onClick={toggleSecondRow}
            variant="text"
            rightIcon={showSecondRow ? <MotionMinus /> : <MotionPlus />}
          >
            {showSecondRow
              ? t("common.actionStatus.hideShow", { context: "hide" })
              : t("common.actionStatus.hideShow", { context: "show" })}
          </EMPButton>
        </Box>
      )}
    </>
  ) : (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={4}
      sx={{
        mb: 4,
        background: "linear-gradient(135deg, #F5F6F7 0%, #FFFFFF 100%)",
        py: 8,
      }}
    >
      <Box gridColumn="span 4" sx={{ gridColumnStart: 5, gridColumnEnd: 9 }}>
        <Typography variant="body2" align="center">
          {t("common.actionStatus.noActionsRequired")}
        </Typography>
      </Box>
    </Box>
  );
};

export default ActionCardGrid;
