export const TRADE_IN_STATUS = {
  ACCEPT_KEEP: "tradeinacceptandkeep",
  ACCEPT_AUCTION: "tradeinacceptandauction",
  REJECTED: "tradeinrejected",
};

export const TRADE_IN_OWNERSHIP_TYPES = {
  OWNED: "OWNED",
  FINANCED: "FINANCED",
  LEASED: "LEASED",
};
