import { Typography, Grid } from "@mui/material";
import React from "react";
import styles from "./DashboardCards-Ford.module.scss";

export const SubTitle = (props) => {
  const { data, type } = props;

  return (
    <Grid container justifyContent="center" className={styles.subTitle}>
      {type !== "accessories" && data && data.hasOwnProperty("addedToday") && (
        <Grid item>
          <Typography
            variant="subtitle2"
            component="p"
            sx={{ mt: 1, textAlign: "center" }}
          >
            <span>{data.addedToday} </span>
            {data.addedToday !== null
              ? props.t("DealerDashboard.addedToday")
              : props.t("DealerDashboard.pleaseRefresh")}
          </Typography>
        </Grid>
      )}

      {type !== "accessories" && data && data.hasOwnProperty("active") && (
        <Grid item>
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            <span>{data.active} </span>
            {props.t("DealerDashboard.active")}
          </Typography>
        </Grid>
      )}

      {type !== "accessories" &&
        data &&
        data.hasOwnProperty("vehicleHolds") && (
          <Grid item>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              <span>{data.vehicleHolds} </span>
              {data.vehicleHolds === 1
                ? props.t("DealerDashboard.vehicleHold")
                : props.t("DealerDashboard.vehicleHolds")}
            </Typography>
          </Grid>
        )}
      {type !== "accessories" && data && data.hasOwnProperty("total") && (
        <Grid item>
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            <span>{data.total}</span>
            {props.t("DealerDashboard.total")}
          </Typography>
        </Grid>
      )}
      {type === "accessories" && data && data.hasOwnProperty("total") && (
        <Grid item>
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            <span>{data.total}</span>
            {data.total === 1
              ? props.t("DealerDashboard.accessoriesSubTitleSingular")
              : props.t("DealerDashboard.accessoriesSubTitlePlural")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
