import React, { Component } from "react";
import { Accordion, AccordionSummary, Grid } from "@mui/material";
import TranslationSpecific from "./TranslationSpecific";
import styles from "../translations/Translation.module.scss";
import AddNewTranslation from "./AddNewTranslation";
import TranslationsClient from "../../clients/TranslationsClient";
import { LANCODES } from "../../common/Constants";
import crStyles from "../customerHandling/crStyles";
import EMPButton from "../../shared/components/EMPButton/EMPButton";

class TranslationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewTranslation: false,
      showInput: true,
    };
  }

  onClick = () => {
    this.setState({ showNewTranslation: true });
    this.setState({ showInput: false });
  };

  cancelSpecificTranslation = () => {
    this.setState({ showNewTranslation: false });
    this.setState({ showInput: true });
  };

  addSpecificTranslation = (updatedLanguage) => {
    this.props.hideOrShow(true);
    TranslationsClient.postAddTranslations(
      updatedLanguage,
      this.props.getMarketsForNewTranslations(),
      this.props.token
    )
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        this.setState({ showInput: true });
        this.setState({ showNewTranslation: false });
        this.props.hideOrShow(false);
        this.props.getTranslations(this.props.currentLan);
      });
  };

  render() {
    return (
      <div>
        <br />
        <Accordion styles={crStyles.expansionPanel} defaultExpanded={true}>
          <AccordionSummary style={crStyles.headerBar}>
            <div className={styles.moduleKey}>
              {this.props.translations.moduleKey}
            </div>

            <br />
          </AccordionSummary>
          <Grid container className={styles.translation_header}>
            <Grid item md={3} className={styles.lanHeader}>
              {this.props.t("Translations.key")}
            </Grid>
            <Grid item md={3} className={styles.lanHeader}>
              {LANCODES["en"]}
            </Grid>
            <Grid item md={3} className={styles.lanHeader}>
              {LANCODES[this.props.currentLan]}
            </Grid>
            <Grid item md={3} className={styles.lanHeader}>
              {this.props.t("Translations.action")}
            </Grid>
          </Grid>

          <div>
            {Object.keys(this.props.translations.languages).length !== 0
              ? Object.keys(this.props.translations.languages).map((lan) => {
                  return (
                    <TranslationSpecific
                      {...this.props}
                      language={this.props.translations.languages[lan]}
                      moduleKey={this.props.translations.moduleKey}
                      key={this.props.translations.languages[lan].key}
                    />
                  );
                })
              : null}
          </div>

          <div className={styles.translation_header}>
            <div
              style={{
                position: "relative",
                float: "left",
                paddingBottom: "15px",
              }}
            >
              {this.state.showInput ? (
                <EMPButton variant="text" onClick={this.onClick}>
                  {this.props.t("Translations.addNewTrans")}
                </EMPButton>
              ) : null}
            </div>
          </div>
          {this.state.showNewTranslation ? (
            <AddNewTranslation
              {...this.props}
              moduleKey={this.props.translations.moduleKey}
              cancelSpecificTranslation={this.cancelSpecificTranslation}
              addSpecificTranslation={this.addSpecificTranslation}
            />
          ) : null}
        </Accordion>
      </div>
    );
  }
}

export default TranslationTable;
