import React, { Component } from "react";
import { Grid, TableCell, Table, TableBody, TableRow } from "@mui/material";
import fordStyles from "./TradeIn.module.scss";
import lincolnStyles from "./TradeInLincoln.module.scss";
import { FINANCED, LEASED, OWNED } from "../../common/Constants";
import DetailsConfirmationDialog from "./dialogs/detailsConfirmation/DetailsConfirmationDialog";
import { withTranslation } from "react-i18next";
import SharedToolTip from "../../shared/sharedToolTip/SharedToolTip";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";
import DataCard from "../../shared/components/DataCard/DataCard";
import KeyValueField from "../../shared/components/DataFields/KeyValueField";
import UpperCaseText from "../../utils/UpperCaseText";

class USTradeIn extends Component {
  constructor(props) {
    super(props);
    this.currency = this.props.localizedInfo.defaultCurrency.symbol;
  }

  formatAppraisalAmount = (appraisalAmount) => {
    return appraisalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  render() {
    const showDecision = this.props.tradeInDetails.dealerSubmitted;
    const decisionType = this.props.tradeInDetails.status;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;
    const OwnedPricingTable = ({ appraisalAmount }) => {
      return (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className={styles.pricingLabelTotal}>
                  {this.props.isModelEOrder
                    ? this.props.t("TradeIn.ModelE.appraisalAmount")
                    : this.props.t("TradeIn.US.appraisalAmount")}
                  <SharedToolTip
                    {...this.props}
                    openTestid="tradeInAppraisalTooltipOpen"
                    closeTestid="tradeInAppraisalTooltipClose"
                    body={this.props.t(
                      "OrderDetailsConsumerDeposit.tradeInAppraisalTooltipBody"
                    )}
                  />
                </span>
              </TableCell>
              <TableCell>
                <span className={styles.pricingValueTotal}>
                  {this.currency}
                  {(
                    <>
                      {appraisalAmount &&
                        this.formatAppraisalAmount(appraisalAmount).concat(
                          ".00"
                        )}
                      <SharedToolTip
                        {...this.props}
                        openTestid="tradeInAppraisalAmountTooltipOpen"
                        closeTestid="tradeInAppraisalAmountTooltipClose"
                        body={this.props.t(
                          "OrderDetailsConsumerDeposit.tradeInAppraisalAmountTooltipBody"
                        )}
                      />
                    </>
                  ) || (
                    <>
                      {this.props.t("TradeIn.US.zeroValue")}
                      <SharedToolTip
                        {...this.props}
                        openTestid="tradeInZeroDollarTooltipOpen"
                        closeTestid="tradeInZeroDollarTooltipClose"
                        body={
                          this.props.isModelEOrder
                            ? this.props.t(
                                "OrderDetailsConsumerDeposit.ModelETradeInZeroDollarTooltipBody"
                              )
                            : this.props.t(
                                "OrderDetailsConsumerDeposit.tradeInZeroDollarTooltipBody"
                              )
                        }
                      />
                    </>
                  )}
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    };

    const FinancedPricingTable = ({
      appraisalAmount,
      enteredBalance,
      remainingBalance,
      netTradeInAppraisal,
    }) => {
      return (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className={styles.pricingLabel}>
                  {this.props.isModelEOrder
                    ? this.props.t("TradeIn.ModelE.tradeInAppraisal")
                    : this.props.t("TradeIn.US.tradeInAppraisal")}
                  <SharedToolTip
                    {...this.props}
                    openTestid="tradeInAppraisalTooltipOpen"
                    closeTestid="tradeInAppraisalTooltipClose"
                    body={this.props.t(
                      "OrderDetailsConsumerDeposit.tradeInAppraisalTooltipBody"
                    )}
                  />
                </span>
              </TableCell>
              <TableCell>
                <span className={styles.pricingValue}>
                  {this.currency}
                  {appraisalAmount &&
                    this.formatAppraisalAmount(appraisalAmount)}
                </span>
              </TableCell>
            </TableRow>
            {enteredBalance ? (
              <TableRow>
                <TableCell>
                  <span className={styles.pricingLabel}>
                    {this.props.t("TradeIn.US.enteredBalance")}
                    <SharedToolTip
                      {...this.props}
                      openTestid="tradeInRemainingBalanceEnteredOpen"
                      closeTestid="tradeInRemainingBalanceEnteredClose"
                      body={this.props.t(
                        "OrderDetailsConsumerDeposit.tradeInRemainingBalanceEnteredTooltipBody"
                      )}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  <span className={styles.pricingValue}>
                    -{this.currency}
                    {enteredBalance &&
                      this.formatAppraisalAmount(enteredBalance)}
                  </span>
                </TableCell>
              </TableRow>
            ) : (
              remainingBalance && (
                <TableRow>
                  <TableCell>
                    <span className={styles.pricingLabel}>
                      {this.props.t("TradeIn.US.remainingBalance")}
                      <SharedToolTip
                        {...this.props}
                        openTestid="tradeInRemainingBalanceQuotedOpen"
                        closeTestid="tradeInRemainingBalanceQuotedClose"
                        body={this.props.t(
                          "OrderDetailsConsumerDeposit.tradeInRemainingBalanceQuotedTooltipBody"
                        )}
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={styles.pricingValue}>
                      -{this.currency}
                      {remainingBalance &&
                        this.formatAppraisalAmount(remainingBalance)}
                    </span>
                  </TableCell>
                </TableRow>
              )
            )}
            <TableRow>
              <TableCell>
                <span className={styles.pricingLabelTotal}>
                  {this.props.t("TradeIn.US.netTradeInAppraisal")}
                  <SharedToolTip
                    {...this.props}
                    openTestid="tradeInNetEquityOpen"
                    closeTestid="tradeInNetEquityClose"
                    body={
                      this.props.isModelEOrder
                        ? this.props.t(
                            "OrderDetailsConsumerDeposit.ModelETradeInNetEquityTooltipBody"
                          )
                        : this.props.t(
                            "OrderDetailsConsumerDeposit.tradeInNetEquityTooltipBody"
                          )
                    }
                  />
                </span>
              </TableCell>
              <TableCell>
                <span className={styles.pricingValueTotal}>
                  {this.currency}
                  {netTradeInAppraisal &&
                    this.formatAppraisalAmount(netTradeInAppraisal)}
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    };

    const LeasedPricingTable = ({
      totalOutstandingBalanceAmount,
      enteredObligation,
    }) => {
      return (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className={styles.pricingLabelTotal}>
                  {this.props.t("TradeIn.US.leaseObligations")}
                  {totalOutstandingBalanceAmount &&
                    this.props.t("TradeIn.US.quoted")}
                  {totalOutstandingBalanceAmount && (
                    <SharedToolTip
                      {...this.props}
                      openTestid="tradeInObligationQuotedOpen"
                      closeTestid="tradeInObligationQuotedClose"
                      body={this.props.t(
                        "OrderDetailsConsumerDeposit.tradeInObligationQuotedTooltipBody"
                      )}
                    />
                  )}
                  {enteredObligation &&
                    this.props.t("TradeIn.US.enteredByCustomer")}
                  {enteredObligation && (
                    <SharedToolTip
                      {...this.props}
                      openTestid="tradeInObligationEnteredOpen"
                      closeTestid="tradeInObligationEnteredClose"
                      body={this.props.t(
                        "OrderDetailsConsumerDeposit.tradeInObligationEnteredTooltipBody"
                      )}
                    />
                  )}
                </span>
              </TableCell>
              <TableCell>
                <span className={styles.pricingValueTotal}>
                  {this.currency}
                  {totalOutstandingBalanceAmount &&
                    this.formatAppraisalAmount(totalOutstandingBalanceAmount)}
                  {enteredObligation &&
                    this.formatAppraisalAmount(enteredObligation)}
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    };
    const DecisionItem = ({ label, value, tooltip }) => {
      return (
        <div className={styles.decisionItem}>
          <span className={styles.label}>
            {label}
            {tooltip}
          </span>
          <span className={styles.value}>{value}</span>
        </div>
      );
    };

    const AcceptAndKeepDecision = () => {
      return (
        <>
          <DecisionItem
            label={this.props.t("TradeIn.US.decision.acceptAndKeep.status")}
            value={this.props.t("TradeIn.US.acceptKeepStatus")}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="tradeInStatusOpen"
                closeTestid="tradeInStatusClose"
                body={this.props.t(
                  "OrderDetailsConsumerDeposit.tradeInStatusTooltipBody"
                )}
              />
            }
          />
          <DecisionItem
            label={
              !IS_FORD_BRAND(this.props.user.brand)
                ? this.props.t(
                    "TradeIn.US.decision.acceptAndKeep.CDSID_Lincoln"
                  )
                : this.props.t("TradeIn.US.decision.acceptAndKeep.CDSID")
            }
            value={this.props.tradeInDetails.userId}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="tradeInCdsidOpen"
                closeTestid="tradeInCdsidClose"
                body={this.props.t(
                  "OrderDetailsConsumerDeposit.tradeInCdsidTooltipBody"
                )}
              />
            }
          />
          <DecisionItem
            label={this.props.t(
              "TradeIn.US.decision.acceptAndKeep.dateVehicleSeen"
            )}
            value={this.props.tradeInDetails.actionDateNA}
          />
        </>
      );
    };

    const AcceptAndSubmitDecision = () => {
      return (
        <>
          <DecisionItem
            label={this.props.t("TradeIn.US.decision.acceptAndSubmit.status")}
            value={this.props.t("TradeIn.US.acceptSubmitStatus")}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="tradeInStatusOpen"
                closeTestid="tradeInStatusClose"
                body={this.props.t(
                  "OrderDetailsConsumerDeposit.tradeInStatusTooltipBody"
                )}
              />
            }
          />
          <DecisionItem
            label={
              !IS_FORD_BRAND(this.props.user.brand)
                ? this.props.t(
                    "TradeIn.US.decision.acceptAndSubmit.CDSID_Lincoln"
                  )
                : this.props.t("TradeIn.US.decision.acceptAndSubmit.CDSID")
            }
            value={this.props.tradeInDetails.userId}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="tradeInCdsidOpen"
                closeTestid="tradeInCdsidClose"
                body={this.props.t(
                  "OrderDetailsConsumerDeposit.tradeInCdsidTooltipBody"
                )}
              />
            }
          />
          <DecisionItem
            label={this.props.t(
              "TradeIn.US.decision.acceptAndKeep.dateVehicleSeen"
            )}
            value={this.props.tradeInDetails.acceptedDateNA}
          />
        </>
      );
    };

    const RejectDecision = () => {
      return (
        <>
          <DecisionItem
            label={this.props.t("TradeIn.US.decision.reject.status")}
            value={this.props.t("TradeIn.US.rejectStatus")}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="tradeInStatusOpen"
                closeTestid="tradeInStatusClose"
                body={this.props.t(
                  "OrderDetailsConsumerDeposit.tradeInStatusTooltipBody"
                )}
              />
            }
          />
          <DecisionItem
            label={
              !IS_FORD_BRAND(this.props.user.brand)
                ? this.props.t("TradeIn.US.decision.reject.CDSID_Lincoln")
                : this.props.t("TradeIn.US.decision.reject.CDSID")
            }
            value={this.props.tradeInDetails.userId}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="tradeInCdsidOpen"
                closeTestid="tradeInCdsidClose"
                body={this.props.t(
                  "OrderDetailsConsumerDeposit.tradeInCdsidTooltipBody"
                )}
              />
            }
          />
          <DecisionItem
            label={this.props.t("TradeIn.US.decision.reject.rejectionDate")}
            value={this.props.tradeInDetails.actionDateNA}
          />
          <DecisionItem
            label={this.props.t("TradeIn.US.decision.reject.dateVehicleSeen")}
            value={this.props.tradeInDetails.acceptedDateNA}
          />
          <DecisionItem
            label={this.props.t(
              "TradeIn.US.decision.reject.reasonForRejecting"
            )}
            value={this.props.tradeInDetails.rejectedReason}
          />
        </>
      );
    };

    return (
      <>
        {UpperCaseText(this.props.tradeInType) === OWNED && (
          <OwnedPricingTable
            appraisalAmount={this.props.tradeInDetails.appraisalAmount}
          />
        )}
        {UpperCaseText(this.props.tradeInType) === FINANCED && (
          <FinancedPricingTable
            appraisalAmount={this.props.tradeInDetails.appraisalAmount}
            enteredBalance={this.props.tradeInDetails.enteredBalance}
            remainingBalance={this.props.tradeInDetails.remainingBalance}
            netTradeInAppraisal={this.props.tradeInDetails.netEquity}
          />
        )}
        {UpperCaseText(this.props.tradeInType) === LEASED && (
          <LeasedPricingTable
            totalOutstandingBalanceAmount={
              this.props.tradeInDetails.totalOutstandingBalanceAmount
            }
            enteredObligation={this.props.tradeInDetails.enteredObligation}
          />
        )}

        <div className={styles.tradeInWrapper}>
          <Grid container spacing={2}>
            <Grid item className={styles.cardGridItemWrapper}>
              <DataCard cardHeader={this.props.t("TradeIn.US.vehicleDetails")}>
                <KeyValueField
                  dataKey={this.props.t("TradeIn.US.VIN")}
                  dataValue={this.props.tradeInDetails.vin}
                ></KeyValueField>
                <KeyValueField
                  dataKey={this.props.t("TradeIn.US.modelYear")}
                  dataValue={this.props.tradeInDetails.modelYear}
                ></KeyValueField>
                <KeyValueField
                  dataKey={this.props.t("TradeIn.US.make")}
                  dataValue={this.props.tradeInDetails.vehicleMake}
                ></KeyValueField>
                <KeyValueField
                  dataKey={this.props.t("TradeIn.US.model")}
                  dataValue={this.props.tradeInDetails.vehicleModel}
                ></KeyValueField>
              </DataCard>
            </Grid>

            <Grid item className={styles.cardGridItemWrapper}>
              <DataCard
                cardHeader={this.props.t("TradeIn.US.tradeInStatus")}
                hasKeyToolTip={this.props.isModelEOrder}
                keyToolTipBody={this.props.t(
                  "OrderDetailsConsumerDeposit.tradeInConditionDetailsTooltipBody"
                )}
                {...this.props}
              >
                {!this.props.tradeInType ||
                  (this.props.tradeInType !== LEASED && (
                    <>
                      <KeyValueField
                        dataKey={this.props.t("TradeIn.US.conditionRating")}
                        dataValue={
                          this.props.tradeInDetails.conditionRating &&
                          !this.props.tradeInDetails.conditionRating.includes(
                            "."
                          )
                            ? this.props.tradeInDetails.conditionRating.concat(
                                ".0"
                              )
                            : this.props.tradeInDetails.conditionRating
                        }
                        hasKeyToolTip={!this.props.isModelEOrder}
                        keyToolTipBody={this.props.t(
                          "OrderDetailsConsumerDeposit.tradeInConditionDetailsTooltipBody"
                        )}
                        {...this.props}
                      ></KeyValueField>
                      <KeyValueField
                        dataKey={this.props.t("TradeIn.US.mileage")}
                        dataValue={this.props.tradeInDetails.mileage}
                        hasKeyToolTip={!this.props.isModelEOrder}
                        keyToolTipBody={this.props.t(
                          "OrderDetailsConsumerDeposit.tradeInMileageTooltipBody"
                        )}
                        {...this.props}
                      ></KeyValueField>
                    </>
                  ))}
                {!this.props.isModelEOrder && (
                  <>
                    <KeyValueField
                      dataKey={this.props.t("TradeIn.US.offerExpiresLabel")}
                      dataValue={this.props.tradeInDetails.expiryDateNA}
                      hasKeyToolTip
                      keyToolTipBody={this.props.t(
                        "TradeIn.US.offerExpiresTooltipBodyLease"
                      )}
                      {...this.props}
                    ></KeyValueField>
                    <KeyValueField
                      dataKey={this.props.t("TradeIn.US.offerStatus")}
                      dataValue={
                        this.props.tradeInDetails.tradeInExpired
                          ? this.props.t("TradeIn.US.expired")
                          : this.props.t("TradeIn.US.notExpired")
                      }
                    ></KeyValueField>
                  </>
                )}
              </DataCard>
            </Grid>

            {!this.props.tradeInType ||
              (this.props.tradeInType !== LEASED && (
                <Grid item className={styles.cardGridItemWrapper}>
                  <DataCard
                    cardHeader={this.props.t("TradeIn.US.conditionDetails")}
                    hasKeyToolTip
                    keyToolTipBody={this.props.t(
                      "OrderDetailsConsumerDeposit.tradeInConditionDetailsTooltipBody"
                    )}
                    {...this.props}
                  >
                    <KeyValueField
                      dataKey={this.props.t("TradeIn.US.vehicleCondition")}
                      dataValue={this.props.tradeInDetails.overallCondition}
                    ></KeyValueField>
                    <KeyValueField
                      dataKey={this.props.t("TradeIn.US.accident")}
                      dataValue={
                        this.props.tradeInDetails.hasAccidents === "true"
                          ? this.props.t("GeneralEMP.statusYes")
                          : this.props.t("GeneralEMP.statusNo")
                      }
                    ></KeyValueField>
                    <KeyValueField
                      dataKey={this.props.t("TradeIn.US.majorDamage")}
                      dataValue={
                        this.props.tradeInDetails.hasMajorDamage === "true"
                          ? this.props.t("GeneralEMP.statusYes")
                          : this.props.t("GeneralEMP.statusNo")
                      }
                    ></KeyValueField>
                    <KeyValueField
                      dataKey={this.props.t("TradeIn.US.smell")}
                      dataValue={
                        this.props.tradeInDetails.hasOdors === "true"
                          ? this.props.t("GeneralEMP.statusYes")
                          : this.props.t("GeneralEMP.statusNo")
                      }
                    ></KeyValueField>
                    <KeyValueField
                      dataKey={this.props.t("TradeIn.US.mechanicalDefects")}
                      dataValue={
                        this.props.tradeInDetails.hasMechanicalDefects ===
                        "true"
                          ? this.props.t("GeneralEMP.statusYes")
                          : this.props.t("GeneralEMP.statusNo")
                      }
                    ></KeyValueField>
                    <KeyValueField
                      dataKey={this.props.t("TradeIn.US.dashboardIllumination")}
                      dataValue={
                        this.props.tradeInDetails
                          .hasIlluminatedWarningLights === "true"
                          ? this.props.t("GeneralEMP.statusYes")
                          : this.props.t("GeneralEMP.statusNo")
                      }
                    ></KeyValueField>
                    <KeyValueField
                      dataKey={this.props.t("TradeIn.US.minorDamage")}
                      dataValue={
                        this.props.tradeInDetails.hasMinorDamage === "true"
                          ? this.props.t("GeneralEMP.statusYes")
                          : this.props.t("GeneralEMP.statusNo")
                      }
                    ></KeyValueField>
                    {this.props.tradeInType !== FINANCED &&
                      this.props.tradeInType !== OWNED && (
                        <>
                          <KeyValueField
                            dataKey={this.props.t(
                              "TradeIn.US.maintenanceRecord"
                            )}
                            dataValue={
                              this.props.tradeInDetails
                                .hasMaintenanceHistory === "true"
                                ? this.props.t("GeneralEMP.statusYes")
                                : this.props.t("GeneralEMP.statusNo")
                            }
                          ></KeyValueField>
                          <KeyValueField
                            dataKey={this.props.t("TradeIn.US.rental")}
                            dataValue={
                              this.props.tradeInDetails.hasProvidedServices ===
                              true
                                ? this.props.t("GeneralEMP.statusYes")
                                : this.props.t("GeneralEMP.statusNo")
                            }
                          ></KeyValueField>
                        </>
                      )}
                  </DataCard>
                </Grid>
              ))}
          </Grid>
        </div>

        {showDecision && (
          <div className={styles.decisionWrapper}>
            <hr className={styles.borderSeparator} />
            <span className={styles.subHeader}>
              {this.props.t("TradeIn.US.decision.title")}
            </span>
            {decisionType === "tradeinrejected" && <RejectDecision />}
            {decisionType === "tradeinacceptandkeep" && (
              <AcceptAndKeepDecision />
            )}
            {decisionType === "tradeinacceptandauction" && (
              <AcceptAndSubmitDecision />
            )}
          </div>
        )}
        <DetailsConfirmationDialog
          {...this.props}
          open={this.props.openConfirmationDialog}
          onClose={(e) => this.props.closeConfirmationDialog(e)}
        />
      </>
    );
  }
}

export default withTranslation("emp")(USTradeIn);
