import React from "react";
import { NativeSelect } from "@mui/material";
import MotionChevronDown from "../../Icons/MotionChevronDown.js";

// available props
// testId: string
// name: string
// menuItems: array of objects with shape { id: string, value: string, text: string, disabled: boolean }
// value: string
// disabled: boolean
// onChange: function

const EMPSelectField = React.forwardRef((props, ref) => {
  const { testId, name, menuItems, value, disabled, onChange, defaultValue } =
    props;
  return (
    <NativeSelect
      disableUnderline
      IconComponent={MotionChevronDown}
      data-testid={testId}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      ref={ref}
      variant="outlined"
      sx={(theme) => ({
        width: "100%",
        padding: "4px 16px",
        margin: 0,
        fontFamily: theme.typography.body1,
      })}
      defaultValue={defaultValue}
    >
      {menuItems.map((item, index) => (
        <option
          data-testid={item.id}
          value={item.value}
          key={index}
          disabled={item.disabled}
        >
          {item.text}
        </option>
      ))}
    </NativeSelect>
  );
});
export default EMPSelectField;
