import React, { Component } from "react";
import lincolnStyles from "./NACustomerOrdersLincoln.module.scss";
import fordStyles from "./NACustomerOrders.module.scss";
import HasPermissionTo from "../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import SubNavDropdown from "../../shared/subnav/SubNavDropdown";
import { ofType } from "../../common/PubSub";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";
import {
  displayError,
  ERROR_PROCESSING,
  isProcessingError,
} from "../../errors/ErrorConstants";
import { IS_FORD_BRAND, NOTNULL_OR_NOTUNDEFINED } from "../../utils/EmpUtil";
import { handleBack } from "../../utils/GoBack";
import ModelEOrders from "./tabs/ModelEOrders";
import SubNav from "../../shared/subnav/SubNav";
import { Typography, Box, Grid } from "@mui/material";
import EMPSearchField from "../../shared/components/EMPSearchField/EMPSearchField";

class NACustomerOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: {},
      selectedTab: "model_e_orders",
      sortDirection: null,
      sortKey: null,
      dealers: null,
      renderApp: true,
      fetchFailed: false,
      hasError: [],
      pageNumber: 0,
    };

    ofType("language").subscribe((response) => {
      this.updateLanguage(response);
    });

    this.getOrdersFor = this.getOrdersFor.bind(this);
  }

  componentDidMount() {
    // const adobeTag = this.props.location.search;
    // const dealers = this.getDealers();
    // this.setState(
    //   {
    //     adobeTag,
    //     dealers: dealers,
    //   },
    //   () => {
    //     this.getOrdersFor(
    //       this.state.selectedTab,
    //       this.state.dealers,
    //       this.state.pageNumber,
    //       null,
    //       null,
    //       this.state.adobeTag
    //     ).finally(() => {
    //       this.meta = this.props.user.commonDealerId.split("|");
    //       this.setState({ renderApp: true });
    //     });
    //   }
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.orders !== this.state.orders) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }

  handleSort = (columnName, sortDirection) => {
    this.getOrdersFor(
      this.state.selectedTab,
      this.state.dealers,
      this.state.pageNumber,
      columnName
    );

    const isReversed =
      sortDirection === null || sortDirection === "ASC" ? 1 : -1;

    this.setState({
      sortDirection: isReversed === 1 ? "DSC" : "ASC",
      sortKey: columnName,
    });
  };

  handleNext = () => {
    this.getOrdersFor(
      this.state.selectedTab,
      this.state.dealers,
      this.state.pageNumber + 1,
      this.state.sortKey
    );
  };

  handlePrevious = () => {
    this.getOrdersFor(
      this.state.selectedTab,
      this.state.dealers,
      this.state.pageNumber - 1,
      this.state.sortKey
    );
  };

  handleSpecificPage = (pageNumber) => {
    this.getOrdersFor(
      this.state.selectedTab,
      this.state.dealers,
      pageNumber,
      this.state.sortKey
    );
  };

  handleBackToList = () => {
    handleBack(this.props.fromCrc, this.props.history);
  };

  getDealers = () => {
    if (this.props.user.isCanadaDealer()) {
      const dealers = this.props.user.getCommonIds().split(",");
      const brand = this.props.user.brand;

      return dealers.filter(function (dealerId) {
        return dealerId.endsWith(brand);
      });
    } else {
      const commonDealerIds = [];
      commonDealerIds.push(
        this.props.user.commonDealerId.split("|").join("%7C")
      );
      return commonDealerIds;
    }
  };

  updateLanguage = (response) => {
    if (response) {
      this.props.user.setLanguageCode(response.data);
      this.getOrdersFor(this.state.selectedTab, this.getDealers());
    }
  };

  getDefaultSort = () => {
    switch (this.state.selectedTab) {
      case "orders_requiring_attention":
        return "byDealerActionReqDateDesc";
      default:
        return "byDate";
    }
  };

  async getOrdersFor(
    selectedTab = this.state.selectedTab,
    dealers = this.state.dealers,
    pageNumber,
    sort,
    isNewTabClick,
    adobeTag
  ) {
    let url = `/customer-handling`;
    if (!isNewTabClick && NOTNULL_OR_NOTUNDEFINED(adobeTag)) {
      url = url + adobeTag;
    }
    window.history.replaceState(null, null, url);

    const defaultSort = "byDate";

    this.props.user.isCanadaDealer() &&
      this.props.user.dealerGroup.length > 1 &&
      this.setState({
        orders: {},
        fetchFailed: false,
      });
    for (const dealerCommonId in dealers) {
      await CustomerReservationsClient.getCustomerOrders(
        this.props.token,
        dealers[dealerCommonId],
        selectedTab,
        this.props.user.lang,
        pageNumber,
        sort || defaultSort
      )
        .then((response) => {
          let orders = this.state.orders;

          if (
            this.props.user.isCanadaDealer() &&
            this.props.user.dealerGroup.length > 1
          ) {
            if (response && response.reservations.length > 0) {
              orders = this.parseOrders(response.reservations);
            }
          } else {
            orders = response && response.reservations;
          }

          this.setState({
            orders: orders,
            selectedTab,
            sortDirection: isNewTabClick ? null : this.state.sortDirection,
            sortKey: isNewTabClick ? null : this.state.sortKey,
            pageNumber: response.pagination.currentPage,
          });
        })
        .catch(() => {
          this.setState({ fetchFailed: true });
        })
        .finally(() => {
          window.scrollTo({ top: 0, behavior: "auto" });
        });
    }
  }

  parseOrders(reservations) {
    const currentTables = JSON.parse(JSON.stringify(this.state.orders));
    const tempReservations = [];
    const dealerId = reservations[0].dealerId;

    reservations.forEach((reservation) => {
      reservation.orderType =
        reservation.orderType.toLowerCase() === "pre-order"
          ? this.props.t("CustomerReservations.preOrder")
          : reservation.orderType;

      tempReservations.push(reservation);
    });
    currentTables[dealerId] = {
      reservations: tempReservations,
    };
    return currentTables;
  }

  updateOrder = async (
    orderId,
    selectedTab,
    tab,
    dealerCommonId,
    rejectReason
  ) => {
    const naDealerActionInfo = {
      reservationId: orderId,
      dealerAction: selectedTab,
    };

    if (rejectReason) {
      naDealerActionInfo.dealerRejectedReason = rejectReason;
    }

    CustomerReservationsClient.updateOrder(
      this.props.token,
      dealerCommonId,
      naDealerActionInfo,
      this.props.user.userId
    )
      .then(() => {
        this.setState({ orders: {}, renderApp: false }, () => {
          this.getOrdersFor(this.state.selectedTab, this.getDealers()).finally(
            () => {
              this.setState({ renderApp: true });
            }
          );
        });
      })
      .catch((error) => {
        if (isProcessingError(error)) {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
      });
  };

  getSpecsWithNameplate(order) {
    let specString = "";
    if (order["nameplate"]) {
      specString = order["nameplate"];
    }
    if (order["nameplate"] && order["specSummary"]) {
      specString = specString + " / ";
    }
    if (order["specSummary"]) {
      const specList = order["specSummary"].filter(Boolean).join(" / ");
      specString = specString + specList;
    }
    return specString;
  }

  getTabClassname = (tab) => {
    const styles = IS_FORD_BRAND(this.props.user.brand)
      ? fordStyles
      : lincolnStyles;

    return this.state.selectedTab === tab ? styles.selectedTab : null;
  };

  getNoContent = (tab) => {
    const styles = IS_FORD_BRAND(this.props.user.brand)
      ? fordStyles
      : lincolnStyles;

    return (
      <div className={styles.noResultsDiv}>
        <div className={styles.noResultsFound}>
          {this.props.t(`CustomerOrders.${tab}`)}
        </div>
      </div>
    );
  };

  displaySubNavDropdown = (user) => {
    return (
      user.isCanadaDealer() &&
      user.dealerGroup.length > 1 &&
      user.dealerGroup.filter((dealer) => dealer.commonId.endsWith(user.brand))
        .length > 1
    );
  };

  render() {
    const isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    const styles = isFordBrand ? fordStyles : lincolnStyles;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    return (
      <>
        <SubNav
          id="subNav"
          dashboard={false}
          title={this.props.t("GlobalNavBar.dashboard")}
          {...this.props}
          isDealer={true}
        />
        <div className={styles.blueBarWrapper}>
          <EMPSearchField
            isFordBrand={IS_FORD_BRAND(this.props.user.brand)}
            searchQuery=""
          />
        </div>

        {this.displaySubNavDropdown(this.props.user) && (
          <div className={styles.dropDownContainer}>
            <SubNavDropdown {...this.props} />
          </div>
        )}
        {this.state.renderApp && (
          <div className="pageWrapper">
            <div className={styles.greyBackground}>
              {this.state.fetchFailed ? (
                <div className={styles.errorText}>
                  <h2>{this.props.t("FetchError.errorText")}</h2>
                </div>
              ) : (
                <div>
                  <HasPermissionTo
                    perform={["modelETab"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    condition={isFordBrand}
                    render={() => {
                      return (
                        <ModelEOrders
                          {...this.props}
                          getDealers={this.state.dealers}
                          updateOrder={this.updateOrder}
                          getNoContent={this.getNoContent}
                          noContentText="noModelEOrders"
                        />
                      );
                    }}
                    noRender={() => {
                      return (
                        <Grid container justifyContent="center">
                          <Grid item xs={12} sm={10} md={10}>
                            <div className={styles.errorText}>
                              <Box py={6}>
                                <Typography variant="h2">
                                  {this.props.t("FetchError.errorText")}
                                </Typography>
                              </Box>
                            </div>
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation("emp")(NACustomerOrders);
