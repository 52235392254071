import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import fordStyles from "./BrandSettings-Ford.module.scss";
import lincolnStyles from "./BrandSettings-Lincoln.module.scss";
import Item from "../../../shared/item/Item";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Typography, Button, Grid, Dialog, Radio, Box } from "@mui/material";
import ValidationRules from "../../../common/ValidationRules";
import { DNP, SRP } from "../PriceType";
import ConfirmDialog from "../../../shared/confirmDialog/ConfirmDialog";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import BrandSettingsClient from "../../../clients/BrandSettingsClient";
import { logMsg } from "../../../common/Logger";
import HasPermissionTo from "../../../common/HasPermissionTo";
import {
  displayError,
  ERROR_PROCESSING,
  is404,
  isProcessingError,
  PAGE_ERROR,
} from "../../../errors/ErrorConstants";
import { BRAND_NAMES } from "../../../common/Constants";
import TitleCase from "../../../utils/TitleCase";
import CapitalizeEachWord from "../../../utils/CapitalizeEachWord";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";

let isFordBrand = true;
let styles = null;

class BrandSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brandSettings: null,
      buttonEnabled: false,
      overridesApplied: false,
      confirmDialog: false,
      isDataLoaded: false,
      hasError: [],
    };
  }

  componentDidMount() {
    AnalyticsService.trackPageLoadEvent(
      "fv:emp:accessories pricing:brand",
      this.props.user
    );
    ValidationRules();
    this.getDealerBrandAdjustments();
  }

  handleOptionChange = (value) => {
    const copy = JSON.parse(JSON.stringify(this.state.brandSettings));
    copy.priceType = value;

    this.setState({ brandSettings: copy }, () => {
      this.enableOrDisableButton();
    });
  };

  handleClose = () => {
    this.setState({ confirmDialog: false });
  };

  handleConfirm = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ confirmDialog: true });
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.hideOrShow(true);
    const data = JSON.parse(JSON.stringify(this.state.brandSettings));
    data.markup = parseFloat(data.markup);
    data.laborRate = parseFloat(data.laborRate);
    data.salesCode = this.props.user.salesCode;

    BrandSettingsClient.saveDealerBrandAdjustments(
      this.props.user,
      data,
      this.props.user.market === "CAN"
        ? "CAN" + this.props.user.dealerId
        : this.props.user.dealerId,
      this.props.user.brand
    )
      .then(() => {
        this.setState(
          {
            buttonEnabled: false,
            confirmDialog: false,
            overridesApplied: true,
          },
          () => {
            this.props.enableOtherTabs(true);
            this.previousData = data;
          }
        );
      })
      .catch((error) => {
        logMsg("the error is: ", error);
        if (is404(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        } else if (isProcessingError(error)) {
          this.setState({
            hasError: [ERROR_PROCESSING],
          });
        }
      })
      .finally(() => this.props.hideOrShow(false));
  };

  getDealerBrandAdjustments() {
    isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    styles = isFordBrand ? fordStyles : lincolnStyles;
    this.props.hideOrShow(true);
    let brandSettings, enableTabs;
    BrandSettingsClient.getDealerBrandAdjustments(this.props.user)
      .then((data) => {
        enableTabs = !!data.priceType;
        if (!enableTabs) {
          data.priceType = SRP;
        }
        brandSettings = data;

        this.confirmDialogProps = {
          title: this.props.t("BrandSettings.dialog.confirm.title"),
          bodyText: this.props.t("BrandSettings.dialog.confirm.body"),
          settings: this.props.t("BrandSettings.dialog.confirm.settings", {
            markup: brandSettings.markup,
            labourRate: brandSettings.laborRate,
          }),
          markup: this.props.t("BrandSettings.dialog.confirm.markup"),
          laborRate: this.props.t("BrandSettings.dialog.confirm.laborRate"),
          checkbox: this.props.t("BrandSettings.dialog.confirm.checkbox"),
          confirm: isFordBrand
            ? this.props.t("BrandSettings.dialog.confirm.F.confirmPublish")
            : this.props.t("BrandSettings.dialog.confirm.L.confirm"),
          cancel: isFordBrand
            ? this.props.t("BrandSettings.dialog.confirm.F.goBack")
            : this.props.t("BrandSettings.dialog.confirm.L.cancel"),
        };

        this.previousData = JSON.parse(JSON.stringify(brandSettings));
      })
      .catch((error) => {
        logMsg("reason", error);
        if (is404(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        } else if (isProcessingError(error)) {
          this.setState({
            hasError: [ERROR_PROCESSING],
          });
        }
      })
      .finally(() => {
        this.setState({
          isDataLoaded: true,
          brandSettings: brandSettings,
          buttonEnabled: false,
        });
        this.props.enableOtherTabs(enableTabs);
        this.props.hideOrShow(false);
      });
  }

  updateModel = (event, model, field) => {
    const copy = JSON.parse(JSON.stringify(this.state.brandSettings));

    if ("markup" === field) {
      copy.markup = event.target.value;
    } else {
      copy.laborRate = event.target.value;
    }

    this.setState({ brandSettings: copy }, () => {
      this.enableOrDisableButton();
    });
    this.confirmDialogProps.settings = this.props.t(
      "BrandSettings.dialog.confirm.settings",
      {
        markup: copy.markup,
        labourRate: copy.laborRate,
      }
    );
  };

  enableOrDisableButton = () => {
    const isPriceTypeChanged =
      this.previousData.priceType !== this.state.brandSettings.priceType;
    const isMarkupChanged =
      this.state.brandSettings.markup &&
      this.previousData.markup !== parseFloat(this.state.brandSettings.markup);
    const isLaborRateChanged =
      this.state.brandSettings.laborRate &&
      this.previousData.laborRate !==
        parseFloat(this.state.brandSettings.laborRate);

    this.setState({
      buttonEnabled:
        isPriceTypeChanged || isMarkupChanged || isLaborRateChanged,
    });
  };

  render() {
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    this.props.user.showWarning(this.state.buttonEnabled);
    return (
      this.state.isDataLoaded && (
        <div className={styles.pagePadding}>
          <ValidatorForm onSubmit={(event) => this.handleConfirm(event)}>
            <Typography variant="h1" sx={{ my: 4 }}>
              {this.props.t("BrandSettings.header.pricing")}
            </Typography>
            <Typography variant="body1">
              {this.props.t("BrandSettings.title.pricing")}
            </Typography>

            <PriceType
              {...this.props}
              onClick={this.handleOptionChange}
              selectedOption={
                this.state.brandSettings && this.state.brandSettings.priceType
              }
            />

            <BrandMarkup
              {...this.props}
              updateModel={this.updateModel}
              brandSettings={this.state.brandSettings}
            />
            <SaveButton
              {...this.props}
              enabled={this.state.buttonEnabled}
              isFordBrand={isFordBrand}
            />
          </ValidatorForm>

          <OverridesDialog
            {...this.props}
            overridesApplied={this.state.overridesApplied}
            handleTabChange={this.props.handleTabChange}
            onClose={() => this.setState({ overridesApplied: false })}
          />

          <ConfirmDialog
            open={this.state.confirmDialog}
            showSettings={true}
            showWarningIcon={true}
            dialogProps={this.confirmDialogProps}
            markupValue={
              this.state.brandSettings ? this.state.brandSettings.markup : null
            }
            laborRateValue={
              this.state.brandSettings
                ? this.state.brandSettings.laborRate
                : null
            }
            onConfirm={this.handleSubmit}
            onCancel={this.handleClose}
            boldTitle={true}
            onClose={this.handleClose}
            disableConfirm={
              !this.props.user.permissions.rolePermissions.hasOwnProperty(
                "accessoriesSubmit"
              )
            }
            {...this.props}
          />
        </div>
      )
    );
  }
}

const SaveButton = (props) => {
  return (
    <Box sx={{ my: 4 }}>
      <EMPButton
        disabled={!props.enabled}
        testId="applyOverrides"
        variant="contained"
        submitButton
      >
        {props.t("BrandSettings.save")}
      </EMPButton>
    </Box>
  );
};

const PriceType = (props) => {
  function getRadio(radio) {
    const isLincolnChecked = !isFordBrand && props.selectedOption === radio;
    return (
      <div
        className={isLincolnChecked ? styles.radioChecked : styles.radio}
        onClick={() => props.onClick(radio)}
      >
        <div>
          <Radio
            value={radio}
            name={radio}
            data-testid={radio}
            checked={props.selectedOption === radio}
            onChange={() => {}}
          />
        </div>
        <div className={styles.text}>
          <div className={styles.radioTitle}>
            {" "}
            {props.t("BrandSettings." + radio)}
          </div>
          <div className={styles.radioText}>
            {props.t("BrandSettings.text." + radio, {
              brand: isFordBrand
                ? TitleCase(BRAND_NAMES.FORD)
                : TitleCase(BRAND_NAMES.LINCOLN),
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.priceType}>
      {getRadio(SRP)}
      {getRadio(DNP)}
    </div>
  );
};

const BrandMarkup = (props) => {
  const model = props.brandSettings;
  return (
    <>
      <Typography variant="h3" sx={{ my: 4 }}>
        {props.t("BrandSettings.header.markup")}
      </Typography>
      <Typography variant="body1" sx={{ my: 4 }}>
        {props.t("BrandSettings.title.markup")}
      </Typography>

      <div className={`${styles.brandHeader}`}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <div className={styles.alignLeft}>
              <Item value={props.t("BrandSettings.brand")} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <Item value={props.t("BrandSettings.markup")} />
          </Grid>
          <Grid item xs={2}>
            <Item value={props.t("BrandSettings.laborRate")} />
          </Grid>
        </Grid>
      </div>

      <div className={`${styles.brandText}`}>
        <Grid container spacing={0}>
          <Grid item xs={5} className={styles.brand}>
            <div className={styles.brandName}>
              {props.t("BrandSettings.model." + props.user.brand)}
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.laborRateRow}>
              <div className={styles.percentage}>%</div>
              <div className={styles.textBox}>
                <TextValidator
                  value={model.markup || model.markup === 0 ? model.markup : ""}
                  data-testid="markup"
                  name="markup"
                  validators={[
                    "required",
                    "matchRegexp:^^[+-d\\b.]+$",
                    "isSignedFloatWithTwoDecimalPlaces",
                  ]}
                  errorMessages={[
                    props.t("Error.requiredField"),
                    props.t("Error.invalidInput"),
                    props.t("Error.invalidInput"),
                    props.t("Error.invalidInput"),
                  ]}
                  onChange={(e) => props.updateModel(e, model, "markup")}
                  disableUnderline
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.laborRateRow}>
              <div className={styles.currency}>$</div>
              <div className={styles.textBox}>
                <TextValidator
                  value={
                    model.laborRate || model.laborRate === 0
                      ? model.laborRate
                      : ""
                  }
                  data-testid="laborRate"
                  name="laborRate"
                  validators={[
                    "required",
                    "matchRegexp:^[0-9\\b.]+$",
                    "isNumber",
                    "isNumericWithTwoDecimalPlaces",
                  ]}
                  errorMessages={[
                    props.t("Error.requiredField"),
                    props.t("Error.invalidInput"),
                    props.t("Error.invalidInput"),
                    props.t("Error.invalidInput"),
                  ]}
                  onChange={(e) => props.updateModel(e, model, "laborRate")}
                  disableUnderline
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const OverridesDialog = (props) => {
  return (
    <Dialog open={props.overridesApplied} onClose={props.onClose}>
      <div className={styles.overridesDialog}>
        <Grid id="overridesDialog">
          <Grid item md={12}>
            <div className={styles.cancelButton}>
              <button
                data-testid="closeDialogBtn"
                className={styles.closeDialogBtn}
                onClick={props.onClose}
                type="submit"
              ></button>
            </div>
          </Grid>

          <Grid item md={12}>
            <div className={styles.savedContainer}>
              <span className={styles.checkMark}></span>
              <div className={styles.dialogTitle}>
                {props.t("BrandSettings.dialog.overrides.title")}
              </div>
            </div>
          </Grid>
          <Grid item md={12}>
            <div className={styles.dialogDescription}>
              {props.t("BrandSettings.dialog.overrides.description")}
            </div>
          </Grid>

          <Grid item md={12}>
            <div className={styles.buttons}>
              <HasPermissionTo
                perform={["namePlateSettingsTabUS"]}
                permissions={props.user.permissions.rolePermissions}
                render={() => {
                  return (
                    <Button
                      className={styles.customiseToBtn}
                      onClick={(event) => props.handleTabChange(event, 1)}
                    >
                      <div
                        className={props.isFordBrand ? "" : styles.lincolnBtn}
                      >
                        {isFordBrand ? (
                          props.t(
                            "BrandSettings.dialog.overrides.nameplatesBtn"
                          )
                        ) : (
                          <>
                            {CapitalizeEachWord(
                              props.t(
                                "BrandSettings.dialog.overrides.nameplatesBtn"
                              )
                            )}
                            <span className={styles.chevronOffset}></span>
                          </>
                        )}
                      </div>
                    </Button>
                  );
                }}
              />
              <HasPermissionTo
                perform={["categorySettingsTabUS"]}
                permissions={props.user.permissions.rolePermissions}
                render={() => {
                  return (
                    <Button
                      className={styles.customiseToBtn}
                      onClick={(event) => props.handleTabChange(event, 2)}
                    >
                      <div
                        className={props.isFordBrand ? "" : styles.lincolnBtn}
                      >
                        {isFordBrand ? (
                          props.t(
                            "BrandSettings.dialog.overrides.categoriesBtn"
                          )
                        ) : (
                          <>
                            {CapitalizeEachWord(
                              props.t(
                                "BrandSettings.dialog.overrides.categoriesBtn"
                              )
                            )}
                            <span className={styles.chevronOffset}></span>
                          </>
                        )}
                      </div>
                    </Button>
                  );
                }}
              />

              <Button className={styles.brandConfirm} onClick={props.onClose}>
                <span>{props.t("BrandSettings.dialog.overrides.done")}</span>
                {!isFordBrand && <span className={styles.chevronOffset}></span>}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default withTranslation("emp")(BrandSettings);
