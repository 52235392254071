import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { DialogActions, Grid, Typography, Box } from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";
import CancelPurchaseRequest from "./CancelPurchaseRequest";
import AcceptPurchaseRequest from "./AcceptPurchaseRequest";
import { trackPurchaseRequestDecisionPopupLoad } from "../../../services/analytics/OrderDetailsPageAnalytics";
import { UserContext } from "../../../context/userContext";

const PurchaseRequestIntro = ({ orderId, user, t, handleDecision }) => {
  const handleClick = (decision) => handleDecision(decision);

  useEffect(() => {
    trackPurchaseRequestDecisionPopupLoad(orderId, user);
  }, []);

  return (
    <div>
      <Box>
        <Typography variant="body1" sx={{ mb: 6 }}>
          Confirm or cancel this customers purchase request. You can modify this
          later in the Pricing tab.
        </Typography>
      </Box>

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="flex-end" spacing={4}>
          <Grid item>
            <EMPButton variant="outlined" onClick={() => handleClick("cancel")}>
              {t("common.buttonCtas.cancelPurchaseRequest")}
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() => handleClick("accept")}
            >
              {t("common.buttonCtas.acceptPurchaseRequest")}
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

const PurchaseRequestDecision = ({ orderId }) => {
  const [decision, setDecision] = useState(null);
  const { t } = useTranslation();
  const user = useContext(UserContext);

  if (decision === "accept") {
    return (
      <AcceptPurchaseRequest
        orderId={orderId}
        backButton
        handleBack={() => setDecision(null)}
      />
    );
  } else if (decision === "cancel") {
    return (
      <CancelPurchaseRequest
        orderId={orderId}
        backButton
        handleBack={() => setDecision(null)}
      />
    );
  } else {
    return (
      <PurchaseRequestIntro
        t={t}
        user={user}
        orderId={orderId}
        handleDecision={(decision) => setDecision(decision)}
      />
    );
  }
};

export default PurchaseRequestDecision;
