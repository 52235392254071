import React from "react";
import { SvgIcon } from "@mui/material";

const MotionClose = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path d="M25.68 24L36 34.32L34.32 36L24 25.68L13.68 36L12 34.32L22.32 24L12 13.68L13.68 12L24 22.32L34.32 12L36 13.68L25.68 24Z" />
    </SvgIcon>
  );
};

export default MotionClose;
