import React, {
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
} from "react";
import {
  DialogActions,
  Grid,
  Typography,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";
import EMPCheckbox from "../../EMPCheckbox/EMPCheckbox";
import EMPSelectField from "../../EMPSelectField/EMPSelectField";
import { ModalContext } from "../ModalContext";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import { UserContext } from "../../../context/userContext";
import { DEALER_ACTIONS } from "../../../../common/Constants";
import { useTranslation } from "react-i18next";
import { useFieldSupportStatus } from "../../../hooks/useFieldSupportStatus";
import {
  trackCancelPurchaseRequestPopupAction,
  trackCancelPurchaseRequestPopupLoad,
} from "../../../services/analytics/OrderDetailsPageAnalytics";
import EMPLoader from "../../EMPLoader/EMPLoader";
import useCancellationReasons from "../../../hooks/useCancellationReasons";
import { usePermission } from "../../../hooks/usePermission";

const CancelPurchaseRequest = ({ orderId, backButton, handleBack }) => {
  const { handleModal } = useContext(ModalContext);
  const user = useContext(UserContext);
  const { market, dealerId, salesCode, dealerBrand, userId } = user;
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const [checked, setChecked] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);
  const isFieldSupport = useFieldSupportStatus();
  const canCancelPurchaseRequest = usePermission(
    "cancelPurchaseRequestNA:write"
  );

  const {
    isFetching,
    isError,
    data: cancellationReasons,
    error,
  } = useCancellationReasons("purchaseRequest");

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef.current]);

  useEffect(() => {
    trackCancelPurchaseRequestPopupLoad(orderId, user);
  }, []);

  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);

  const { paymentType, todayDate } = customerReservation;

  const handleCheckboxChange = (checked) => setChecked(checked);
  const handleDropdownChange = (e) => setDropdownValue(e.target.value);

  useEffect(() => {
    if (isFieldSupport || !canCancelPurchaseRequest) {
      return;
    }
    setDisabled(!(checked && dropdownValue));
  }, [checked, dropdownValue]);

  const handleErrorMessage = () => {
    let errorMessage =
      "An unexpected error occurred. Please use the back button and try again.";
    if (submitStatus.error.response && submitStatus.error.response.data) {
      try {
        const errorData = submitStatus.error.response.data.message;
        if (errorData) {
          errorMessage =
            typeof errorData === "string" ? JSON.parse(errorData) : errorData;
          return errorMessage.errors[0].message;
        } else {
          return errorMessage;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Failed to parse error message:", e);
      }
    } else {
      return errorMessage;
    }
  };

  const submitStatus = useMutation({
    mutationFn: (actionData) =>
      CustomerReservationsClient.actionOnOrder(
        sessionStorage.getItem("adfs.token"),
        userCommonId,
        actionData,
        userId
      ),
    onSuccess: () => {
      handleModal(false, false);
      trackCancelPurchaseRequestPopupAction(
        dropdownValue,
        paymentType,
        orderId,
        todayDate,
        user
      );
      queryClient.invalidateQueries({ queryKey: ["order", { orderId }] });
      queryClient.invalidateQueries({
        queryKey: ["notifications", { orderId }],
      });
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        // eslint-disable-next-line no-console
        console.log(`400 Error: ${errorMessage}`);
      } else {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  });

  if (submitStatus.status === "loading") {
    return (
      <Box sx={{ minHeight: contentHeight, py: 10 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item>
            <EMPLoader loadingMessage="Submitting..." />
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (submitStatus.status === "error") {
    return (
      <Box sx={{ minHeight: contentHeight, pb: 4 }}>
        <Typography variant="body1" sx={{ lineBreak: "anywhere" }}>
          {handleErrorMessage()}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 6 }}>
        {t("common.actions.cancelModal.message")}
      </Typography>
      <Box sx={{ mb: 10 }}>
        {isFetching ? (
          <Grid container spacing={2}>
            <Grid item>
              <EMPLoader loadingMessage="Loading..." />
            </Grid>
          </Grid>
        ) : isError ? (
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="error">
              {`Error: ${error.message}`}
            </Typography>
          </Grid>
        ) : (
          <>
            <InputLabel disableAnimation shrink={false}>
              {t("pricing.purchaseRequestCanceled.fields.0")}
            </InputLabel>
            <FormControl id="cancelOrderDropDown" fullWidth>
              <EMPSelectField
                testId="dropdown"
                value={dropdownValue}
                onChange={handleDropdownChange}
                menuItems={cancellationReasons}
              />
            </FormControl>
          </>
        )}
      </Box>
      <Box sx={{ mb: 4 }}>
        <EMPCheckbox
          label={t("common.actions.cancelModal.checkboxConfirm")}
          checked={checked}
          handleChange={handleCheckboxChange}
          id="cancel-purchase-request-checkbox"
        />
      </Box>

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton
              variant="text"
              onClick={() =>
                backButton ? handleBack() : handleModal(false, false)
              }
              disabled={submitStatus.status === "loading"}
            >
              {backButton ? "Back" : t("common.cancel")}
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() =>
                submitStatus.mutate({
                  reservationId: orderId,
                  acceptedDate: todayDate,
                  dealerAction: DEALER_ACTIONS.PURCHASE_REQUEST_CANCELLED,
                  dealerRejectedReason: dropdownValue,
                })
              }
              disabled={
                disabled ||
                submitStatus.status === "loading" ||
                submitStatus.status === "error"
              }
              testId="cancel-purchase-request-button"
            >
              {t("common.buttonCtas.cancelPurchaseRequest")}
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Box>
  );
};

export default CancelPurchaseRequest;
