import React from "react";
import { SvgIcon } from "@mui/material";

const MotionChevronLeft = ({ props }) => {
  return (
    <SvgIcon {...props}>
      <path d="M7.2 12L14.4 19.2L15.24 18.36L8.88 12L15.24 5.63999L14.4 4.79999L7.2 12Z" />
    </SvgIcon>
  );
};

export default MotionChevronLeft;
