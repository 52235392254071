import React from "react";
import EMPDialog from "../shared/components/EMPDialog/EMPDialog";
import { Typography } from "@mui/material";

const ErrorProcessingDialog = (props) => {
  const { open, close, title, body, buttonText } = props;

  return (
    <EMPDialog
      dialogTitle={title}
      open={open}
      hasCloseIcon
      handleClose={close}
      hasActionButton
      actionButtonText={buttonText}
      onConfirm={close}
    >
      <Typography variant="body1">{body}</Typography>
    </EMPDialog>
  );
};

export default ErrorProcessingDialog;
