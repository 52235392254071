import React from "react";
import { Box } from "@mui/material";
import Delivery from "../../components/customerHandling/modelEDetails/Delivery";

const NewDelivery = ({ orderId, content }) => {
  const { customerInfo } = content;
  const vehicleInfo = {
    namePlate: content.customerReservation.nameplate,
    modelYear: content.customerReservation.modelYear,
    vin: content.customerReservation.vinNumber,
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      columnGap={4}
      rowGap={4}
    >
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <Delivery
          orderId={orderId}
          customerInfo={customerInfo}
          vehicleInfo={vehicleInfo}
          setCustomerFollowupNotification={() => {}}
        />
      </Box>
    </Box>
  );
};

export default NewDelivery;
