import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import App from "./apps/App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";
import "./fonts/ford.scss";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import F1Theme from "./F1-theme";

const AppWithCallbackAfterRender = () => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("EMP rendered");
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={F1Theme}>
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);
