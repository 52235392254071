import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import EMPObjectCard from "../../../shared/components/EMPDataCard/EMPObjectCard";

const CashTable = ({ cashPaymentData }) => {
  return (
    <>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <Grid container>
          <Grid item>
            <Typography variant="h3" sx={{ color: "text.primary" }}>
              Cash
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <EMPObjectCard data={cashPaymentData} />
      </Box>
    </>
  );
};

export default CashTable;
