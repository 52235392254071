import React, { createContext } from "react";
import EMPModal from "./EMPModal";
import useModal from "./useModal";

let ModalContext;
const { Provider } = (ModalContext = createContext());

const ModalProvider = ({ children }) => {
  const { openModal, handleModal, modalContent, modalHeading } = useModal();
  return (
    <Provider
      value={{
        openModal,
        handleModal,
        modalContent,
        modalHeading,
      }}
    >
      <EMPModal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
