import React, { useContext, useEffect, useState } from "react";
import { DialogActions, Grid, Typography, Box } from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";
import EMPCheckbox from "../../EMPCheckbox/EMPCheckbox";
import { ModalContext } from "../ModalContext";
import { useQueryClient } from "@tanstack/react-query";
import {
  doesAttestationExist,
  setAttestation,
} from "../../../services/localstorage/LocalStorageService";
import {
  trackEVPowerPromisePopupAction,
  trackEVPowerPromisePopupLoad,
} from "../../../services/analytics/OrderDetailsPageAnalytics";
import { UserContext } from "../../../context/userContext";
import { trackReferralExit } from "../../../services/analytics/AnalyticsService";
import { Link } from "react-router-dom";

const EVPowerPromise = ({ orderId }) => {
  const { handleModal } = useContext(ModalContext);
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const queryClient = useQueryClient();
  const user = useContext(UserContext);

  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);
  const { vinNumber } = customerReservation;
  const customLink = `https://www.smartvincent.dealerconnection.com/smartvincent/svaccess.do?vin=${vinNumber}&source=vehiclelocator&Language=E`;

  useEffect(() => {
    const existingAttestation = doesAttestationExist(
      orderId,
      "ev-power-promise"
    );

    if (existingAttestation) {
      setChecked(true);
      setDisabled(true);
    }
  }, [orderId]);

  useEffect(() => {
    trackEVPowerPromisePopupLoad(orderId, user);
  }, []);

  const handleChange = (checkedBool) => {
    if (doesAttestationExist(orderId, "ev-power-promise")) {
      return;
    } else {
      setChecked(checkedBool);
      setDisabled(!checkedBool);
    }
  };

  const saveAttestation = () => {
    setAttestation("ev-power-promise", orderId, new Date());
    handleModal(false, false);
    queryClient.invalidateQueries("order", { orderId });

    trackEVPowerPromisePopupAction("confirm completed", orderId, user);
  };

  return (
    <div>
      <Typography variant="body1" sx={{ mb: 6 }}>
        Enroll your customer in the Ford Power Promise complimentary Home
        Charger and Standard Installation promotion through Smart Vincent.{" "}
        <Link
          to={{
            pathname: customLink,
          }}
          target="_blank"
          referrer="noreferrer"
          onClick={() => trackReferralExit(customLink, orderId, user)}
        >
          Visit Smart Vincent
        </Link>
      </Typography>
      <Box sx={{ mb: 10 }}>
        <EMPCheckbox
          label="I attest that I have submitted info for the Ford Power Promise."
          checked={checked}
          handleChange={(checkedBool) => handleChange(checkedBool)}
        />
      </Box>

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton
              variant="text"
              onClick={() => {
                handleModal(false, false);
                trackEVPowerPromisePopupAction("cancel", orderId, user);
              }}
            >
              Cancel
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() => saveAttestation()}
              disabled={disabled}
            >
              Confirm Completed
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

export default EVPowerPromise;
