import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import HasPermissionTo from "../../../common/HasPermissionTo";
import CancelOrderDialog from "../CancelOrderDialog";
import {
  CHECK_RECALLS,
  CHECK_RECALLS_WITH_VIN,
  DEALER_ACTIONS,
} from "../../../common/Constants";
import CustomerReservationClient from "../../../clients/CustomerReservationsClient";
import { withTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";

const AcceptRejectDetails = (props) => {
  const {
    currentStatus,
    date,
    user,
    t,
    orderId,
    todayDate,
    dealerId,
    vin,
    paymentType,
    orderCreatedDate,
  } = props;

  const queryClient = useQueryClient();

  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setAcceptDialogOpen(false);
    setCancelDialogOpen(false);
  };

  const submitStatus = useMutation({
    mutationFn: (actionData) =>
      CustomerReservationClient.actionOnOrder(
        user.token,
        dealerId,
        actionData,
        user.userId
      ),
    onSuccess: (_, variables) => {
      handleDialogClose();
      if (variables.dealerAction === DEALER_ACTIONS.PURCHASE_REQUEST_ACCEPTED) {
        trackActionSuccess(
          "fv:emp:model e details:accept purchase popup",
          `emp:model e details:accept purchase popup:${
            paymentType.toLowerCase() || ""
          }:order id:${variables.reservationId}`,
          `emp:model e:order created:${variables.orderCreatedDate || ""}`,
          "emp:model e details:accept purchase popup:content:confirm",
          "fv:emp:model e details:accept purchase popup:content action"
        );
      }

      if (
        variables.dealerAction === DEALER_ACTIONS.PURCHASE_REQUEST_CANCELLED
      ) {
        trackActionSuccess(
          "fv:emp:model e details:cancel purchase popup",
          `emp:model e details:cancel purchase:${variables.dealerRejectedReason}:order id:${variables.reservationId}`,
          `emp:model e:order created:${variables.orderCreatedDate || ""}`,
          "emp:model e details:cancel purchase popup:content:confirm",
          "fv:emp:model e details:cancel purchase popup:content action"
        );
      }

      queryClient.invalidateQueries({ queryKey: ["order"] });
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
    // eslint-disable-next-line no-console
    onError: (error) => console.log(error),
  });

  const trackModal = (pageName, tool) =>
    AnalyticsService.trackPageLoadDetailEvent(pageName, tool, orderId, user);

  const trackActionSuccess = (
    pageName,
    tool,
    toolDescriptor,
    onclick,
    onclickLinkName
  ) =>
    AnalyticsService.trackOnClickDetailEvent(
      pageName,
      tool,
      toolDescriptor,
      orderId,
      onclick,
      onclickLinkName,
      user
    );

  const acceptDialogTranslationStrings = {
    title: t("ModelEOrders.acceptPurchaseDialogText.title"),
    body: t("ModelEOrders.acceptPurchaseDialogText.body"),
    checkbox: t("ModelEOrders.acceptPurchaseDialogText.checkbox"),
    checkboxList: [
      t("ModelEOrders.acceptPurchaseDialogText.checkboxList0"),
      t("ModelEOrders.acceptPurchaseDialogText.checkboxList1"),
      t("ModelEOrders.acceptPurchaseDialogText.checkboxList2"),
      t("ModelEOrders.acceptPurchaseDialogText.checkboxList3"),
      t("ModelEOrders.acceptPurchaseDialogText.checkForRecalls"),
    ],
    confirm: t("ModelEOrders.acceptPurchaseDialogText.confirm"),
    cancel: t("ModelEOrders.acceptPurchaseDialogText.cancel"),
    recallLink: vin ? `${CHECK_RECALLS_WITH_VIN}${vin}` : CHECK_RECALLS,
  };

  const cancelDialogTranslationStrings = {
    title: t("ModelEOrders.cancelPurchaseDialogText.title"),
    body: t("ModelEOrders.cancelPurchaseDialogText.body"),
    checkbox: t("ModelEOrders.cancelPurchaseDialogText.checkbox"),
    checkboxList: [
      t("ModelEOrders.cancelPurchaseDialogText.checkboxList1"),
      t("ModelEOrders.cancelPurchaseDialogText.checkboxList2"),
      t("ModelEOrders.cancelPurchaseDialogText.checkboxList3"),
      t("ModelEOrders.cancelPurchaseDialogText.checkboxList4"),
    ],
    confirm: t("ModelEOrders.cancelPurchaseDialogText.confirm"),
    cancel: t("ModelEOrders.cancelPurchaseDialogText.cancel"),
  };

  const formattedDate = date
    ? new Date(date).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "";

  const statuses = {
    created: {
      message: t("ModelEOrders.orderNeedsAcceptance"),
      showAcceptAction: true,
      showRejectAction: true,
    },
    accepted: {
      message: `${t("ModelEOrders.orderAccepted")} ${formattedDate}`,
      showAcceptAction: false,
      showRejectAction: true,
    },
    canceled: {
      message: `${t("ModelEOrders.orderCanceled")} ${formattedDate}`,
      showAcceptAction: false,
      showRejectAction: false,
    },
    cancelled: {
      message: `${t("ModelEOrders.orderCancelled")} ${formattedDate}`,
      showAcceptAction: false,
      showRejectAction: false,
    },
    autoAccepted: {
      message: t("ModelEOrders.orderAutoAcceptedMessage"),
      showAcceptAction: false,
      showRejectAction: true,
    },
  };

  return (
    <>
      <Box
        mb={6}
        py={4}
        pl={4}
        pr={4}
        style={{
          backgroundColor: "white",
          border: "1px solid #00095b",
          borderLeft: "8px solid #00095b",
        }}
      >
        <Grid container alignItems="center" style={{ color: "#00095b" }}>
          <Grid item container xs={12} sm={8} md={9}>
            <Grid item xs={10}>
              {currentStatus && statuses[currentStatus] && (
                <Typography variant="body1" sx={{ color: "text.primary" }}>
                  {statuses[currentStatus].message}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            container
            spacing={2}
            justifyContent="flex-end"
          >
            {currentStatus && statuses[currentStatus].showAcceptAction && (
              <HasPermissionTo
                perform={["acceptPurchaseRequestNA:write"]}
                permissions={user.permissions.rolePermissions}
                render={() => (
                  <Grid item>
                    <EMPButton
                      testId="accept-button"
                      variant="contained"
                      onClick={() => setAcceptDialogOpen(true)}
                    >
                      {t("ModelEOrders.accept")}
                    </EMPButton>
                  </Grid>
                )}
              ></HasPermissionTo>
            )}
            {currentStatus && statuses[currentStatus].showRejectAction && (
              <HasPermissionTo
                perform={["cancelPurchaseRequestNA:write"]}
                permissions={user.permissions.rolePermissions}
                render={() => (
                  <Grid item>
                    <EMPButton
                      testId="cancel-button"
                      onClick={() => setCancelDialogOpen(true)}
                    >
                      {t("ModelEOrders.cancel")}
                    </EMPButton>
                  </Grid>
                )}
              ></HasPermissionTo>
            )}
          </Grid>
        </Grid>
      </Box>
      {/*Accept Popup*/}
      <CancelOrderDialog
        {...props}
        loading={submitStatus.isLoading}
        dialogProps={acceptDialogTranslationStrings}
        open={acceptDialogOpen}
        onClose={() => {
          setAcceptDialogOpen(false);
          submitStatus.reset();
        }}
        handleConfirm={() =>
          submitStatus.mutate({
            reservationId: orderId,
            acceptedDate: todayDate,
            orderCreatedDate,
            dealerAction: DEALER_ACTIONS.PURCHASE_REQUEST_ACCEPTED,
          })
        }
        transportError={submitStatus.isError}
        onEnter={() =>
          trackModal(
            "fv:emp:model e details:accept purchase popup",
            "emp:model e details:accept purchase popup"
          )
        }
      />

      {/*Cancel Popup*/}
      <CancelOrderDialog
        {...props}
        loading={submitStatus.isLoading}
        dialogProps={cancelDialogTranslationStrings}
        open={cancelDialogOpen}
        onClose={() => {
          setCancelDialogOpen(false);
          submitStatus.reset();
        }}
        handleConfirm={(cancelReason) =>
          submitStatus.mutate({
            reservationId: orderId,
            acceptedDate: todayDate,
            dealerAction: DEALER_ACTIONS.PURCHASE_REQUEST_CANCELLED,
            orderCreatedDate,
            dealerRejectedReason: cancelReason,
          })
        }
        showDropdown
        purchaseRequestCancel
        cancelType="purchaseRequest"
        transportError={submitStatus.isError}
        onEnter={() =>
          trackModal(
            "fv:emp:model e details:cancel purchase popup",
            "emp:model e details:cancel purchase popup"
          )
        }
      />
    </>
  );
};

export default withTranslation("emp")(AcceptRejectDetails);
