import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import MotionCloseIcon from "../../Icons/MotionCloseIcon.svg";
import DialogActions from "@mui/material/DialogActions";
import EMPButton from "../EMPButton/EMPButton";

const EMPDialog = (props) => {
  const {
    open,
    dialogTitle,
    hasCloseIcon,
    handleClose,
    onEnter,
    onExit,
    children,
    hasSecondaryActionButton,
    secondaryActionButtonText,
    hasActionButton,
    actionButtonText,
    onConfirm,
    disableActionButton,
    actionLoading,
    onSecondaryAction,
    dialogActionsDataTestId,
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={(event, reason) =>
        reason === "backdropClick" && hasCloseIcon && handleClose()
      }
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
        onEnter,
        onExit,
      }}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item xs={9}>
            <Typography variant="h4" data-testid="dialog-title">
              {dialogTitle}
            </Typography>
          </Grid>
          {hasCloseIcon && (
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                disableRipple
                data-testid="dialog-close-button"
                size="large"
              >
                <img src={MotionCloseIcon} alt="close" width="24px" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {(hasActionButton || hasSecondaryActionButton) && (
        <DialogActions data-testid={dialogActionsDataTestId}>
          {actionLoading && <CircularProgress size={24} />}
          {hasSecondaryActionButton && (
            <EMPButton
              variant="outlined"
              testId="dialog-secondary-action-button"
              onClick={onSecondaryAction}
            >
              {secondaryActionButtonText}
            </EMPButton>
          )}
          {hasActionButton && (
            <EMPButton
              variant="contained"
              testId="dialog-action-button"
              onClick={onConfirm}
              disabled={disableActionButton}
            >
              {actionButtonText}
            </EMPButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default EMPDialog;
