import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { INCLUDED, SELECTED } from "../../../../common/Constants";
import { Accordion, AccordionSummary } from "@mui/material";
import {
  StyledBigTableHeader,
  StyledTableRow,
} from "../../../../shared/Tables/TableRow";
import { formatPriceValue } from "../../../../utils/FormatPrice";
import {
  IS_FORD_BRAND,
  NOTNULL_OR_NOTUNDEFINED,
} from "../../../../utils/EmpUtil";
import fordSharedStyles from "../../orderDetails/NAOrderDetailsNewVersion.module.scss";
import lincolnSharedStyles from "../../orderDetails/NAOrderDetailsNewVersionLincoln.module.scss";
import crStylesFord from "../../crStyles";
import crStylesLincoln from "../../crStylesLincoln";
import fordStyles from "./PurchaseAccessories.module.scss";
import lincolnStyles from "./PurchaseAccessoriesLincoln.module.scss";
import EMPEmptyState from "../../../../shared/components/EMPEmptyState/EMPEmptyState";

const ChargingOptions = (props) => {
  function getValue(item, price) {
    return (
      NOTNULL_OR_NOTUNDEFINED(item) && (price ? formatPriceValue(item) : item)
    );
  }

  return (
    <>
      {props.data && props.data.length > 0 ? (
        props.data.map((option, index) => {
          return (
            <StyledTableRow
              isLincolnBrand={props.user && !IS_FORD_BRAND(props.user.brand)}
              text={getValue(option.shortDescription)}
              data={
                props.type === SELECTED
                  ? getValue(option.dealerSellingPrice, true)
                  : props.t("ChargingModule.included")
              }
              index={index}
              key={index}
              indent={true}
            />
          );
        })
      ) : (
        <StyledTableRow
          isLincolnBrand={props.user && !IS_FORD_BRAND(props.user.brand)}
          text={
            props.type === INCLUDED
              ? props.t("ChargingModule.noneIncluded")
              : props.t("ChargingModule.noneAdditional")
          }
          index={0}
          indent={true}
        />
      )}
      {props.type === SELECTED && (
        <StyledBigTableHeader
          isLincolnBrand={props.user && !IS_FORD_BRAND(props.user.brand)}
          text={props.t("ChargingModule.chargingTotal")}
          data={
            props.total !== null ? (
              getValue(props.total, true)
            ) : (
              <EMPEmptyState />
            )
          }
        />
      )}
    </>
  );
};

class Charging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandCharging: false,
      expandStandard: true,
      expandAdditional: true,
    };
    this.currency = this.props.localizedInfo.defaultCurrency.symbol;
  }

  componentDidMount() {
    this.setState({ expandCharging: this.props.expandAccordian });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({
        expandCharging: this.props.expandAccordian,
        expandStandard: this.props.expandAccordian,
        expandAdditional: this.props.expandAccordian,
      });
    }
  }

  expandStandardAccordion = () =>
    this.setState((prevState) => ({
      expandStandard: !prevState.expandStandard,
    }));

  expandAdditionalAccordion = () =>
    this.setState((prevState) => ({
      expandAdditional: !prevState.expandAdditional,
    }));

  expandCharging = () => {
    this.setState((prevState) => ({
      expandCharging: !prevState.expandCharging,
    }));
  };

  render() {
    const { chargingModuleNA } = this.props.customerReservation;
    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const sharedStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordSharedStyles
        : lincolnSharedStyles;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    return (
      <Accordion
        defaultExpanded={false}
        onChange={this.expandCharging}
        expanded={this.state.expandCharging}
        data-testid="chargingDropDown"
      >
        <AccordionSummary style={crStyles.headerBarBg}>
          <div className={sharedStyles.headerBar}>
            <div className={sharedStyles.header}>
              {this.props.t("ChargingModule.title")}
            </div>
            <div className={styles.acceptRejectDiv}>
              {this.state.expandCharging ? (
                <ExpandLessIcon data-testid="less" />
              ) : (
                <ExpandMoreIcon data-testid="more" />
              )}
            </div>
          </div>
        </AccordionSummary>

        {/*Standard Charging Options*/}
        <Accordion
          onChange={this.expandStandardAccordion}
          expanded={this.state.expandStandard}
        >
          <AccordionSummary style={crStyles.headerBarNeutral}>
            <div className={sharedStyles.headerBar}>
              <div className={sharedStyles.header}>
                {this.props.t("ChargingModule.standard")}
              </div>
              <div className={styles.acceptRejectDiv}>
                {chargingModuleNA &&
                  chargingModuleNA.standardChargingOptions &&
                  chargingModuleNA.standardChargingOptions.length > 0 &&
                  formatPriceValue(0)}
                {this.state.expandStandard ? (
                  <ExpandMoreIcon data-testid="more" />
                ) : (
                  <ExpandLessIcon data-testid="less" />
                )}
              </div>
            </div>
          </AccordionSummary>

          <ChargingOptions
            type={INCLUDED}
            data={chargingModuleNA.standardChargingOptions}
            currency={this.currency}
            {...this.props}
          />
        </Accordion>

        {/*Additional Charging Options*/}
        <Accordion
          defaultExpanded={true}
          onChange={this.expandAdditionalAccordion}
          expanded={this.state.expandAdditional}
        >
          <AccordionSummary style={crStyles.headerBarNeutral}>
            <div className={sharedStyles.headerBar}>
              <div className={sharedStyles.header}>
                {this.props.t("ChargingModule.additional")}
              </div>
              <div className={styles.acceptRejectDiv}>
                {chargingModuleNA &&
                  chargingModuleNA.additionalChargingOptions &&
                  chargingModuleNA.additionalChargingOptions.length > 0 &&
                  formatPriceValue(chargingModuleNA.chargingTotal)}
                {this.state.expandAdditional ? (
                  <ExpandMoreIcon data-testid="more" />
                ) : (
                  <ExpandLessIcon data-testid="less" />
                )}
              </div>
            </div>
          </AccordionSummary>

          <ChargingOptions
            type={SELECTED}
            data={chargingModuleNA.additionalChargingOptions}
            total={
              chargingModuleNA &&
              ((chargingModuleNA.additionalChargingOptions &&
                chargingModuleNA.additionalChargingOptions.length > 0) ||
                (chargingModuleNA.standardChargingOptions &&
                  chargingModuleNA.standardChargingOptions.length > 0))
                ? chargingModuleNA.chargingTotal
                : null
            }
            currency={this.currency}
            {...this.props}
          />
        </Accordion>
      </Accordion>
    );
  }
}

export default withTranslation("emp")(Charging);
