import React from "react";
import UpperCaseText from "../../../utils/UpperCaseText";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";

export const DashboardActions = (props) => {
  const { title, hideActionButton } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      {!hideActionButton && (
        <Grid item xs={12}>
          <Box textAlign="center">
            <EMPButton
              href="/customer-handling"
              to="/customer-handling"
              variant="outlined"
            >
              {props.t("DealerDashboard.viewActions")}
            </EMPButton>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box textAlign="center">
          <EMPButton
            href="/customer-handling"
            buttonElement={Link}
            link={{
              pathname: "/customer-handling",
            }}
            variant="text"
          >
            {UpperCaseText(title) === "ORDERS"
              ? props.t("DealerDashboard.viewSubmitted")
              : props.t("DealerDashboard.viewAll")}
          </EMPButton>
        </Box>
      </Grid>
    </Grid>
  );
};
