import React from "react";
import { Typography } from "@mui/material";
import PriceList from "./PriceList";

const FinancedTradeInTable = ({ appraisalDetails, t }) => {
  return (
    <>
      <Typography variant="h3" sx={{ color: "text.primary" }}>
        {t("TradeIn.ModelE.tradeInAppraisal")}
      </Typography>

      <Typography variant="body2">
        {t("OrderDetailsConsumerDeposit.tradeInAppraisalTooltipBody")}
      </Typography>

      <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>
        {appraisalDetails.appraisalVehicle}
      </Typography>

      <PriceList
        amounts={appraisalDetails.amounts}
        netEquity={appraisalDetails.netEquity}
        t={t}
      />
    </>
  );
};

export default FinancedTradeInTable;
