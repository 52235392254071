import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import styles from "./SubNav.module.scss";
import EMPSelectField from "../components/EMPSelectField/EMPSelectField";
import {
  OPT_IN,
  RESERVATION_DEPOSIT,
  TERMS_AND_CONDITIONS,
} from "../../common/Constants";
import { Grid, FormControl, Dialog } from "@mui/material";

const SubNavDropdown = (props) => {
  const [open, setOpen] = useState(false);
  const brand = props.user.brand;

  const notFullyOnboardedDealers = props.user.dealersNotFullyOnboarded
    ? props.user.dealersNotFullyOnboarded[TERMS_AND_CONDITIONS].concat(
        props.user.dealersNotFullyOnboarded[RESERVATION_DEPOSIT]
      ).concat(props.user.dealersNotFullyOnboarded[OPT_IN])
    : [];

  const dealerGroup = props.user.dealerGroup.filter((d) => {
    return d.isOnboarded && d.commonId.endsWith(brand);
  });

  const updateDealer = (event) => {
    let dealer;
    const showAll = event.target.value === "All";

    if (event.target.value === "DISABLED") {
      setOpen(true);
    } else if (showAll) {
      dealer = props.user.dealerGroup.filter(
        (dealer) => dealer.dealerType === "HUB" || dealer.dealerType === "MDLR"
      )[0];
      props.onDealerChanged(dealer, true);
    } else {
      dealer = props.user.dealerGroupingsMap[event.target.value];
      props.onDealerChanged(dealer);
    }
  };

  const menuItems = [
    {
      text: props.t("SubNav.all"),
      value: "All",
    },
  ];
  if (dealerGroup) {
    dealerGroup.forEach((dealer) => {
      menuItems.push({
        value: dealer.isOnboarded ? dealer.commonId : "DISABLED",
        text: notFullyOnboardedDealers.includes(dealer.completeDealerId)
          ? `${String.fromCharCode(0x26a0)} ${dealer.name} (${
              dealer.regionalId
            })`
          : `${dealer.name} (${dealer.regionalId})`,
      });
    });
  }
  return (
    <div id="dropdown" className={styles.dropdownDiv}>
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl id="menu" fullWidth={true}>
            <EMPSelectField
              testId="dealerSelect"
              value={
                props.user.showAll
                  ? "All"
                  : props.user.dealerGroup.filter(
                      (dealer) => dealer.commonId === props.user.commonId
                    )[0].commonId
              }
              onChange={updateDealer}
              menuItems={menuItems}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        close={() => setOpen(false)}
        icon={true}
        title={props.t("SubNav.disabledDealerTitle")}
        body={props.t("SubNav.disabledDealerBody")}
      />
    </div>
  );
};

export default withTranslation("emp")(SubNavDropdown);
