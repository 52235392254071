import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import {
  Typography,
  Box,
  Grid,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputLabel,
} from "@mui/material";
import { ModalContext } from "../ModalContext";
import EMPButton from "../../EMPButton/EMPButton";
import EMPCheckbox from "../../EMPCheckbox/EMPCheckbox";
import EMPBanner from "../../EMPBanner/EMPBanner";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  AUCTION_SITE_MAP,
  STATE_LIST,
  DEALER_ACTIONS,
  MINORMAX_REDEMPTION_MILEAGE,
} from "../../../../common/Constants";
import { UserContext } from "../../../context/userContext";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import { useFieldSupportStatus } from "../../../hooks/useFieldSupportStatus";
import { useCanAcceptTradeIn } from "../../../hooks/useCanAcceptTradeIn";
import {
  trackConfirmTradeInDetailsPopupAction,
  trackDecisionAction,
  trackTradeInDecisionPopupAction,
} from "../../../services/analytics/OrderDetailsPageAnalytics";
import { tradeInLinkEnabled } from "../../../rules/TradeInRules";
import { ValidatorForm } from "react-material-ui-form-validator";
import EMPValidatedInputField from "../../EMPValidatedInputField/EMPValidatedInputField";
import EMPValidatedDatePicker from "../../EMPValidatedDatePicker/EMPValidatedDatePicker";
import EMPLoader from "../../EMPLoader/EMPLoader";
import EMPValidatedSelectField from "../../EMPValidatedSelectField/EMPValidatedSelectField";
import { useTranslation } from "react-i18next";

const generateRadioLabel = (label, subtitle) => {
  return (
    <Box>
      {label !== "" && <Typography variant="subtitle1">{label}</Typography>}
      {subtitle !== "" && <Typography variant="body1">{subtitle}</Typography>}
    </Box>
  );
};

const Step1 = ({ formData, handleChange, message }) => {
  return (
    <Box sx={{ mb: 6 }}>
      {message !== null && (
        <Box sx={{ mb: 4 }}>
          <EMPBanner
            title="Trade-In Disabled"
            content={message}
            sx={{ mb: 4, p: 2 }}
          />
        </Box>
      )}
      <Typography variant="body1">
        Before this trade-in can be processed, please verify that:
      </Typography>
      <ul style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
        <li>
          <Typography variant="body1">
            The customer is making a new vehicle purchase
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            The dealer has physically seen the vehicle being traded in
          </Typography>
        </li>
      </ul>
      <Box sx={{ mb: 4 }}>
        <EMPCheckbox
          label="I understand and agree to these statements, and I am ready to make a decision on this trade-in vehicle."
          checked={formData.checked1}
          handleChange={(checkedBool) => handleChange("checked1", checkedBool)}
        />
      </Box>
      <Box sx={{ mb: 4 }}>
        <EMPCheckbox
          label="I am authorized to make trade-in decisions on the dealer's behalf."
          checked={formData.checked2}
          handleChange={(checkedBool) => handleChange("checked2", checkedBool)}
        />
      </Box>
    </Box>
  );
};

const Step2 = ({ formData, handleChange, tradeIn, t }) => {
  const isLeased = tradeIn.ownershipType === "LEASED";

  return (
    <Box sx={{ mb: 6 }}>
      <Typography variant="body1" sx={{ mb: 6 }}>
        {isLeased
          ? t("TradeIn.US.dialogs.decisionDialog.body.titleLease")
          : t("TradeIn.US.dialogs.decisionDialog.body.title")}
      </Typography>

      <RadioGroup
        value={formData.decision}
        onChange={(e) => handleChange("decision", e.target.value)}
      >
        <FormControlLabel
          value="accept-and-keep"
          control={<Radio />}
          label={generateRadioLabel(
            isLeased
              ? t("common.actions.tradeInModal.options.3")
              : t("common.actions.tradeInModal.options.0"),
            isLeased
              ? t("common.actions.tradeInModal.acceptAndKeepVehicleLease")
              : t("common.actions.tradeInModal.acceptAndKeepVehicle")
          )}
          sx={{ mb: 3 }}
        />

        {!isLeased && (
          <FormControlLabel
            value="accept-and-send"
            control={<Radio />}
            label={generateRadioLabel(
              t("common.actions.tradeInModal.options.1"),
              t("common.actions.tradeInModal.acceptAndSendToAuction")
            )}
            sx={{ mb: 3 }}
          />
        )}

        <FormControlLabel
          value="reject"
          control={<Radio />}
          label={generateRadioLabel(
            t("common.actions.tradeInModal.options.2"),
            isLeased
              ? t("common.actions.tradeInModal.rejectTradeInLease")
              : t("common.actions.tradeInModal.rejectTradeIn")
          )}
        />
      </RadioGroup>
    </Box>
  );
};

const AcceptAndKeep = ({
  formData,
  handleChange,
  t,
  tradeInDetails,
  handleBack,
  onSubmit,
}) => {
  useEffect(() => {
    ValidatorForm.addValidationRule("isBelowMaxMileage", (value) => {
      const vehicleMileage = tradeInDetails.mileage;
      const calculatedMileage =
        vehicleMileage && vehicleMileage > 0
          ? parseInt(vehicleMileage) + MINORMAX_REDEMPTION_MILEAGE
          : MINORMAX_REDEMPTION_MILEAGE;

      return value <= calculatedMileage;
    });
  });

  return (
    <ValidatorForm onSubmit={onSubmit}>
      <Box sx={{ mb: 6 }}>
        <Typography variant="body1" sx={{ mb: 6 }}>
          To accept this trade-in and apply the Ford-provided valuation to the
          customer&apos;s new vehicle purchase, specify the mileage and date you
          saw the vehicle.
        </Typography>
        <Box sx={{ mb: 6 }}>
          <InputLabel>Offer Redemption Mileage</InputLabel>
          <EMPValidatedInputField
            name="offerRedemptionMileage"
            testId="offer-redemption-mileage-input"
            value={formData.offerRedemptionMileage}
            onChange={(e) =>
              handleChange("offerRedemptionMileage", e.target.value)
            }
            validators={[
              "required",
              "matchRegexp:^[0-9]+$",
              "isBelowMaxMileage",
            ]}
            errorMessages={[
              t("Error.requiredField"),
              t("TradeIn.invalidInputMileage"),
              t("TradeIn.maxValue"),
            ]}
            helperText="Upon turn in, the mileage cannot be more than 250 miles over the entered amount."
          />
        </Box>
        <Box>
          <InputLabel>Date Vehicle was Seen</InputLabel>
          <EMPValidatedDatePicker
            maxDate={new Date()}
            transportFormData={(date) => handleChange("dateVehicleSeen", date)}
            validators={["required"]}
            errorMessages={[t("Error.requiredField")]}
            name="dateVehicleSeen"
          ></EMPValidatedDatePicker>
        </Box>
      </Box>
      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton variant="text" onClick={handleBack}>
              Back
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton variant="contained" submitButton>
              Confirm
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </ValidatorForm>
  );
};

const AcceptAndSend = ({
  orderId,
  formData,
  handleChange,
  onSubmit,
  handleBack,
}) => {
  const queryClient = useQueryClient();
  const handoffMethodRef = useRef(null);
  const [tradeInDetails, setTradeInDetails] = useState(null);

  useEffect(() => {
    if (formData.handoffMethod !== "") {
      handoffMethodRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [formData]);

  useEffect(() => {
    ValidatorForm.addValidationRule("isBelowMaxMileage", (value) => {
      const vehicleMileage = tradeInDetails.mileage;
      const calculatedMileage =
        vehicleMileage && vehicleMileage > 0
          ? parseInt(vehicleMileage) + MINORMAX_REDEMPTION_MILEAGE
          : MINORMAX_REDEMPTION_MILEAGE;

      return value < calculatedMileage;
    });
  });

  useEffect(() => {
    const { tradeIn } = queryClient.getQueryData(["order", { orderId }]);

    if (tradeIn) {
      setTradeInDetails(tradeIn);
    }
  }, []);

  const getAuctionSiteMenuItems = () => {
    const menuItems = [
      {
        text: "Select One",
        value: "",
        disabled: true,
      },
    ];
    Object.keys(AUCTION_SITE_MAP).forEach((code, index) => {
      menuItems.push({
        id: index,
        text: AUCTION_SITE_MAP[code],
        value: AUCTION_SITE_MAP[code],
      });
    });

    return menuItems;
  };

  const transformStateList = (STATE_LIST) => {
    const transformedList = STATE_LIST.map((state, index) => ({
      id: index + 1,
      value: state,
      text: state,
    }));

    return [
      { id: 0, value: "Select One", text: "Select One" },
      ...transformedList,
    ];
  };

  return (
    <ValidatorForm onSubmit={onSubmit}>
      <Box sx={{ mb: 6 }}>
        <Typography variant="body1" sx={{ mb: 6 }}>
          Please provide your contact information, vehicle mileage (must be
          within 250 miles of customer-entered mileage to be eligible) and date
          you saw the vehicle, and select a handover method. You can choose to
          drop off the vehicle at your local Ford-sponsored auction location or
          have the auction house pick up the vehicle at the standard rate.
          (Note: You must be authorized to make trade-in decisions on the
          dealer&rsquo;s behalf.)
        </Typography>
        <Box sx={{ mb: 6 }}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <InputLabel>Contact Full Name</InputLabel>
              <EMPValidatedInputField
                value={formData.dealerContactName}
                onChange={(e) =>
                  handleChange("dealerContactName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Contact Email</InputLabel>
              <EMPValidatedInputField
                value={formData.dealerContactEmail}
                onChange={(e) =>
                  handleChange("dealerContactEmail", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 6 }}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <InputLabel>Phone</InputLabel>
              <EMPValidatedInputField
                value={formData.dealerContactPhone}
                onChange={(e) =>
                  handleChange("dealerContactPhone", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>VIN being traded</InputLabel>
              <EMPValidatedInputField
                value={formData.vinBeingPurchased}
                onChange={(e) =>
                  handleChange("vinBeingPurchased", e.target.value)
                }
                disabled={formData.vinBeingPurchased !== ""}
                helperText="This value comes from the customer-entered information."
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 6 }}>
          <InputLabel sx={{ mb: 1 }}>Date Vehicle was Seen</InputLabel>
          <EMPValidatedDatePicker
            maxDate={new Date()}
            transportFormData={(date) => handleChange("dateVehicleSeen", date)}
          ></EMPValidatedDatePicker>
        </Box>
        <Box sx={{ mb: 6 }}>
          <InputLabel>Offer Redemption Mileage</InputLabel>
          <EMPValidatedInputField
            name="offerRedemptionMileage"
            testId="offer-redemption-mileage-input"
            value={formData.offerRedemptionMileage}
            onChange={(e) =>
              handleChange("offerRedemptionMileage", e.target.value)
            }
            validators={[
              "required",
              "matchRegexp:^[0-9]+$",
              "isBelowMaxMileage",
            ]}
            errorMessages={[
              "Enter valid redemption mileage",
              "Enter valid redemption mileage. Mileage must only include numbers.",
              "Mileage cannot be more than 250 miles over the customer-entered amount.  Go back to reject this trade-in and contact the customer.",
            ]}
            helperText="Upon turn in, the mileage cannot be more than 250 miles over the customer-entered amount."
          />
        </Box>
        <Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Handover Method
          </Typography>
          <RadioGroup
            onChange={(e) => handleChange("handoffMethod", e.target.value)}
            row
          >
            <FormControlLabel
              value="dropoff"
              control={<Radio />}
              label={generateRadioLabel("", "Drop-off")}
            />
            <FormControlLabel
              value="pickup"
              control={<Radio />}
              label={generateRadioLabel("", "Pickup")}
            />
          </RadioGroup>
        </Box>
        {formData.handoffMethod !== "" && (
          <Box sx={{ mt: 6 }} ref={handoffMethodRef}>
            {formData.handoffMethod === "dropoff" && (
              <>
                <Typography variant="body1" sx={{ mb: 4 }}>
                  By selecting drop-off, you understand:
                </Typography>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    <Typography variant="body1">
                      The dealer is responsible for delivering the vehicle and
                      title to the Ford auction location prior to the specified
                      date
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      If the vehicle and/or title are delayed, your dealer will
                      be subject to additional fees and/or program eligibility.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      This action cannot be undone
                    </Typography>
                  </li>
                </ul>

                <Typography variant="body1" sx={{ mt: 6, mb: 4 }}>
                  Please specify a Ford auction location and date:
                </Typography>

                <Box sx={{ mb: 6 }}>
                  <InputLabel sx={{ mb: 1 }}>Auction Location</InputLabel>
                  <EMPValidatedSelectField
                    name="auctionLocation"
                    testId="auction-location-select"
                    value={formData.auctionLocation}
                    onChange={(e) =>
                      handleChange("auctionLocation", e.target.value)
                    }
                    menuItems={getAuctionSiteMenuItems()}
                    validators={["required"]}
                    errorMessages={["Select an Auction Location"]}
                  ></EMPValidatedSelectField>
                </Box>
                <Box>
                  <InputLabel sx={{ mb: 1 }}>
                    Vehicle/Title arrive by date
                  </InputLabel>
                  <EMPValidatedInputField
                    value={formData.vehicleArriveByDate}
                    helperText="This value comes from the customer-entered information."
                    disabled
                    testId="vehicle-arrive-by-date-field"
                  />
                </Box>
              </>
            )}
            {formData.handoffMethod === "pickup" && (
              <>
                <Typography variant="body1" sx={{ mb: 6 }}>
                  By selecting pick up, you understand the auction house is
                  responsible for picking up the vehicle, and that you will be
                  subject to their transportation fees. Please specify an
                  auction house and contact information for the person the
                  auction should contact for pick-up directions/questions.
                </Typography>
                <Box sx={{ mb: 6 }}>
                  <InputLabel sx={{ mb: 1 }}>Auction Location</InputLabel>
                  <EMPValidatedSelectField
                    name="auctionLocation"
                    value={formData.auctionLocation}
                    onChange={(e) =>
                      handleChange("auctionLocation", e.target.value)
                    }
                    menuItems={getAuctionSiteMenuItems()}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  ></EMPValidatedSelectField>
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <InputLabel>Contact Full Name</InputLabel>
                      <EMPValidatedInputField
                        value={formData.auctionContactName}
                        onChange={(e) =>
                          handleChange("auctionContactName", e.target.value)
                        }
                      ></EMPValidatedInputField>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Contact Email</InputLabel>
                      <EMPValidatedInputField
                        value={formData.auctionContactEmail}
                        onChange={(e) =>
                          handleChange("auctionContactEmail", e.target.value)
                        }
                      ></EMPValidatedInputField>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Phone</InputLabel>
                      <EMPValidatedInputField
                        value={formData.auctionContactPhone}
                        onChange={(e) =>
                          handleChange("auctionContactPhone", e.target.value)
                        }
                      ></EMPValidatedInputField>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <InputLabel>Address</InputLabel>
                      <EMPValidatedInputField
                        value={formData.auctionContactAddress}
                        onChange={(e) =>
                          handleChange("auctionContactAddress", e.target.value)
                        }
                      ></EMPValidatedInputField>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>City</InputLabel>
                      <EMPValidatedInputField
                        value={formData.auctionContactCity}
                        onChange={(e) =>
                          handleChange("auctionContactCity", e.target.value)
                        }
                      ></EMPValidatedInputField>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel sx={{ mb: 1 }}>State</InputLabel>
                      <EMPValidatedSelectField
                        name="state"
                        value={formData.auctionContactState}
                        onChange={(e) =>
                          handleChange("auctionContactState", e.target.value)
                        }
                        menuItems={transformStateList(STATE_LIST)}
                      ></EMPValidatedSelectField>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Zip</InputLabel>
                      <EMPValidatedInputField
                        value={formData.auctionContactZip}
                        onChange={(e) =>
                          handleChange("auctionContactZip", e.target.value)
                        }
                      ></EMPValidatedInputField>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <InputLabel sx={{ mb: 1 }}>
                    Vehicle/Title arrive by date
                  </InputLabel>
                  <EMPValidatedInputField
                    value={formData.vehicleArriveByDate}
                    disabled
                    helperText="Help text"
                    testId="vehicle-arrive-by-date-field"
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton variant="text" onClick={handleBack}>
              Back
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton variant="contained" submitButton>
              Send to Auction
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </ValidatorForm>
  );
};

const Reject = ({ formData, handleChange, t, handleBack, onSubmit }) => {
  return (
    <ValidatorForm onSubmit={onSubmit}>
      <Box sx={{ mb: 6 }}>
        <Typography variant="body1" sx={{ mb: 4 }}>
          When you reject this trade-in, you confirm that:
        </Typography>
        <ul style={{ paddingLeft: "20px" }}>
          <li>
            <Typography variant="body1">
              The trade certificate will be voided.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              You are responsible for notifying your finance department of the
              trade-in status.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              You can still work with the customer to facilitate a transaction
              in person.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" sx={{ mb: 6 }}>
          This action cannot be undone. Enter the date you saw the vehicle then
          select a reason for rejection to continue.
        </Typography>
        <Box sx={{ mb: 6 }}>
          <InputLabel sx={{ mb: 1 }}>Date Vehicle was Seen</InputLabel>
          <EMPValidatedDatePicker
            maxDate={new Date()}
            transportFormData={(date) => handleChange("dateVehicleSeen", date)}
            validators={["required"]}
            errorMessages={[t("Error.requiredField")]}
            name="dateVehicleSeen"
          ></EMPValidatedDatePicker>
        </Box>
        <Box>
          <InputLabel sx={{ mb: 1 }}>Reason for Rejecting</InputLabel>
          <EMPValidatedSelectField
            name="rejectionReason"
            testId="rejection-reason-select"
            value={formData.rejectionReason}
            validators={["required"]}
            errorMessages={[t("Error.requiredField")]}
            onChange={(e) => handleChange("rejectionReason", e.target.value)}
            menuItems={[
              { id: 0, value: "", text: "Select One", disabled: true },
              {
                id: 1,
                value: "Vehicle condition does not match customer inputs",
                text: "Vehicle condition does not match customer inputs",
                disabled: false,
              },
              {
                id: 2,
                value: "Vehicle more than 250 miles over stated mileage",
                text: "Vehicle more than 250 miles over stated mileage",
                disabled: false,
              },
              {
                id: 3,
                value: "Remaining balance inaccurate",
                text: "Remaining balance inaccurate",
                disabled: false,
              },
              {
                id: 4,
                value: "Customer no longer wants to trade-in vehicle",
                text: "Customer no longer wants to trade-in vehicle",
                disabled: false,
              },
            ]}
          />
        </Box>
      </Box>
      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton variant="text" onClick={handleBack}>
              Back
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton variant="contained" submitButton>
              Confirm
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </ValidatorForm>
  );
};

const TradeInDecision = ({ orderId }) => {
  const { handleModal } = useContext(ModalContext);
  const user = useContext(UserContext);
  const { market, dealerId, salesCode, dealerBrand, userId } = user;
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const [currentStep, setCurrentStep] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [tradeInDisabledReason, setTradeInDisabledReason] = useState(null);
  const queryClient = useQueryClient();
  const isFieldSupport = useFieldSupportStatus();
  const canAcceptTradeIn = useCanAcceptTradeIn(orderId);
  const hasTradeInLinkEnabled = tradeInLinkEnabled(orderId);
  const { t } = useTranslation();

  const initialFormData = {
    checked1: false,
    checked2: false,
    decision: null,
    rejectionReason: "",
    dealerContactName: "",
    dealerContactEmail: "",
    dealerContactPhone: "",
    vinBeingPurchased: "",
    dateVehicleSeen: "",
    offerRedemptionMileage: "",
    handoffMethod: "",
    auctionLocation: "",
    vehicleArriveByDate: "",
    auctionContactName: "",
    auctionContactEmail: "",
    auctionContactPhone: "",
    auctionContactAddress: "",
    auctionContactCity: "",
    auctionContactState: "",
    auctionContactZip: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const { tradeIn } = queryClient.getQueryData(["order", { orderId }]);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef.current]);

  useEffect(() => {
    setFormData({
      ...formData,
      vehicleArriveByDate: tradeIn?.arriveByDate || "",
      vinBeingPurchased: tradeIn?.vin || "",
    });

    return () => {
      setFormData(initialFormData);
    };
  }, []);

  useEffect(() => {
    if (!canAcceptTradeIn) {
      setTradeInDisabledReason(
        "This user does not have permission to accept trade-ins, the trade-in time has expired, or this trade-in has already been evaluated."
      );
    }

    if (!hasTradeInLinkEnabled) {
      setTradeInDisabledReason(
        "Trade-in cannot be confirmed at this time. Please confirm that the purchase request has been accepted."
      );
    }
  }, []);

  useEffect(() => {
    if (currentStep === 1 && !isFieldSupport) {
      setDisabled(
        !(
          formData.checked1 &&
          formData.checked2 &&
          canAcceptTradeIn &&
          !!hasTradeInLinkEnabled
        )
      );
    } else if (currentStep === 2) {
      setDisabled(formData.decision === null);
    } else {
      setDisabled(false);
    }
  }, [currentStep, formData.checked1, formData.checked2, formData.decision]);

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleNext = () => {
    if (currentStep === 1) {
      trackConfirmTradeInDetailsPopupAction("confirm", orderId, user);
    } else if (currentStep === 2) {
      trackTradeInDecisionPopupAction("confirm", orderId, user);
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    if (currentStep === 1) {
      trackConfirmTradeInDetailsPopupAction("cancel", orderId, user);
      handleModal(false, false);
      setFormData(initialFormData);
    } else if (currentStep === 2) {
      trackTradeInDecisionPopupAction("cancel", orderId, user);
      setCurrentStep((prevStep) => prevStep - 1);
    } else if (currentStep === 3) {
      trackDecisionAction("cancel", formData.decision, orderId, user);
      setCurrentStep((prevStep) => prevStep - 1);
      setFormData(initialFormData);
    }
  };

  const handleErrorMessage = () => {
    let errorMessage =
      "An unexpected error occurred. Please use the back button and try again.";
    if (actionSubmit.error.response && actionSubmit.error.response.data) {
      try {
        const errorData = actionSubmit.error.response.data.message;
        if (errorData) {
          errorMessage =
            typeof errorData === "string" ? JSON.parse(errorData) : errorData;
          return errorMessage.errors[0].message;
        } else {
          return errorMessage;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Failed to parse error message:", e);
      }
    } else {
      return errorMessage;
    }
  };

  const formatPhoneNumber = (phone) => {
    return phone.replace(/\D/g, "");
  };

  const uviSubmit = useMutation({
    mutationFn: () => {
      let uvisRequest = {};

      const {
        vin,
        customerCode,
        categoryCode,
        appraisalId,
        modelMarketPrice,
        modelYear,
        vehicleMake,
        vehicleModel,
        appraisalAmount,
        conditionRating,
        arriveByDate,
      } = tradeIn;

      const dropoffRequest = {
        vin,
        customerCode,
        categoryCode,
        contactName: formData.dealerContactName,
        contactEmail: formData.dealerContactEmail,
        contactPhoneNumber: formatPhoneNumber(formData.dealerContactPhone),
        leadIdentifier: appraisalId,
        gdiaMarketPrice: modelMarketPrice,
        offerRedemptionMileage: formData.offerRedemptionMileage,
        modelYear,
        make: vehicleMake,
        model: vehicleModel,
        tradeInAppraisal: appraisalAmount,
        dropOffPickupInd: "Y",
        tradeInConditionRating: conditionRating,
        requiredByArrivalDate: arriveByDate,
        dropOffPickUpLocation: formData.auctionLocation,
        pickupLocationName: "",
        pickupContactName: "",
        pickupEmail: "",
        pickupPhone: "",
        pickupAddress: "",
        pickupCity: "",
        pickupState: "",
        pickupZip: "",
        pickupCountry: "",
      };

      if (formData.handoffMethod === "dropoff") {
        uvisRequest = {
          ...dropoffRequest,
        };
      }

      if (formData.handoffMethod === "pickup") {
        uvisRequest = {
          ...dropoffRequest,
          dropOffPickupInd: "N",
          pickupLocationName: "Ford ARS DTP Trade-In",
          pickupContactName: formData.auctionContactName,
          pickupEmail: formData.auctionContactEmail,
          pickupPhone: formatPhoneNumber(formData.auctionContactPhone),
          pickupAddress: formData.auctionContactAddress,
          pickupCity: formData.auctionContactCity,
          pickupState: formData.auctionContactState,
          pickupZip: formData.auctionContactZip,
          pickupCountry: "USA",
        };
      }

      return CustomerReservationsClient.sendToUVIS(
        sessionStorage.getItem("adfs.token"),
        uvisRequest
      );
    },
    onSuccess: () => {
      actionSubmit.mutate();
    },
  });

  const actionSubmit = useMutation({
    mutationFn: () => {
      let decisionRequest = {};
      if (formData.decision === "reject") {
        decisionRequest = {
          reservationId: orderId,
          dealerAction: DEALER_ACTIONS.TRADE_IN_REJECTED,
          acceptedDate: new Date(formData.dateVehicleSeen).toISOString(),
          dealerRejectedReason: formData.rejectionReason,
        };
      }

      if (formData.decision === "accept-and-keep") {
        decisionRequest = {
          reservationId: orderId,
          dealerAction: DEALER_ACTIONS.TRADE_IN_ACCEPT_KEEP,
          acceptedDate: new Date(formData.dateVehicleSeen).toISOString(),
        };
      }

      if (formData.decision === "accept-and-send") {
        decisionRequest = {
          reservationId: orderId,
          dealerAction: DEALER_ACTIONS.TRADE_IN_ACCEPT_AUCTION,
          acceptedDate: new Date(formData.dateVehicleSeen).toISOString(),
        };
      }

      return CustomerReservationsClient.actionOnOrder(
        sessionStorage.getItem("adfs.token"),
        userCommonId,
        decisionRequest,
        userId
      );
    },
    onSuccess: () => {
      handleModal(false, false);
      queryClient.invalidateQueries({ queryKey: ["order", { orderId }] });
      trackDecisionAction("confirm", formData.decision, orderId, user);
    },
  });

  if (actionSubmit.isLoading) {
    return (
      <Box sx={{ py: 10, minHeight: contentHeight }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item>
            <EMPLoader loadingMessage="Submitting..." />
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (actionSubmit.isError) {
    return (
      <>
        <Box sx={{ minHeight: contentHeight, pb: 4 }}>
          <Typography variant="body2" sx={{ lineBreak: "anywhere" }}>
            {handleErrorMessage()}
          </Typography>
        </Box>
        <DialogActions sx={{ padding: "16px 0" }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <EMPButton
                variant="text"
                onClick={() => {
                  actionSubmit.reset();
                  handleBack();
                }}
              >
                Back
              </EMPButton>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    );
  }

  return (
    <div>
      {formData.decision && currentStep > 2 && (
        <Typography variant="caption" sx={{ mb: 1 }}>
          Decision: {formData.decision}
        </Typography>
      )}
      {currentStep === 1 && (
        <Step1
          formData={formData}
          handleChange={handleChange}
          message={tradeInDisabledReason}
        />
      )}
      {currentStep === 2 && (
        <Step2
          formData={formData}
          handleChange={handleChange}
          tradeIn={tradeIn}
          t={t}
        />
      )}
      {currentStep === 3 && formData.decision === "accept-and-keep" && (
        <AcceptAndKeep
          formData={formData}
          handleChange={handleChange}
          t={t}
          tradeInDetails={tradeIn}
          currentStep={currentStep}
          handleBack={handleBack}
          onSubmit={() => actionSubmit.mutate()}
        />
      )}

      {currentStep === 3 && formData.decision === "accept-and-send" && (
        <AcceptAndSend
          orderId={orderId}
          formData={formData}
          handleChange={handleChange}
          t={t}
          handleBack={handleBack}
          tradeInDetails={tradeIn}
          onSubmit={() => uviSubmit.mutate()}
        />
      )}

      {currentStep === 3 && formData.decision === "reject" && (
        <Reject
          formData={formData}
          t={t}
          tradeInDetails={tradeIn}
          handleChange={handleChange}
          handleBack={handleBack}
          onSubmit={() => actionSubmit.mutate()}
        />
      )}

      {currentStep < 3 && (
        <DialogActions sx={{ padding: "16px 0" }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <EMPButton variant="text" onClick={handleBack}>
                {currentStep === 1 ? "Cancel" : "Back"}
              </EMPButton>
            </Grid>
            <Grid item>
              {currentStep <= 2 && (
                <EMPButton
                  variant="contained"
                  onClick={handleNext}
                  disabled={disabled}
                >
                  Next
                </EMPButton>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </div>
  );
};

export default TradeInDecision;
