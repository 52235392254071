import { usePermission } from "../hooks/usePermission";
import {
  PURCHASE_DEPOSIT_STATUS,
  PURCHASE_REFUND_STATUS,
} from "../../common/Constants";

export function canViewPurchaseDeposit() {
  return usePermission("vehicleHold");
}

export function canAcceptPurchaseDeposit() {
  return usePermission("acceptDepositNA:write");
}

export function canRejectPurchaseDeposit() {
  return usePermission("rejectDepositNA:write");
}

export function canRefundPurchaseDeposit() {
  return usePermission("refundPurchaseDepositNA:write");
}

export function isPurchaseDepositStatusValid(purchaseDeposit) {
  if (!purchaseDeposit) {
    return false;
  }

  const { acceptRejectStatus } = purchaseDeposit;

  return [
    PURCHASE_DEPOSIT_STATUS.ACCEPTED,
    PURCHASE_DEPOSIT_STATUS.REJECTED,
    PURCHASE_DEPOSIT_STATUS.DECLINED,
  ].includes(acceptRejectStatus);
}

export function isPurchaseDepositStatusAccepted(purchaseDeposit) {
  if (!purchaseDeposit) {
    return false;
  }

  const { acceptRejectStatus } = purchaseDeposit;
  return acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.ACCEPTED;
}

// check if purchase deposit status is invalid
export function isPurchaseDepositStatusInvalid(purchaseDeposit) {
  if (!purchaseDeposit) {
    return false;
  }

  const { acceptRejectStatus } = purchaseDeposit;

  return [
    PURCHASE_DEPOSIT_STATUS.DECLINED,
    PURCHASE_DEPOSIT_STATUS.FAILED,
  ].includes(acceptRejectStatus);
}

// check for refund status
export function getRefundStatus(purchaseDeposit) {
  if (!purchaseDeposit) {
    return null;
  }

  const { refundStatus } = purchaseDeposit;
  return refundStatus;
}

// is refund status invalid
export function isRefundStatusInvalid(purchaseDeposit) {
  const refundStatus = getRefundStatus(purchaseDeposit);
  return (
    refundStatus === PURCHASE_REFUND_STATUS.ACCEPTED ||
    refundStatus === PURCHASE_REFUND_STATUS.DECLINED ||
    refundStatus === PURCHASE_REFUND_STATUS.REFUNDED_OFFLINE
  );
}

export function isRefundStatusAccepted(purchaseDeposit) {
  const refundStatus = getRefundStatus(purchaseDeposit);
  return (
    refundStatus === PURCHASE_REFUND_STATUS.ACCEPTED ||
    refundStatus === PURCHASE_REFUND_STATUS.REFUNDED_OFFLINE
  );
}
