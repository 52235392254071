import React from "react";
import { styled } from "@mui/material/styles";
import MotionInfo from "../Icons/MotionInfo";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    border: "1px solid #808080",
    borderRadius: "4px",
    padding: "8px",
    lineHeight: "20px",
    font: theme.typography.body2,
  },
}));

const SharedToolTip = (props) => {
  const placement = props.placement ? props.placement : "top";

  return (
    <LightTooltip
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      placement={placement}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 2],
              },
            },
          ],
        },
      }}
      title={<Typography variant="body2">{props.body}</Typography>}
    >
      <MotionInfo fontSize="10" />
    </LightTooltip>
  );
};

export default SharedToolTip;
