import React, { useEffect, useState } from "react";
import styles from "./EMPStatusCard.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import EMPButton from "../EMPButton/EMPButton";
import HasPermissionTo from "../../../common/HasPermissionTo";

// Available Props
// cardTitle: string
// status: string (default, warning, success)
// hasActionButton: Boolean
// actionButtonText: string
// onActionClick: func
// icon: Component
// user: object
// actionButtonPermission: Array

const EMPStatusHeadline = (props) => {
  const { cardTitle, icon } = props;
  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      {icon && (
        <Grid item>
          <Box mr={1}>{icon}</Box>
        </Grid>
      )}
      <Grid item>
        <Typography variant="h3" data-testid="status-headline">
          {cardTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

const EMPStatusCard = (props) => {
  const [color, setColor] = useState("primary.main");
  const {
    cardTitle,
    status,
    hasActionButton,
    children,
    onActionClick,
    actionButtonText,
    user,
    icon,
    actionButtonPermission,
  } = props;

  useEffect(() => {
    updateColor(status);
  }, [status]);

  const updateColor = (statusColorProp) => {
    setColor(statusColorProp);
  };

  return (
    <Grid
      container
      spacing={0}
      justifyContent="space-between"
      alignItems="center"
      className={styles.statusCardContainer}
      style={color ? color.container : null}
    >
      <Grid item>
        <EMPStatusHeadline cardTitle={cardTitle} icon={icon} />
      </Grid>
      {hasActionButton && actionButtonPermission && (
        <HasPermissionTo
          perform={actionButtonPermission}
          permissions={user.permissions.rolePermissions}
          render={() => {
            return (
              <Grid item>
                <EMPButton
                  onClick={onActionClick}
                  testId="action-button"
                  variant="text"
                >
                  {actionButtonText}
                </EMPButton>
              </Grid>
            );
          }}
        ></HasPermissionTo>
      )}
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Box mt={2}>{children}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EMPStatusCard;
