import React from "react";
import FordLogo from "../../../common/FordLogo";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import LincolnLogo from "../../../common/LincolnLogo";
import { Box, Grid, Typography } from "@mui/material";

const formatBranding = (str, showLogoProp) => {
  let text;

  if (showLogoProp) {
    const words = str.split(" ");
    text = (
      <>
        {words[0]} {words[1]} {words[2]}
      </>
    );
  } else {
    text = str;
  }

  return (
    <Typography variant={showLogoProp ? "body1" : "body2"} component="p">
      {text}
    </Typography>
  );
};

const EMPBranding = (props) => {
  return (
    <Grid container alignItems="center">
      {props.showLogo && (
        <Box sx={{ marginRight: 4 }}>
          {IS_FORD_BRAND(props.brand) ? (
            <FordLogo width={props.width} />
          ) : (
            <LincolnLogo width={props.width} />
          )}
        </Box>
      )}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        {formatBranding(props.t("GeneralEMP.branding"), props.showLogo)}
      </Box>
    </Grid>
  );
};

export default withTranslation("emp")(EMPBranding);
