const stylesOverride = {
  tableHeaderFord: {
    backgroundColor: "#ffffff",
  },
  tableHeaderLincoln: {
    backgroundColor: "#d2d2d2",
  },
  cellHeader: {
    verticalAlign: "bottom",
    paddingBottom: 15,
    paddingLeft: 16,
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
  },
  cellBody: {
    width: "15%",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    padding: "0px 0px 0px 16px",
  },
  dealer: {
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    textDecoration: "underline",
    width: "15%",
  },
};

export default stylesOverride;
