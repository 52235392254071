import { useQueryClient } from "@tanstack/react-query";
import {
  FINANCE_PRODUCT_TYPE,
  PIPELINE,
  PENDING_CREDIT_APP,
  PURCHASE_TYPES,
  PURCHASE_INFO_CONTRACT_STATUS,
} from "../../common/Constants";

function applicationStatus(paymentDesc) {
  return paymentDesc &&
    paymentDesc.length > 0 &&
    paymentDesc[paymentDesc.length - 1].status
    ? paymentDesc[paymentDesc.length - 1].status
    : "--";
}

export function getPaymentType(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);
  const { financeProvider, paymentType } = customerReservation;

  if (
    paymentType.toLowerCase() === FINANCE_PRODUCT_TYPE.FINANCE &&
    financeProvider === "RouteOne"
  ) {
    return "Ford Credit Financing";
  }

  if (paymentType === FINANCE_PRODUCT_TYPE.THIRD_PARTY) {
    return "Third Party Financing";
  }

  if (paymentType.toLowerCase() === FINANCE_PRODUCT_TYPE.OTHER) {
    return "Other Financing Options";
  }

  if (paymentType.toLowerCase() === FINANCE_PRODUCT_TYPE.CASH) {
    return "Cash";
  }

  if (paymentType.toLowerCase() === FINANCE_PRODUCT_TYPE.LEASE) {
    return "Lease";
  }
}

export function hasCreditApp(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);

  if (!customerReservation) {
    return null;
  }

  const { paymentType, subJourneyType, financeStatus } = customerReservation;
  const isPipeline = subJourneyType === PIPELINE;
  const isFordCreditType = [
    FINANCE_PRODUCT_TYPE.FINANCE,
    FINANCE_PRODUCT_TYPE.FORD_OPTIONS,
    FINANCE_PRODUCT_TYPE.FLEX_BUY,
  ].includes(paymentType.toLowerCase());
  const displayFordCreditApp = !isPipeline && isFordCreditType;
  const display3pfCreditApp =
    paymentType === FINANCE_PRODUCT_TYPE.THIRD_PARTY && financeStatus;

  return displayFordCreditApp || display3pfCreditApp || false;
}

export function getFordCreditAppStatus(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);
  const { paymentDescription } = customerReservation;

  const creditAppStatus =
    applicationStatus(paymentDescription) === PENDING_CREDIT_APP
      ? "Pending"
      : applicationStatus(paymentDescription);

  return creditAppStatus;
}

export function get3pfCreditAppStatus(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);
  const { financeStatus } = customerReservation;

  return financeStatus && financeStatus.toUpperCase();
}

export function getEsignStatus(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);
  const { purchaseType } = customerReservation;

  return purchaseType === PURCHASE_TYPES.SIGN_ONLINE ? "Yes" : "No";
}

export function displayAlternativeLending(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);
  const { paymentType, alternativeLenderSelection } = customerReservation;

  return (
    (paymentType === FINANCE_PRODUCT_TYPE.FINANCE &&
      alternativeLenderSelection) ||
    "--"
  );
}

export function getContractStatus(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);
  const { purchaseInfo } = customerReservation;

  return purchaseInfo &&
    purchaseInfo.contractStatus === PURCHASE_INFO_CONTRACT_STATUS.SIGNED
    ? "Contract Signed"
    : "--";
}
