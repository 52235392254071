import React, { useContext } from "react";
import EMPDataCard from "../../shared/components/EMPDataCard/EMPDataCard";
import { Grid, Box, Typography } from "@mui/material";
import { formatToSimpleDate, capitalizeText } from "../../utils/EmpUtil";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import EMPBanner from "../../shared/components/EMPBanner/EMPBanner";
import { ModalContext } from "../../shared/components/EMPModal/ModalContext";
import TradeInDecision from "../../shared/components/EMPModal/ModalFragments/TradeInDecision";
import { useTranslation } from "react-i18next";
import {
  TRADE_IN_OWNERSHIP_TYPES,
  TRADE_IN_STATUS,
} from "../../shared/constants/TradeInConstants";
import LeasedTradeInTable from "./tradein-tables/LeasedTradeInTable";
import OwnedTradeInTable from "./tradein-tables/OwnedTradeInTable";
import FinancedTradeInTable from "./tradein-tables/FinancedTradeInTable";

const extractAppraisalData = (content, t) => {
  const { tradeIn } = content;

  if (tradeIn) {
    const appraisalData = {
      appraisalId: tradeIn.appraisalId,
      appraisalVehicle: `${tradeIn.modelYear} ${tradeIn.vehicleMake} ${
        tradeIn.vehicleModel
      } ${tradeIn.trim || ""}`,
      netEquity: tradeIn?.netEquity,
      amounts: [],
    };

    if (tradeIn.ownershipType === "LEASED") {
      const leasedItems = [
        {
          heading: t("tradeIn.quoted"),
          amount: Number(tradeIn.totalOutstandingBalanceAmount),
          tooltip: {
            body: t(
              "OrderDetailsConsumerDeposit.tradeInObligationQuotedTooltipBody"
            ),
          },
        },
        {
          heading: t("tradeIn.enteredByCustomer"),
          amount: tradeIn?.enteredObligation
            ? Number(tradeIn.enteredObligation)
            : 0,
          tooltip: {
            body: t(
              "OrderDetailsConsumerDeposit.tradeInObligationEnteredTooltipBody"
            ),
          },
        },
      ];

      appraisalData.amounts.push(...leasedItems);
    }

    if (tradeIn.ownershipType === "FINANCED") {
      const financedItems = [
        {
          id: "appraisal",
          heading: "Appraisal Amount",
          amount: Number(tradeIn.appraisalAmount),
          tooltip: {
            body: t(
              "OrderDetailsConsumerDeposit.tradeInAppraisalAmountTooltipBody"
            ),
          },
        },
      ];

      if (tradeIn.enteredBalance) {
        financedItems.push({
          id: "entered",
          heading: t("TradeIn.US.enteredBalance"),
          amount: -Number(tradeIn.enteredBalance),
          tooltip: {
            body: t(
              "OrderDetailsConsumerDeposit.tradeInRemainingBalanceEnteredTooltipBody"
            ),
          },
        });
      } else {
        financedItems.push({
          id: "remaining",
          heading: t("TradeIn.US.remainingBalance"),
          amount: -Number(tradeIn.remainingBalance),
          tooltip: {
            body: t(
              "OrderDetailsConsumerDeposit.tradeInRemainingBalanceQuotedTooltipBody"
            ),
          },
        });
      }

      appraisalData.amounts.push(...financedItems);
    }

    if (tradeIn.ownershipType === "OWNED") {
      const ownedItems = [
        {
          heading: "Appraisal Amount",
          amount: Number(tradeIn.appraisalAmount),
          tooltip: {
            body: t(
              "OrderDetailsConsumerDeposit.tradeInAppraisalAmountTooltipBody"
            ),
          },
        },
      ];

      appraisalData.amounts.push(...ownedItems);
    }

    return appraisalData;
  }
};

const extractTradeInData = (content, t) => {
  const { tradeIn } = content;
  const hasTradeInStatus = tradeIn &&
    tradeIn.status !== null && {
      heading: t("tradeIn.fields.0"),
      content:
        tradeIn.status === TRADE_IN_STATUS.ACCEPT_KEEP
          ? t("tradeIn.acceptAndKeep")
          : tradeIn.status === TRADE_IN_STATUS.REJECTED
          ? t("tradeIn.rejected")
          : tradeIn.status === TRADE_IN_STATUS.ACCEPT_AUCTION
          ? t("tradeIn.acceptAndSend")
          : tradeIn.status,
      highlight: true,
      color: "success",
    };

  const acceptedDateNAField = tradeIn &&
    tradeIn.acceptedDateNA && {
      heading: t("tradeIn.fields.2"),
      content: formatToSimpleDate(tradeIn.acceptedDateNA),
    };

  const showActionedBy = tradeIn &&
    tradeIn.userId && {
      heading: t("tradeIn.fields.3"),
      content: tradeIn.userId,
    };

  const showRejectionReason = tradeIn &&
    tradeIn.rejectedReason && {
      heading: t("tradeIn.fields.1"),
      content: tradeIn.rejectedReason,
    };

  return (
    tradeIn && [
      ...(hasTradeInStatus ? [hasTradeInStatus] : []),
      ...(showRejectionReason ? [showRejectionReason] : []),
      ...(acceptedDateNAField ? [acceptedDateNAField] : []),
      ...(showActionedBy ? [showActionedBy] : []),

      {
        heading: t("tradeIn.fields.4"),
        content: capitalizeText(tradeIn.ownershipType),
      },
      { heading: t("tradeIn.fields.5"), content: tradeIn.vin },
      {
        heading: t("tradeIn.fields.6"),
        content: tradeIn.expiryDateTimestamp
          ? formatToSimpleDate(tradeIn.expiryDateTimestamp)
          : "--",
      },
      { heading: t("tradeIn.fields.7"), content: tradeIn.modelYear },
      {
        heading: t("tradeIn.fields.8"),
        content: tradeIn.mileage ? `${tradeIn.mileage} miles` : "--",
      },
      {
        heading: t("tradeIn.fields.9"),
        content: tradeIn.conditionRating
          ? Number(tradeIn.conditionRating).toFixed(1)
          : "--",
      },
    ]
  );
};

const extractConditionData = (content, t) => {
  const { tradeIn } = content;

  return (
    tradeIn && [
      {
        heading: t("tradeIn.fields.10"),
        content: tradeIn.overallCondition || "--",
      },
      {
        heading: t("tradeIn.fields.11"),
        content: tradeIn.hasOdors === true ? "Yes" : "No",
      },
      {
        heading: t("tradeIn.fields.12"),
        content: tradeIn.hasMajorDamage === true ? "Yes" : "No",
      },
      {
        heading: t("tradeIn.fields.13"),
        content: tradeIn.hasMinorDamage === true ? "Yes" : "No",
      },
      {
        heading: t("tradeIn.fields.14"),
        content: tradeIn.hasIlluminatedWarningLights === true ? "Yes" : "No",
      },
      {
        heading: t("tradeIn.fields.15"),
        content: tradeIn.hasAccidents === true ? "Yes" : "No",
      },
    ]
  );
};

const TradeIn = ({ content, orderId }) => {
  const { t } = useTranslation();
  const dealerOptIn = content?.customerReservation?.dealerTradeInOptIn;
  const appraisalDetails = extractAppraisalData(content, t);
  const tradeInVehicleDetails = extractTradeInData(content, t);
  const tradeInConditionDetails = extractConditionData(content, t);
  const { handleModal } = useContext(ModalContext);

  return content.tradeIn !== null ? (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <EMPBanner
          title={t("common.banners.estimatedTradeIn.title")}
          content={t("common.banners.estimatedTradeIn.content")}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        {content.tradeIn.ownershipType.toUpperCase() ===
          TRADE_IN_OWNERSHIP_TYPES.LEASED && (
          <LeasedTradeInTable t={t} appraisalDetails={appraisalDetails} />
        )}
        {content.tradeIn.ownershipType.toUpperCase() ===
          TRADE_IN_OWNERSHIP_TYPES.OWNED && (
          <OwnedTradeInTable t={t} appraisalDetails={appraisalDetails} />
        )}
        {content.tradeIn.ownershipType.toUpperCase() ===
          TRADE_IN_OWNERSHIP_TYPES.FINANCED && (
          <FinancedTradeInTable t={t} appraisalDetails={appraisalDetails} />
        )}
      </Grid>

      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          columnGap={4}
          rowGap={4}
          sx={{ mb: 11 }}
        >
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h3" sx={{ color: "text.primary" }}>
                  {t("tradeIn.tradeIn", { context: "vehicleInfo" })}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {t(
                    "OrderDetailsConsumerDeposit.tradeInConditionDetailsTooltipBody"
                  )}
                </Typography>
              </Grid>
              <Grid item>
                {tradeInVehicleDetails[0].content === "evaluate" &&
                  dealerOptIn && (
                    <EMPButton
                      onClick={() =>
                        handleModal(
                          t("tradeIn.confirmTradeInDetails"),
                          <TradeInDecision orderId={orderId} />
                        )
                      }
                    >
                      {t("common.buttonCtas.startTradeInDecision")}
                    </EMPButton>
                  )}
              </Grid>
            </Grid>
          </Box>
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <EMPDataCard data={tradeInVehicleDetails} twoByTwo />
          </Box>
          <Box
            gridColumn={{
              xs: "span 12",
            }}
            sx={{ mt: 2 }}
          >
            <Typography variant="h3" sx={{ color: "text.primary" }}>
              {t("tradeIn.tradeIn", { context: "condition" })}
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {t(
                "OrderDetailsConsumerDeposit.tradeInConditionDetailsTooltipBody"
              )}
            </Typography>
          </Box>
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <EMPDataCard data={tradeInConditionDetails} twoByTwo />
          </Box>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <>
      <Typography variant="h3" sx={{ color: "text.primary", mb: 7 }}>
        {t("tradeIn.tradeIn")}
      </Typography>
      <Typography variant="h5" component="p">
        {t("tradeIn.noTradeInFound")}
      </Typography>
    </>
  );
};

export default TradeIn;
