import React from "react";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import { Box } from "@mui/material";

const PublishButton = (props) => {
  return props.overridesApplied ? (
    <div className={props.styles.overridesApplied}>
      <span>{"✓ "}</span>
      {props.t("CategorySettings.overridesApplied")}
    </div>
  ) : (
    <Box textAlign="center" mt={2}>
      <EMPButton
        variant="contained"
        disabled={!props.enablePublishBtn}
        testId="publishAccessoriesSettingsFees"
        submitButton
      >
        {props.t("NamePlate.publishOverrides")}
      </EMPButton>
    </Box>
  );
};

export default PublishButton;
