import Clear from "@mui/icons-material/Clear";
import { Grid, Button } from "@mui/material";
import Item from "../../../shared/item/Item";
import SortingIcon from "../../../shared/sortingIcon/SortingIcon";
import RectangleEdit from "../../../shared/rectangleEdit/RectangleEdit";
import { TextValidator } from "react-material-ui-form-validator";
import React from "react";
import { withTranslation } from "react-i18next";

class CategorySettingsRowHeader extends React.Component {
  render() {
    const props = this.props;

    return (
      <>
        <div className={props.styles.categoryHeader}>
          <Grid container spacing={0}>
            <Grid item xs={4} onClick={() => props.sortColumns("namePlate")}>
              <div className={props.styles.typeHeader}>
                <Item value={props.t("CategorySettings.namePlate")} />
                <span className={props.styles.sortingIcon}>
                  <SortingIcon
                    currentKey="namePlate"
                    sortKey={props.sortKey}
                    sortDirection={props.sortDirection}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={props.styles.markupTypeLabel}>
                <Item value={props.t("BrandSettings.markup")} />
                {!props.markupEditEnabled ? (
                  <RectangleEdit
                    dataTestId="markupEditBtn"
                    onEditClick={props.updateMarkupEditEnabled}
                  />
                ) : (
                  <Button
                    size="small"
                    name="Cancel"
                    data-testid="cancelMarkupRate"
                    onClick={props.updateMarkupEditEnabled}
                  >
                    <Clear />
                  </Button>
                )}
              </div>
            </Grid>
            <Grid item xs={2} onClick={() => props.sortColumns("markupType")}>
              <div className={props.styles.markupTypeLabel}>
                <Item value={props.t("CategorySettings.markUpType")} />
                <span className={props.styles.sortingIcon}>
                  <SortingIcon
                    currentKey="markupType"
                    sortKey={props.sortKey}
                    sortDirection={props.sortDirection}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={2} onClick={() => props.sortColumns("laborRate")}>
              <div className={props.styles.markupTypeLabel}>
                <Item value={props.t("BrandSettings.laborRate")} />
                {!props.laborRateEditEnabled ? (
                  <RectangleEdit
                    dataTestId="laborRateEditBtn"
                    onEditClick={props.updateLaborRateEditEnabled}
                  />
                ) : (
                  <Button
                    size="small"
                    name="Cancel"
                    data-testid="cancelLaborRateBtn"
                    onClick={props.updateLaborRateEditEnabled}
                  >
                    <Clear />
                  </Button>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              onClick={() => props.sortColumns("laborRateType")}
            >
              <div className={props.styles.markupTypeLabel}>
                <Item value={props.t("CategorySettings.laborRateType")} />
                <span className={props.styles.sortingIcon}>
                  <SortingIcon
                    currentKey="laborRateType"
                    sortKey={props.sortKey}
                    sortDirection={props.sortDirection}
                  />
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={props.styles.namePlateTextDiv}>
          <Grid container spacing={0}>
            <Grid item xs={4} />
            <Grid item xs={2}>
              {this.props.markupEditEnabled && (
                <div className={props.styles.laborRateRow}>
                  <div className={props.styles.headerPercentage}>&#37;</div>
                  <div className={props.styles.textBox}>
                    <TextValidator
                      value={props.overAllMarkup}
                      data-testid="markup"
                      name="markup"
                      placeholder="--"
                      validators={[
                        "required",
                        "matchRegexp:^^[+-d\\b.]+$",
                        "isSignedFloatWithTwoDecimalPlaces",
                      ]}
                      errorMessages={[
                        props.t("Error.requiredField"),
                        props.t("Error.invalidInput"),
                        props.t("Error.invalidInput"),
                        props.t("Error.invalidInput"),
                      ]}
                      onChange={(e) => props.updateModel(e)}
                    />
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
              {props.laborRateEditEnabled && (
                <div className={props.styles.laborRateRow}>
                  <div className={props.styles.headerCurrency}>&#36;</div>
                  <div className={props.styles.textBox}>
                    <TextValidator
                      value={props.overAllLaborRate}
                      data-testid="laborRate"
                      name="laborRate"
                      placeholder="--"
                      validators={[
                        "required",
                        "matchRegexp:^[0-9\\b.]+$",
                        "isNumber",
                        "isNumericWithTwoDecimalPlaces",
                      ]}
                      errorMessages={[
                        props.t("Error.requiredField"),
                        props.t("Error.invalidInput"),
                        props.t("Error.invalidInput"),
                        props.t("Error.invalidInput"),
                      ]}
                      onChange={(e) => props.updateModel(e)}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withTranslation("emp")(CategorySettingsRowHeader);
