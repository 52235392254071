import React, { useContext } from "react";
import EMPBanner from "../../shared/components/EMPBanner/EMPBanner";
import { Box, Typography } from "@mui/material";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import NADealerNotificationsClient from "../../clients/NADealerNotificationsClient";
import { UserContext } from "../../shared/context/userContext";
import { canViewNotifications } from "../../shared/rules/NotificationRules";

const OrderNotifications = ({ notifications, orderId }) => {
  const user = useContext(UserContext);
  const { market, dealerId, salesCode, dealerBrand } = user;
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const queryClient = useQueryClient();

  const { isFetching, isError } = useQuery({
    queryKey: ["notifications", { orderId }],
    queryFn: async () =>
      await NADealerNotificationsClient.getNotifications(
        sessionStorage.getItem("adfs.token"),
        userCommonId.split("|").join("%7C"),
        orderId
      ),
    enabled: canViewNotifications() && !!orderId,
    staleTime: 60000,
  });

  if (!canViewNotifications()) {
    return (
      <Typography variant="body2" textAlign="left">
        You do not have permission to view notifications. Please contact your
        administrator.
      </Typography>
    );
  }

  if (isFetching) {
    return (
      <Typography variant="body2" textAlign="center">
        Loading...
      </Typography>
    );
  }

  if (isError) {
    return (
      <>
        <Box>
          <Typography variant="body2" textAlign="center" sx={{ mb: 4 }}>
            An error occurred and we are not able to access notifications. Try
            again later.
          </Typography>
        </Box>
        <Box textAlign="center">
          <EMPButton
            variant="contained"
            onClick={async () =>
              await queryClient.invalidateQueries({
                queryKey: ["notifications", { orderId }],
              })
            }
          >
            Try Again
          </EMPButton>
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: "16px",
      }}
    >
      {notifications.data.ordersData.length > 0 ? (
        notifications.data.ordersData.map((order) => (
          <EMPBanner
            key={order.uid}
            title={
              order.notificationType === "ACTION"
                ? "Action Required"
                : "Info Only"
            }
            content={order.params.NotificationMsg}
          />
        ))
      ) : (
        <Typography variant="body2" textAlign="center">
          No notifications to show.
        </Typography>
      )}
    </Box>
  );
};

export default OrderNotifications;
