import { useQueryClient } from "@tanstack/react-query";
import UpperCaseText from "../../utils/UpperCaseText";
import { VENDOR_CATEGORIES } from "../constants/VendorCategoryConstants";

export function getJourneyTypeAndVendorCategory(orderId) {
  const queryClient = useQueryClient();
  const { customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);

  const journeys = [];
  const { spotBuy, vendorCategory, subJourneyType } = customerReservation;

  if (spotBuy) {
    journeys.push("In Store");
  } else {
    journeys.push("Ford.com Order");
  }

  if (subJourneyType === "PIPELINE") {
    journeys.push("From Plant");
  }

  if (vendorCategory && UpperCaseText(vendorCategory) !== "COMPANY") {
    const value = VENDOR_CATEGORIES[UpperCaseText(vendorCategory)];
    journeys.push(value);
  }

  return journeys;
}
