import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import MotionDash from "../../Icons/MotionDash";

const EMPEmptyState = ({ inline }) => {
  return (
    <span style={{ display: inline ? "inline-block" : "block" }}>
      <Tooltip
        arrow
        placement="top"
        title="No data available"
        enterTouchDelay={0}
        leaveTouchDelay={3000}
      >
        <IconButton
          disableRipple
          color="inherit"
          data-testid="empty-state-icon"
          size="large"
          sx={{ padding: 0 }}
        >
          <MotionDash />
        </IconButton>
      </Tooltip>
    </span>
  );
};

export default EMPEmptyState;
