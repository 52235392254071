import React, { Component } from "react";
import fordStyles from "./DashboardCards-Ford.module.scss";
import { SubTitle } from "./DashboardSubtitle";
import { Content } from "./DashboardContent";
import { DashboardActions } from "./DashboardActions";
import HasPermissionTo from "../../../common/HasPermissionTo";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Box,
} from "@mui/material";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import { Link } from "react-router-dom";

const styles = fordStyles;

class MainDashboard extends Component {
  constructor(props) {
    super(props);
    this.dateRange = 7;
  }

  render() {
    return (
      <div className={styles.pagePadding}>
        <Grid
          container
          spacing={4}
          alignItems="stretch"
          justifyContent="center"
        >
          <HasPermissionTo
            perform={["modelEDashboardCard"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => (
              <Grid item xs={12} sm={6} md={6}>
                <DashboardCard
                  {...this.props}
                  type="modele"
                  title={this.props.t("DealerDashboard.purchaseRequests")}
                  data={this.props.dashboardNotifications.modelEData}
                  testid="purchase-requests-card"
                  pathname=""
                  location="model_e_orders"
                  viewAllTag="intcmp=emphp-cta-modelecard-viewall"
                  hideActionButton
                />
              </Grid>
            )}
          />
          <HasPermissionTo
            perform={["accessoriesDashboardCard"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={!this.props.accesoriesPricingNotSet}
            render={() => (
              <Grid item xs={12} sm={6} md={6}>
                <DashboardCard
                  {...this.props}
                  type="accessories"
                  title={this.props.t("DealerDashboard.accessories")}
                  showBreakDown
                  data={this.props.dashboardNotifications.accessoriesData}
                  testid="accessories-card"
                  viewAllTag="intcmp=emphp-cta-accessoriescard-view"
                  pathname="pricing/accessories"
                />
              </Grid>
            )}
          />
        </Grid>
      </div>
    );
  }
}

const DashboardCard = (props) => {
  const { type, pathname, viewAllTag } = props;

  return (
    <Card data-testid={props.testid} className={styles.card} variant="outlined">
      <CardHeader
        title={props.title}
        titleTypographyProps={{
          variant: "h4",
          sx: { color: "primary.main", textAlign: "center" },
        }}
        sx={{ p: 0 }}
      />
      <SubTitle {...props} />
      <CardContent>
        <Content {...props} />
      </CardContent>
      <CardActions>
        {type === "accessories" ? (
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box textAlign="center">
                <EMPButton
                  href={pathname}
                  buttonElement={Link}
                  link={{
                    pathname: pathname,
                    search: `?${viewAllTag}`,
                    state: { redirectToAccSearch: true },
                  }}
                >
                  {props.t("DealerDashboard.viewAccessories")}
                </EMPButton>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <DashboardActions {...props} />
        )}
      </CardActions>
    </Card>
  );
};

export default MainDashboard;
