import { React } from "react";
import { SvgIcon } from "@mui/material";

const MotionMinus = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 48 48"
      htmlColor="#066FEF"
      shapeRendering="crispEdges"
    >
      <path d="M10.8006 25.1996V22.7996H37.2006V25.1996L10.8006 25.1996Z" />
    </SvgIcon>
  );
};

export default MotionMinus;
