import React, { Component } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { withTranslation } from "react-i18next";
import Options from "./Options";
import UpperCaseText from "../../../../utils/UpperCaseText";
import crStylesFord from "../../crStyles";
import crStylesLincoln from "../../crStylesLincoln";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import fordStyles from "./PurchaseAccessories.module.scss";
import lincolnStyles from "./PurchaseAccessoriesLincoln.module.scss";
import fordSharedStyles from "../../orderDetails/NAOrderDetailsNewVersion.module.scss";
import lincolnSharedStyles from "../../orderDetails/NAOrderDetailsNewVersionLincoln.module.scss";
import { Accordion, AccordionSummary } from "@mui/material";

class PurchaseAccessories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandPurchaseInfo: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ expandPurchaseInfo: this.props.expandAccordian });
    }
  }

  expandCollapseAccordion = () =>
    this.setState({
      expandPurchaseInfo: !this.state.expandPurchaseInfo,
    });

  render() {
    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const sharedStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordSharedStyles
        : lincolnSharedStyles;

    const { accessories, isDealerInstalledOptionEnabled = true } = this.props;

    return (
      <Accordion
        onChange={this.expandCollapseAccordion}
        expanded={this.state.expandPurchaseInfo}
        data-testid="accessoriesDropDown"
      >
        <AccordionSummary style={crStyles.headerBarBg}>
          <div className={sharedStyles.headerBar}>
            <div className={sharedStyles.header}>
              {UpperCaseText(
                this.props.t("PurchaseDetails.purchaseAccessories.header")
              )}
            </div>
            <div className={styles.acceptRejectDiv}>
              {this.state.expandPurchaseInfo ? (
                <ExpandLessIcon data-testid="less" />
              ) : (
                <ExpandMoreIcon data-testid="more" />
              )}
            </div>
          </div>
        </AccordionSummary>

        {isDealerInstalledOptionEnabled && (
          <Options
            {...this.props}
            name="dealer"
            total={accessories && accessories.totalDealerPrice}
            options={accessories && accessories.dealerAccessoriesList}
          />
        )}
        <Options
          {...this.props}
          name="customer"
          total={accessories && accessories.totalPrice}
          options={accessories && accessories.accessoriesList}
          overallTotal={
            accessories &&
            ((accessories.dealerAccessoriesList &&
              accessories.dealerAccessoriesList.length > 0) ||
              (accessories.accessoriesList &&
                accessories.accessoriesList.length > 0))
              ? isDealerInstalledOptionEnabled
                ? accessories.totalDealerPrice + accessories.totalPrice
                : accessories && accessories.totalPrice
              : null
          }
        />
      </Accordion>
    );
  }
}

export default withTranslation("emp")(PurchaseAccessories);
