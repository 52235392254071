import React from "react";
import { createPortal } from "react-dom";
import { Drawer, Typography, Box, IconButton } from "@mui/material";
import { DrawerContext } from "./DrawerContext";
import MotionClose from "../../Icons/MotionClose";

const EMPDrawer = () => {
  const { drawerHeading, drawerContent, handleDrawer, openDrawer, drawerRef } =
    React.useContext(DrawerContext);
  return createPortal(
    <Drawer
      ref={drawerRef}
      anchor="right"
      open={openDrawer}
      onClose={() => handleDrawer(false, false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: 390,
          padding: 4,
          height: "100vmin",
          overflow: "hidden",
        },
      }}
    >
      <Box textAlign="right">
        <IconButton
          onClick={() => handleDrawer(false, false)}
          data-testid="drawer-close-button"
        >
          <MotionClose />
        </IconButton>
      </Box>
      <Box role="presentation">
        <Typography variant="h4" sx={{ mb: 6 }}>
          {drawerHeading}
        </Typography>
        {drawerContent}
      </Box>
    </Drawer>,
    document.querySelector("#drawer-root")
  );
};

export default EMPDrawer;
