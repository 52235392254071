import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const EMPLoader = ({ loadingMessage }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", height: "40px" }}>
      <Box sx={{ mr: 2 }}>
        <CircularProgress size={22} />
      </Box>
      <Box>
        <Typography variant="button" align="center">
          {loadingMessage}
        </Typography>
      </Box>
    </Box>
  );
};

export default EMPLoader;
