import React from "react";
import {
  Box,
  AlertTitle,
  Alert as MuiAlert,
  Typography,
  Link,
} from "@mui/material";
import MotionInfo from "../../Icons/MotionInfo";
import { styled } from "@mui/material/styles";

const StyledAlert = styled(MuiAlert)(({ theme, severity }) => ({
  borderRadius: 0,
  backgroundColor: severity === "info" ? theme.palette.info.main : undefined,
  padding: theme.spacing(4),
}));

const EMPBanner = ({ title, content, hasLink, linkText, href }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <StyledAlert icon={<MotionInfo />} severity="info" variant="filled">
        <AlertTitle
          variant="subtitle1"
          component="p"
          sx={{ margin: 0, padding: 0 }}
        >
          {title}
        </AlertTitle>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ display: "inline" }}
        >
          {content}
        </Typography>
        {hasLink && (
          <Link
            variant="body1"
            href={href}
            underline="always"
            target="_blank"
            rel="noreferrer"
            color="text.primary"
          >
            {linkText}
          </Link>
        )}
      </StyledAlert>
    </Box>
  );
};

export default EMPBanner;
