import React from "react";
import "../../../../styles/sharedStyles.scss";
import { Grid } from "@mui/material";
import styles from "./AcceptAndKeepDialog.module.scss";
import { withTranslation } from "react-i18next";
import {
  ACCEPT_AND_KEEP,
  ACCEPT_AND_KEEP_CONFIRM,
  DECISION,
  MINORMAX_REDEMPTION_MILEAGE,
} from "../../../../common/Constants";
import * as DateConstants from "../../../../shared/constants/DateConstants";
import DatePicker from "react-datepicker";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import AcceptAndKeepConfirmDialog from "../acceptAndKeepConfirm/AcceptAndKeepConfirmDialog";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";
class AcceptAndKeepDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        dateVehicleSeen: null,
        redemptionMileage: "",
      },
      enableSave: false,
    };
    this.minRedemptionValue = this.minRedemptionMileage();
    this.maxRedemptionValue = this.maxRedemptionMileage();
  }

  handleConfirm = (e) => {
    this.props.onConfirm(e);
  };

  handleClose = () => {
    this.props.onClose(ACCEPT_AND_KEEP);
  };

  handleAdjustmentEdit = (event) => {
    const redemptionMileage = event.target.value;
    this.setState(
      (prevState) => {
        const data = { ...prevState.data };
        data["redemptionMileage"] = redemptionMileage;
        return { data };
      },
      () => {
        this.enableSaveBtn();
      }
    );
  };

  handleSave = () => {
    this.props.onDialogChange(ACCEPT_AND_KEEP_CONFIRM, ACCEPT_AND_KEEP);
  };

  goBack = () => {
    this.props.onDialogChange(DECISION, ACCEPT_AND_KEEP);
  };

  selectDateVehicleSeen = (date) => {
    if (date !== null) {
      this.setState(
        (prevState) => {
          const data = { ...prevState.data };
          data["dateVehicleSeen"] = date;
          return { data };
        },
        () => {
          this.enableSaveBtn();
        }
      );
    }
  };

  enableSaveBtn = () => {
    this.setState((prevState) => ({
      enableSave:
        prevState.data.dateVehicleSeen && prevState.data.redemptionMileage,
    }));
  };

  maxRedemptionMileage = () => {
    const vehicleMileage =
      this.props.tradeInDetails && this.props.tradeInDetails.mileage;
    return vehicleMileage && vehicleMileage > 0
      ? parseInt(vehicleMileage) + MINORMAX_REDEMPTION_MILEAGE
      : MINORMAX_REDEMPTION_MILEAGE;
  };

  minRedemptionMileage = () => {
    const vehicleMileage =
      this.props.tradeInDetails && this.props.tradeInDetails.mileage;
    return vehicleMileage && vehicleMileage > MINORMAX_REDEMPTION_MILEAGE
      ? parseInt(vehicleMileage) - MINORMAX_REDEMPTION_MILEAGE
      : 0;
  };

  render() {
    return (
      <>
        {this.props.open && (
          <EMPDialog
            open={this.props.open}
            handleClose={this.handleClose}
            dialogTitle={this.props.t("TradeIn.US.dialogs.acceptAndKeep.title")}
            hasCloseIcon
            dialogActionsDataTestId="acceptAndKeepDialogActions"
            hasActionButton
            actionButtonText={this.props.t(
              "TradeIn.US.dialogs.acceptAndKeep.save"
            )}
            onConfirm={this.handleSave}
            disableActionButton={!this.state.enableSave}
            hasSecondaryActionButton
            secondaryActionButtonText={this.props.t(
              "TradeIn.US.dialogs.acceptAndKeep.goBack"
            )}
            onSecondaryAction={this.goBack}
          >
            <Grid
              container
              data-testid="acceptKeepDialog"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <div className={styles.body}>
                  <div>
                    {this.props.t("TradeIn.US.dialogs.acceptAndKeep.body")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <ValidatorForm onSubmit={this.handleSave}>
                  <Grid item xs={12}>
                    <div className={styles.wrapper}>
                      <div className={styles.title}>
                        {this.props.t(
                          "TradeIn.US.dialogs.acceptAndKeep.redemptionMileage"
                        )}
                      </div>
                      <TextValidator
                        name="redemptionMileage"
                        id="redemptionMileage"
                        value={this.state.data.redemptionMileage}
                        onChange={this.handleAdjustmentEdit}
                        className={styles.redemptionMileage}
                        validators={[
                          "required",
                          "matchRegexp:^[0-9]+$",
                          `maxValue:${this.maxRedemptionValue}`,
                        ]}
                        errorMessages={[
                          this.props.t("Error.requiredField"),
                          this.props.t("TradeIn.invalidInputMileage"),
                          this.props.t("TradeIn.maxValue"),
                        ]}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.wrapper}>
                      <div className={styles.title}>
                        {this.props.t(
                          "TradeIn.US.dialogs.acceptAndKeep.dateVehicleSeen"
                        )}
                      </div>
                      <DatePicker
                        selected={this.state.data.dateVehicleSeen}
                        onChange={this.selectDateVehicleSeen}
                        className={styles.datepicker}
                        popperPlacement="top-end"
                        id="acceptKeepVehicleSeen"
                        maxDate={new Date()}
                        dateFormat={DateConstants.MMddyyyy}
                        placeholderText={DateConstants.MM_DD_YYYY}
                      />
                    </div>
                  </Grid>
                </ValidatorForm>
              </Grid>
            </Grid>
          </EMPDialog>
        )}
        <AcceptAndKeepConfirmDialog
          {...this.props}
          data={this.state.data}
          open={this.props.dialogBoxes[ACCEPT_AND_KEEP_CONFIRM]}
          onClose={this.props.onClose}
          onDialogChange={this.props.onDialogChange}
        />
      </>
    );
  }
}

export default withTranslation("emp")(AcceptAndKeepDialog);
