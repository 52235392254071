import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CircularProgress,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EMPTypeAheadField = (props) => {
  const {
    fieldLabel,
    noOptionsText,
    fieldName,
    onFetch,
    dataKey,
    transportFormData,
    disabledItem,
  } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    onFetch()
      .then((value) => {
        if (hasError) {
          setHasError(false);
        }
        if (active) {
          setOptions(value[dataKey]);
        }
      })
      .catch(() => {
        active = false;
        setOpen(false);
        setHasError(true);
      })
      .finally(() => {
        active = false;
      });
  }, [loading, onFetch, dataKey, hasError]);

  return (
    <>
      <InputLabel shrink={false} htmlFor={fieldName}>
        <Typography variant="body1">{fieldLabel}</Typography>
      </InputLabel>
      <Autocomplete
        data-testid="type-ahead-field"
        blurOnSelect
        clearOnBlur
        options={options}
        noOptionsText={noOptionsText}
        popupIcon={<ExpandMoreIcon size="small" />}
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(e, value) => transportFormData(value)}
        isOptionEqualToValue={(option, value) =>
          option.fullName === value.fullName
        }
        getOptionDisabled={(option) => option.fullName === disabledItem}
        getOptionLabel={(option) => option.fullName}
        renderInput={(params) => (
          <TextField
            {...params}
            name={fieldName}
            size="small"
            variant="outlined"
            error={hasError}
            readOnly={hasError}
            helperText={hasError ? "Something went wrong" : ""}
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      ></Autocomplete>
    </>
  );
};

export default EMPTypeAheadField;
