import React, { forwardRef, useState } from "react";
import "../../../styles/DateTime.scss";
import DatePicker from "react-datepicker";
import { Button } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";

// const useStyles = makeStyles({
//   // input styles below
//   // datepicker styles come from imported stylesheet
//   wrapper: {
//     width: "100%",
//     display: "block",
//   },
//   input: {
//     width: "100%",
//     boxSizing: "border-box",
//     border: "none",

//     letterSpacing: 0,
//     lineHeight: "24px",
//     fontSize: 16,
//     padding: 8,
//   },
//   buttonRoot: {
//     fontSize: 14,
//     color: "#333333",
//     letterSpacing: 0,
//     border: "1px solid #4d4d4d",
//     borderRadius: 8,
//     padding: "8px 10px 8px 10px !important",
//     justifyContent: "flex-start",
//     textTransform: "none",

//     fullWidth: {
//       "&:hover": {
//         borderColor: "#194BB5",
//         backgroundColor: "none",
//       },
//     },
//   },
// });

const EMPDatePicker = (props) => {
  const { incomingDate, transportFormData, startAtToday, showTimeSelect } =
    props;

  const [date, setDate] = useState(incomingDate);
  const placeholder = "mm/dd/yyyy";

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      onClick={onClick}
      ref={ref}
      disableRipple
      fullWidth
      endIcon={<EventIcon color="text.main" />}
      data-testid="date-picker-button"
      sx={(theme) => ({
        width: "100%",
        color: value ? theme.palette.text.primary : theme.palette.text.disabled,
        letterSpacing: 0,
        border: "1px solid #4d4d4d",
        borderRadius: "8px",
        padding: "8px 10px 8px 10px !important",
        justifyContent: "space-between",
        textTransform: "none",
        fontFamily: theme.typography.body1,
      })}
    >
      {value ? value : placeholder}
    </Button>
  ));

  return (
    <DatePicker
      {...props}
      data-testid="date-picker"
      selected={date}
      minDate={startAtToday && new Date()}
      showTimeSelect={showTimeSelect}
      dateFormat={showTimeSelect ? "EEEE, MMMM do yyyy p" : "MM/dd/yyyy"}
      calendarClassName="emp-datepicker"
      onChange={(value) => {
        setDate(value);
        if (!showTimeSelect) {
          return transportFormData(value);
        }

        return transportFormData(
          value.toLocaleDateString("en-CA") +
            " " +
            value.toLocaleTimeString([], {
              hour12: false,
            })
        );
      }}
      customInput={<DateInput />}
    />
  );
};

export default EMPDatePicker;
