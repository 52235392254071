import React from "react";
import styles from "../NACustomerOrders.module.scss";
import Button from "@mui/material/Button";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import MotionChevronLeft from "../../../shared/Icons/MotionChevronLeft";
import MotionChevronRight from "../../../shared/Icons/MotionChevronRight";

class TabsPagination extends React.Component {
  printPaginationSeries = () => {
    const row = [];
    let counter = 0;
    if (this.props.numberOfPages <= 6) {
      for (let i = 0; i < this.props.numberOfPages; i++) {
        const numb = i + 1;
        row.push(
          <Button
            key={"page_" + i}
            className={styles.paginationNumbers}
            style={{
              backgroundColor:
                this.props.currentPage === i
                  ? "rgba(0, 0, 0, 0.04)"
                  : "transparent",
            }}
            onClick={() => {
              this.props.handleSpecificPage(numb - 1);
            }}
          >
            {numb}
          </Button>
        );
      }
    } else {
      for (let i = 0; i < this.props.numberOfPages; i++) {
        const numb = i + 1;
        if (this.props.currentPage <= 5) {
          if (numb > 6 && numb !== this.props.numberOfPages) {
            if (counter < 3) {
              row.push(<span key={i}>.</span>);
            }
            counter = counter + 1;
          } else {
            row.push(
              <button
                key={"page_" + i}
                className={`${styles.paginationNumbers} ${
                  this.props.currentPage === numb - 1 ? styles.active : ""
                }`}
                onClick={() => {
                  this.props.handleSpecificPage(numb - 1);
                }}
              >
                {numb}
              </button>
            );
          }
        } else {
          if (
            numb !== 1 &&
            (numb < this.props.currentPage - 2 ||
              numb > this.props.currentPage + 2) &&
            numb !== this.props.numberOfPages
          ) {
            if (counter < 3) {
              row.push(<span key={i}>.</span>);
            }
            counter = counter + 1;
          } else {
            counter = 0;
            row.push(
              <button
                key={"page_" + i}
                className={styles.paginationNumbers}
                onClick={() => {
                  this.props.handleSpecificPage(numb - 1);
                }}
              >
                {numb}
              </button>
            );
          }
        }
      }
    }

    return row;
  };

  render() {
    return (
      <div
        className={styles.pagesDiv}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
          paddingBottom: 40,
        }}
      >
        <EMPButton
          disabled={this.props.currentPage + 1 === 1}
          testId="prev"
          onClick={this.props.handlePrevious}
          hasLeftIcon
          leftIcon={<MotionChevronLeft />}
          variant="text"
        >
          Prev
        </EMPButton>
        <div
          key={
            "pagination_number_" +
            this.props.currentPage +
            "_" +
            this.props.numberOfPages
          }
          className={styles.pages}
        >
          {this.printPaginationSeries()}
        </div>

        <EMPButton
          disabled={this.props.currentPage + 1 === this.props.numberOfPages}
          testId="next"
          onClick={this.props.handleNext}
          hasRightIcon
          rightIcon={<MotionChevronRight></MotionChevronRight>}
          variant="text"
        >
          Next
        </EMPButton>
      </div>
    );
  }
}
export default TabsPagination;
