import React, { Component } from "react";
import fordStyles from "./../orderDetails/NAOrderDetailsNewVersion.module.scss";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  ACCEPTED,
  APPROVED,
  CANCELED,
  CANCELLED,
  CONDITIONED_CREDIT_APP,
  DENIED_CREDIT_APP,
  DENIED_CREDIT_APP_ERROR,
  FINANCE_PROVIDER,
  PENDING_CREDIT_APP,
  PURCHASE_TYPES,
} from "../../../common/Constants";
import UpperCaseText from "../../../utils/UpperCaseText";
import { withTranslation } from "react-i18next";
import { CANCELLED_STATUSES } from "../../../utils/EmpUtil";
import applicationStatus from "../../../utils/GetApplicationStatus";
import { displayError } from "../../../errors/ErrorConstants";
import { InfoDetail } from "../orderDetails/InfoDetail";
import DataCard from "../../../shared/components/DataCard/DataCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";

class ModelEOrderInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandPurchaseInfo: false,
      openAcceptPurchaseDialog: false,
      openCancelPurchaseDialog: false,
      multipleVINTooltipFlag: false,
      vehicleHoldIconTooltipFlag: false,
      tradeInIconTooltipFlag: false,
      depositAcceptedIconTooltipFlag: false,
      holdDepositIconTooltipFlag: false,
      hasError: [],
    };
  }

  componentDidMount() {
    this.setState({ expandPurchaseInfo: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ expandPurchaseInfo: this.props.expandAccordian });
    }
  }

  expandPurchaseInfo = () => {
    this.setState((prevState) => ({
      expandPurchaseInfo: !prevState.expandPurchaseInfo,
    }));
  };

  toggleToolTip = (tooltip) => {
    this.setState((prevState) => ({
      [tooltip]: !prevState[tooltip],
    }));
  };

  getStatus(customerReservation) {
    if (CANCELLED_STATUSES.includes(customerReservation.status)) {
      return customerReservation.status === "RESERVATION_CANCELLED"
        ? this.props.t("OrderDetails.businessStatusCustomer")
        : this.props.t("OrderDetails.businessStatusDealer");
    } else {
      return customerReservation.businessStatus;
    }
  }

  render() {
    const styles = fordStyles;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    const { customerReservation } = this.props;

    const purchaseTypeIsValid =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType;

    const paymentDescriptionStatusIsValid =
      this.props.customerReservation &&
      this.props.customerReservation.paymentDescription &&
      this.props.customerReservation.paymentDescription[0] &&
      this.props.customerReservation.paymentDescription[0].status;

    const purchaseTypeIsThirdParty =
      purchaseTypeIsValid &&
      (this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.THIRD_PARTY ||
        this.props.customerReservation.purchaseInfo.purchaseType ===
          PURCHASE_TYPES.DEALER_FINANCING);

    const statusIsApproved =
      paymentDescriptionStatusIsValid &&
      this.props.customerReservation.paymentDescription[0].status ===
        "APPROVED";

    const showDealerUserID = !purchaseTypeIsThirdParty;

    const showStockID = !(statusIsApproved && !purchaseTypeIsValid);

    return (
      <Accordion
        defaultExpanded
        onChange={() => this.expandPurchaseInfo()}
        expanded={this.state.expandPurchaseInfo}
        sx={{ boxShadow: "none", margin: 0 }}
      >
        <AccordionSummary
          id="purchaseExpansion"
          expandIcon={<ExpandLessIcon />}
          sx={{ paddingLeft: 6, paddingRight: 6 }}
        >
          <Typography variant="h4">
            {this.props.t("CustomerReservations.purchaseRequest")}
          </Typography>
        </AccordionSummary>
        {customerReservation && customerReservation.purchaseInfo ? (
          <AccordionDetails>
            {customerReservation ? (
              <Grid container spacing={2} alignContent="stretch">
                <Grid item xs={12} className={styles.cardGridItemWrapper}>
                  <DataCard
                    cardHeader={this.props.t(
                      "PurchaseDetails.purchaseRequestDetails"
                    )}
                  >
                    <InfoDetail
                      isFordBrand
                      header={this.props.t("PurchaseDetails.orderId")}
                      value={customerReservation.id}
                    />

                    <InfoDetail
                      isFordBrand
                      header={this.props.t(
                        "PurchaseDetails.purchaseRequestReceiptDate"
                      )}
                      value={
                        customerReservation.purchaseInfo.purchaseRequestDate
                      }
                    />

                    <InfoDetail
                      isFordBrand
                      header={this.props.t(
                        "PurchaseDetails.purchaseTypes.header"
                      )}
                      value={
                        customerReservation.paymentDescription &&
                        applicationStatus(
                          customerReservation.paymentDescription
                        ) === PENDING_CREDIT_APP
                          ? this.props.t(
                              "PurchaseDetails.purchaseTypes.PENDING_CREDIT_APPLICATION"
                            )
                          : applicationStatus(
                              customerReservation.paymentDescription
                            ) === CONDITIONED_CREDIT_APP
                          ? this.props.t(
                              "PurchaseDetails.purchaseTypes.CONDITIONED_CREDIT_APPLICATION"
                            )
                          : applicationStatus(
                              customerReservation.paymentDescription
                            ) === DENIED_CREDIT_APP ||
                            applicationStatus(
                              customerReservation.paymentDescription
                            ) === DENIED_CREDIT_APP_ERROR
                          ? this.props.t(
                              "PurchaseDetails.purchaseTypes.DENIED_CREDIT_APPLICATION"
                            )
                          : applicationStatus(
                              customerReservation.paymentDescription
                            ) === APPROVED &&
                            customerReservation.purchaseInfo.purchaseType ===
                              null
                          ? this.props.t(
                              "PurchaseDetails.purchaseTypes.abandoned_checkout"
                            )
                          : customerReservation.financeProvider ===
                            FINANCE_PROVIDER.THIRD_PARTY
                          ? this.props.t(
                              "PurchaseDetails.purchaseTypes.THIRD_PARTY_FINANCING"
                            )
                          : customerReservation.purchaseInfo.purchaseType &&
                            this.props.t(
                              `PurchaseDetails.purchaseTypes.${UpperCaseText(
                                customerReservation.purchaseInfo.purchaseType
                              )}`
                            )
                      }
                    />
                    <InfoDetail
                      isFordBrand
                      header={this.props.t("PurchaseDetails.vin")}
                      value={customerReservation.purchaseInfo.vin}
                    />

                    {showStockID && (
                      <InfoDetail
                        isFordBrand
                        header={this.props.t("PurchaseDetails.stockId")}
                        value={customerReservation.purchaseInfo.stockId}
                      />
                    )}

                    <InfoDetail
                      isFordBrand
                      header={this.props.t("PurchaseDetails.status")}
                      value={
                        customerReservation.purchaseInfo.acceptRejectStatus
                      }
                    />

                    {UpperCaseText(
                      customerReservation.purchaseInfo.acceptRejectStatus
                    ) === ACCEPTED && (
                      <InfoDetail
                        isFordBrand
                        header={this.props.t("PurchaseDetails.acceptanceDate")}
                        value={
                          customerReservation.purchaseInfo.dealerActionDate
                        }
                      />
                    )}

                    {(UpperCaseText(
                      customerReservation.purchaseInfo.acceptRejectStatus
                    ) === CANCELLED ||
                      UpperCaseText(
                        customerReservation.purchaseInfo.acceptRejectStatus
                      ) === CANCELED) && (
                      <InfoDetail
                        isFordBrand
                        header={this.props.t(
                          "PurchaseDetails.cancellationDate"
                        )}
                        value={
                          customerReservation.purchaseInfo.dealerActionDate
                        }
                      />
                    )}

                    {showDealerUserID && (
                      <InfoDetail
                        isFordBrand
                        header={this.props.t("PurchaseDetails.dealerUserId")}
                        value={
                          customerReservation.purchaseInfo
                            .purchaseRequestDealerAction &&
                          customerReservation.purchaseInfo
                            .purchaseRequestDealerAction.dealerActionUserId
                        }
                      />
                    )}

                    {(UpperCaseText(
                      customerReservation.purchaseInfo.acceptRejectStatus
                    ) === CANCELLED ||
                      UpperCaseText(
                        customerReservation.purchaseInfo.acceptRejectStatus
                      ) === CANCELED) && (
                      <InfoDetail
                        isFordBrand
                        header={this.props.t(
                          "PurchaseDetails.cancellationReason"
                        )}
                        value={
                          customerReservation.purchaseInfo
                            .purchaseRequestDealerAction &&
                          customerReservation.purchaseInfo
                            .purchaseRequestDealerAction.dealerRejectedReason
                        }
                      />
                    )}

                    <InfoDetail
                      isFordBrand
                      header={this.props.t("PurchaseDetails.soldDate")}
                      value={customerReservation.purchaseInfo.soldDate}
                    />
                  </DataCard>
                </Grid>

                {customerReservation.conversionDate && (
                  <Grid item className={styles.cardGridItemWrapper}>
                    <DataCard
                      cardHeader={this.props.t(
                        "PurchaseDetails.reservationDetails"
                      )}
                    >
                      <InfoDetail
                        isFordBrand
                        header={this.props.t("PurchaseDetails.reservationDate")}
                        value={customerReservation.reservationDate}
                      />
                      <InfoDetail
                        isFordBrand
                        header={this.props.t("OrderDetails.status")}
                        value={
                          customerReservation.reservationStatus
                            ? customerReservation.reservationStatus
                            : this.getStatus(customerReservation)
                        }
                      />

                      <InfoDetail
                        isFordBrand
                        header={this.props.t("PurchaseDetails.dealerUserId")}
                        value={
                          customerReservation.reservationDealerAction &&
                          customerReservation.reservationDealerAction
                            .dealerActionUserId
                        }
                      />
                    </DataCard>
                  </Grid>
                )}
              </Grid>
            ) : (
              <span className={styles.labelError}>
                {" "}
                {this.props.t("CustomerOrderDetails.errorLoadingDetails")}
              </span>
            )}
          </AccordionDetails>
        ) : (
          <div className={styles.customerInfoGrid}>No data found</div>
        )}
      </Accordion>
    );
  }
}

export default withTranslation("emp")(ModelEOrderInformation);
