import React from "react";
import SharedToolTip from "../sharedToolTip/SharedToolTip";
import EMPEmptyState from "../components/EMPEmptyState/EMPEmptyState";
import { Typography, Grid, Box } from "@mui/material";

const CHARS_PER_LINE = 75;

export const StyledTableRow = (props) => {
  const { text, data, testID } = props;

  const splitIntoLines = (text) => {
    if (!text || text.length < CHARS_PER_LINE) return [text];

    const wordArr = text.split(" ");
    const lines = [];
    let currLine = "";

    while (wordArr.length) {
      if (currLine.length + wordArr[0].length <= CHARS_PER_LINE) {
        currLine += ` ${wordArr.shift()}`;
      } else {
        lines.push(currLine);
        currLine = "";
      }
    }

    if (currLine.length) lines.push(currLine);

    return lines;
  };

  const lines = splitIntoLines(text);

  const TextWrap = ({ lines }) => {
    return lines.map((line) => (
      <Box key={line} sx={{ padding: 2 }}>
        {line}
      </Box>
    ));
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <TextWrap lines={lines} />
        {props.tooltip && (
          <SharedToolTip
            openTestid={props.tooltip.openTestid}
            closeTestid={props.tooltip.closeTestid}
            title={props.tooltip.title}
            body={props.tooltip.body}
          />
        )}
      </Grid>
      {data !== undefined && (
        <Grid item data-testid={testID}>
          <Box sx={{ padding: 2 }}>
            {data !== null ? data : <EMPEmptyState />}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export const StyledBigTableHeader = ({ text, data, testID }) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={8}>
        <Typography variant="h5">{text}</Typography>
      </Grid>
      <Grid item xs={4} data-testid={testID}>
        {data !== null ? (
          <Typography variant="h5" textAlign="right">
            {data}
          </Typography>
        ) : (
          <EMPEmptyState />
        )}
      </Grid>
    </Grid>
  );
};

export const StyledTableHeader = ({ text, data, testID }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ my: 4 }}
    >
      <Grid item>
        <Typography variant="subtitle1">{text}</Typography>
      </Grid>
      <Grid item data-testid={testID}>
        {data !== null ? (
          <Typography variant="subtitle1">{data}</Typography>
        ) : (
          <EMPEmptyState />
        )}
      </Grid>
    </Grid>
  );
};
