import React, { useState } from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import { CANCELLED } from "../../common/Constants";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";
import UpperCaseText from "../../utils/UpperCaseText";
import { IS_NA_MARKET, IS_USA_MARKET } from "../../utils/EmpUtil";
import EMPDialog from "../../shared/components/EMPDialog/EMPDialog";
import EMPSelectField from "../../shared/components/EMPSelectField/EMPSelectField";
import { useQuery } from "@tanstack/react-query";
import EMPCheckbox from "../../shared/components/EMPCheckbox/EMPCheckbox";

const CancelReasons = ({
  user,
  t,
  updateDropdown,
  value,
  orderType,
  cancelType,
}) => {
  const cancelOrder = "REQUEST FROM CUSTOMER TO CANCEL ORDER";
  const cancelReservation = "Request from customer to cancel reservation";

  const translations = {
    "REQUEST FROM CUSTOMER TO CANCEL RESERVATION":
      "Demande d'annulation de la réservation par le client",
    OTHER: "Autre",
    "UNABLE TO CONTACT CUSTOMER": "Impossible de contacter le client",
    "INABILITY TO COMPLETE PURCHASE": "Incapacité d'effectuer l'achat",
    "PURCHASE OF UNAUTHORIZED RE-SELLER": "Achat d'un revendeur non autorisé",
  };

  function getMenuItems(data) {
    const menuItems = data?.cancellationReasons.map((item) => {
      let message = item.displayText;
      const key = item.code;
      const isReservation = UpperCaseText(orderType) === "RESERVATION";
      const isFrenchCanadian = localStorage.getItem("lang") === "fr_CA";

      if (isReservation) {
        if (UpperCaseText(message) === cancelOrder) {
          message = cancelReservation;
        }

        if (isFrenchCanadian && translations[UpperCaseText(message)]) {
          message = translations[UpperCaseText(message)];
        }
      }

      return {
        text: message,
        value: IS_NA_MARKET(user.market) ? message : key,
      };
    });

    menuItems.unshift({
      text: t("CustomerOrderDetails.pleaseSelect"),
      value: "",
      disabled: true,
    });

    return menuItems;
  }

  const {
    isFetching,
    isError,
    data: cancellationReasons,
    error,
  } = useQuery({
    queryKey: ["cancelReasons", cancelType],
    staleTime: 1000 * 60,
    queryFn: async () => {
      const { data } = await CustomerReservationsClient.getCancelOrderReasons(
        user.token,
        user.lang,
        cancelType,
        user.commonId
      );

      return data;
    },
    select: getMenuItems,
  });

  if (isFetching) {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <CircularProgress size={24} />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" align="center">
            {t("common.loading")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="error">
          {`${t("common.error")}: ${error.message}`}
          <br />
          {t("common.errorMessage1")}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <InputLabel disableAnimation shrink={false}>
        {t("CustomerOrderDetails.reasonForCancelling")}
      </InputLabel>
      <FormControl id="cancelOrderDropDown" fullWidth>
        <EMPSelectField
          testId="dropdown"
          value={value}
          onChange={(event) => updateDropdown(event)}
          menuItems={cancellationReasons}
        />
      </FormControl>
    </>
  );
};

const CancelOrderDialog = ({
  open,
  onClose,
  loading,
  showDropdown,
  isRefund,
  user,
  t,
  orderType,
  cancelType,
  purchaseRequestCancel,
  handleConfirm,
  dialogProps,
  transportError,
  onEnter,
}) => {
  const [dropDownValue, setDropDownValue] = useState("");
  const [checkBox, setCheckBox] = useState(false);

  const doHandleConfirm = () => {
    isRefund
      ? handleConfirm()
      : purchaseRequestCancel
      ? handleConfirm(dropDownValue)
      : IS_NA_MARKET(user.market)
      ? handleConfirm(CANCELLED, dropDownValue)
      : handleConfirm(CANCELLED, null, dropDownValue);
  };

  const compareCheckBoxItemToString = (item, comparator) => {
    return (
      typeof item === "string" &&
      typeof comparator === "string" &&
      item.toLowerCase().includes(comparator)
    );
  };

  const isConfirmDisabled = () => {
    const { rolePermissions } = user.permissions;
    const lacksPermissions =
      !rolePermissions.hasOwnProperty("depositSubmit") ||
      !rolePermissions.hasOwnProperty("purchaseRequestSubmit");
    const isInvalidDropdown = showDropdown && !dropDownValue;

    if (loading || (IS_USA_MARKET(user.market) && lacksPermissions)) {
      return true;
    }

    return !checkBox || isInvalidDropdown;
  };

  const updateDropdown = (event) => setDropDownValue(event.target.value);

  return (
    <EMPDialog
      open={open}
      onEnter={onEnter}
      hasCloseIcon
      handleClose={onClose}
      hasActionButton
      actionButtonText={dialogProps.confirm}
      onConfirm={doHandleConfirm}
      disableActionButton={isConfirmDisabled()}
      dialogTitle={dialogProps.title}
      actionLoading={loading}
    >
      <Grid container data-testid="cancelOrderDialog">
        <Grid item xs={12}>
          <Typography variant="body1">{dialogProps.body}</Typography>
        </Grid>

        {showDropdown && (
          <Grid item xs={12} sm={12}>
            <Box mb={3}>
              <CancelReasons
                user={user}
                t={t}
                token={user.token}
                updateDropdown={updateDropdown}
                value={dropDownValue}
                orderType={orderType}
                cancelType={cancelType}
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={10}>
          <Box mb={3}>
            <EMPCheckbox
              checked={checkBox}
              name="chkBxConfirm"
              id="chkBxConfirm"
              label={dialogProps.checkbox}
              handleChange={(status) => setCheckBox(status)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ul style={{ margin: 0, paddingLeft: "16px" }}>
            {dialogProps.checkboxList.map((item, index) =>
              compareCheckBoxItemToString(item, "recall") ? (
                <li key={index}>
                  <Typography variant="body2">
                    <a
                      href={encodeURIComponent(dialogProps.recallLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#333333" }}
                    >
                      {item}
                    </a>
                  </Typography>
                </li>
              ) : (
                <li key={index}>
                  <Typography variant="body2">{item}</Typography>
                </li>
              )
            )}
          </ul>
        </Grid>

        {transportError && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="error">
              {`${t("common.error")}`}
              <br />
              {t("common.errorMessage1")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </EMPDialog>
  );
};

export default withTranslation("emp")(CancelOrderDialog);
