import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { BASE_URL } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";
import axios from "axios";
import { getHeaders } from "../utils/AuthorizationUtil";

class NADealerNotificationsClient {
  static baseUrl = BASE_URL;

  static getNotifications(token, encodedCommonId, orderId) {
    const currentPageCount = 0;
    const url = `${this.baseUrl}/dealer/notifications?dealerCommonIds=${encodedCommonId}&orderId=${orderId}&currentPage=${currentPageCount}`;
    return axios.get(url, getHeaders(token));
  }

  static getNADealerNotifications(user, encodedCommonId, orderId) {
    const currentPageCount = 0;
    const url = `${this.baseUrl}/dealer/notifications?dealerCommonIds=${encodedCommonId}&orderId=${orderId}&currentPage=${currentPageCount}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static deleteNotification(user, id) {
    const url = `${this.baseUrl}/dealer/notifications/${id}`;

    return axios.delete(url, getHeaders(user.token)).catch((error) => {
      if (is401(error)) {
        AuthenticationFailureHandler.execute();
      }

      return Promise.reject(error);
    });
  }
}

export default NADealerNotificationsClient;
