import React, { Component } from "react";
import { Link } from "react-router-dom";
import HasPermissionTo from "../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import { CANADA_FRENCH, CANADA_ENGLISH } from "../../common/Constants";
import LanguageUtil from "../../utils/LanguageUtil";
import HelpAndSupportDialog from "./HelpAndSupportDialog";
import { publish } from "../../common/PubSub";
import styles from "./Footer.module.scss";
import EMPBranding from "../components/EMPBranding/EMPBranding";
import { Typography, Box } from "@mui/material";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));
  };

  updateLanguage = (language) => {
    LanguageUtil.updateLanguage(this.props, language);
    publish("language", language);
  };

  render() {
    return (
      <Box
        component="footer"
        sx={{
          py: 8,
          px: { xs: 4, sm: 4, md: 4, lg: 14 },
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "flex-start", lg: "space-between" },
          width: "100%",
        }}
        data-testid="appFooter"
        className={styles.footerBox}
      >
        <div>
          <EMPBranding></EMPBranding>
        </div>
        <div className={styles.helpContainer}>
          <ul>
            <HasPermissionTo
              perform={["languagePreference:write"]}
              permissions={this.props.permissions.rolePermissions}
              render={() => (
                <>
                  <li>
                    <button
                      data-testid="english"
                      onClick={() => this.updateLanguage(CANADA_ENGLISH)}
                    >
                      English
                    </button>
                  </li>
                  <li>
                    <button
                      data-testid="french"
                      onClick={() => this.updateLanguage(CANADA_FRENCH)}
                    >
                      Français
                    </button>
                  </li>
                </>
              )}
            />
            <li>
              <Link to="/ecommerce-releases">
                <Typography
                  variant="body2"
                  paragraph
                  sx={{ mb: 0, color: "inherit" }}
                >
                  Upcoming Changes to eCommerce
                </Typography>
              </Link>
            </li>
            <HasPermissionTo
              perform={["helpAndSupportLink"]}
              permissions={this.props.permissions.rolePermissions}
              render={() => (
                <li>
                  <Link to="/help">
                    <Typography
                      variant="body2"
                      sx={{ mb: 0, color: "inherit" }}
                    >
                      {this.props.helpSupportTitle}
                    </Typography>
                  </Link>
                </li>
              )}
            />
            <HasPermissionTo
              perform={["helpAndSupportModal"]}
              permissions={this.props.permissions.rolePermissions}
              render={() => {
                return (
                  <li>
                    <button onClick={this.toggleModal}>
                      <Typography
                        variant="body2"
                        sx={{ mb: 0, color: "inherit" }}
                      >
                        {this.props.t("Footer.help")}
                      </Typography>
                    </button>
                    <HelpAndSupportDialog
                      {...this.props}
                      open={this.state.openModal}
                      closeModal={this.toggleModal}
                    />
                  </li>
                );
              }}
            />
          </ul>
        </div>
      </Box>
    );
  }
}

export default withTranslation("emp")(Footer);
