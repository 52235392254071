import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import MotionCheck from "../../Icons/MotionCheck";

const EMPCheckbox = ({
  disabled,
  required,
  name,
  label,
  ariaLabel,
  value,
  id,
  handleChange,
  checked,
}) => {
  function onChange(e) {
    handleChange(e.target.checked);
  }

  return (
    <FormControlLabel
      disabled={disabled}
      data-testid={id}
      sx={{ margin: 0 }}
      slotProps={{
        typography: {
          variant: "body2",
          sx: {
            marginLeft: (theme) => theme.spacing(2),
          },
        },
      }}
      control={
        <Checkbox
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          required={required}
          disableRipple
          name={name}
          checkedIcon={<MotionCheck />}
          icon={<span />}
          inputProps={{ "aria-label": ariaLabel }}
          color="primary"
          value={value}
          sx={{ flex: "0 0 24px" }}
        />
      }
      label={label}
    />
  );
};

export default EMPCheckbox;
