const doesAttestationExist = (orderId, attestationId) => {
  // Retrieve the existing attestation data from localStorage
  const existingAttestation = localStorage.getItem("attestation");

  if (existingAttestation) {
    // Parse the existing attestation data
    const attestationData = JSON.parse(existingAttestation);

    if (attestationData[orderId]) {
      // Check if the attestationId matches the provided id
      return attestationData[orderId].hasOwnProperty(attestationId);
    }
  }

  return false;
};

const setAttestation = (attestationId, orderId, date) => {
  // Retrieve the existing value from localStorage
  const existingData = localStorage.getItem("attestation");

  // Parse the existing value to a JavaScript object, or initialize an empty object if null
  const attestationData = existingData ? JSON.parse(existingData) : {};
  const formattedDate = new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  // Append the new object to the existing object
  attestationData[orderId] = {
    ...attestationData[orderId],
    [attestationId]: {
      ...attestationData[orderId]?.[attestationId],
      date: formattedDate,
    },
  };

  // Convert the updated object back to a JSON string
  const attestationString = JSON.stringify(attestationData);

  // Store the updated JSON string back in localStorage
  localStorage.setItem("attestation", attestationString);
};

export { doesAttestationExist, setAttestation };
