import React from "react";
import { Box, Typography, Grid } from "@mui/material";

const EMPAnnouncementBanner = (props) => {
  const { text, textAlign, linkText, handleClick } = props;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Box
          p={1}
          textAlign={textAlign}
          sx={{ bgcolor: "#0068d0", cursor: "pointer" }}
          onClick={handleClick}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={10} md={9}>
              <Typography
                variant="body1"
                display="inline"
                sx={{ color: "white" }}
              >
                {text}
                {"  "}
              </Typography>
              <Typography
                variant="body1"
                display="inline"
                style={{ textDecoration: "underline", color: "white" }}
              >
                {linkText}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EMPAnnouncementBanner;
