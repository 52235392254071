import { useState, useEffect } from "react";
import { usePermission } from "./usePermission";
import { useQueryClient } from "@tanstack/react-query";

export function useCanAcceptTradeIn(orderId) {
  const [canAccept, setCanAccept] = useState(false);
  const tradeInWritePermission = usePermission("tradeIn:write");
  const queryClient = useQueryClient();

  useEffect(() => {
    const { tradeIn, customerReservation } = queryClient.getQueryData([
      "order",
      { orderId },
    ]);

    if (!tradeIn || !customerReservation) {
      setCanAccept(false);
      return;
    }

    const {
      dealerTradeInOptIn,
      purchaseInfo,
      vehicleSoldOrCancelledPast30Days,
      vehicleStatusCode,
    } = customerReservation;

    const tradeInData = {
      ...tradeIn,
      dealerTradeInOptIn,
      purchaseInfo,
      vehicleSoldOrCancelledPast30Days,
      vehicleStatusCode,
    };

    if (tradeInWritePermission) {
      const {
        dealerSubmitted,
        tradeInLinkExpired,
        ownershipType,
        dealerTradeInOptIn,
      } = tradeInData;

      setCanAccept(
        !dealerSubmitted &&
          !tradeInLinkExpired &&
          ownershipType !== null &&
          !!dealerTradeInOptIn
      );
    } else {
      setCanAccept(false);
    }
  }, [orderId]);

  return canAccept;
}
