import React, { Component } from "react";
import styles from "./DealerReporting.module.scss";
import { Grid } from "@mui/material";
import Item from "../../../../shared/item/Item";
import SortingIcon from "../../../../shared/sortingIcon/SortingIcon";
import SortData from "../../../../utils/SortUtil";
import { withTranslation } from "react-i18next";
import { fileDownload } from "../../../../utils/FileDownloadUtil";
import { logMsg } from "../../../../common/Logger";
import DealerReportingClient from "../../../../clients/DealerReportingClient";
import { IS_CANADA_MARKET, IS_USA_MARKET } from "../../../../utils/EmpUtil";
import EMPButton from "../../../../shared/components/EMPButton/EMPButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

class DealerReporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortKey: null,
      sortDirection: null,
      reports: [
        {
          key: "report1",
          name: this.props.t("dealerReporting.report1Name"),
          description: this.props.t("dealerReporting.report1Description"),
          condition: !(
            IS_USA_MARKET(this.props.user.market) ||
            IS_CANADA_MARKET(this.props.user.market)
          ),
          downloadLink: this.downloadOrderStatusReport.bind(this),
        },
      ],
    };
  }

  downloadOrderStatusReport = (report) => {
    DealerReportingClient.downloadOrderStatusReportInCsv(
      this.props.user,
      this.props.user.lang
    )
      .then((res) => {
        const filename = this.props.t(report.description) + ".csv";
        const BOM = "\uFEFF";
        const response = BOM + res;
        fileDownload(
          decodeURIComponent(response),
          "text/csv;charset=utf-8;",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      });
  };

  sortColumns = (sortByCol) => {
    let sortDir = "DSC";
    if (sortByCol === this.state.sortKey) {
      sortDir = this.state.sortDirection === "ASC" ? "DSC" : "ASC";
    }
    const that = this;
    SortData(
      sortByCol,
      sortDir,
      "alphanumeric",
      this.state.reports,
      function (sortedData) {
        that.setState({
          sortDirection: sortDir,
          sortKey: sortByCol,
          reports: sortedData,
        });
      }
    );
  };

  render() {
    return (
      <>
        <Grid container className={`pagePadding ${styles.gridHeader}`}>
          <Grid
            item
            md={3}
            className={styles.typeHeader}
            onClick={() => this.sortColumns("name")}
          >
            <Item value={this.props.t("dealerReporting.type")} />
            <span className={styles.sorting}>
              <SortingIcon
                currentKey="name"
                sortKey={this.state.sortKey}
                sortDirection={this.state.sortDirection}
              />
            </span>
          </Grid>
          <Grid
            item
            md={7}
            className={styles.typeHeader}
            onClick={() => this.sortColumns("description")}
          >
            <Item value={this.props.t("dealerReporting.document")} />
            <span className={styles.sorting}>
              <SortingIcon
                currentKey="description"
                sortKey={this.state.sortKey}
                sortDirection={this.state.sortDirection}
              />
            </span>
          </Grid>
          <Grid item md={2} className={styles.actionHeader}>
            <Item value={this.props.t("dealerReporting.action")} />
          </Grid>
        </Grid>

        {this.state.reports.map(
          (report) =>
            report.condition && (
              <React.Fragment key={report.key}>
                <Grid
                  container
                  className={`pagePaddingLeft pagePaddingRight ${styles.gridBody}`}
                >
                  <Grid item md={3} className={styles.colType}>
                    <div>{report.name}</div>
                  </Grid>
                  <Grid item md={7} className={styles.colDocument}>
                    <div>{report.description}</div>
                  </Grid>
                  <Grid item md={2} className={`${styles.end}`}>
                    <EMPButton
                      onClick={() => report.downloadLink(report)}
                      variant="text"
                      leftIcon={<CloudDownloadIcon />}
                    >
                      {this.props.t("dealerReporting.download")}
                    </EMPButton>
                  </Grid>
                </Grid>
              </React.Fragment>
            )
        )}
      </>
    );
  }
}

export default withTranslation("emp")(DealerReporting);
