import React from "react";
import { Typography } from "@mui/material";
import PriceList from "./PriceList";

const OwnedTradeInTable = ({ appraisalDetails, t }) => {
  return (
    <>
      <Typography variant="h3" sx={{ color: "text.primary" }}>
        {t("TradeIn.ModelE.appraisalAmount")}
      </Typography>

      <Typography variant="body2">
        {t("OrderDetailsConsumerDeposit.tradeInAppraisalTooltipBody")}
      </Typography>

      <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>
        {appraisalDetails.appraisalVehicle}
      </Typography>

      <PriceList amounts={appraisalDetails.amounts} showTotal />
    </>
  );
};

export default OwnedTradeInTable;
