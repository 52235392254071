import React from "react";
import EMPDialog from "../../../shared/components/EMPDialog/EMPDialog";
import { Typography } from "@mui/material";
import { withTranslation } from "react-i18next";

const EVTaxCreditLinksDialog = (props) => {
  const { open, handleClose, t } = props;

  const content = [
    {
      id: 1,
      text: t("ModelEOrders.evTaxCreditLinkDialogText.info1"),
      link: t("ModelEOrders.evTaxCreditLinkDialogText.info1LinkUrl"),
      linkText: t("ModelEOrders.evTaxCreditLinkDialogText.info1LinkText"),
    },
    {
      id: 2,
      text: t("ModelEOrders.evTaxCreditLinkDialogText.info2"),
      link: t("ModelEOrders.evTaxCreditLinkDialogText.info2LinkUrl"),
      linkText: t("ModelEOrders.evTaxCreditLinkDialogText.info2LinkText"),
    },
    {
      id: 3,
      text: t("ModelEOrders.evTaxCreditLinkDialogText.info3"),
      link: t("ModelEOrders.evTaxCreditLinkDialogText.info3LinkUrl"),
      linkText: t("ModelEOrders.evTaxCreditLinkDialogText.info3LinkText"),
    },
  ];

  return (
    <EMPDialog
      open={open}
      hasCloseIcon
      handleClose={handleClose}
      dialogTitle={t("ModelEOrders.evTaxCreditLinkDialogText.title")}
    >
      <ol>
        {content.map((item) => (
          <li key={item.id}>
            <Typography variant="body1">
              {item.text}{" "}
              <a href={item.link} target="_blank" rel="noreferrer">
                {item.linkText}
              </a>
            </Typography>
          </li>
        ))}
      </ol>
    </EMPDialog>
  );
};

export default withTranslation("emp")(EVTaxCreditLinksDialog);
