import { DEALER_ACTIONS } from "../../common/Constants";
import { usePermission } from "../hooks/usePermission";
import { useQueryClient } from "@tanstack/react-query";

export function canWriteTradeIn() {
  return usePermission("tradeIn:write");
}

export function getTradeIn(orderId) {
  const queryClient = useQueryClient();
  const { tradeIn, customerReservation } = queryClient.getQueryData([
    "order",
    { orderId },
  ]);

  const {
    dealerTradeInOptIn,
    purchaseInfo,
    vehicleSoldOrCancelledPast30Days,
    vehicleStatusCode,
  } = customerReservation;

  return {
    ...tradeIn,
    dealerTradeInOptIn,
    purchaseInfo,
    vehicleSoldOrCancelledPast30Days,
    vehicleStatusCode,
  };
}

export function tradeInLinkEnabled(orderId) {
  const {
    purchaseInfo,
    vehicleStatusCode,
    vehicleSoldOrCancelledPast30Days,
    ownershipType,
    appraisalAmount,
    status,
  } = getTradeIn(orderId);

  if (purchaseInfo) {
    const { acceptRejectStatus } = purchaseInfo;

    const vehicleSoldDateBefore30Days =
      !vehicleSoldOrCancelledPast30Days && vehicleStatusCode === "VPHD";

    const vehicleCancelDateBefore30Days =
      !vehicleSoldOrCancelledPast30Days && acceptRejectStatus === "cancelled";

    const tradeInAlreadyActioned = status !== null;

    const linksEnabled =
      acceptRejectStatus.toUpperCase() === DEALER_ACTIONS.ACCEPTED ||
      !tradeInAlreadyActioned ||
      vehicleSoldDateBefore30Days ||
      vehicleCancelDateBefore30Days;

    if (ownershipType === "FINANCED" || ownershipType === "OWNED") {
      return linksEnabled && appraisalAmount;
    } else {
      return linksEnabled;
    }
  } else {
    return false;
  }
}
