import fordStyles from "./Loading.module.scss";
import React, { Component } from "react";

export default class Loading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      styles: fordStyles,
    };
  }

  render() {
    return (
      <div
        className={this.state.styles.homeLoaderWrapper}
        id="loading"
        style={{ display: "none" }}
      >
        <span>
          <div className={this.state.styles.loaderImageHomeLoaderRegular} />
        </span>
      </div>
    );
  }
}
