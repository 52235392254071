import React, { useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  IconButton,
} from "@mui/material";
import { ModalContext } from "./ModalContext";
import MotionClose from "../../Icons/MotionClose";

const EMPModal = () => {
  const { modalHeading, modalContent, handleModal, openModal } =
    useContext(ModalContext);

  useEffect(() => {
    if (openModal) {
      const root = document.getElementById("root");
      root?.setAttribute("aria-hidden", "false");
    }
  }, [openModal]);

  return createPortal(
    <Dialog
      open={openModal}
      maxWidth="md"
      fullWidth
      onClose={(event, reason) =>
        reason === "backdropClick" && handleModal(false, false)
      }
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
      }}
    >
      <DialogTitle
        variant="h4"
        component="h4"
        data-testid="dialog-title"
        sx={{ padding: "40px 56px 16px 56px" }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>{modalHeading}</Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={() => handleModal(false, false)}
              disableRipple
              data-testid="dialog-close-button"
              size="large"
              sx={{ padding: 0 }}
            >
              <MotionClose />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 56px 16px" }}>
        {modalContent}
      </DialogContent>
    </Dialog>,
    document.querySelector("#modal-root")
  );
};

export default EMPModal;
