import React, { Component } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND, IS_USA_MARKET } from "../../utils/EmpUtil";
import TradeIn from "./TradeIn";
import HasPermissionTo from "../../common/HasPermissionTo";
import { FINANCED, LEASED, OWNED } from "../../common/Constants";
import SharedToolTip from "../../shared/sharedToolTip/SharedToolTip";
import { Accordion, AccordionSummary, Tooltip } from "@mui/material";
import crStylesFord from "../customerHandling/crStyles";
import crStylesLincoln from "../customerHandling/crStylesLincoln";
import fordStyles from "./TradeInWrapper.module.scss";
import lincolnStyles from "./TradeInWrapperLincoln.module.scss";
import sharedStylesFord from "../customerHandling/orderDetails/OrderDetailsNewVersion.module.scss";
import sharedStylesLincoln from "../customerHandling/orderDetails/OrderDetailsNewVersionLincoln.module.scss";

class TradeInWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandTradeInDetails: false,
      confirmationDialog: false,
    };
  }

  componentDidMount() {
    this.setState({ expandTradeInDetails: this.props.expandAccordian });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({
        expandTradeInDetails: this.props.expandAccordian,
      });
    }
  }
  handleConfirmationDialog = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      confirmationDialog: !prevState.confirmationDialog,
    }));
  };

  expandCollapseAccordion = () =>
    this.setState((prevState) => ({
      expandTradeInDetails: !prevState.expandTradeInDetails,
    }));

  parseBoolean = (value) => {
    if (value === "false" || value === false) {
      return this.props.t("GeneralEMP.statusNo");
    } else if (value === "true" || value === true) {
      return this.props.t("GeneralEMP.statusYes");
    } else {
      return value;
    }
  };

  getTradeInType = (type) => {
    switch (type) {
      case FINANCED:
        return this.props.t("TradeIn.US.finance");
      case LEASED:
        return this.props.t("TradeIn.US.lease");
      case OWNED:
        return this.props.t("TradeIn.US.owned");
      default:
        return null;
    }
  };

  render() {
    const { customerReservation } = this.props;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const sharedStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? sharedStylesFord
        : sharedStylesLincoln;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    const market =
      this.props.fromCrc &&
      this.props.user.market !== "USA" &&
      this.props.user.market !== "CAN"
        ? this.props.selectedMarket
        : this.props.user.market;

    const tradeInType =
      this.props.tradeInDetails &&
      this.getTradeInType(this.props.tradeInDetails.ownershipType);

    let tradeInHeader = IS_USA_MARKET(market)
      ? this.props.isModelEOrder
        ? this.props.t("TradeIn.ModelE.header")
        : this.props.t("TradeIn.US.header")
      : this.props.t("TradeIn.header");

    if (tradeInType) {
      tradeInHeader = `${tradeInHeader} - ${tradeInType}`;
    }

    const disableDealerActionsFlag =
      (customerReservation &&
        customerReservation.purchaseInfo &&
        customerReservation.purchaseInfo.disableDealerActionsFlag) ||
      this.props.disableDealerActionsFlagForPurchaseRequests;

    const tradeInAssessmentFlag =
      !this.props.tradeInDetails.dealerSubmitted &&
      !this.props.tradeInDetails.tradeInLinkExpired;

    return (
      <Accordion
        defaultExpanded={false}
        onChange={this.expandCollapseAccordion}
        expanded={this.state.expandTradeInDetails}
      >
        <AccordionSummary style={crStyles.headerBarBg}>
          <div
            className={sharedStyles.headerBar}
            data-testid="tradeInAccordion"
          >
            <div className={sharedStyles.header}>
              {tradeInHeader}
              {IS_USA_MARKET(market) && (
                <span className={sharedStyles.iconContainer}>
                  <SharedToolTip
                    {...this.props}
                    openTestid="tradeInTooltipOpen"
                    closeTestid="tradeInTooltipClose"
                    body={this.props.t(
                      "OrderDetailsConsumerDeposit.tradeInTooltipBody"
                    )}
                  />
                </span>
              )}
              {this.props.isModelEOrder && (
                <span className={sharedStyles.headerInfo}>
                  {this.props.t("TradeIn.ModelE.headerInfo")}
                </span>
              )}
            </div>
            <div className={styles.acceptRejectDiv}>
              <HasPermissionTo
                perform={["tradeIn:write"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={
                  IS_USA_MARKET(market) &&
                  !this.props.tradeInDetails.dealerSubmitted &&
                  !this.props.tradeInDetails.tradeInLinkExpired &&
                  tradeInType !== null &&
                  (!this.props.isModelEOrder ||
                    (this.props.isModelEOrder &&
                      !!customerReservation.dealerTradeInOptIn))
                }
                render={() => {
                  return this.props.linkEnabled ? (
                    <button
                      className={`${styles.acceptRejectButton} ${
                        tradeInAssessmentFlag && disableDealerActionsFlag
                          ? styles.disableDealerActionsAccept
                          : ""
                      }`}
                      onClick={this.handleConfirmationDialog}
                      data-testid="accept-reject-trade-in"
                      disabled={
                        tradeInAssessmentFlag && disableDealerActionsFlag
                      }
                    >
                      {this.props.t("TradeIn.US.acceptRejectTradeIn")}
                    </button>
                  ) : (
                    <Tooltip
                      placement="top"
                      title={
                        <div className={styles.bodyContent}>
                          {this.props.t(
                            "TradeIn.US.mustAcceptPurchaseRequestTooltipBody"
                          )}
                        </div>
                      }
                    >
                      <button
                        className={styles.disabledAcceptRejectButton}
                        data-testid="accept-reject-trade-in-disabled"
                        disabled={
                          tradeInAssessmentFlag && disableDealerActionsFlag
                        }
                      >
                        {this.props.t("TradeIn.US.acceptRejectTradeIn")}
                      </button>
                    </Tooltip>
                  );
                }}
              />
              {!this.state.expandTradeInDetails ? (
                <ExpandMoreIcon data-testid="more" />
              ) : (
                <ExpandLessIcon data-testid="less" />
              )}
            </div>
          </div>
        </AccordionSummary>

        {this.props.isOrderCancelled(this.props.status) && (
          <div className={this.props.overlay} data-testid="tradeInOverlay" />
        )}

        {IS_USA_MARKET(market) && (
          <TradeIn
            {...this.props}
            tradeInType={tradeInType}
            parseBoolean={this.parseBoolean}
            openConfirmationDialog={this.state.confirmationDialog}
            closeConfirmationDialog={this.handleConfirmationDialog}
          />
        )}
      </Accordion>
    );
  }
}

export default withTranslation("emp")(TradeInWrapper);
