import React, { forwardRef, useState } from "react";
import "../../../styles/DateTime.scss";
import DatePicker from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";
import { TextValidator } from "react-material-ui-form-validator";

const EMPValidatedDatePicker = (props) => {
  const {
    incomingDate,
    transportFormData,
    startAtToday,
    showTimeSelect,
    validators,
    errorMessages,
    name,
    maxDate,
  } = props;

  const [date, setDate] = useState(incomingDate);
  const placeholder = "mm/dd/yyyy";

  const ValidatedDateInput = forwardRef(
    ({ value, name, onClick, validators, errorMessages }, ref) => (
      <TextValidator
        variant="standard"
        InputProps={{
          endAdornment: <EventIcon color="text.main" />,
          disableUnderline: true,
        }}
        inputProps={{ "data-testid": "date-picker-button" }}
        onClick={onClick}
        name={name}
        ref={ref}
        fullWidth
        value={value}
        placeholder={placeholder}
        validators={validators}
        errorMessages={errorMessages}
      ></TextValidator>
    )
  );

  const formatDate = (value) => {
    const d = new Date(value);
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <DatePicker
      {...props}
      data-testid="date-picker"
      selected={date}
      minDate={startAtToday && new Date()}
      maxDate={maxDate}
      showTimeSelect={showTimeSelect}
      dateFormat={showTimeSelect ? "EEEE, MMMM do yyyy p" : "MM/dd/yyyy"}
      calendarClassName="emp-datepicker"
      onChange={(value) => {
        setDate(value);
        if (!showTimeSelect) {
          const formattedDate = formatDate(value);
          return transportFormData(formattedDate);
        }

        return transportFormData(
          value.toLocaleDateString("en-CA") +
            " " +
            value.toLocaleTimeString([], {
              hour12: false,
            })
        );
      }}
      customInput={
        <ValidatedDateInput
          validators={validators}
          errorMessages={errorMessages}
          name={name}
        />
      }
    />
  );
};

export default EMPValidatedDatePicker;
