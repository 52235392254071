import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import EMPObjectCard from "../../../shared/components/EMPDataCard/EMPObjectCard";
import EMPAccordion from "../../../shared/components/EMPAccordion/EMPAccordion";
import { StyledTableRow } from "../../../shared/Tables/TableRow";
import { useTranslation } from "react-i18next";

const LeaseTable = ({ leasePaymentData }) => {
  const { t } = useTranslation();
  const [accordion1Expanded, setAccordion1Expanded] = useState(false);
  const [accordion1Data, setAccordion1Data] = useState({});
  const [accordion2Expanded, setAccordion2Expanded] = useState(false);
  const [accordion2Data, setAccordion2Data] = useState({});
  const [strippedData, setStrippedData] = useState({});

  const stripLeasePaymentData = (data) => {
    if (!data || typeof data !== "object") {
      return null;
    }

    const {
      capitalizedCostReduction,
      availableIncentivesAndNonCashCredits,
      tradeInPrice,
      cashDueAtSigning,
      totalAmountDueAtSigning,
      dataQuoteTotalAmountDueAtSigning,
      ...strippedData
    } = data;

    setAccordion1Data({
      capitalizedCostReduction,
      monthlyPayment: strippedData["monthlyPayment"],
      downPayment: strippedData["downPayment"],
    });

    setAccordion2Data({
      availableIncentivesAndNonCashCredits,
      tradeInPrice,
      cashDueAtSigning,
      totalAmountDueAtSigning,
      dataQuoteTotalAmountDueAtSigning,
    });

    return strippedData;
  };

  useEffect(() => {
    const strippedData = stripLeasePaymentData(leasePaymentData);
    setStrippedData(strippedData);
  }, []);

  return (
    <>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <Grid container>
          <Grid item>
            <Typography variant="h3" sx={{ color: "text.primary" }}>
              Lease
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <EMPObjectCard data={strippedData} />
      </Box>
      <Box gridColumn={{ xs: "span 12" }}>
        <EMPAccordion
          expanded={accordion1Expanded}
          onChange={() => setAccordion1Expanded(!accordion1Expanded)}
          heading={t("financing.lease.accordionHeaders.0")}
        >
          <StyledTableRow
            text={t(
              "PurchaseDetails.paymentMethod.firstEstimatedMonthlyPayment"
            )}
            data={accordion1Data["monthlyPayment"]?.content
              .replace(/per mo/g, "")
              .trim()}
          />

          <StyledTableRow
            text={t("PurchaseDetails.paymentMethod.downPayment")}
            indent
            data={accordion1Data["downPayment"]?.content}
          />

          <StyledTableRow
            text={t("PurchaseDetails.paymentMethod.capitalizedCostReduction")}
            data={accordion1Data["capitalizedCostReduction"]?.content}
          />
        </EMPAccordion>

        <EMPAccordion
          expanded={accordion2Expanded}
          onChange={() => setAccordion2Expanded(!accordion2Expanded)}
          heading={t("financing.lease.accordionHeaders.1")}
        >
          <StyledTableRow
            text={t(
              "PurchaseDetails.paymentMethod.currentAvailableIncentivesAndNonCashCredits"
            )}
            indent
            data={
              accordion2Data["availableIncentivesAndNonCashCredits"]?.content
            }
          />

          <StyledTableRow
            text={t("PurchaseDetails.paymentMethod.estimatedNetTradeInAmount")}
            indent
            data={accordion2Data["tradeInPrice"]?.content}
          />

          <StyledTableRow
            text={t(
              "PurchaseDetails.paymentMethod.estimatedAmountsToBePaidInCash"
            )}
            indent
            data={accordion2Data["cashDueAtSigning"]?.content}
          />

          <StyledTableRow
            text={t(
              "PurchaseDetails.paymentMethod.howEstimatedAmountsWillBePaidSumTotal"
            )}
            data={
              accordion2Data["totalAmountDueAtSigning"]?.content !== null
                ? accordion2Data["totalAmountDueAtSigning"]?.content
                : accordion2Data["dataQuoteTotalAmountDueAtSigning"]?.content
            }
          />
        </EMPAccordion>
      </Box>
    </>
  );
};

export default LeaseTable;
