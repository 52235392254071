import { isRefundStatusAccepted } from "./PurchaseDepositRules";
import { isPurchaseRequestCanceled } from "./PurchaseRequestRules";
import {
  ACCEPT_REJECT_STATUS_CANCELED,
  ACCEPT_REJECT_STATUS_CANCELLED,
} from "../constants/ActionCardConstants";
import { PURCHASE_DEPOSIT_STATUS } from "../../common/Constants";

export function setPurchaseDepositRefundCard(purchaseDeposit, purchaseInfo) {
  const purchaseDepositStatus = purchaseDeposit?.status;
  const purchaseRequestCanceled = isPurchaseRequestCanceled(purchaseInfo);
  const purchaseDepositRefunded = isRefundStatusAccepted(purchaseDeposit);
  let status;

  // if purchase request is canceled but purchase deposit is accepted, show refund card
  // else return the status of the purchase deposit
  if (
    (purchaseRequestCanceled &&
      purchaseDepositStatus === PURCHASE_DEPOSIT_STATUS.CREATED &&
      !purchaseDepositRefunded) ||
    (purchaseRequestCanceled &&
      purchaseDepositStatus === PURCHASE_DEPOSIT_STATUS.ACCEPTED &&
      !purchaseDepositRefunded)
  ) {
    status = "refund";
  } else {
    status =
      purchaseDepositStatus === PURCHASE_DEPOSIT_STATUS.CREATED
        ? "created"
        : purchaseDepositStatus;
  }

  return status;
}

export function setCanceledPurchaseRequestDisplay(actionCardState) {
  const isSingleCard = actionCardState.length === 1;
  const isCanceledStatus =
    actionCardState[0].id === ACCEPT_REJECT_STATUS_CANCELED ||
    actionCardState[0].id === ACCEPT_REJECT_STATUS_CANCELLED;

  return isSingleCard && isCanceledStatus;
}
