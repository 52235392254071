import React, { Component } from "react";
import {
  DEALER_ACTIONS,
  PURCHASE_DEPOSIT_STATUS,
  PURCHASE_REFUND_STATUS,
  PURCHASE_REQUEST_BUSINESS_STATUS,
  REFUND_TYPE,
} from "../../../../common/Constants";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../../utils/UpperCaseText";
import SharedToolTip from "../../../../shared/sharedToolTip/SharedToolTip";
import { logMsg } from "../../../../common/Logger";
import { InfoDetail } from "../InfoDetail";
import OfflineRefundCalendarDialog from "../offlineRefundDialog/OfflineRefundCalendarDialog";
import OfflineRefundConfirmDialog from "../offlineRefundDialog/OfflineRefundConfirmDialog";
import Tooltip from "@mui/material/Tooltip";
import {
  displayError,
  ERROR_PROCESSING,
  is400,
} from "../../../../errors/ErrorConstants";
import CancelOrderDialog from "../../CancelOrderDialog";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import fordStyles from "../NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "../NAOrderDetailsNewVersionLincoln.module.scss";
import isOlderThan730Days from "../../../../utils/DateRange";
import DataCard from "../../../../shared/components/DataCard/DataCard";

class DownPayment extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: [] };
  }

  openAcceptPurchaseDepositDialog = (e) => {
    e.stopPropagation();
    this.setState({ openAcceptPurchaseDepositDialog: true });
  };

  closeAcceptPurchaseDepositDialog = (e) => {
    e.stopPropagation();
    this.setState({
      openAcceptPurchaseDepositDialog: false,
    });
  };

  toggleRejectPurchaseDepositDialog = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      openRejectPurchaseDepositDialog:
        !prevState.openRejectPurchaseDepositDialog,
    }));
  };

  toggleRefundPurchaseDepositDialog = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      openRefundPurchaseDepositDialog:
        !prevState.openRefundPurchaseDepositDialog,
    }));
  };

  openOfflineRefundConfirmDialog = (date) => {
    this.refundDate = date;
    this.setState({
      openOfflineRefundPurchaseDepositDialog: false,
      openOfflineRefundPurchaseDepositConfirmDialog: true,
    });
  };

  openOfflineRefundCalendarDialog = () => {
    this.setState({
      openOfflineRefundPurchaseDepositDialog: true,
      openOfflineRefundPurchaseDepositConfirmDialog: false,
    });
  };

  closeOfflineRefundDialog = () => {
    this.setState({
      openOfflineRefundPurchaseDepositDialog: false,
    });
  };

  closeOfflineRefundConfirmDialog = () => {
    this.setState({
      openOfflineRefundPurchaseDepositConfirmDialog: false,
    });
  };

  submitAcceptPurchaseDepositRequest = () => {
    const acceptPurchaseDepositInfo = {
      reservationId: this.props.customerReservation.id,
      dealerAction: DEALER_ACTIONS.PURCHASE_DEPOSIT_ACCEPTED,
    };
    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.user.token,
      this.props.customerReservation.dealerId,
      acceptPurchaseDepositInfo,
      this.props.user.userId
    )
      .catch((error) => {
        if (is400(error)) {
          this.props.processFordPayError(error);
        } else {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
        logMsg("the error is: ", error);
      })
      .finally(() => {
        this.setState({ openAcceptPurchaseDepositDialog: false });
        this.props.refreshReservationDetailsWithNotifications();
        this.props.hideOrShow(false);
      });
  };

  submitRejectPurchaseDepositRequest = () => {
    const acceptPurchaseDepositInfo = {
      reservationId: this.props.customerReservation.id,
      dealerAction: DEALER_ACTIONS.PURCHASE_DEPOSIT_REJECTED,
    };
    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.user.token,
      this.props.customerReservation.dealerId,
      acceptPurchaseDepositInfo,
      this.props.user.userId
    )
      .catch((error) => {
        if (is400(error)) {
          this.props.processFordPayError(error);
        } else {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
        logMsg("the error is: ", error);
      })
      .finally(() => {
        this.setState({ openRejectPurchaseDepositDialog: false });

        this.props.refreshReservationDetailsWithNotifications();
        this.props.hideOrShow(false);
      });
  };

  submitRefundPurchaseDepositRequest = () => {
    const refundPurchaseDepositInfo = {
      reservationId: this.props.customerReservation.id,
      dealerAction: DEALER_ACTIONS.PURCHASE_DEPOSIT_REFUND,
    };
    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.user.token,
      this.props.customerReservation.dealerId,
      refundPurchaseDepositInfo,
      this.props.user.userId
    )
      .catch((error) => {
        if (is400(error)) {
          this.props.processFordPayError(error);
        } else {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
        logMsg("the error is: ", error);
      })
      .finally(() => {
        this.setState((prevState) => ({
          openRefundPurchaseDepositDialog:
            !prevState.openRefundPurchaseDepositDialog,
        }));
        this.props.refreshReservationDetailsWithNotifications();
        this.props.hideOrShow(false);
      });
  };

  getAcceptPurchaseDepositDialogProps = () => {
    return {
      title: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositTitle"
      ),
      body: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositSubTitle"
      ),
      checkbox: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositConfirmText"
      ),
      checkboxList: [
        this.props.t(
          "OrderDetailsConsumerDeposit.acceptPurchaseDepositBullet1"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.acceptPurchaseDepositBullet2"
        ),
      ],
      confirm: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogConfirm"
      ),
      cancel: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogCancel"
      ),
    };
  };
  getAcceptDownPaymentDialogProps = () => {
    return {
      title: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogTitle"
      ),
      body: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogBody"
      ),
      checkbox: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogCheckbox"
      ),
      checkboxList: [
        this.props.t(
          "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogCheckboxList1"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogCheckboxList2"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogCheckboxList3"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogCheckboxList4"
        ),
      ],
      confirm: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogConfirm"
      ),
      cancel: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogCancel"
      ),
    };
  };

  getRejectPurchaseDepositDialogProps = () => {
    return {
      title: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositTitle"
      ),
      body: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositSubTitle"
      ),
      checkbox: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositConfirmText"
      ),
      checkboxList: [
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositBullet1"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositBullet2"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositBullet3"
        ),
      ],
      confirm: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogConfirm"
      ),
      cancel: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogCancel"
      ),
    };
  };

  getRejectDownPaymentDialogProps = () => {
    return {
      title: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogTitle"
      ),
      body: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogBody"
      ),
      checkbox: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogCheckbox"
      ),
      checkboxList: [
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogCheckboxList1"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogCheckboxList2"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogCheckboxList3"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogCheckboxList4"
        ),
      ],
      confirm: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogConfirm"
      ),
      cancel: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogCancel"
      ),
    };
  };

  getRefundPurchaseDepositDialogProps = () => {
    return {
      title: this.props.t(
        "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogTitle"
      ),
      body: this.props.t(
        "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogBody"
      ),
      checkbox: this.props.t(
        "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogCheckbox"
      ),
      checkboxList: [
        this.props.t(
          "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogCheckboxList1"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogCheckboxList2"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogCheckboxList3"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogCheckboxList4"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogCheckboxList5"
        ),
      ],
      confirm: this.props.t(
        "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogConfirm"
      ),
      cancel: this.props.t(
        "OrderDetailsConsumerDeposit.refundPurchaseDepositDialogCancel"
      ),
    };
  };

  isPurchaseRequestAccepted = () => {
    const customerReservation = this.props.customerReservation;
    return (
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseRequestDealerAction &&
      UpperCaseText(
        customerReservation.purchaseInfo.purchaseRequestDealerAction
          .dealerAction
      ) === PURCHASE_REQUEST_BUSINESS_STATUS.ACCEPTED
    );
  };

  isPurchaseRequestCancelled = () => {
    const customerReservation = this.props.customerReservation;
    return (
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseRequestDealerAction &&
      UpperCaseText(
        customerReservation.purchaseInfo.purchaseRequestDealerAction
          .dealerAction
      ) === PURCHASE_REQUEST_BUSINESS_STATUS.CANCELLED
    );
  };

  showAcceptRejectDownPaymentLink = () => {
    const acceptRejectStatus =
      this.props.customerReservation.purchaseDeposit.acceptRejectStatus;
    const refundStatus =
      this.props.customerReservation.purchaseDeposit.refundStatus;
    return !(
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.ACCEPTED ||
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.REJECTED ||
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.DECLINED ||
      Object.values(PURCHASE_REFUND_STATUS).includes(refundStatus)
    );
  };

  isRefundDepositEligible = () => {
    const acceptRejectStatus =
      this.props.customerReservation.purchaseDeposit.acceptRejectStatus;
    const refundStatus =
      this.props.customerReservation.purchaseDeposit.refundStatus;
    if (
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.DECLINED ||
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.FAILED ||
      refundStatus === PURCHASE_REFUND_STATUS.ACCEPTED ||
      refundStatus === PURCHASE_REFUND_STATUS.DECLINED
    ) {
      return false;
    }
    return (
      (this.props.customerReservation.purchaseInfo &&
        (this.isPurchaseRequestAccepted() ||
          this.isPurchaseRequestCancelled()) &&
        acceptRejectStatus &&
        PURCHASE_DEPOSIT_STATUS.ACCEPTED === acceptRejectStatus &&
        PURCHASE_DEPOSIT_STATUS.REJECTED !== acceptRejectStatus &&
        !(
          refundStatus &&
          PURCHASE_REFUND_STATUS.REFUNDED_OFFLINE === refundStatus
        )) ||
      PURCHASE_REFUND_STATUS.FAILED === refundStatus
    );
  };

  isOfflineRefundDepositEligible = () => {
    const acceptRejectStatus =
      this.props.customerReservation.purchaseDeposit.acceptRejectStatus;
    const refundStatus =
      this.props.customerReservation.purchaseDeposit.refundStatus;
    if (
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.DECLINED ||
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.FAILED ||
      refundStatus === PURCHASE_REFUND_STATUS.ACCEPTED ||
      refundStatus === PURCHASE_REFUND_STATUS.DECLINED ||
      refundStatus === PURCHASE_REFUND_STATUS.REFUNDED_OFFLINE
    ) {
      return false;
    }
    return (
      (this.props.customerReservation.purchaseInfo &&
        (this.isPurchaseRequestAccepted() ||
          this.isPurchaseRequestCancelled()) &&
        acceptRejectStatus &&
        PURCHASE_DEPOSIT_STATUS.ACCEPTED === acceptRejectStatus &&
        PURCHASE_DEPOSIT_STATUS.REJECTED !== acceptRejectStatus) ||
      PURCHASE_REFUND_STATUS.FAILED === refundStatus
    );
  };

  getPaymentStatus = (acceptRejectStatus, refundStatus) => {
    if (Object.values(PURCHASE_REFUND_STATUS).includes(refundStatus)) {
      return this.props.t(
        "OrderDetailsConsumerDeposit.purchasedepositaccepted"
      );
    }

    if (
      UpperCaseText(acceptRejectStatus) ===
      UpperCaseText(PURCHASE_DEPOSIT_STATUS.ACCEPTED)
    ) {
      return this.props.t(
        "OrderDetailsConsumerDeposit.purchasedepositaccepted"
      );
    }

    if (
      UpperCaseText(acceptRejectStatus) ===
      UpperCaseText(PURCHASE_DEPOSIT_STATUS.REJECTED)
    ) {
      return this.props.t(
        "OrderDetailsConsumerDeposit.purchasedepositrejected"
      );
    }
  };

  getInvoice(value) {
    return value ? value.substr(value.length - 12, value.length) : null;
  }

  render() {
    const { customerReservation } = this.props;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);
    const disableDealerActionsFlag =
      this.props.disableDealerActionsFlagForPurchaseRequests ||
      (customerReservation &&
        customerReservation.purchaseInfo &&
        customerReservation.purchaseInfo.disableDealerActionsFlag);
    const { purchaseDeposit, showroomJourneyType } =
      this.props.customerReservation;

    const isDownPaymentAccepted =
      UpperCaseText(purchaseDeposit && purchaseDeposit.acceptRejectStatus) ===
      UpperCaseText(PURCHASE_DEPOSIT_STATUS.ACCEPTED);

    const isOlderThan2Years =
      customerReservation && isOlderThan730Days(customerReservation.date);

    const isPurchaseRequestOlderThan2Years =
      customerReservation &&
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseRequestDate &&
      isOlderThan730Days(customerReservation.purchaseInfo.purchaseRequestDate);

    const isShowroomPurchaseRequest = showroomJourneyType !== false;

    let disableRefundCTA;
    if (isShowroomPurchaseRequest) {
      disableRefundCTA = isPurchaseRequestOlderThan2Years;
    } else {
      disableRefundCTA = disableDealerActionsFlag || isOlderThan2Years;
    }

    return (
      <DataCard
        {...this.props}
        cardHeader={
          this.props.isModelE
            ? this.props.t("OrderDetailsConsumerDeposit.purchaseDepositTitle")
            : this.props.t("OrderDetailsConsumerDeposit.purchaseDeposit")
        }
        hasToolTip={true}
        toolTipBody={this.props.t(
          "OrderDetailsConsumerDeposit.purchaseDepositTooltipBody1"
        )}
        openTestid="purchaseTooltipId"
        headerDetail={
          <>
            <HasPermissionTo
              perform={["acceptDepositNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.showAcceptRejectDownPaymentLink()}
              render={() => {
                return this.isPurchaseRequestAccepted() ? (
                  <button
                    id="acceptPurchaseDepositBtn"
                    data-testid="acceptPurchaseDepositBtn"
                    className={styles.refundDepositNA}
                    onClick={this.openAcceptPurchaseDepositDialog}
                    disabled={disableDealerActionsFlag}
                  >
                    {this.props.t("OrderDetailsConsumerDeposit.accept")}
                  </button>
                ) : (
                  <Tooltip
                    title={
                      !this.props.customerReservation.depositDisabled24Hours ? (
                        <div className={styles.bodyContent}>
                          {this.props.t(
                            "OrderDetailsConsumerDeposit.purchaseRequestNotYetAcceptedAcceptTooltipBody"
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    }
                  >
                    <button
                      className={styles.disabledRefundDepositNA}
                      data-testid="must-accept-request-trade-in"
                      disabled={disableDealerActionsFlag}
                    >
                      {this.props.t("OrderDetailsConsumerDeposit.accept")}
                    </button>
                  </Tooltip>
                );
              }}
            />
            <HasPermissionTo
              perform={["rejectDepositNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.showAcceptRejectDownPaymentLink()}
              render={() => {
                return this.isPurchaseRequestAccepted() ? (
                  <button
                    id="rejectPurchaseDepositBtn"
                    data-testid="rejectPurchaseDepositBtn"
                    className={styles.refundDepositNA}
                    onClick={this.toggleRejectPurchaseDepositDialog}
                    disabled={disableDealerActionsFlag}
                  >
                    {this.props.t("OrderDetailsConsumerDeposit.reject")}
                  </button>
                ) : (
                  <Tooltip
                    title={
                      !this.props.customerReservation.depositDisabled24Hours ? (
                        <div className={styles.bodyContent}>
                          {this.props.t(
                            "OrderDetailsConsumerDeposit.purchaseRequestNotYetAcceptedRejectTooltipBody"
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    }
                  >
                    <button
                      className={styles.disabledRefundDepositNA}
                      data-testid="accept-reject-trade-in"
                      disabled={disableDealerActionsFlag}
                    >
                      {this.props.t("OrderDetailsConsumerDeposit.reject")}
                    </button>
                  </Tooltip>
                );
              }}
            />
            <HasPermissionTo
              perform={["refundPurchaseDepositNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.isRefundDepositEligible()}
              render={() => {
                return (
                  <div>
                    <button
                      id="refundPurchaseDepositBtn"
                      data-testid="refundPurchaseDepositBtn"
                      className={styles.refundDepositNA}
                      onClick={this.toggleRefundPurchaseDepositDialog}
                      disabled={disableRefundCTA}
                    >
                      {this.props.t("OrderDetailsConsumerDeposit.refund")}
                    </button>
                  </div>
                );
              }}
            />
            <HasPermissionTo
              perform={["refundPurchaseDepositNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.isOfflineRefundDepositEligible()}
              render={() => {
                return (
                  <div>
                    <button
                      id="offlineRefundPurchaseDepositBtn"
                      data-testid="offlineRefundPurchaseDepositBtn"
                      className={styles.refundDepositNA}
                      onClick={this.openOfflineRefundCalendarDialog}
                      disabled={disableRefundCTA}
                    >
                      {this.props.t(
                        "OrderDetailsConsumerDeposit.offlineRefund"
                      )}
                    </button>
                  </div>
                );
              }}
            />
          </>
        }
      >
        <InfoDetail
          header={
            this.props.isModelE
              ? this.props.t(
                  "OrderDetailsConsumerDeposit.purchaseDepositAmount"
                )
              : this.props.t("OrderDetailsConsumerDeposit.depositAmt")
          }
          value={purchaseDeposit.depositAmount}
        />
        <InfoDetail
          header={this.props.t("OrderDetailsConsumerDeposit.last4DigCC")}
          value={
            this.props.customerReservation.purchaseDeposit.cardLastFourDigits
          }
        />
        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["transactionId:view"]}
          render={() => {
            return (
              <div className={styles.itemHeaderSpan}>
                <InfoDetail
                  header={
                    this.props.isModelE
                      ? this.props.t(
                          "OrderDetailsConsumerDeposit.purchaseDepositRefInvoice"
                        )
                      : this.props.t(
                          "OrderDetailsConsumerDeposit.purchaseRefInvoice"
                        )
                  }
                  value={this.getInvoice(
                    this.props.customerReservation.purchaseDeposit
                      .paymentTransactionId
                  )}
                  headerTooltip={
                    <SharedToolTip
                      {...this.props}
                      openTestid="openPurchaseInvoiceId"
                      closeTestid="closePurchaseInvoiceId"
                      title={this.props.t(
                        "OrderDetailsConsumerDeposit.invoiceTooltipHeader"
                      )}
                      body={this.props.t(
                        "OrderDetailsConsumerDeposit.invoiceTooltipBody"
                      )}
                    />
                  }
                />
              </div>
            );
          }}
        />
        {Object.values(PURCHASE_DEPOSIT_STATUS)
          .slice(1, 3)
          .includes(purchaseDeposit.acceptRejectStatus) ? (
          <InfoDetail
            header={this.props.t("OrderDetailsConsumerDeposit.paymentStatus")}
            value={this.props.t(
              `OrderDetailsConsumerDeposit.${purchaseDeposit.acceptRejectStatus}`
            )}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="failedPaymentStatusTooltipId"
                closeTestid="closeTooltipId"
                title={this.props.t(
                  `OrderDetailsConsumerDeposit.${purchaseDeposit.acceptRejectStatus}`
                )}
                body={this.props.t(
                  `OrderDetailsConsumerDeposit.tooltip${purchaseDeposit.acceptRejectStatus}`
                )}
              />
            }
          />
        ) : (
          <InfoDetail
            header={this.props.t("OrderDetailsConsumerDeposit.paymentStatus")}
            value={this.getPaymentStatus(
              purchaseDeposit.acceptRejectStatus,
              purchaseDeposit.refundStatus
            )}
          />
        )}

        {isDownPaymentAccepted && (
          <InfoDetail
            header={
              this.props.isModelE
                ? this.props.t(
                    "OrderDetailsConsumerDeposit.purchaseDepositAcceptedDate"
                  )
                : this.props.t(
                    "OrderDetailsConsumerDeposit.downPaymentAcceptedDate"
                  )
            }
            value={customerReservation.purchaseDeposit.acceptRejectDate}
          />
        )}

        <InfoDetail
          header={this.props.t("OrderDetails.dealerUserId")}
          value={
            customerReservation.purchaseDeposit &&
            customerReservation.purchaseDeposit.acceptRejectDealerUserId
          }
        />

        {Object.values(PURCHASE_REFUND_STATUS)
          .slice(1, 3)
          .includes(purchaseDeposit.refundStatus) ? (
          <InfoDetail
            header={this.props.t(
              "OrderDetailsConsumerDeposit.purchaseRefStatus"
            )}
            value={this.props.t(
              `OrderDetailsConsumerDeposit.${purchaseDeposit.refundStatus}`
            )}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="failedPaymentStatusTooltipId"
                closeTestid="closeTooltipId"
                title={this.props.t(
                  `OrderDetailsConsumerDeposit.${purchaseDeposit.refundStatus}`
                )}
                body={this.props.t(
                  `OrderDetailsConsumerDeposit.tooltip${purchaseDeposit.refundStatus}`
                )}
              />
            }
          />
        ) : (
          <InfoDetail
            header={
              this.props.isModelE
                ? this.props.t(
                    "OrderDetailsConsumerDeposit.purchaseDepositRefStatus"
                  )
                : this.props.t("OrderDetailsConsumerDeposit.purchaseRefStatus")
            }
            value={
              purchaseDeposit.refundStatus ===
              PURCHASE_REFUND_STATUS.REFUNDED_OFFLINE
                ? this.props.t(
                    "OrderDetailsConsumerDeposit.refundedOfflineDialogRefundedOffline"
                  )
                : purchaseDeposit.refundStatus ===
                    PURCHASE_REFUND_STATUS.ACCEPTED &&
                  this.props.t(
                    `OrderDetailsConsumerDeposit.${purchaseDeposit.refundStatus}`
                  )
            }
          />
        )}

        <InfoDetail
          header={
            this.props.isModelE
              ? this.props.t(
                  "OrderDetailsConsumerDeposit.purchaseDepositRefDate"
                )
              : this.props.t("OrderDetailsConsumerDeposit.purchaseRefDate")
          }
          value={this.props.customerReservation.purchaseDeposit.refundDate}
        />
        <InfoDetail
          header={
            this.props.isModelE
              ? this.props.t(
                  "OrderDetailsConsumerDeposit.purchaseDepositRefAmount"
                )
              : this.props.t("OrderDetailsConsumerDeposit.purchaseRefAmount")
          }
          value={
            customerReservation.purchaseDeposit &&
            customerReservation.purchaseDeposit.refundAmount
          }
        />
        <div className={styles.itemHeaderSpan}>
          <InfoDetail
            header={
              this.props.isModelE
                ? this.props.t(
                    "OrderDetailsConsumerDeposit.purchaseDepositRefundInvoice"
                  )
                : this.props.t(
                    "OrderDetailsConsumerDeposit.purchaseRefundInvoice"
                  )
            }
            value={this.getInvoice(
              this.props.customerReservation.purchaseDeposit.refundTransactionId
            )}
            headerTooltip={
              <SharedToolTip
                {...this.props}
                openTestid="openPurchaseRefundInvoiceId"
                closeTestid="closePurchaseRefundInvoiceId"
                title={this.props.t(
                  "OrderDetailsConsumerDeposit.invoiceTooltipHeader"
                )}
                body={this.props.t(
                  "OrderDetailsConsumerDeposit.invoiceTooltipBody"
                )}
              />
            }
          />
        </div>

        <InfoDetail
          header={this.props.t("OrderDetails.dealerUserId")}
          value={
            customerReservation.purchaseDeposit &&
            customerReservation.purchaseDeposit.refundDealerUserId
          }
        />

        {this.state.openAcceptPurchaseDepositDialog && (
          <CancelOrderDialog
            {...this.props}
            dialogProps={
              this.props.isModelE
                ? this.getAcceptPurchaseDepositDialogProps()
                : this.getAcceptDownPaymentDialogProps()
            }
            open={this.state.openAcceptPurchaseDepositDialog}
            onClose={this.closeAcceptPurchaseDepositDialog}
            handleConfirm={this.submitAcceptPurchaseDepositRequest}
            showDropdown={false}
          />
        )}

        {this.state.openRejectPurchaseDepositDialog && (
          <CancelOrderDialog
            {...this.props}
            dialogProps={
              this.props.isModelE
                ? this.getRejectPurchaseDepositDialogProps()
                : this.getRejectDownPaymentDialogProps()
            }
            open={this.state.openRejectPurchaseDepositDialog}
            onClose={this.toggleRejectPurchaseDepositDialog}
            handleConfirm={this.submitRejectPurchaseDepositRequest}
            showDropdown={false}
          />
        )}

        {this.state.openRefundPurchaseDepositDialog && (
          <CancelOrderDialog
            {...this.props}
            dialogProps={this.getRefundPurchaseDepositDialogProps()}
            open={this.state.openRefundPurchaseDepositDialog}
            onClose={this.toggleRefundPurchaseDepositDialog}
            handleConfirm={this.submitRefundPurchaseDepositRequest}
            showDropdown={false}
          />
        )}

        {this.state.openOfflineRefundPurchaseDepositDialog && (
          <OfflineRefundCalendarDialog
            {...this.props}
            refundType={REFUND_TYPE.PURCHASE_DEPOSIT}
            open={this.state.openOfflineRefundPurchaseDepositDialog}
            todayDate={this.props.customerReservation.todayDate}
            onToggle={this.openOfflineRefundConfirmDialog}
            onClose={this.closeOfflineRefundDialog}
            onGoBack={this.closeOfflineRefundDialog}
          />
        )}

        {this.state.openOfflineRefundPurchaseDepositConfirmDialog && (
          <OfflineRefundConfirmDialog
            {...this.props}
            open={this.state.openOfflineRefundPurchaseDepositConfirmDialog}
            refundDate={this.refundDate}
            refundType={REFUND_TYPE.PURCHASE_DEPOSIT}
            onToggle={this.openOfflineRefundCalendarDialog}
            onClose={this.closeOfflineRefundConfirmDialog}
          />
        )}
      </DataCard>
    );
  }
}

export default withTranslation("emp")(DownPayment);
