import React from "react";
import "../../../../styles/sharedStyles.scss";
import Grid from "@mui/material/Grid";
import styles from "./DetailsConfirmationDialog.module.scss";
import { withTranslation } from "react-i18next";
import checkboxStyles from "../../../../shared/checkBox/CheckBox.module.scss";
import CheckBox from "../../../../shared/checkBox/CheckBox";
import { DECISION } from "../../../../common/Constants";
import DecisionDialog from "../decision/DecisionDialog";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

class DetailsConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm: false,
      dialogBoxes: {
        decision: false,
        acceptAndKeep: false,
        acceptAndKeepConfirm: false,
        acceptAndSubmit: false,
        acceptAndSubmitDropOffConfirm: false,
        acceptAndSubmitPickUpConfirm: false,
        rejectTradeIn: false,
        rejectTradeInConfirm: false,
      },
    };
  }

  handleDialogChange = (openKey, closeKey) => {
    const dialogBoxes = { ...this.state.dialogBoxes };

    dialogBoxes[openKey] = true;
    dialogBoxes[closeKey] = false;

    this.setState({ dialogBoxes });
  };

  handleConfirm = (e) => {
    this.handleClose(e);

    const dialogBoxes = { ...this.state.dialogBoxes };
    dialogBoxes[DECISION] = true;

    this.setState({ dialogBoxes });
  };

  handleClose = (e) => {
    this.setState({ confirm: false });
    this.props.onClose(e);
  };

  closeDialogBox = (key) => {
    const dialogBoxes = { ...this.state.dialogBoxes };
    dialogBoxes[key] = false;

    this.setState({ dialogBoxes });
  };

  toggleCheckbox = () => {
    this.setState((prevState) => ({
      confirm: !prevState.confirm,
    }));
  };

  render() {
    return (
      <>
        {this.props.open ? (
          <EMPDialog
            open={this.props.open}
            handleClose={this.handleClose}
            dialogTitle={this.props.t(
              "TradeIn.US.dialogs.detailsConfirmation.title"
            )}
            hasCloseIcon
            hasActionButton
            actionButtonText={this.props.t(
              "TradeIn.US.dialogs.detailsConfirmation.confirm"
            )}
            onConfirm={this.handleConfirm}
            disableActionButton={!this.state.confirm}
            hasSecondaryActionButton
            secondaryActionButtonText={this.props.t(
              "TradeIn.US.dialogs.detailsConfirmation.cancel"
            )}
            onSecondaryAction={this.handleClose}
          >
            <Grid
              container
              data-testid="detailsConfirmationDialog"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <div className={styles.subTitle}>
                  {this.props.t(
                    "TradeIn.US.dialogs.detailsConfirmation.subTitle"
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <ul className={styles.bulletPoints}>
                  <li>
                    {this.props.t(
                      "TradeIn.US.dialogs.detailsConfirmation.bullet1"
                    )}
                  </li>
                  <li>
                    {this.props.t(
                      "TradeIn.US.dialogs.detailsConfirmation.bullet2"
                    )}
                  </li>
                </ul>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.checkbox}>
                  <CheckBox
                    boldCheck={true}
                    textClass={checkboxStyles.cancelLabel}
                    checked={this.state.confirm}
                    isDisabled={false}
                    name="chkBxConfirm"
                    id="chkBxConfirm"
                    label={this.props.t(
                      "TradeIn.US.dialogs.detailsConfirmation.body"
                    )}
                    onChange={this.toggleCheckbox}
                  />
                </div>
              </Grid>
            </Grid>
          </EMPDialog>
        ) : (
          ""
        )}

        <DecisionDialog
          {...this.props}
          open={this.state.dialogBoxes[DECISION]}
          onClose={this.closeDialogBox}
          onDialogChange={this.handleDialogChange}
          dialogBoxes={this.state.dialogBoxes}
        />
      </>
    );
  }
}

export default withTranslation("emp")(DetailsConfirmationDialog);
