import React, { Component } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "./SearchResultsTable.module.scss";
import { withTranslation } from "react-i18next";
import { BRAND_NAMES, LINCOLN_ENGLISH } from "../../common/Constants";
import fieldViewSearchTableStyles from "./FieldViewSearchTableStyles";
import { publish } from "../../common/PubSub";
import {
  IS_CANADA_MARKET,
  IS_FORD_BRAND,
  IS_USA_MARKET,
} from "../../utils/EmpUtil";
import TitleCase from "../../utils/TitleCase";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import MotionChevronRight from "../../shared/Icons/MotionChevronRight";

class FieldViewOrderIdSearchResultsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit(props, dealer) {
    props.user.updateCurrentDealer(dealer);
    props.user.updateBrand(dealer.commonId.charAt(dealer.commonId.length - 1));
    publish("brandChanged", dealer.commonId.charAt(dealer.commonId.length - 1));
    if (IS_USA_MARKET(props.user.market)) {
      if (!IS_FORD_BRAND(props.user.brand)) {
        props.i18n.changeLanguage(LINCOLN_ENGLISH);
      } else {
        props.i18n.changeLanguage("en");
      }
    }
    props.user.setLanguageCode(props.i18n.language);
  }

  formatPostalCode(postalCode) {
    if (postalCode) {
      return postalCode.substring(0, 5);
    }
  }

  getSpecs(reservation) {
    if (this.props.fromFieldView && this.props.isNADealer) {
      return (
        reservation.specSummary &&
        reservation.specSummary.filter(Boolean).join(" / ")
      );
    } else {
      return reservation.specs && reservation.specs.join(" / ");
    }
  }

  translateOrderType(reservation) {
    //Purchase Request
    if (reservation.purchaseType || reservation.purchaseRequestDate) {
      return this.props.t("CustomerReservations.purchaseRequest");
    } else {
      return this.props.t("CustomerReservations.order");
    }
  }

  getBrandName(dealerData) {
    if (dealerData && dealerData.commonId) {
      return IS_FORD_BRAND(
        dealerData.commonId.charAt(dealerData.commonId.length - 1)
      )
        ? TitleCase(BRAND_NAMES.FORD)
        : TitleCase(BRAND_NAMES.LINCOLN);
    }
    return "--";
  }

  render() {
    return (
      <div key={this.props.index}>
        <TableContainer component={Paper} square>
          <Table id="newTable" key={this.props.index}>
            <TableHead className={styles.tableHead}>
              <TableRow>
                <TableCell
                  data-testid={"orderType" + this.props.commonDealerId}
                  style={fieldViewSearchTableStyles.orderTypeHeader}
                >
                  <div className={styles.alignStart}>
                    {this.props.t("CustomerReservations.orderType")}
                  </div>
                </TableCell>
                <TableCell
                  style={fieldViewSearchTableStyles.customerNameHeader}
                >
                  <div className={styles.alignStart}>
                    {this.props.t("fieldView.customerName")}
                  </div>
                </TableCell>
                <TableCell style={fieldViewSearchTableStyles.specsHeader}>
                  <div className={styles.alignStart}>
                    {this.props.t("fieldView.vehicleSpecs")}
                  </div>
                </TableCell>
                <TableCell style={fieldViewSearchTableStyles.cellHeader}>
                  <div className={styles.alignStart}>
                    {this.props.t("fieldView.dealerName")}
                  </div>
                </TableCell>
                <TableCell style={fieldViewSearchTableStyles.cellHeader}>
                  <div className={styles.alignStart}>
                    {this.props.t("fieldView.salesCode")}
                  </div>
                </TableCell>
                <TableCell style={fieldViewSearchTableStyles.cellHeader}>
                  <div className={styles.alignStart}>
                    {this.props.t("fieldView.paCode")}
                  </div>
                </TableCell>
                {IS_CANADA_MARKET(this.props.user.market) && (
                  <TableCell style={fieldViewSearchTableStyles.cellHeader}>
                    <div className={styles.alignStart}>
                      {this.props.t("fieldView.brand")}
                    </div>
                  </TableCell>
                )}
                <TableCell style={fieldViewSearchTableStyles.cellHeader}>
                  <div className={styles.alignStart}>
                    {this.props.t("fieldView.dealerAddress")}
                  </div>
                </TableCell>
                <TableCell style={fieldViewSearchTableStyles.emptyHeader} />
              </TableRow>
            </TableHead>
            <TableBody
              data-testid="reservationTable"
              className={styles.tableBody}
            >
              {this.props.reservations.map((reservation, index) => (
                <TableRow
                  className="pagePaddingLeft pagePaddingRight"
                  id={"row" + index}
                  key={index}
                >
                  <TableCell style={fieldViewSearchTableStyles.orderType}>
                    <div style={fieldViewSearchTableStyles.orderTypeText}>
                      {this.translateOrderType(reservation)}
                    </div>
                    <div style={fieldViewSearchTableStyles.reservationIdText}>
                      {reservation.id}
                    </div>
                  </TableCell>
                  <TableCell style={fieldViewSearchTableStyles.cellContent}>
                    {reservation.customerName || "--"}
                  </TableCell>
                  <TableCell style={fieldViewSearchTableStyles.specsORA}>
                    <div className={fieldViewSearchTableStyles.specsWrapperORA}>
                      <div className={styles.nameplate}>
                        {reservation.nameplate}
                      </div>
                      <span className={styles.specs}>
                        {reservation.specSummary &&
                          reservation.specSummary.filter(Boolean).join(" / ")}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell style={fieldViewSearchTableStyles.cellContent}>
                    <div style={fieldViewSearchTableStyles.dealerNameText}>
                      {reservation.dealerName || "--"}
                    </div>
                  </TableCell>
                  <TableCell style={fieldViewSearchTableStyles.codeContent}>
                    {this.props.dealerData && this.props.dealerData.salesCode
                      ? this.props.dealerData.salesCode
                      : "--"}
                  </TableCell>
                  <TableCell style={fieldViewSearchTableStyles.codeContent}>
                    {this.props.dealerData && this.props.dealerData.paCode
                      ? this.props.dealerData.paCode
                      : "--"}
                  </TableCell>
                  {IS_CANADA_MARKET(this.props.user.market) && (
                    <TableCell style={fieldViewSearchTableStyles.cellContent}>
                      {this.getBrandName(this.props.dealerData)}
                    </TableCell>
                  )}
                  <TableCell style={fieldViewSearchTableStyles.cellContent}>
                    <div className={fieldViewSearchTableStyles.addressText}>
                      {this.props.dealerData &&
                        this.props.dealerData.address && (
                          <>
                            <span>
                              {this.props.dealerData.address.street},{" "}
                            </span>
                            <span>
                              {this.props.dealerData.address.city},{" "}
                              {this.props.dealerData.address.state ||
                                this.props.dealerData.address.province}
                              ,{" "}
                              {IS_USA_MARKET(this.props.user.market)
                                ? this.formatPostalCode(
                                    this.props.dealerData.address.postalCode
                                  )
                                : this.props.dealerData.address.postalCode}
                              , {this.props.dealerData.address.country}
                            </span>
                          </>
                        )}
                    </div>
                  </TableCell>
                  <TableCell style={fieldViewSearchTableStyles.view}>
                    <EMPButton
                      testId="fieldViewOrderLink"
                      variant="text"
                      rightIcon={<MotionChevronRight />}
                      id={reservation.id}
                      onClick={() => {
                        this.props.history.push(
                          `customer-handling/${reservation.id}`
                        );
                        this.onSubmit(this.props, this.props.dealerData);
                      }}
                    >
                      {this.props.t("fieldView.viewOrder")}
                    </EMPButton>
                    <EMPButton
                      testId="fieldViewDealerLink"
                      variant="text"
                      rightIcon={<MotionChevronRight />}
                      onClick={() => {
                        this.props.history.push("/dashboard");
                        this.onSubmit(this.props, this.props.dealerData);
                      }}
                    >
                      {this.props.t("fieldView.viewDealer")}
                    </EMPButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default withTranslation("emp")(FieldViewOrderIdSearchResultsTable);
