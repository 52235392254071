import React from "react";
import { Chip } from "@mui/material";

const EMPChip = (props) => {
  const { label, testId, labelColor, variant } = props;
  return (
    <Chip
      data-testid={testId}
      size="small"
      label={label}
      clickable={false}
      color={labelColor}
      variant={variant || "filled"}
    />
  );
};

export default EMPChip;
