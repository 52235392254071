import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { useLocation, Link } from "react-router-dom";

const capitalizeFirstLetter = (string) => {
  const stringWithoutHyphens = string.replace(/-/g, " ");
  return (
    stringWithoutHyphens.charAt(0).toUpperCase() + stringWithoutHyphens.slice(1)
  );
};

const EMPBreadcrumbs = () => {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((path) => path);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link to="/dashboard">Dashboard</Link>
      {pathNames.map((link, index) => {
        const isLastItem = index === pathNames.length - 1;
        const href = `/${pathNames.slice(0, index + 1).join("/")}`;
        const displayText = /\d/.test(link)
          ? link
          : capitalizeFirstLetter(link);
        return isLastItem ? (
          <Typography variant="body2" key={displayText}>
            {displayText}
          </Typography>
        ) : (
          <Link to={href} key={displayText}>
            {displayText}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default EMPBreadcrumbs;
