import React from "react";
import "../../../../styles/sharedStyles.scss";
import { Grid, Button } from "@mui/material";
import fordStyles from "./DecisionDialog.module.scss";
import lincolnStyles from "./DecisionDialogLincoln.module.scss";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import commonStyles from "../../../../styles/common/CommonStyles.module.scss";
import { withTranslation } from "react-i18next";
import {
  ACCEPT_AND_KEEP,
  ACCEPT_AND_SUBMIT,
  DECISION,
  LEASED,
  REJECT,
} from "../../../../common/Constants";
import AcceptAndKeepDialog from "../acceptAndKeep/AcceptAndKeepDialog";
import AcceptAndSubmitDialog from "../acceptAndSubmit/AcceptAndSubmitDialog";
import RejectDialog from "../reject/RejectDialog";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import { logMsg } from "../../../../common/Logger";
import {
  displayError,
  ERROR_PROCESSING,
  isProcessingError,
} from "../../../../errors/ErrorConstants";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

class DecisionDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: [] };
  }

  handleConfirm = (e) => {
    this.props.onConfirm(e);
  };

  handleClose = () => {
    this.props.onClose(DECISION);
  };

  handleSubmit = (requestBody) => {
    this.props.hideOrShow(true);

    CustomerReservationsClient.updateOrder(
      this.props.token,
      this.props.customerReservation.dealerId,
      requestBody,
      this.props.user.userId
    )
      .then(() => {
        this.props.refreshReservationDetailsWithNotifications();
      })
      .catch((error) => {
        if (isProcessingError(error)) {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
        logMsg("the error is: ", error);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  render() {
    const styles = !IS_FORD_BRAND(this.props.user.brand)
      ? lincolnStyles
      : fordStyles;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    return (
      <>
        {this.props.open ? (
          <EMPDialog
            open={this.props.open}
            handleClose={this.handleClose}
            dialogTitle={this.props.t(
              "TradeIn.US.dialogs.decisionDialog.title"
            )}
            hasCloseIcon
          >
            <Grid
              container
              data-testid="decisionDialog"
              justifyContent="center"
            >
              {this.props.tradeInType.toUpperCase() !== LEASED ? (
                <Grid item xs={12}>
                  <div className={styles.body}>
                    <div>
                      {this.props.t(
                        "TradeIn.US.dialogs.decisionDialog.body.title"
                      )}
                    </div>
                    <ul>
                      <li>
                        {this.props.t(
                          "TradeIn.US.dialogs.decisionDialog.body.acceptAndKeepVehicle"
                        )}
                      </li>
                      <li>
                        {this.props.t(
                          "TradeIn.US.dialogs.decisionDialog.body.acceptAndSendToAuction"
                        )}
                      </li>
                      <li>
                        {this.props.t(
                          "TradeIn.US.dialogs.decisionDialog.body.rejectTradeIn"
                        )}
                      </li>
                    </ul>
                    <div
                      className={
                        !IS_FORD_BRAND(this.props.user.brand)
                          ? styles.dealerDecision
                          : null
                      }
                    >
                      {this.props.t(
                        "TradeIn.US.dialogs.decisionDialog.body.decision"
                      )}
                    </div>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <div className={styles.body}>
                    <div>
                      {this.props.t(
                        "TradeIn.US.dialogs.decisionDialog.body.titleLease"
                      )}
                    </div>
                    <ul>
                      <li>
                        {this.props.t(
                          "TradeIn.US.dialogs.decisionDialog.body.acceptAndKeepVehicleLease"
                        )}
                      </li>
                      <li>
                        {this.props.t(
                          "TradeIn.US.dialogs.decisionDialog.body.rejectTradeInLease"
                        )}
                      </li>
                    </ul>
                    <div
                      className={
                        !IS_FORD_BRAND(this.props.user.brand)
                          ? styles.dealerDecision
                          : null
                      }
                    >
                      {this.props.t(
                        "TradeIn.US.dialogs.decisionDialog.body.decision"
                      )}
                    </div>
                  </div>
                </Grid>
              )}

              <Grid item xs={12}>
                <div className={styles.buttonContainer}>
                  <div className={styles.buttonWrapper}>
                    <Button
                      className={
                        !IS_FORD_BRAND(this.props.user.brand)
                          ? [
                              commonStyles.actionBtnLincolnBlue,
                              styles.lincolnButtonSpacing,
                            ]
                          : commonStyles.actionBtn
                      }
                      data-testid="acceptAndKeepVehicle"
                      onClick={() =>
                        this.props.onDialogChange(ACCEPT_AND_KEEP, DECISION)
                      }
                    >
                      {this.props.tradeInType.toUpperCase() !== LEASED ? (
                        <span>
                          {!IS_FORD_BRAND(this.props.user.brand)
                            ? this.props.t(
                                "TradeIn.US.dialogs.decisionDialog.acceptAndKeepVehicleLincoln"
                              )
                            : this.props.t(
                                "TradeIn.US.dialogs.decisionDialog.acceptAndKeepVehicle"
                              )}
                        </span>
                      ) : (
                        <span>
                          {!IS_FORD_BRAND(this.props.user.brand)
                            ? this.props.t(
                                "TradeIn.US.dialogs.decisionDialog.acceptAndKeepVehicleLeaseLincoln"
                              )
                            : this.props.t(
                                "TradeIn.US.dialogs.decisionDialog.acceptAndKeepVehicleLease"
                              )}
                        </span>
                      )}
                    </Button>
                  </div>
                  {this.props.tradeInType.toUpperCase() !== LEASED && (
                    <div className={styles.buttonWrapper}>
                      <Button
                        className={
                          !IS_FORD_BRAND(this.props.user.brand)
                            ? [
                                commonStyles.actionBtnLincolnWhite,
                                styles.lincolnButtonSpacing,
                              ]
                            : commonStyles.actionBtn
                        }
                        data-testid="acceptAndSendToAuction"
                        onClick={() =>
                          this.props.onDialogChange(ACCEPT_AND_SUBMIT, DECISION)
                        }
                      >
                        <span>
                          {!IS_FORD_BRAND(this.props.user.brand)
                            ? this.props.t(
                                "TradeIn.US.dialogs.decisionDialog.acceptAndSendToAuctionLincoln"
                              )
                            : this.props.t(
                                "TradeIn.US.dialogs.decisionDialog.acceptAndSendToAuction"
                              )}
                        </span>
                      </Button>
                    </div>
                  )}
                  <div className={styles.buttonWrapper}>
                    {IS_FORD_BRAND(this.props.user.brand) ? (
                      <Button
                        className={`${commonStyles.closeBtn} ${styles.lincolnButtonSpacing}`}
                        data-testid="rejectTradeIn"
                        onClick={() =>
                          this.props.onDialogChange(REJECT, DECISION)
                        }
                      >
                        <span>
                          {this.props.t(
                            "TradeIn.US.dialogs.decisionDialog.rejectTradeIn"
                          )}
                        </span>
                      </Button>
                    ) : (
                      <div
                        className={commonStyles.closeLinkLincoln}
                        data-testid="rejectTradeInLincoln"
                        onClick={() =>
                          this.props.onDialogChange(REJECT, DECISION)
                        }
                      >
                        <span>
                          {this.props.t(
                            "TradeIn.US.dialogs.decisionDialog.rejectTradeInLincoln"
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </EMPDialog>
        ) : (
          ""
        )}

        <AcceptAndKeepDialog
          {...this.props}
          open={this.props.dialogBoxes[ACCEPT_AND_KEEP]}
          onClose={this.props.onClose}
          onDialogChange={this.props.onDialogChange}
          onSubmit={this.handleSubmit}
        />

        <AcceptAndSubmitDialog
          {...this.props}
          open={this.props.dialogBoxes[ACCEPT_AND_SUBMIT]}
          onClose={this.props.onClose}
          onDialogChange={this.props.onDialogChange}
          onSubmit={this.handleSubmit}
        />

        <RejectDialog
          {...this.props}
          open={this.props.dialogBoxes[REJECT]}
          onClose={this.props.onClose}
          onDialogChange={this.props.onDialogChange}
          onSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

export default withTranslation("emp")(DecisionDialog);
