import { useQueryClient } from "@tanstack/react-query";
import { usePricingStack } from "./UsePricingStack";
import { FINANCE_PRODUCT_TYPE } from "../../common/Constants";

export async function usePaymentTypeDetails(orderId) {
  try {
    const queryClient = useQueryClient();
    const { customerReservation } = queryClient.getQueryData([
      "order",
      { orderId },
    ]);

    const {
      spotBuy,
      financeType,
      financeDataQuote,
      paymentType,
      financeProvider,
      purchaseDeposit,
      pricingStack: reservationPricingStack,
    } = customerReservation;

    if (spotBuy) {
      return { spotBuy };
    }

    const { data: pricingStackData } = await usePricingStack(orderId);
    const {
      totalPriceWithAddOns,
      monthlyUseTax,
      residualData,
      capitalizedCostReduction,
      availableIncentivesAndNonCashCredits,
      totalAmountDueAtSigning,
    } = pricingStackData;

    const {
      deposit: downPayment,
      apr,
      monthlyPayment,
      term,
      totalAmountFinancedStandard,
      estimatedBasePayment,
      baseMonthlyPayment,
      annualMileage,
      totalAmountDueAtSigning: dataQuoteTotalAmountDueAtSigning,
    } = financeDataQuote;

    const { tradeInPrice, cashDueAtSigning } = reservationPricingStack;

    let purchaseDepositAmountInt;

    if (purchaseDeposit) {
      ({ depositAmountInt: purchaseDepositAmountInt } = purchaseDeposit);
    }

    if (
      paymentType.toLowerCase() === FINANCE_PRODUCT_TYPE.FINANCE &&
      financeProvider
    ) {
      return {
        financeType,
        totalAmountFinancedStandard,
        downPayment,
        apr,
        monthlyPayment,
        term,
      };
    }

    if (paymentType === FINANCE_PRODUCT_TYPE.THIRD_PARTY) {
      return {
        financeType,
      };
    }

    if (
      paymentType.toLowerCase() === FINANCE_PRODUCT_TYPE.THIRD_PARTY_OPTIONS &&
      financeProvider
    ) {
      return {
        financeType,
        totalPriceWithAddOns,
        downPayment,
        apr,
        monthlyPayment,
        term,
        totalAmountFinancedStandard,
      };
    }

    if (paymentType.toLowerCase() === FINANCE_PRODUCT_TYPE.CASH) {
      const result = {};

      if (financeType) {
        result.financeType = financeType;
      }

      if (totalPriceWithAddOns) {
        result.totalPriceWithAddOns = totalPriceWithAddOns;
      }

      if (purchaseDepositAmountInt) {
        result.purchaseDepositAmountInt = purchaseDepositAmountInt;
      }

      return result;
    }

    if (paymentType.toLowerCase() === FINANCE_PRODUCT_TYPE.OTHER) {
      return {
        financeType,
        totalPriceWithAddOns,
        downPayment,
        apr,
        monthlyPayment,
        term,
        totalAmountFinancedStandard,
      };
    }

    if (paymentType.toLowerCase() === FINANCE_PRODUCT_TYPE.LEASE) {
      return {
        baseMonthlyPayment,
        estimatedBasePayment,
        financeType,
        monthlyUseTax,
        annualMileage,
        residualData,
        capitalizedCostReduction,
        downPayment,
        monthlyPayment,
        term,
        availableIncentivesAndNonCashCredits,
        tradeInPrice,
        cashDueAtSigning,
        totalAmountDueAtSigning,
        dataQuoteTotalAmountDueAtSigning,
      };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching payment type details:", error);
    throw error;
  }
}
