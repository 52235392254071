import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DialogActions, Grid, Typography, Box } from "@mui/material";
import { ModalContext } from "../ModalContext";
import { UserContext } from "../../../context/userContext";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import EMPButton from "../../EMPButton/EMPButton";
import EMPCheckbox from "../../EMPCheckbox/EMPCheckbox";
import EMPLoader from "../../EMPLoader/EMPLoader";
import { DEALER_ACTIONS } from "../../../../common/Constants";
import { useFieldSupportStatus } from "../../../hooks/useFieldSupportStatus";
import { usePermission } from "../../../hooks/usePermission";
import {
  trackAcceptPurchaseDepositPopupAction,
  trackAcceptPurchaseDepositPopupLoad,
} from "../../../services/analytics/OrderDetailsPageAnalytics";

const AcceptPurchaseDeposit = ({ orderId, backButton, handleBack }) => {
  const { handleModal } = useContext(ModalContext);
  const user = useContext(UserContext);
  const { market, dealerId, salesCode, dealerBrand, userId } = user;
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const queryClient = useQueryClient();
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const { t } = useTranslation();
  const isFieldSupport = useFieldSupportStatus();
  const canAcceptPurchaseDeposit = usePermission("acceptDepositNA:write");

  useEffect(() => {
    if (isFieldSupport) {
      return;
    } else {
      setDisabled(!checked);
    }
    trackAcceptPurchaseDepositPopupLoad(orderId, user);
  }, []);

  useEffect(() => {
    setDisabled(!checked);
  }, [checked]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef.current]);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleErrorMessage = () => {
    let errorMessage = "An error occurred. Please try again.";
    if (submitAccept.error.response && submitAccept.error.response.data) {
      try {
        const errorData = submitAccept.error.response.data.message;
        errorMessage =
          typeof errorData === "string" ? JSON.parse(errorData) : errorData;
        return errorMessage.errors[0].message;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Failed to parse error message:", e);
      }
    }
  };

  const submitAccept = useMutation({
    mutationFn: (actionData) =>
      CustomerReservationsClient.actionOnOrder(
        sessionStorage.getItem("adfs.token"),
        userCommonId,
        actionData,
        userId
      ),
    onSuccess: () => {
      handleModal(false, false);
      trackAcceptPurchaseDepositPopupAction("confirm", orderId, user);
      queryClient.invalidateQueries({ queryKey: ["order", { orderId }] });
      queryClient.invalidateQueries({
        queryKey: ["notifications", { orderId }],
      });
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        // eslint-disable-next-line no-console
        console.log(`400 Error: ${errorMessage}`);
      } else {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  });

  return (
    <div>
      {submitAccept.isLoading ? (
        <Box sx={{ minHeight: contentHeight, py: 10 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Grid item>
              <EMPLoader loadingMessage="Submitting..." />
            </Grid>
          </Grid>
        </Box>
      ) : submitAccept.isError ? (
        <Box sx={{ minHeight: contentHeight, pb: 4 }}>
          <Typography variant="body2" sx={{ lineBreak: "anywhere" }}>
            {handleErrorMessage()}
          </Typography>
        </Box>
      ) : (
        <Box ref={contentRef}>
          <Typography variant="body1" sx={{ mb: 6 }}>
            {t("financing.acceptDepositModal.message")}
          </Typography>

          <Box sx={{ mb: 10 }}>
            <ul style={{ paddingLeft: "12px" }}>
              <li>
                <Typography variant="body2">
                  I accept the deposit from the customer
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Dealership merchant fees may apply to this transaction
                </Typography>
              </li>
            </ul>
          </Box>

          <Box sx={{ mb: 4 }}>
            <EMPCheckbox
              label={t("financing.acceptDepositModal.checkboxConfirm")}
              checked={checked}
              handleChange={() => handleChange()}
            />
          </Box>
        </Box>
      )}

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton
              variant="text"
              onClick={() => {
                backButton ? handleBack() : handleModal(false, false);
                trackAcceptPurchaseDepositPopupAction("close", orderId, user);
              }}
              disabled={submitAccept.isLoading}
            >
              {backButton ? "Back" : t("common.cancel")}
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() =>
                submitAccept.mutate({
                  reservationId: orderId,
                  dealerAction: DEALER_ACTIONS.PURCHASE_DEPOSIT_ACCEPTED,
                })
              }
              disabled={
                disabled ||
                submitAccept.isLoading ||
                submitAccept.isError ||
                !canAcceptPurchaseDeposit
              }
            >
              {t("common.buttonCtas.acceptPurchaseDeposit")}
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

export default AcceptPurchaseDeposit;
