import { useContext } from "react";
import { UserContext } from "../context/userContext";
import { useQuery } from "@tanstack/react-query";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";

function getMenuItems({ cancellationReasons }) {
  const menuItems = cancellationReasons.map((item) => ({
    text: item.displayText,
    value: item.displayText,
  }));

  return [
    {
      text: "Select one",
      value: "",
      disabled: true,
    },
    ...menuItems,
  ];
}

const useCancellationReasons = (cancelType) => {
  const { market, dealerId, salesCode, dealerBrand } = useContext(UserContext);
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;

  return useQuery({
    queryKey: ["cancelReasons", cancelType],
    staleTime: 1000 * 60,
    queryFn: async () => {
      const { data } = await CustomerReservationsClient.getCancelOrderReasons(
        sessionStorage.getItem("adfs.token"),
        "en",
        cancelType,
        userCommonId
      );

      return data;
    },
    select: getMenuItems,
  });
};

export default useCancellationReasons;
