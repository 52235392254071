import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@mui/material";
import MotionPlus from "../../Icons/MotionPlus";
import MotionMinus from "../../Icons/MotionMinus";

const EMPAccordion = ({
  children,
  heading,
  subHeading,
  onChange,
  expanded,
}) => {
  return (
    <Accordion square elevation={0} onChange={onChange} expanded={expanded}>
      <AccordionSummary
        expandIcon={expanded ? <MotionMinus /> : <MotionPlus />}
        sx={{ py: 6, px: 4 }}
      >
        <Box>
          <Typography variant="h4" color="inherit">
            {heading}
          </Typography>
          <Typography variant="body2">{subHeading}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default EMPAccordion;
