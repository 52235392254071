import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DialogActions, Grid, Typography, Box } from "@mui/material";
import { ModalContext } from "../ModalContext";
import { UserContext } from "../../../context/userContext";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import EMPButton from "../../EMPButton/EMPButton";
import EMPCheckbox from "../../EMPCheckbox/EMPCheckbox";
import EMPLoader from "../../EMPLoader/EMPLoader";
import { DEALER_ACTIONS } from "../../../../common/Constants";
import { useFieldSupportStatus } from "../../../hooks/useFieldSupportStatus";

const RejectPurchaseDeposit = ({ orderId, backButton, handleBack }) => {
  const { handleModal } = useContext(ModalContext);
  const { market, dealerId, salesCode, dealerBrand, userId } =
    useContext(UserContext);
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const queryClient = useQueryClient();
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const { t } = useTranslation();
  const isFieldSupport = useFieldSupportStatus();

  useEffect(() => {
    if (isFieldSupport) {
      return;
    } else {
      setDisabled(!checked);
    }
  }, [checked]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef.current]);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleErrorMessage = () => {
    let errorMessage = "An error occurred. Please try again.";
    if (submitReject.error.response && submitReject.error.response.data) {
      try {
        const errorData = submitReject.error.response.data.message;
        errorMessage =
          typeof errorData === "string" ? JSON.parse(errorData) : errorData;
        return errorMessage.errors[0].message;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Failed to parse error message:", e);
      }
    }
  };

  const submitReject = useMutation({
    mutationFn: (actionData) =>
      CustomerReservationsClient.actionOnOrder(
        sessionStorage.getItem("adfs.token"),
        userCommonId,
        actionData,
        userId
      ),
    onSuccess: () => {
      handleModal(false, false);
      queryClient.invalidateQueries({ queryKey: ["order", { orderId }] });
      queryClient.invalidateQueries({
        queryKey: ["notifications", { orderId }],
      });
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        // eslint-disable-next-line no-console
        console.log(`400 Error: ${errorMessage}`);
      } else {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  });

  return (
    <div>
      {submitReject.isLoading ? (
        <Box sx={{ minHeight: contentHeight, py: 10 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Grid item>
              <EMPLoader loadingMessage="Submitting..." />
            </Grid>
          </Grid>
        </Box>
      ) : submitReject.isError ? (
        <Box sx={{ minHeight: contentHeight, pb: 4 }}>
          <Typography variant="body2" sx={{ lineBreak: "anywhere" }}>
            {handleErrorMessage()}
          </Typography>
        </Box>
      ) : (
        <Box ref={contentRef}>
          <Typography variant="body1" sx={{ mb: 6 }}>
            Please provide a reason and confirm that youd like to decline this
            purchase deposit.
          </Typography>
          <Box sx={{ mb: 10 }}>
            <ul style={{ paddingLeft: "12px" }}>
              <li>
                <Typography variant="body2">
                  I have contacted the customer and they have approved the
                  reject
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Cancelling this deposit can NOT be undone
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Dealership merchant fees may apply to this transaction
                </Typography>
              </li>
            </ul>
          </Box>

          <Box sx={{ mb: 4 }}>
            <EMPCheckbox
              label="I understand that by rejecting this purchase deposit I agree to the terms above"
              checked={checked}
              handleChange={() => handleChange()}
            />
          </Box>
        </Box>
      )}

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton
              variant="text"
              onClick={() => {
                backButton ? handleBack() : handleModal(false, false);
              }}
              disabled={submitReject.isLoading}
            >
              {backButton ? "Back" : t("common.cancel")}
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() =>
                submitReject.mutate({
                  reservationId: orderId,
                  dealerAction: DEALER_ACTIONS.PURCHASE_DEPOSIT_REJECTED,
                })
              }
              disabled={
                disabled || submitReject.isLoading || submitReject.isError
              }
            >
              {t("common.buttonCtas.rejectPurchaseDeposit")}
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

export default RejectPurchaseDeposit;
