import { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/userContext";

export function useFieldSupportStatus() {
  const [isFieldSupport, setIsFieldSupport] = useState(false);
  const { userType } = useContext(UserContext);

  useEffect(() => {
    setIsFieldSupport(userType === "field_view");

    return () => setIsFieldSupport(false);
  });

  return isFieldSupport;
}
