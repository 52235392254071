import React, { useEffect, useState, createRef, useContext } from "react";
import EMPStatusCard from "../../../shared/components/EMPStatusCard/EMPStatusCard";
import { Box, Grid, Typography } from "@mui/material";
import EMPDialog from "../../../shared/components/EMPDialog/EMPDialog";
import EMPTypeAheadField from "../../../shared/components/EMPTypeAheadField/EMPTypeAheadField";
import DeliveryClient from "../../../clients/DeliveryClient";
import { withTranslation } from "react-i18next";
import UiDateUtils from "../../../utils/UiDateUtils";
import getDeliveryStatus from "../../../utils/DeliveryStatusUtils/GetDeliveryStatus";
import EMPDatePicker from "../../../shared/components/EMPDatePicker/EMPDatePicker";
import UpperCaseText from "../../../utils/UpperCaseText";
import { GET_SCHEDULER_URL, ENV } from "../../../utils/EmpUtil";
import Tooltip from "@mui/material/Tooltip";
import EMPInputField from "../../../shared/components/EMPInputField/EMPInputField";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import { MODELE_DEALER_INTERACTIONS } from "../../../common/Constants";
import EMPEmptyState from "../../../shared/components/EMPEmptyState/EMPEmptyState";
import DealerDeliveryToolClient from "../../../clients/DealerDeliveryToolClient";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Skeleton from "@mui/material/Skeleton";
import { UserContext } from "../../../shared/context/userContext";
import {
  trackDeliveryPopupAction,
  trackDeliveryPopupLoad,
} from "../../../shared/services/analytics/OrderDetailsPageAnalytics";

// Available Props:
// customerInfo: object
// vehicleInfo: object
// user: object
// transportError: Func

const NoDeliveryCard = (props) => {
  const { url, t, isCanceled, title } = props;
  const [value] = useState(url);
  const [copied, setCopied] = useState(false);

  const buttonRef = createRef();

  const copyAndReset = (result) => {
    navigator.clipboard.writeText(result);
    setCopied(result);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <EMPStatusCard cardTitle={title} status="default">
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="body1">
              {isCanceled
                ? t("ModelEOrders.deliveryCancelledExplanation")
                : t("ModelEOrders.deliveryNotScheduledExplanation")}
            </Typography>
          </Box>
        </Grid>

        {!isCanceled && (
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={12} sm={8}>
                <Box mr={2}>
                  <EMPInputField
                    value={value}
                    readOnly
                    testId="scheduler-url"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm>
                <Tooltip
                  slotProps={{
                    popper: {
                      disablePortal: true,
                    },
                  }}
                  open={copied}
                  disableFocusListener
                  disableHoverListener
                  title={t("ModelEOrders.copied")}
                  arrow
                  placement="top"
                  data-testid="copy-tooltip"
                >
                  <EMPButton
                    variant="outlined"
                    testId="copy-button"
                    onClick={() => copyAndReset(value)}
                    ref={buttonRef}
                  >
                    {t("ModelEOrders.copyToClipboard")}
                  </EMPButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </EMPStatusCard>
  );
};

const DeliveryDialog = (props) => {
  const {
    open,
    status,
    handleClose,
    customerInfo,
    vehicleInfo,
    t,
    appointment,
    specialist,
    onConfirm,
    actionLoading,
    user,
  } = props;

  const [formData, setFormData] = useState({
    newDate: appointment.date,
    specialist: {
      dealershipEmployeeName: appointment["dealershipEmployeeName"],
      dealershipEmployee: appointment["dealershipEmployee"],
    },
  });

  const [submissionDisabled, setSubmissionDisabled] = useState(true);

  const dealerId = sessionStorage.getItem("dealerId");
  const token = sessionStorage.getItem("adfs.token");

  const getEmployees = async () =>
    await DeliveryClient.getEmployees(token, dealerId);

  const validateAndSetFormData = (data) => {
    if (data !== null) {
      setFormData({ ...formData, ...data });
    }
  };

  const setModalHeader = (status) =>
    status === "requested"
      ? t("ModelEOrders.confirmAppointment")
      : t("ModelEOrders.deliveryDetails");

  const submitFormData = (token, dealerId, appointment, formData) => {
    setSubmissionDisabled(true);
    onConfirm(token, dealerId, appointment, formData);
  };

  const currentButtonText =
    status === "requested"
      ? t("ModelEOrders.confirmAppointment")
      : t("ModelEOrders.saveChanges");

  useEffect(() => {
    const checkForNull = Object.values(formData).some(
      (value) => value === null || value === ""
    );

    return setSubmissionDisabled(checkForNull);
  }, [formData]);

  return (
    <EMPDialog
      open={open}
      hasCloseIcon
      handleClose={handleClose}
      onEnter={() => {
        trackDeliveryPopupLoad(
          setModalHeader(status),
          appointment.orderId,
          user
        );
        setSubmissionDisabled(true);
      }}
      onExit={() =>
        setFormData({
          newDate: appointment.date,
          specialist: {
            dealershipEmployeeName: appointment["dealershipEmployeeName"],
            dealershipEmployee: appointment["dealershipEmployee"],
          },
        })
      }
      dialogTitle={setModalHeader(status)}
      hasActionButton
      actionLoading={actionLoading}
      actionButtonText={currentButtonText}
      onConfirm={() => {
        trackDeliveryPopupAction(
          setModalHeader(status).toLowerCase(),
          appointment.orderId,
          currentButtonText.toLowerCase(),
          user
        );
        submitFormData(token, dealerId, appointment, formData);
      }}
      disableActionButton={submissionDisabled}
      hasSecondaryActionButton
      secondaryActionButtonText={t("ModelEOrders.cancel")}
      onSecondaryAction={handleClose}
    >
      <Box mb={4}>
        <Typography variant="caption">{t("ModelEOrders.customer")}</Typography>
        <Typography variant="body2">{`${customerInfo?.firstName} ${customerInfo?.lastName}`}</Typography>
        <Typography variant="body2">{`${vehicleInfo.modelYear} ${vehicleInfo.namePlate}`}</Typography>
        <Typography variant="body2">{`VIN: ${vehicleInfo.vin}`}</Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="caption">
          {status === "requested"
            ? t("ModelEOrders.requestedDateAndTime")
            : t("ModelEOrders.dateAndTime")}
        </Typography>

        <Typography variant="body2" sx={{ mb: 4 }}>
          {t("ModelEOrders.dateTimeHelperText")}
        </Typography>

        <EMPDatePicker
          incomingDate={new Date(formData.newDate)}
          startAtToday
          transportFormData={(newDate) => validateAndSetFormData({ newDate })}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="caption">
          {t("ModelEOrders.deliverySpecialist")}
        </Typography>
        {specialist ? (
          <Box mb={2}>
            <Typography variant="subtitle2" sx={{ mb: 4 }}>
              Assigned to {specialist}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              {t("ModelEOrders.deliveryAssigned2")}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2">
            {t("ModelEOrders.deliveryNotAssigned")}
          </Typography>
        )}

        <EMPTypeAheadField
          fieldName="employees"
          fieldLabel="Search for an employee"
          noOptionsText="No results found"
          onFetch={getEmployees}
          dataKey="dealerEmployees"
          transportFormData={(specialist) =>
            validateAndSetFormData({ specialist })
          }
          disabledItem={specialist}
        ></EMPTypeAheadField>
      </Box>
    </EMPDialog>
  );
};

const Delivery = (props) => {
  const {
    orderId,
    customerInfo,
    vehicleInfo,
    t,
    setCustomerFollowupNotification,
    transportError,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDeliverySpecialist, setSelectedDeliverySpecialist] =
    useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const user = useContext(UserContext);

  const queryClient = useQueryClient();

  const {
    isFetching,
    data: deliveryModule,
    isError,
  } = useQuery({
    queryKey: ["deliveryModule", { orderId }],
    retry: 1,
    staleTime: 60 * 1000,
    queryFn: () =>
      DealerDeliveryToolClient.getDeliveryModuleInfo(
        sessionStorage.getItem("adfs.token"),
        sessionStorage.getItem("dealerId"),
        orderId
      ),
    onSuccess: (deliveryModule) => {
      setDeliveryStatus(() =>
        getDeliveryStatus(deliveryModule?.deliveryAppointment)
      );
      setSelectedDeliverySpecialist(
        deliveryModule?.deliveryAppointment?.dealershipEmployeeName
          ? deliveryModule.deliveryAppointment.dealershipEmployeeName
          : null
      );
      setCustomerFollowupNotification(
        deliveryModule?.showCustomerFollowupNotification
      );
    },
  });

  useEffect(() => {
    if (deliveryModule) {
      setDeliveryStatus(() =>
        getDeliveryStatus(deliveryModule.deliveryAppointment)
      );
      setSelectedDeliverySpecialist(
        deliveryModule?.deliveryAppointment?.dealershipEmployeeName
          ? deliveryModule.deliveryAppointment.dealershipEmployeeName
          : null
      );
      setCustomerFollowupNotification(
        deliveryModule?.showCustomerFollowupNotification
      );
    }
  }, [deliveryModule]);

  const generateSchedulerUrl = (orderId) => {
    const url = GET_SCHEDULER_URL(ENV);
    return `${url}?orderId=${orderId}`;
  };

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => {
    setLoading(false);
    setDialogOpen(false);
  };

  const submitFormData = async (token, dealerId, appointment, formData) => {
    setLoading(true);
    try {
      await DeliveryClient.updateAppointment(
        token,
        dealerId,
        appointment,
        formData
      );

      queryClient.invalidateQueries({
        queryKey: ["deliveryModule", { orderId }],
      });
      handleDialogClose();
    } catch (err) {
      transportError(err);
    }
  };

  const isCanceled =
    deliveryStatus &&
    deliveryStatus.status &&
    UpperCaseText(deliveryStatus.status) === "CANCELED";

  if (isFetching) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={243}
        data-testid="delivery-skeleton"
      />
    );
  }

  if (isError) {
    return (
      <>
        <Typography variant="h3" sx={{ mb: 7 }}>
          Delivery
        </Typography>
        <Typography variant="h5" component="p" data-testid="delivery-error">
          {t("common.errorMessage2", {
            element: "Delivery information",
          })}
        </Typography>
      </>
    );
  }

  return deliveryModule.deliveryAppointment === null ? (
    <NoDeliveryCard
      t={t}
      isCanceled={isCanceled}
      url={generateSchedulerUrl(orderId)}
      title={deliveryStatus && deliveryStatus.message}
    />
  ) : (
    deliveryStatus && (
      <>
        <EMPStatusCard
          cardTitle={deliveryStatus.message}
          status={deliveryStatus.statusColor}
          icon={deliveryStatus.icon}
          hasActionButton={deliveryStatus.status !== "completed"}
          actionButtonText={deliveryStatus.actionText}
          onActionClick={handleDialogOpen}
          actionButtonPermission={["modelEDeliveryAction"]}
          user={user}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} md={5}>
              <Typography variant="caption">
                {t("ModelEOrders.deliveryAppointmentTime")}
              </Typography>
              <Typography variant="body2">
                {UiDateUtils.formatToDayDateTime(
                  deliveryModule.deliveryAppointment.date
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={3}>
              <Typography variant="caption">
                {t("ModelEOrders.deliverySpecialist")}
              </Typography>
              <Typography variant="body2">
                {selectedDeliverySpecialist
                  ? selectedDeliverySpecialist
                  : t("ModelEOrders.notAssigned")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <Typography variant="caption">
                {t("ModelEOrders.appointmentType")}
              </Typography>
              <Typography variant="body2">
                {deliveryModule.deliveryAppointment.dealerInteraction ? (
                  MODELE_DEALER_INTERACTIONS[
                    deliveryModule.deliveryAppointment.dealerInteraction
                  ]
                ) : (
                  <EMPEmptyState />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="caption">
                {t("ModelEOrders.topicsOfInterest")}
              </Typography>
              {deliveryModule.deliveryAppointment.orientationTopics.length ? (
                <Typography variant="body2">
                  {deliveryModule.deliveryAppointment.orientationTopics.join(
                    ", "
                  )}
                </Typography>
              ) : (
                <EMPEmptyState />
              )}
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="caption">
                {t("ModelEOrders.deliveryNotes")}
              </Typography>
              {deliveryModule.deliveryAppointment.additionalDetails ? (
                <Typography variant="body2">
                  {deliveryModule.deliveryAppointment.additionalDetails}
                </Typography>
              ) : (
                <EMPEmptyState />
              )}
            </Grid>
          </Grid>
        </EMPStatusCard>
        <DeliveryDialog
          open={dialogOpen}
          status={deliveryStatus.status}
          handleClose={handleDialogClose}
          customerInfo={customerInfo}
          vehicleInfo={vehicleInfo}
          appointment={deliveryModule.deliveryAppointment}
          specialist={selectedDeliverySpecialist}
          t={t}
          actionLoading={loading}
          onConfirm={(token, dealerId, appointment, formData) =>
            submitFormData(token, dealerId, appointment, formData)
          }
          user={user}
        />
      </>
    )
  );
};

export default withTranslation("emp")(Delivery);
