import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import EMPObjectCard from "../../../shared/components/EMPDataCard/EMPObjectCard";
import {
  getFordCreditAppStatus,
  getEsignStatus,
  getContractStatus,
  displayAlternativeLending,
  get3pfCreditAppStatus,
} from "../../../shared/rules/FinancingRules";
import { FINANCE_STATUS } from "../../../shared/constants/FinanceConstants";

const CreditApplicationTable = ({ orderId, paymentType }) => {
  const get3pfCreditStatusInfo = () => {
    const status = get3pfCreditAppStatus(orderId);
    switch (status) {
      case FINANCE_STATUS.INITIATED:
        return {
          heading: "Credit application status",
          content: "Submitted",
          highlight: true,
          color: "info",
        };
      case FINANCE_STATUS.APPROVED:
        return {
          heading: "Credit application status",
          content: "Accepted",
          highlight: true,
          color: "success",
        };
      default:
        return {};
    }
  };

  const getCreditAppDetails = (orderId, paymentType) => {
    if (paymentType === "Ford Credit Financing") {
      return {
        creditApp: {
          heading: "Credit application submitted",
          content: "Yes",
        },
        applicationStatus: {
          heading: "Credit application status",
          content: getFordCreditAppStatus(orderId),
        },
        eSign: {
          heading: "eSign Requested",
          content: getEsignStatus(orderId),
        },
        contractStatus: {
          heading: "Contract Signed",
          content: getContractStatus(orderId),
        },
        lendingOptions: {
          heading: "More lending options consent",
          content: displayAlternativeLending(orderId),
        },
      };
    } else if (paymentType === "Third Party Financing") {
      return {
        applicationStatus: get3pfCreditStatusInfo(),
      };
    } else {
      return {};
    }
  };

  const creditAppDetails = getCreditAppDetails(orderId, paymentType);

  return (
    <>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
        sx={{ mt: 2 }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h3" sx={{ color: "text.primary" }}>
              {paymentType === "Ford Credit Financing"
                ? "Ford Credit Application"
                : "Credit Application"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <EMPObjectCard data={creditAppDetails} />
      </Box>
    </>
  );
};

export default CreditApplicationTable;
