const dashboardStyles = {
  header: {
    paddingLeft: "6%",
    paddingRight: "6%",
    boxSizing: "border-box",
  },
  dashboardHeader: {
    height: "25px",
  },
  typeHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: "6%",
    paddingRight: 15,
    width: "23%",
    letterSpacing: "1.88px",
  },
  orderIdHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: 15,
    paddingRight: 15,
    width: "36%",
    letterSpacing: "1.88px",
  },
  dateHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: 15,
    paddingRight: 15,
    width: "15%",
    textAlign: "center",
    letterSpacing: "1.88px",
  },
  actionHeader: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 0,
    paddingBottom: 0,
    width: "12%",
    textAlign: "left",
    letterSpacing: "1.88px",
  },
  emptyHeader: {
    paddingLeft: 15,
    paddingRight: "6%",
    paddingTop: 0,
    paddingBottom: 0,
    width: "9%",
  },
  leftCell: {
    paddingLeft: "3%",
    paddingRight: 15,
  },
  centerCell: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  rightCell: {
    paddingLeft: 15,
    paddingRight: "6%",
  },
  viewIcon: {
    height: "17.5px",
  },
};

export default dashboardStyles;
