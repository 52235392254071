import React, { Component } from "react";
import styles from "./NAOrderDetailsNewVersion.module.scss";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { InfoDetail } from "./InfoDetail";
import { Accordion, AccordionSummary } from "@mui/material";
import DataCard from "../../../shared/components/DataCard/DataCard";

class DealerInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandDealerInfo: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ expandDealerInfo: this.props.expandAccordian });
    }
  }
  expandDealerInfo = () => {
    this.setState((prevState) => ({
      expandDealerInfo: !prevState.expandDealerInfo,
    }));
  };

  render() {
    const { dealerInfo } = this.props;

    return (
      <Accordion
        defaultExpanded={true}
        onChange={() => this.expandDealerInfo()}
        expanded={this.state.expandDealerInfo}
      >
        <AccordionSummary id="exp" expandIcon={<ExpandLessIcon />}>
          {this.props.t("DealerInfo.dealerInformation")}
        </AccordionSummary>

        {dealerInfo ? (
          <div className={styles.customerInfoGrid}>
            <Grid container spacing={2}>
              <Grid item className={styles.cardGridItemWrapper}>
                <DataCard
                  item
                  cardHeader={this.props.t("DealerInfo.dealerDetails")}
                >
                  <InfoDetail
                    header={this.props.t("DealerInfo.dealerName")}
                    value={dealerInfo.name}
                  />
                  <>
                    <div className={styles.itemHeaderText}>
                      {this.props.t("DealerInfo.dealerAddress")}
                    </div>

                    <div
                      className={`${styles.smallHeaderMargin} ${styles.customerInfoContainer}`}
                    >
                      <div className={styles.customerInfoTextBold}>
                        {dealerInfo.addressLine1}
                      </div>
                      <div className={styles.customerInfoTextBold}>
                        {dealerInfo.addressLine2}
                      </div>
                    </div>
                  </>
                  <InfoDetail
                    header={this.props.t("DealerInfo.dealerPhone")}
                    value={dealerInfo.phone}
                  />
                  <InfoDetail
                    header={this.props.t("DealerInfo.dealerPACode")}
                    value={dealerInfo.paCode}
                  />
                  <InfoDetail
                    header={this.props.t("DealerInfo.dealerSalesCode")}
                    value={dealerInfo.salesCode}
                  />
                </DataCard>
              </Grid>
            </Grid>
          </div>
        ) : (
          <span className={styles.labelError}>
            {" "}
            {this.props.t("DealerInfo.errorLoadingDealerInfo")}
          </span>
        )}
      </Accordion>
    );
  }
}

export default withTranslation("emp")(DealerInformation);
