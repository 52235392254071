import React, { useContext, useState, useEffect } from "react";
import {
  DialogActions,
  Grid,
  Typography,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputLabel,
} from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";
import EMPCheckbox from "../../EMPCheckbox/EMPCheckbox";
import { ModalContext } from "../ModalContext";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import { UserContext } from "../../../context/userContext";
import { DEALER_ACTIONS } from "../../../../common/Constants";
import EMPDatePicker from "../../EMPDatePicker/EMPDatePicker";
import { useFieldSupportStatus } from "../../../hooks/useFieldSupportStatus";
import {
  trackRefundPurchaseDepositPopupAction,
  trackRefundPurchaseDepositPopupLoad,
} from "../../../services/analytics/OrderDetailsPageAnalytics";
import { canRefundPurchaseDeposit } from "../../../rules/PurchaseDepositRules";

const RefundPurchaseDeposit = ({ orderId }) => {
  const { handleModal } = useContext(ModalContext);
  const user = useContext(UserContext);
  const { market, dealerId, salesCode, dealerBrand, userId } = user;
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const isFieldSupport = useFieldSupportStatus();

  const initialFormData = {
    refundMethod: null,
    checked1: false,
    offlineRefundDate: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [disabled, setDisabled] = useState(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isFieldSupport) {
      return;
    } else {
      activateButton();
      trackRefundPurchaseDepositPopupLoad(orderId, user);
    }
  }, [formData]);

  const activateButton = () => {
    if (formData.refundMethod === "offline") {
      setDisabled(!(formData.checked1 && formData.offlineRefundDate));
    } else {
      setDisabled(!(formData.refundMethod && formData.checked1));
    }
  };

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const generateRadioLabel = (label, subtitle) => {
    return (
      <Box>
        {label !== "" && <Typography variant="subtitle1">{label}</Typography>}
        {subtitle !== "" && <Typography variant="body1">{subtitle}</Typography>}
      </Box>
    );
  };

  const submitRefund = useMutation({
    mutationFn: (actionData) =>
      CustomerReservationsClient.actionOnOrder(
        sessionStorage.getItem("adfs.token"),
        userCommonId,
        actionData,
        userId
      ),
    onSuccess: () => {
      handleModal(false, false);
      trackRefundPurchaseDepositPopupAction("refund deposit", orderId, user);
      queryClient.invalidateQueries({ queryKey: ["order", { orderId }] });
      queryClient.invalidateQueries({
        queryKey: ["notifications", { orderId }],
      });
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        // eslint-disable-next-line no-console
        console.log(`400 Error: ${errorMessage}`);
      } else {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  });

  if (submitRefund.isLoading) {
    return <div>...Loading</div>;
  }

  if (submitRefund.isError) {
    let errorMessage = "An error occurred. Please try again.";
    if (submitRefund.error.response && submitRefund.error.response.data) {
      try {
        const errorData = submitRefund.error.response.data.message;
        errorMessage =
          typeof errorData === "string" ? JSON.parse(errorData) : errorData;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Failed to parse error message:", e);
      }
    }
    return <div>{errorMessage.errors[0].message}</div>;
  }

  return (
    <div>
      <Typography variant="body1" sx={{ mb: 6 }}>
        Taking this action will refund this customers deposit.
      </Typography>
      <Box sx={{ mb: 4 }}>
        <Box>
          <Typography variant="caption" sx={{ mb: 3 }}>
            Refund Type
          </Typography>
          <RadioGroup
            onChange={(e) => handleChange("refundMethod", e.target.value)}
          >
            <FormControlLabel
              value="online"
              control={<Radio />}
              label={generateRadioLabel(
                "",
                "Online (Refund issued to original form of payment automatically)"
              )}
            />
            <FormControlLabel
              value="offline"
              control={<Radio />}
              label={generateRadioLabel(
                "",
                "Offline (Refund issued by the dealer via check)"
              )}
            />
          </RadioGroup>

          {formData.refundMethod === "offline" && (
            <Box>
              <InputLabel sx={{ mt: 3 }}>Refund date</InputLabel>
              <EMPDatePicker
                transportFormData={(data) => {
                  setFormData({ ...formData, offlineRefundDate: data });
                }}
              />
            </Box>
          )}

          <Box sx={{ my: 8 }}>
            <EMPCheckbox
              label="I confirm that I want to refund this deposit."
              checked={formData.checked1}
              handleChange={(checkedBool) =>
                handleChange("checked1", checkedBool)
              }
            />
          </Box>
        </Box>
      </Box>

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <EMPButton
              variant="text"
              onClick={() => {
                handleModal(false, false);
                trackRefundPurchaseDepositPopupAction("close", orderId, user);
              }}
              disabled={submitRefund.isLoading}
            >
              Cancel
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() => {
                const actionData = {
                  reservationId: orderId,
                  dealerAction: DEALER_ACTIONS.PURCHASE_DEPOSIT_REFUND,
                };
                if (formData.offlineRefundDate) {
                  actionData.acceptedDate = formData.offlineRefundDate;
                }
                submitRefund.mutate(actionData);
              }}
              disabled={
                disabled ||
                submitRefund.isLoading ||
                !canRefundPurchaseDeposit()
              }
            >
              Refund Deposit
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

export default RefundPurchaseDeposit;
