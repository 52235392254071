import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import fordStyles from "./OfflineRefund.module.scss";
import lincolnStyles from "./OfflineRefundLincoln.module.scss";
import Grid from "@mui/material/Grid";
import UiDateUtils from "../../../../utils/UiDateUtils";
import { DEALER_ACTIONS, REFUND_TYPE } from "../../../../common/Constants";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import { logMsg } from "../../../../common/Logger";
import {
  displayError,
  ERROR_PROCESSING,
  isProcessingError,
} from "../../../../errors/ErrorConstants";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import * as AnalyticsService from "../../../../shared/services/analytics/AnalyticsService";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

class OfflineRefundConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: [],
    };
  }

  handleConfirm = () => {
    if (!this.props.isPurchaseOrder) {
      if (this.props.isOrderDeposit) {
        AnalyticsService.trackOnClickDetailEvent(
          "fv:emp:customer handling:orders details:refund order deposit offline:are you sure popup",
          "emp:order details:order id",
          `emp:order details:order id:${this.props.customerReservation.id}`,
          this.props.customerReservation.id,
          "emp:customer handling:orders details:refund order deposit offline:are you sure popup:content:confirm",
          "fv:emp:customer handling:orders details:refund order deposit offline:are you sure popup:content action",
          this.props.user
        );
      }
      if (this.props.isReservationDeposit) {
        AnalyticsService.trackOnClickDetailEvent(
          "fv:emp:customer handling:orders details:refund reservation deposit offline:are you sure popup",
          "emp:order details:order id",
          `emp:order details:order id:${this.props.customerReservation.id}`,
          this.props.customerReservation.id,
          "emp:customer handling:orders details:refund reservation deposit offline:are you sure popup:content:confirm",
          "fv:emp:customer handling:orders details:refund reservation deposit offline:are you sure popup:content action",
          this.props.user
        );
      }
    }

    let refundDealerActionType;
    switch (this.props.refundType) {
      case REFUND_TYPE.PURCHASE_DEPOSIT:
        refundDealerActionType = DEALER_ACTIONS.OFFLINE_PURCHASE_DEPOSIT_REFUND;
        break;
      case REFUND_TYPE.ORDER_DEPOSIT:
        refundDealerActionType = DEALER_ACTIONS.OFFLINE_ORDER_DEPOSIT_REFUND;
        break;
      case REFUND_TYPE.RESERVATION_DEPOSIT:
        refundDealerActionType =
          DEALER_ACTIONS.OFFLINE_RESERVATION_DEPOSIT_REFUND;
        break;
      case REFUND_TYPE.HOLD_DEPOSIT:
        refundDealerActionType = DEALER_ACTIONS.OFFLINE_PURCHASE_DEPOSIT_REFUND;
        break;
      default:
    }

    const offlineRefundRequest = {
      reservationId: this.props.customerReservation.id,
      dealerAction: refundDealerActionType,
      acceptedDate: this.props.refundDate,
    };
    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.user.token,
      this.props.customerReservation.dealerId,
      offlineRefundRequest,
      this.props.user.userId
    )
      .then(() => {
        this.props.onClose();
        this.props.refreshReservationDetailsWithNotifications();
      })
      .catch((error) => {
        logMsg("the error is: ", error);
        if (isProcessingError(error)) {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  render() {
    const styles = IS_FORD_BRAND(this.props.user.brand)
      ? fordStyles
      : lincolnStyles;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    return (
      <EMPDialog
        open={this.props.open}
        handleClose={this.props.onClose}
        onEnter={this.props.onEnter}
        dialogTitle={this.props.t(
          "OrderDetailsConsumerDeposit.refundedOfflineDialogAreYouSureTitle"
        )}
        titleType="warning"
        hasActionButton
        actionButtonText={this.props.t(
          "OrderDetailsConsumerDeposit.refundedOfflineDialogConfirm"
        )}
        onConfirm={this.handleConfirm}
        actionButtonDisabled={
          !this.props.user.permissions.rolePermissions.hasOwnProperty(
            "depositSubmit"
          )
        }
        hasSecondaryActionButton
        onSecondaryAction={this.props.onToggle}
        secondaryActionButtonText={this.props.t(
          "OrderDetailsConsumerDeposit.refundedOfflineDialogGoBack"
        )}
      >
        <Grid container data-testid="offlineRefundConfirmDialog">
          <Grid item xs={12}>
            <p className={styles.text}>
              {this.props.t(
                `OrderDetailsConsumerDeposit.refundedOfflineDialogBody${this.props.refundType}Confirm`
              )}
            </p>
          </Grid>

          <Grid item xs={12}>
            <p className={styles.refundedDateLabel}>
              {this.props.t(
                "OrderDetailsConsumerDeposit.refundedOfflineDialogRefundDate"
              ) +
                ": " +
                UiDateUtils.formatDateMmDdYyyy(this.props.refundDate)}
            </p>
          </Grid>
        </Grid>
      </EMPDialog>
    );
  }
}

export default withTranslation("emp")(OfflineRefundConfirmDialog);
