import React, { Component } from "react";
import { Grid } from "@mui/material";
import fordStyle from "./Table.module.scss";
import lincolnStyle from "./TableLincoln.module.scss";
import EMPEmptyState from "../components/EMPEmptyState/EMPEmptyState";

class StyledTableRowWithToolTip extends Component {
  render() {
    const { text, data, indent, index, testID, toolTip, isLincolnBrand } =
      this.props;
    const style = isLincolnBrand ? lincolnStyle : fordStyle;

    if ((index && index % 2 === 0) || index === 0) {
      return (
        <Grid
          container
          alignItems="center"
          className={indent ? style.greyIndent : style.greyRegular}
        >
          <Grid item xs={data !== undefined ? 8 : 12}>
            {text}
            {toolTip}
          </Grid>
          {data !== undefined && (
            <Grid item xs={4} className={style.rightCell} data-testid={testID}>
              {data !== null ? data : <EMPEmptyState />}
            </Grid>
          )}
        </Grid>
      );
    }
    return (
      <Grid
        container
        alignItems="center"
        className={indent ? style.indent : style.regular}
      >
        <Grid item xs={data !== undefined ? 8 : 12}>
          {text}
          {toolTip}
        </Grid>
        {data !== undefined && (
          <Grid item xs={4} className={style.rightCell} data-testid={testID}>
            {data !== null ? data : <EMPEmptyState />}
          </Grid>
        )}
      </Grid>
    );
  }
}

export default StyledTableRowWithToolTip;
