import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import fordStyles from "./OfflineRefund.module.scss";
import lincolnStyles from "./OfflineRefundLincoln.module.scss";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import OfflineRefundConfirmDialog from "./OfflineRefundConfirmDialog";
import * as DateConstants from "../../../../shared/constants/DateConstants";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

class OfflineRefundCalendarDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      openOfflineRefundConfirmDialog: false,
    };
  }

  updateDate = (date) => {
    if (date !== null) {
      this.setState({ currentDate: date }); //Set newly selected date
    }
  };

  formatMaxDate = () => {
    const todayDate = this.props.customerReservation.todayDate.split("-");
    return new Date(todayDate[0], todayDate[1] - 1, todayDate[2]);
  };

  render() {
    const styles = IS_FORD_BRAND(this.props.user.brand)
      ? fordStyles
      : lincolnStyles;

    return (
      <>
        <EMPDialog
          open={this.props.open}
          handleClose={this.props.onClose}
          hasCloseIcon
          dialogTitle={this.props.t(
            `OrderDetailsConsumerDeposit.refundedOfflineDialogTitle${this.props.refundType}`
          )}
          hasSecondaryActionButton
          secondaryActionButtonText={this.props.t(
            "OrderDetailsConsumerDeposit.refundedOfflineDialogGoBack"
          )}
          onSecondaryAction={this.props.onGoBack}
          hasActionButton
          actionButtonText={this.props.t(
            "OrderDetailsConsumerDeposit.refundedOfflineDialogNext"
          )}
          onConfirm={() => this.props.onToggle(this.state.currentDate)}
          onEnter={this.props.onEnter}
        >
          <Grid container data-testid="offlineRefundCalendarDialog">
            <Grid item xs={12}>
              <p className={styles.body}>
                {this.props.t(
                  `OrderDetailsConsumerDeposit.refundedOfflineDialogBody${this.props.refundType}`
                )}
              </p>

              <DatePicker
                open={true}
                id="offlineRefundCalendar"
                selected={this.state.currentDate}
                inline
                maxDate={this.formatMaxDate()}
                shouldCloseOnSelect={false}
                dateFormat={DateConstants.MMddyyyy}
                onChange={this.updateDate}
              />
            </Grid>
          </Grid>
        </EMPDialog>

        <OfflineRefundConfirmDialog
          {...this.props}
          open={this.state.openOfflineRefundConfirmDialog}
          refundDate={this.state.currentDate}
          refundType={this.props.refundType}
        />
      </>
    );
  }
}

export default withTranslation("emp")(OfflineRefundCalendarDialog);
