export const FINANCE_PRODUCT_TYPE_MAP = {
  FINANCE: "finance",
  LEASE: "lease",
  FLEX_BUY: "flex buy",
  FORD_OPTIONS: "ford options",
  CASH: "cash",
  THIRD_PARTY: "ThirdPartyFinancingOptions",
  DEALER_FINANCING_OPTIONS: "dealer financing options",
  BUSINESS_CONTRACT_HIRE: "lease",
  OTHER: "other financing options",
  THIRD_PARTY_OPTIONS: "thirdpartyfinancingoptions",
};

export const FINANCE_PROVIDER = {
  AUTOFI: "AutoFi",
};

export const FINANCE_STATUS = {
  SUBMITTED: "SUBMITTED",
  INITIATED: "INITIATED",
  ACCEPTED: "ACCEPTED",
  APPROVED: "APPROVED",
};
