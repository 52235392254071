import React from "react";
import { TableCell, TableRow } from "@mui/material";
import styles from "../dealerDashBoard/DashboardStyles.module.scss";
import { Link } from "react-router-dom";
import MotionChevronRight from "../../../shared/Icons/MotionChevronRight";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { IS_CANADA_MARKET } from "../../../utils/EmpUtil";
import UiDateUtils from "../../../utils/UiDateUtils";
import {
  ACCESSORIES_PRICING,
  ALD_PRICING,
  CUPID_ID,
  CUSTOMER_CONTRACT,
  DELIVERY_BANDS,
  GPIS_PRICING,
  LEAD_TIMES,
  NEW_VEHICLE_LINE,
  OPT_IN,
  PAYMENT_THRESHOLD,
  PRICING,
  RESERVATION_DEPOSIT,
  STORE_ID,
  TERMS_AND_CONDITIONS,
  VARIABLE_MARKETING,
} from "../../../common/Constants";

export const PendingActionsRow = (pendingAction, index, props, vehicleLine) => {
  const translationKey = props.isNsc
    ? "NscPendingActions"
    : "DealerPendingActions";
  const actionName = props.t(translationKey + "." + pendingAction.actionName);
  const actionDescription =
    props.t(translationKey + "Description." + pendingAction.actionName) +
    (vehicleLine ? " for " + vehicleLine.year + " " + vehicleLine.model : "");
  const actions = actionToPerform();
  const rowColor = getColor();
  const dueDate =
    pendingAction.status === "OVERDUE"
      ? props.t(translationKey + ".overdue")
      : IS_CANADA_MARKET(props.user.market)
      ? UiDateUtils.formatDateDDMMYYYYToMMDDYYYY(
          pendingAction.dueDate,
          "/",
          "/"
        )
      : pendingAction.dueDate;

  function getColor() {
    switch (pendingAction.status) {
      case "GREEN":
        return "";
      case "AMBER":
        return styles.amber;
      case "RED":
        return styles.red;
      case "OVERDUE":
        return styles.red;
      default:
        return "";
    }
  }

  function getBusinessTabs(actionName) {
    if (actionName === DELIVERY_BANDS) {
      return 2;
    } else if (
      actionName === VARIABLE_MARKETING ||
      actionName === OPT_IN ||
      actionName === ALD_PRICING ||
      actionName === GPIS_PRICING
    ) {
      return 5;
    } else if (actionName === CUSTOMER_CONTRACT) {
      return 4;
    } else if (actionName === STORE_ID) {
      return 3;
    } else if (actionName === TERMS_AND_CONDITIONS) {
      //returning -1 rather than 0 so we can identify in Business Settings if user is coming from Pending Actions or other tabs within BusinessSettings
      return -1;
    }
  }

  function actionToPerform() {
    switch (pendingAction.actionName) {
      case TERMS_AND_CONDITIONS:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case "VEHICLE_PRICE_CHANGE":
      case NEW_VEHICLE_LINE:
      case PRICING:
        return {
          pathname: "/pricing/vehicles",
          navigateText: props.t(translationKey + ".go"),
        };
      case STORE_ID:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case RESERVATION_DEPOSIT:
        return {
          pathname: "/pricing/deposit",
          navigateText: props.t(translationKey + ".go"),
        };
      case PAYMENT_THRESHOLD:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case ACCESSORIES_PRICING:
        return {
          pathname: "/pricing/accessories",
          navigateText: props.t(translationKey + ".go"),
        };
      case LEAD_TIMES:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case CUPID_ID:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case DELIVERY_BANDS:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case VARIABLE_MARKETING:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case OPT_IN:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case CUSTOMER_CONTRACT:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case ALD_PRICING:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case GPIS_PRICING:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      default:
        return "";
    }
  }

  function updateBusinessSettings(actionName) {
    !props.isNsc &&
      props.user.updateBusinessSettingsTab(getBusinessTabs(actionName));
  }

  return (
    <React.Fragment key={index}>
      <TableRow
        className={`${styles.textContentPendingActions} ${rowColor}`}
        key={index}
        data-testid={pendingAction.actionName}
      >
        <TableCell>
          <div className={styles.alignStart}>
            <ReportProblemOutlinedIcon className={styles.actionIcon} />
            <span className={styles.actionName}>{actionName}</span>
          </div>
        </TableCell>
        <TableCell className={styles.col2}>
          <div className={styles.alignStart}>
            <span className={styles.description}>{actionDescription}</span>
          </div>
        </TableCell>
        <TableCell className={styles.col3}>
          <div className={styles.alignStart}>{dueDate}</div>
        </TableCell>
        <TableCell>
          <div>
            <Link
              onClick={() => updateBusinessSettings(pendingAction.actionName)}
              data-testid={pendingAction.actionName + "_GO"}
              to={{
                pathname: actions.pathname,
                query: {
                  fromPendingActions: true,
                  modelYear: vehicleLine && vehicleLine.year,
                  model: vehicleLine && vehicleLine.model,
                },
                actionName: pendingAction.actionName,
              }}
              className={styles.goToPage}
            >
              {actions.navigateText}
              <MotionChevronRight />
            </Link>
          </div>
        </TableCell>
        <TableCell />
      </TableRow>
    </React.Fragment>
  );
};
