import React from "react";
import { useFieldSupportStatus } from "../../hooks/useFieldSupportStatus";
import { useCrcStatus } from "../../hooks/useCrcStatus";

const MessageBar = ({ message }) => (
  <div
    style={{
      backgroundColor: "#0562D2",
      width: "100%",
      padding: "4px 0",
      textAlign: "center",
      color: "white",
      fontSize: "12px",
    }}
  >
    {message}
  </div>
);

const EMPFieldSupportBar = () => {
  const isFieldSupport = useFieldSupportStatus();
  const isCrc = useCrcStatus();

  if (isFieldSupport) {
    return (
      <MessageBar message="Logged in as Field Support. Please note that some features may be restricted." />
    );
  }

  if (isCrc) {
    return (
      <MessageBar message="Logged in as CRC. Please note that some features may be restricted." />
    );
  }

  return null;
};

export default EMPFieldSupportBar;
