import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./ErrorScreen.module.scss";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { withTranslation } from "react-i18next";

const ErrorScreen = (props) => {
  const handleLoginBack = () => {
    AuthenticationFailureHandler.execute();
  };
  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={styles.alertBody} data-testid="errorPopupScreen">
          <DialogTitle className={styles.alertDialogTitle}>
            <div className={styles.alertHeader}>
              <div className={styles.reportProblemOutlinedIcon} />
              <div
                data-testid="alert-dialog-text"
                className={styles.alertDialogText}
              >
                {props.t("ErrorPopupScreen.header")}
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <div
              data-testid="alert-dialog-description"
              className={styles.alertDialogDescription}
            >
              {props.t("ErrorPopupScreen.errorMessage")}
            </div>
          </DialogContent>
          <DialogActions id={styles.alertErrorButton}>
            <button
              data-testid="login-back-button"
              className={styles.loginBackButton}
              onClick={() => handleLoginBack()}
            >
              {props.t("ErrorPopupScreen.buttonText")}
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default withTranslation("emp")(ErrorScreen);
