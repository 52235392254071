import { useState } from "react";

export default () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("Heading");
  const [modalContent, setModalContent] = useState("Content");

  const handleModal = (heading = false, content = false) => {
    setOpenModal(!openModal);
    if (heading) {
      setModalHeading(heading);
    }
    if (content) {
      setModalContent(content);
    }
  };

  return {
    openModal,
    handleModal,
    modalHeading,
    modalContent,
  };
};
