import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { logMsg } from "../../common/Logger";
import ReleaseNotesClient from "../../clients/ReleaseNotesClient";
import { trackPageLoadEvent } from "../../shared/services/analytics/AnalyticsService";

const PageHeader = ({ title, subtitle }) => {
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1">{subtitle}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const Hero = ({ title, subtitle }) => {
  return (
    <Box
      style={{ backgroundColor: "whitesmoke" }}
      px={[4, 4, 4, 14, 14]}
      py={3}
      mb={3}
    >
      <PageHeader title={title} subtitle={subtitle} />
    </Box>
  );
};

const Notes = ({ notes }) => {
  return (
    <Box px={[4, 4, 4, 14, 14]} py={3}>
      {notes.map((note, index) => (
        <Box
          key={index}
          mb={6}
          sx={{
            borderBottom: "1px solid #f2f2f2",
            paddingBottom: "16px",
          }}
        >
          <Typography data-testid={`notes_title_${index}`} variant="h6">
            {note.text[0]}
          </Typography>

          <ul>
            {note.text.map(
              (item, index) =>
                index > 0 && (
                  <li key={index}>
                    <Typography variant="body1">{item}</Typography>
                  </li>
                )
            )}
          </ul>
        </Box>
      ))}
    </Box>
  );
};

const ReleaseNotes = (props) => {
  const { t, hideOrShow, token, user } = props;

  const { pathname } = useLocation();
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getReleaseNotes();
  }, [pathname]);

  useEffect(() => {
    trackPageLoadEvent("fv:emp:release note", user);
  }, [user]);

  const getReleaseNotes = async () => {
    hideOrShow(true);
    await ReleaseNotesClient.getReleaseNotes(token)
      .then((response) => {
        response !== null &&
          response.sort((a, b) => new Date(b.date) - new Date(a.date));

        setNotes(response);
      })
      .catch(() => {
        logMsg("Error fetching release notes.");
      })
      .finally(() => {
        hideOrShow(false);
      });
  };

  return (
    notes && (
      <section>
        <Hero
          title={t("ReleaseNotes.title")}
          subtitle={t("ReleaseNotes.subtitle")}
        />
        <Grid container>
          <Grid item xs={12} sm={12} md={10}>
            <Notes notes={notes} />
          </Grid>
        </Grid>
      </section>
    )
  );
};

export default withTranslation("emp")(ReleaseNotes);
