import React, { useState, useContext, useEffect } from "react";
import { Grid, Box, Typography, Menu, MenuItem } from "@mui/material";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import EMPTextAreaField from "../../shared/components/EMPTextAreaField/EMPTextAreaField";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import DealerNotesClient from "../../clients/DealerNotesClient";
import { formatToSimpleDate } from "../../utils/EmpUtil";
import { UserContext } from "../../shared/context/userContext";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../../shared/components/EMPInteractiveList/EMPInteractiveList.css";
import {
  trackDealerNotesDrawerAction,
  trackDealerNotesDrawerLoad,
} from "../../shared/services/analytics/OrderDetailsPageAnalytics";
import {
  canSubmitNotes,
  canViewNotes,
} from "../../shared/rules/DealerNotesRules";

const DealerNotes = ({ orderId }) => {
  const user = useContext(UserContext);
  const { market, dealerId, salesCode, dealerBrand } = user;
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const [anchorEl, setAnchorEl] = useState({ el: null, note: null });
  const [newNote, setNewNote] = useState("");
  const [editingNoteId, setEditingNoteId] = useState(null);
  const open = Boolean(anchorEl.el);
  const hasSubmitPermission = canSubmitNotes();
  const hasNotesViewPermission = canViewNotes();

  useEffect(() => {
    trackDealerNotesDrawerLoad(orderId, user);
  }, []);

  const handleClick = (event, note) => {
    setAnchorEl({ el: event.currentTarget, note });
    trackDealerNotesDrawerAction("note actions", orderId, user);
  };

  const handleClose = () => {
    setAnchorEl({ el: null, note: null });
  };

  const queryClient = useQueryClient();
  const {
    isLoading,
    isLoadingError,
    data: notes,
    isError,
  } = useQuery({
    queryKey: ["dealerNotes", { orderId }],
    queryFn: async () =>
      await DealerNotesClient.getDealerNotes(
        sessionStorage.getItem("adfs.token"),
        orderId,
        0,
        100
      ),
    enabled: hasNotesViewPermission && !!orderId,
    staleTime: 60000,
    select: ({ data }) => data,
  });

  const addNote = useMutation({
    mutationFn: (newNote) => {
      return DealerNotesClient.saveDealerNotes(
        sessionStorage.getItem("adfs.token"),
        userCommonId.split("|").join("%7C"),
        newNote
      );
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["dealerNotes", { orderId }], data);
      queryClient.invalidateQueries({ queryKey: ["dealerNotes", { orderId }] });
      setNewNote("");
      setEditingNoteId(null);
      trackDealerNotesDrawerAction("post", orderId, user);
    },
  });

  const updateNote = useMutation({
    mutationFn: (editingNote) => {
      return DealerNotesClient.updateDealerNotes(
        sessionStorage.getItem("adfs.token"),
        {
          id: editingNote.id,
          dealerNotes: newNote,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["dealerNotes", { orderId }] });
      setNewNote("");
      setEditingNoteId(null);
    },
  });

  const editNote = (note) => {
    setNewNote(note.dealerNotes);
    setEditingNoteId(note.id);
    setAnchorEl((prevState) => ({ ...prevState, el: null }));
  };

  const handleSave = () => {
    if (editingNoteId) {
      updateNote.mutate({ id: editingNoteId, dealerNotes: newNote });
    } else {
      addNote.mutate({
        dealerNotes: newNote,
        orderId,
        userId: user.userId,
      });
    }
  };

  const deleteNote = useMutation({
    mutationFn: (noteId) => {
      return DealerNotesClient.deleteDealerNotes(
        sessionStorage.getItem("adfs.token"),
        noteId
      );
    },
    onSuccess: () => {
      setAnchorEl({ el: null, note: null });
      queryClient.invalidateQueries({ queryKey: ["dealerNotes", { orderId }] });
    },
  });

  if (isLoading) {
    return (
      <Typography variant="body2" textAlign="center">
        Loading...
      </Typography>
    );
  }

  if (isError || isLoadingError) {
    return (
      <>
        <Box>
          <Typography variant="body2" textAlign="center" sx={{ mb: 4 }}>
            An error occurred and we are not able to access notes. Try again
            later.
          </Typography>
        </Box>
        <Box textAlign="center">
          <EMPButton
            variant="contained"
            onClick={() => queryClient.invalidateQueries("dealerNotes")}
          >
            Try Again
          </EMPButton>
        </Box>
      </>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          height: "calc(100vh - 200px)",
          overflow: "scroll",
          paddingBottom: "200px",
        }}
      >
        <Box sx={{ display: "grid", rowGap: "16px" }}>
          <TransitionGroup component={Box} appear>
            {notes.dealerNotes.map((note) => (
              <CSSTransition key={note.id} timeout={1000} classNames="item">
                <Box
                  sx={{
                    background:
                      "linear-gradient(135deg, #F5F6F7 0%, #FFFFFF 100%)",
                    padding: "16px 24px 0px 24px",
                    borderRadius: 8,
                  }}
                  key={note.id}
                >
                  <Typography variant="h5" component="p">
                    {note.dealerNotes}
                  </Typography>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ my: 2 }}
                  >
                    <Grid item>
                      <Typography variant="body2" component="p">
                        {formatToSimpleDate(note.date)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <EMPButton
                        variant="text"
                        disabled={!hasSubmitPermission}
                        onClick={(e) => handleClick(e, note)}
                      >
                        Note Actions
                      </EMPButton>

                      <Menu
                        id="long-menu"
                        data-testid="note-actions-menu"
                        anchorEl={anchorEl.el}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        elevation={1}
                      >
                        <MenuItem
                          disabled={editingNoteId}
                          onClick={() => editNote(anchorEl.note)}
                          sx={{ fontSize: 14 }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => deleteNote.mutate(anchorEl.note.id)}
                          sx={{ fontSize: 14 }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Box>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Box>
      </Box>
      <Box
        sx={{
          px: 4,
          py: 4,
          position: "absolute",
          width: "100%",
          bottom: 0,
          left: 0,
          background: "white",
        }}
      >
        <EMPTextAreaField
          data-testid="dealer-notes-text-area"
          disabled={!hasSubmitPermission}
          hasCharacterCount
          maxCharacters={250}
          minRows={3}
          label={editingNoteId ? "Update Note" : "New Dealer Note"}
          onChange={(e) => {
            setNewNote(e.target.value);
          }}
          value={newNote}
        />
        <Grid container justifyContent="flex-end" spacing={4}>
          <Grid item>
            <EMPButton
              variant="outlined"
              onClick={() => {
                setEditingNoteId(null);
                setNewNote("");
                setAnchorEl({ el: null, note: null });
                trackDealerNotesDrawerAction("clear", orderId, user);
              }}
            >
              {editingNoteId ? "Cancel" : "Clear"}
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              disabled={
                !canSubmitNotes() ||
                !newNote ||
                editNote.isLoading ||
                addNote.isLoading
              }
              variant="contained"
              onClick={handleSave}
            >
              {editingNoteId ? "Edit" : "Post"}
            </EMPButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DealerNotes;
