import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  IS_FORD_BRAND,
  IS_MODEL_E_ORDER,
  NOTNULL_OR_NOTUNDEFINED,
} from "../../../../utils/EmpUtil";
import {
  StyledBigTableHeader,
  StyledTableRow,
} from "../../../../shared/Tables/TableRow";
import { formatPriceValue } from "../../../../utils/FormatPrice";
import SharedToolTip from "../../../../shared/sharedToolTip/SharedToolTip";
import fordSharedStyles from "../../orderDetails/NAOrderDetailsNewVersion.module.scss";
import lincolnSharedStyles from "../../orderDetails/NAOrderDetailsNewVersionLincoln.module.scss";
import crStylesFord from "../../crStyles";
import crStylesLincoln from "../../crStylesLincoln";
import fordStyles from "./OffersAndIncentives.module.scss";
import lincolnStyles from "./OffersAndIncentivesLincoln.module.scss";
import { Accordion, AccordionSummary } from "@mui/material";
import EMPEmptyState from "../../../../shared/components/EMPEmptyState/EMPEmptyState";

class OffersAndIncentives extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offersAndIncentivesExpanded: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({
        offersAndIncentivesExpanded: this.props.expandAccordian,
      });
    }
  }

  flipIcon() {
    this.setState({
      offersAndIncentivesExpanded: !this.state.offersAndIncentivesExpanded,
    });
  }

  render() {
    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const sharedStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordSharedStyles
        : lincolnSharedStyles;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    const pricingStack = this.props.pricingStack;

    const affinityPrograms =
      this.props.customerReservation &&
      this.props.customerReservation.qualifiedIncentiveTypes &&
      this.props.customerReservation.qualifiedIncentiveTypes;

    return (
      <div>
        <Accordion
          onChange={() => this.flipIcon()}
          expanded={this.state.offersAndIncentivesExpanded}
          data-testid="offersIncentivesDropDown"
        >
          <AccordionSummary
            style={crStyles.headerBarBg}
            data-testid="offersAndIncentives"
          >
            <div className={sharedStyles.headerBar}>
              <div
                className={sharedStyles.header}
                data-testid="customerOptOfflineTitle"
              >
                {this.props.t("PurchaseDetails.offersAndIncentives.title")}
                <span className={sharedStyles.iconContainer}>
                  <SharedToolTip
                    {...this.props}
                    openTestid="headerTooltipOpen"
                    closeTestid="headerTooltipClose"
                    body={this.props.t(
                      "OrderDetailsConsumerDeposit.offersAndIncentivesTooltipBody"
                    )}
                    isLincolnBrand={
                      this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                    }
                  />
                </span>
              </div>
              <div className={styles.acceptRejectDiv}>
                {this.state.offersAndIncentivesExpanded ? (
                  <ExpandLessIcon data-testid="lessOffersAndIncentives" />
                ) : (
                  <ExpandMoreIcon data-testid="moreOffersAndIncentives" />
                )}
              </div>
            </div>
          </AccordionSummary>

          <Grid container>
            {pricingStack &&
              NOTNULL_OR_NOTUNDEFINED(pricingStack.offersList) &&
              pricingStack.offersList.map((offersAndIncentive, index) => {
                return (
                  <StyledTableRow
                    isLincolnBrand={
                      this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                    }
                    text={`${offersAndIncentive.name} - ${this.props.t(
                      "PurchaseDetails.offersAndIncentives.prgramNo"
                    )} ${offersAndIncentive.programId}`}
                    key={index}
                    data={formatPriceValue(offersAndIncentive.amount)}
                    index={index}
                    testID={offersAndIncentive.name}
                  />
                );
              })}
            {this.props.fromPurchaseDetails &&
              affinityPrograms &&
              IS_MODEL_E_ORDER(this.props.customerReservation.journeyType) &&
              IS_FORD_BRAND(this.props.user.brand) &&
              NOTNULL_OR_NOTUNDEFINED(affinityPrograms) &&
              affinityPrograms.map((program, index) => {
                return (
                  <StyledTableRow
                    isLincolnBrand={
                      this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                    }
                    text={this.props.t(
                      "PurchaseDetails.offersAndIncentives.recognitionProgram"
                    )}
                    key={`affinityProgram${index}`}
                    index={
                      pricingStack.offersList &&
                      pricingStack.offersList.length % 2 === 0
                        ? index
                        : index + 1
                    }
                    testID={`affinityProgram${index}`}
                  />
                );
              })}
            <StyledBigTableHeader
              isLincolnBrand={
                this.props.user && !IS_FORD_BRAND(this.props.user.brand)
              }
              text={this.props.t("PurchaseDetails.offersAndIncentives.total")}
              data={
                (pricingStack &&
                  pricingStack.offersList &&
                  pricingStack.offersList.length > 0) ||
                (affinityPrograms && affinityPrograms.length > 0) ? (
                  formatPriceValue(
                    pricingStack ? pricingStack.totalIncentivesPrice : 0
                  )
                ) : (
                  <EMPEmptyState />
                )
              }
              testID="totalIncentives"
            />
          </Grid>
        </Accordion>
      </div>
    );
  }
}

export default withTranslation("emp")(OffersAndIncentives);
