import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Route, Router } from "react-router-dom";
import { logMsg } from "../common/Logger";
import Loading from "../shared/loading/Loading";
import AccessDeniedAsBevDisabled from "../components/accessDenied/AccessDeniedAsBevDisabled";
import CRCApp from "./CRCApp";
import NADealerApp from "./NADealerApp";
import {
  ADFS_TOKEN,
  BRAND,
  PAGE,
  QUERY,
  SALESCODE_KEY,
} from "../common/Constants";
import { FORD_BRAND, LINCOLN_BRAND } from "../components/accessories/Brands";
import AuthenticationClient from "../clients/AuthenticationClient";
import { ENV, IS_CANADA_MARKET } from "../utils/EmpUtil";
import { displayError, is500, PAGE_ERROR } from "../errors/ErrorConstants";
import FieldViewApp from "./FieldViewApp";
import NSCApp from "./NSCApp";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { initDynatrace } from "../shared/services/dynatrace/DynatraceService";
import ErrorBoundary from "../errors/ErrorBoundary";
import { UserContext } from "../shared/context/userContext";

class App extends Component {
  static getTheToken(params) {
    let token = params.access_token;

    if (!token) {
      token = sessionStorage.getItem(ADFS_TOKEN);
    }
    return token;
  }

  static hideOrShow(show, setZIndex = false) {
    if (show) {
      App.loadingCount += 1;
      if (document.getElementById("loading"))
        document.getElementById("loading").style.display = "block";
      if (document.getElementById("appWrapper") && setZIndex) {
        document.getElementById("appWrapper").style.zIndex = "-1";
      }
    } else {
      if (App.loadingCount === 1) {
        if (document.getElementById("loading"))
          document.getElementById("loading").style.display = "none";
        if (
          document.getElementById("appWrapper") &&
          (document.getElementById("appWrapper").style.zIndex === "-1" ||
            document.getElementById("appWrapper").style.zIndex === -1)
        ) {
          document.getElementById("appWrapper").style.zIndex = "auto";
        }
      }
      if (App.loadingCount > 0) App.loadingCount -= 1;
    }
  }

  static createLocalUrl() {
    if (ENV !== "prod") {
      const token = sessionStorage.getItem(ADFS_TOKEN);
      const salesCode = sessionStorage.getItem(SALESCODE_KEY);
      const page = sessionStorage.getItem(PAGE);
      const query = sessionStorage.getItem(QUERY);
      const brand = sessionStorage.getItem(BRAND);
      let stateParam = "";
      if (salesCode) stateParam += ",salesCode=" + salesCode;
      if (page) stateParam += ",page=" + page;
      if (query) stateParam += ",query=" + query;
      if (brand) stateParam += ",brand=" + brand;

      if (stateParam !== "") stateParam = "&state=" + stateParam;
      sessionStorage.setItem(
        "localHost",
        `localhost:3000/#access_token=${token}${stateParam}`
      );
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      renderApp: false,
      hasError: [],
    };
    App.loadingCount = 0;

    App.hideOrShow = App.hideOrShow.bind(this);
  }

  componentDidMount() {
    initDynatrace();
    const params = this.getUrlParams();
    const token = App.getTheToken(params);
    let salesCode = params.salesCode || sessionStorage.getItem(SALESCODE_KEY);
    const page = params.page || sessionStorage.getItem(PAGE);
    const query = params.query || sessionStorage.getItem(QUERY);
    const brand = params.brand || sessionStorage.getItem(BRAND);

    const authenticationRequest = {};
    if (salesCode) {
      authenticationRequest["salesCode"] = salesCode;
    }
    if (page) {
      authenticationRequest["page"] = page;
    }
    if (query) {
      authenticationRequest["query"] = query;
    }
    if (brand) {
      authenticationRequest["brand"] = brand;
    }

    App.hideOrShow(true);
    AuthenticationClient.status(token, authenticationRequest)
      .then((response) => {
        sessionStorage.setItem(ADFS_TOKEN, token);
        if (!salesCode) salesCode = response.salesCode;
        if (salesCode) sessionStorage.setItem(SALESCODE_KEY, salesCode);
        if (response.dealerId)
          sessionStorage.setItem("dealerId", response.dealerId);
        if (page) sessionStorage.setItem(PAGE, page);
        if (query) sessionStorage.setItem(QUERY, query);
        if (brand) sessionStorage.setItem(BRAND, brand);
        this.token = token;
        this.userDetails = response;
        sessionStorage.setItem("market", this.userDetails.market);
        if (salesCode) {
          this.userDetails.brand = salesCode.startsWith(LINCOLN_BRAND)
            ? LINCOLN_BRAND
            : FORD_BRAND;
        } else {
          this.userDetails.brand = FORD_BRAND;
        }
        App.createLocalUrl();
        this.setState({ renderApp: true });
      })
      .catch((error) => {
        logMsg(error);
        if (is500(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        }
      });
  }

  getUrlParams() {
    const hash = window.location.hash.substring(1);
    const params = {};
    hash.split("&").forEach((hk) => {
      if (hk.startsWith("state")) {
        hk.split(",").forEach((state) => {
          const temp = state.split("=");
          params[temp[0]] = temp[1];
        });
      } else {
        const temp = hk.split("=");
        params[temp[0]] = temp[1];
      }
    });
    return params;
  }

  isNsc = (userDetails) => userDetails.userType === "nsc";

  isCrc = (userDetails) => userDetails.userType === "crc";

  isNADealer = (userDetails) => userDetails.userType === "na_dealer";

  isFieldView = (userDetails) => userDetails.userType === "field_view";

  render() {
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    return this.state.renderApp ? (
      <QueryClientProvider
        client={
          new QueryClient({
            defaultOptions: {
              queries: {
                refetchOnWindowFocus: false,
              },
            },
          })
        }
      >
        <UserContext.Provider value={this.userDetails}>
          <ErrorBoundary>
            <Router history={createBrowserHistory()}>
              <Loading brand={this.userDetails.brand} />
              <Route
                render={(props) => {
                  if (this.isNADealer(this.userDetails)) {
                    return (
                      <NADealerApp
                        {...props}
                        userDetails={this.userDetails}
                        hideOrShow={App.hideOrShow}
                        token={this.token}
                      />
                    );
                  } else if (
                    this.isNsc(this.userDetails) &&
                    IS_CANADA_MARKET(this.userDetails.market)
                  ) {
                    return (
                      <NSCApp
                        {...props}
                        token={this.token}
                        isNsc={true}
                        i18n={this.i18n}
                        userDetails={this.userDetails}
                        hideOrShow={App.hideOrShow}
                      />
                    );
                  } else if (this.isCrc(this.userDetails)) {
                    return (
                      <CRCApp
                        {...props}
                        token={this.token}
                        hideOrShow={App.hideOrShow}
                        i18n={this.i18n}
                        userDetails={this.userDetails}
                      />
                    );
                  } else if (this.isFieldView(this.userDetails)) {
                    return (
                      <FieldViewApp
                        {...props}
                        token={this.token}
                        i18n={this.i18n}
                        userDetails={this.userDetails}
                        hideOrShow={App.hideOrShow}
                      />
                    );
                  } else {
                    if (document.getElementById("loading")) {
                      return (
                        <AccessDeniedAsBevDisabled
                          {...props}
                          userDetails={this.userDetails}
                        />
                      );
                    } else {
                      return null;
                    }
                  }
                }}
              />
            </Router>
          </ErrorBoundary>
        </UserContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    ) : (
      <Loading />
    );
  }
}

export default App;
