import React from "react";
import fordStyles from "../globalNavBar/GlobalNavBar.module.scss";
import lincolnStyles from "../globalNavBar/GlobalNavBar-Lincoln.module.scss";
import "../../styles/sharedStyles.scss";
import { Typography, Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";
import EMPDialog from "../components/EMPDialog/EMPDialog";
import EMPCheckbox from "../components/EMPCheckbox/EMPCheckbox";

class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      checked: false,
    };
  }

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open });
    }
  }

  changeCheckbox = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  render() {
    const { showWarningIcon, dialogProps, showSettings } = this.props;
    const isFordBrand = IS_FORD_BRAND(this.props.user && this.props.user.brand);
    const styles = isFordBrand ? fordStyles : lincolnStyles;

    return (
      <EMPDialog
        open={this.state.open}
        dialogTitle={dialogProps ? dialogProps.title : null}
        titleType={this.props.greenTitle ? "success" : "warning"}
        hasCloseIcon={showWarningIcon}
        handleClose={this.props.onClose}
        hasSecondaryActionButton={
          !this.props.showOneButton && !this.props.hideCancelAndConfirm
        }
        secondaryActionButtonText={dialogProps ? dialogProps.cancel : "Cancel"}
        hasActionButton={!this.props.hideCancelAndConfirm}
        actionButtonText={dialogProps ? dialogProps.confirm : "Confirm"}
        onConfirm={this.props.onConfirm}
        disableActionButton={
          (showSettings && !this.state.checked) || this.props.disableConfirm
        }
        onSecondaryAction={this.props.onClose}
      >
        <Grid container id="deleteDialog" data-testid="confirmSaveSettings">
          <Grid item xs={12} className={styles.popupSection}>
            <Typography variant="body1">
              {dialogProps && dialogProps.bodyText}
            </Typography>
            {this.props.additionalDescription && (
              <Typography variant="body1">
                {dialogProps && dialogProps.additionalBodyText}
              </Typography>
            )}
          </Grid>

          {showSettings && (
            <Grid container className={styles.popupSection}>
              <Grid item xs={4} className={styles.settingsHeader}>
                <Typography variant="body1">
                  {dialogProps && dialogProps.markup}
                </Typography>
              </Grid>
              <Grid item xs={4} className={styles.settingsHeader}>
                <Typography variant="body1">
                  {dialogProps && dialogProps.laborRate}
                </Typography>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4} className={styles.settingsInfo}>
                <Typography variant="body1">
                  {this.props.markupValue}%
                </Typography>
              </Grid>
              <Grid item xs={4} className={styles.settingsInfo}>
                <Typography variant="body1">
                  ${this.props.laborRateValue}
                </Typography>
              </Grid>
            </Grid>
          )}

          {showSettings && (
            <Grid item xs={12} className={styles.popupSection}>
              <EMPCheckbox
                checked={this.state.checked}
                name="checkboxBrandSettings"
                id="checkboxBrandSettings"
                label={dialogProps && dialogProps.checkbox}
                handleChange={() => this.changeCheckbox()}
              ></EMPCheckbox>
            </Grid>
          )}

          {this.props.showWarning && (
            <Grid
              item
              xs={12}
              data-testid="dealerList"
              className={styles.popupSection}
            >
              {this.props.dealerList.map((dealer, index) => {
                const attribute =
                  this.props.attributes &&
                  this.props.attributes[dealer.commonId];
                return (
                  <div key={index} className={styles.nonStyledListItem}>
                    {dealer.name} ({dealer.dealerId + dealer.dealerSubcode}){" "}
                    {attribute}
                  </div>
                );
              })}
            </Grid>
          )}
        </Grid>
      </EMPDialog>
    );
  }
}

export default withTranslation("emp")(ConfirmDialog);
