import React, { useState, useEffect, useContext } from "react";
import { FormControl, Box } from "@mui/material";
import EMPInputField from "../EMPInputField/EMPInputField";
import EMPButton from "../EMPButton/EMPButton";
import EMPSearchFieldRouting from "./EMPSearchFieldRouting";
import * as AnalyticsService from "../../services/analytics/AnalyticsService";
import { UserContext } from "../../context/userContext";

const EMPSearchField = ({ searchQuery: initialSearchQuery }) => {
  const { searchQuery, routeChange, updateSearchQuery } =
    EMPSearchFieldRouting();
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const user = useContext(UserContext);

  useEffect(() => {
    updateSearchQuery(initialSearchQuery);
  }, [initialSearchQuery]);

  const validator = (value) => {
    if (!value) {
      return { isValid: false, message: "Input cannot be empty." };
    }
    return { isValid: true };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validation = validator(searchQuery);
    if (!validation.isValid) {
      setError(true);
      setHelperText(validation.message);
    } else {
      setError(false);
      setHelperText("");
      routeChange(e);
      AnalyticsService.trackOnClickEvent(
        "",
        "emp:search:content:search by",
        "fv:emp:search:content action",
        user
      );
    }
  };

  return (
    <FormControl
      component="form"
      onSubmit={handleSubmit}
      error={error}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "445px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <EMPInputField
          testId="inputField"
          value={searchQuery}
          type="text"
          onChange={(e) => {
            updateSearchQuery(e.target.value);
            if (error) {
              setError(false);
              setHelperText("");
            }
          }}
          placeholder="Customer name, email, order ID, or VIN"
          error={error}
          helperText={helperText}
        />
        <Box ml={4}>
          <EMPButton testId="searchBtn" submitButton variant="contained">
            Search
          </EMPButton>
        </Box>
      </Box>
    </FormControl>
  );
};

export default EMPSearchField;
