import React from "react";
import styles from "./KeyValueField.module.scss";
import SharedToolTip from "../../sharedToolTip/SharedToolTip";
import EMPEmptyState from "../EMPEmptyState/EMPEmptyState";
import { Typography } from "@mui/material";

//Props to use
//dataKey: string (i.e Color)
//dataValue: string (i.e Blue) (empty strings and null values will be replaced with no-value-output)
//hasKeyToolTip: boolean (has a tool-tip on the left which applies to the key)
//hasValueToolTip: boolean (has a tool-tip on the right side which applies to the value)
//keyToolTipBody: string
//valueToolTipBody: string

const hasValidDataValue = (value) => value && value !== "";

const KeyValueField = (props) => {
  const { hasKeyToolTip, hasValueToolTip, keyToolTipBody, valueToolTipBody } =
    props;
  return (
    <div
      className={
        props.verticalAlignment === "center"
          ? styles.dataFieldWrapperCenterAligned
          : styles.dataFieldWrapper
      }
    >
      <div className={styles.dataKey}>
        <Typography variant="subtitle2">{props.dataKey}</Typography>
        <span className={styles.tooltipIcon}>
          {hasKeyToolTip && (
            <SharedToolTip {...props} placement="top" body={keyToolTipBody} />
          )}
        </span>
        {hasValidDataValue(props.dataValue) ? (
          <>
            <span className={styles.dataValue}>
              {props.dataValue.toString()}
            </span>
            <span className={styles.tooltipIcon}>
              {hasValueToolTip && (
                <SharedToolTip
                  {...props}
                  placement="top"
                  body={valueToolTipBody}
                />
              )}
            </span>
          </>
        ) : (
          <EMPEmptyState />
        )}
      </div>
      <span>{props.children}</span>
    </div>
  );
};

export default KeyValueField;
