import React, { Component } from "react";
import fordStyles from "./../orderDetails/NAOrderDetailsNewVersion.module.scss";
import { Accordion, Grid, Typography, AccordionSummary } from "@mui/material";
import { withTranslation } from "react-i18next";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DataCard from "../../../shared/components/DataCard/DataCard";
import KeyValueField from "../../../shared/components/DataFields/KeyValueField";
import UpperCaseText from "../../../utils/UpperCaseText";
import {
  DOC_UPLOAD_STATUS_COMPLETE,
  DOC_UPLOAD_STATUS_LOCKED,
  DOC_UPLOAD_STATUS_READY,
  PDS_STATUS_ACCEPT,
  PDS_STATUS_READY,
  PDS_STATUS_REJECT,
} from "../../../common/Constants";

class CustomerContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandCustomerContract: false,
    };
    this.preDeliverySummaryStatus = this.getPreDeliverySummaryStatus(
      this.props.preDeliverySummary
    );
    this.docUploadStatus = this.getDocUploadStatus(
      this.props.docUploadDataStatus
    );
    this.docUploadToolTipMessage = this.getDocUploadToolTipMessage(
      this.props.docUploadDataStatus
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ expandCustomerContract: this.props.expandAccordian });
    }
  }
  expandCustomerInfo = () => {
    this.setState({
      expandCustomerContract: !this.state.expandCustomerContract,
    });
  };

  getPreDeliverySummaryStatus(preDeliverySummary) {
    if (preDeliverySummary) {
      switch (preDeliverySummary.systemStatus) {
        case PDS_STATUS_READY:
          return this.props.t("ModelEOrders.pdsReadyStatus");
        case PDS_STATUS_ACCEPT:
          return this.props.t("ModelEOrders.pdsAcceptedStatus");
        case PDS_STATUS_REJECT:
          return this.props.t("ModelEOrders.pdsRejectedStatus");
        default:
          return null;
      }
    }
    return null;
  }

  getDocUploadStatus(docUploadStatus) {
    if (docUploadStatus) {
      switch (UpperCaseText(docUploadStatus)) {
        case DOC_UPLOAD_STATUS_COMPLETE:
          return this.props.t("ModelEOrders.complete");
        case DOC_UPLOAD_STATUS_READY:
          return this.props.t("ModelEOrders.ready");
        case DOC_UPLOAD_STATUS_LOCKED:
          return this.props.t("ModelEOrders.locked");
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  getDocUploadToolTipMessage(docUploadStatus) {
    if (docUploadStatus) {
      switch (UpperCaseText(docUploadStatus)) {
        case DOC_UPLOAD_STATUS_COMPLETE:
          return this.props.t("ModelEOrders.allRequestedDocumentationUploaded");
        case DOC_UPLOAD_STATUS_READY:
          return this.props.t("ModelEOrders.documentRequestSubmitted");
        case DOC_UPLOAD_STATUS_LOCKED:
          return this.props.t("ModelEOrders.documentUploadLocked");
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  render() {
    const hasDocUploadData =
      this.props.docUploadDataStatus !== undefined &&
      this.props.docUploadDataStatus !== null;
    return (
      <Accordion
        onChange={() => this.expandCustomerInfo()}
        expanded={this.state.expandCustomerContract}
      >
        <AccordionSummary
          expandIcon={<ExpandLessIcon />}
          id="exp"
          sx={{ paddingLeft: 6, paddingRight: 6 }}
        >
          <Typography variant="h4">
            {this.props.t("ModelEOrders.customerContractsAccordionHeader")}
          </Typography>
        </AccordionSummary>
        <div className={fordStyles.customerInfoGrid}>
          <Grid container>
            <Grid item className={fordStyles.cardGridItemWrapper}>
              <DataCard
                cardHeader={this.props.t(
                  "ModelEOrders.customerContractsHeader"
                )}
              >
                <KeyValueField
                  {...this.props}
                  dataKey={this.props.t("ModelEOrders.documentUpload")}
                  dataValue={this.docUploadStatus}
                  hasValueToolTip={
                    hasDocUploadData && this.docUploadToolTipMessage !== ""
                  }
                  valueToolTipBody={this.docUploadToolTipMessage}
                ></KeyValueField>
                <KeyValueField
                  dataKey={this.props.t("ModelEOrders.dealJacket")}
                  dataValue={null}
                ></KeyValueField>

                <KeyValueField
                  dataKey={this.props.t("ModelEOrders.preDeliverySummary")}
                  dataValue={this.preDeliverySummaryStatus}
                ></KeyValueField>
              </DataCard>
            </Grid>
          </Grid>
        </div>
      </Accordion>
    );
  }
}

export default withTranslation("emp")(CustomerContracts);
