const subNavStyles = {
  tab: {
    color: "white",
  },
  menuItem: {
    backgroundColor: "#102b4e",
    color: "white",
  },
  highlightedMenuItem: {
    fontSize: "14px",
    color: "white",
  },
  highlighted: {
    color: "white",
  },
  dropdown: {
    minWidth: "390px",
    width: "100%",
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",

    fontSize: 14,
    lineHeight: 1.43,
    color: "#102b4e",
  },
  modelDropDown: {
    height: 35,
    width: 250,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",

    fontSize: 14,
    lineHeight: 1.43,
    color: "#102b4e",
  },
  smallDropDown: {
    height: 35,
    width: 200,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",

    fontSize: 14,
    lineHeight: 1.43,
    color: "#102b4e",
  },
  disabledDropDown: {
    height: 35,
    width: 250,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #d8d8d8",
    backgroundColor: "white",

    fontSize: 14,
    lineHeight: 1.43,
  },

  item: {
    fontSize: 14,
    lineHeight: 1.43,
    color: "#102b4e",
  },
  nonOnboardedDealer: {
    fontSize: 14,
    lineHeight: 1.43,
    color: "#102b4e",
    background: "rgba(225, 37, 70, 0.1)",
  },
  disabledItem: {
    fontSize: 14,
    lineHeight: 1.43,
    color: "#d8d8d8",
  },
  icon: {
    top: "calc(50% - 12px)",
    right: 0,
    color: "#102b4e",
    position: "absolute",
    paddingRight: 10,
    cursor: "pointer",
    pointerEvents: "none",
  },
  lincolnIcon: {
    top: "calc(50% - 12px)",
    right: 0,
    color: "#f26147",
    position: "absolute",
    paddingRight: 10,
    cursor: "pointer",
    pointerEvents: "none",
  },
  disabledIcon: {
    top: "calc(50% - 12px)",
    right: 0,
    color: "#d8d8d8",
    position: "absolute",
    paddingRight: 10,
    cursor: "pointer",
    pointerEvents: "none",
  },
  hide: {
    display: "none",
  },
  disabledTab: {
    color: "#979797",
    whiteSpace: "nowrap",
    maxWidth: "none",
    cursor: "default",
  },
};

export default subNavStyles;
