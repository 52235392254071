import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import TranslationsClient from "./clients/TranslationsClient.js";
import en_ford from "./shared/translations/en_ford";
import fr_CA from "./shared/translations/fr_CA";

const waitForToken = (key, interval = 100, timeout = 10000) => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    const checkToken = () => {
      const token = sessionStorage.getItem(key);
      if (token) {
        resolve(token);
      } else if (Date.now() - startTime >= timeout) {
        reject(new Error("Timeout waiting for token"));
      } else {
        setTimeout(checkToken, interval);
      }
    };

    checkToken();
  });
};

const languageUrl = TranslationsClient.getTranslationUrl();

const mapLanguageCode = (lng) => {
  const languageMap = {
    en: "fd_USA",
  };
  return languageUrl + languageMap[lng] || languageUrl + lng;
};

const runI18n = (initObj) => {
  // eslint-disable-next-line import/no-named-as-default-member
  i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(initObj);
};

const initI18n = async () => {
  try {
    const token = await waitForToken("adfs.token");
    // eslint-disable-next-line import/no-named-as-default-member
    runI18n({
      fallbackLng: "en",
      supportedLngs: ["en", "fr"],
      load: "languageOnly",
      debug: true,
      backend: {
        loadPath: mapLanguageCode("en"),
        customHeaders: () => {
          return {
            authorization: `Bearer ${token}`,
          };
        },
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error initializing i18n.  Loading fallback.", error);
    runI18n({
      fallbackLng: "en",
      supportedLngs: ["en", "fr"],
      load: "languageOnly",
      debug: true,
      resources: {
        en: {
          emp: en_ford,
        },
        fr: {
          emp: fr_CA,
        },
      },
    });
  }
};

initI18n();

export default i18n;
