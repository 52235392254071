import EMPEmptyState from "../../../shared/components/EMPEmptyState/EMPEmptyState";
import fordStyles from "./NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "./NAOrderDetailsNewVersionLincoln.module.scss";
import React from "react";
import { Typography } from "@mui/material";

export const InfoDetail = ({
  header,
  value,
  headerTooltip,
  tooltip,
  upperCase = true,
  isFordBrand = true,
}) => {
  const styles = isFordBrand ? fordStyles : lincolnStyles;

  return (
    <>
      <div>
        <Typography variant="subtitle2">{header}</Typography>
        <span className={styles.iconContainer}>{headerTooltip}</span>
      </div>
      <div
        className={
          upperCase ? styles.itemValueText : styles.itemValueCapitalizeText
        }
      >
        {value || <EMPEmptyState leftAligned />}
        {value && tooltip}
      </div>
    </>
  );
};
