import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { formatPriceValue } from "../../../../utils/FormatPrice";
import {
  StyledBigTableHeader,
  StyledTableHeader,
  StyledTableRow,
} from "../../../../shared/Tables/TableRow";
import SharedToolTip from "../../../../shared/sharedToolTip/SharedToolTip";
import {
  FINANCE_PRODUCT_TYPE,
  PIPELINE,
  PURCHASE_TYPES,
} from "../../../../common/Constants";
import LowerCaseText from "../../../../utils/LowerCaseText";
import fordStyles from "../../orderDetails/NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "../../orderDetails/NAOrderDetailsNewVersionLincoln.module.scss";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import EMPEmptyState from "../../../../shared/components/EMPEmptyState/EMPEmptyState";
import UpperCaseText from "../../../../utils/UpperCaseText";
import {
  Accordion,
  AccordionSummary,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import EMPAccordion from "../../../../shared/components/EMPAccordion/EMPAccordion";

class PricingSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricingSummaryExpansion: true,
      dealerPriceExpansion: false,
      totalEstimatedTaxAndFees: false,
      estTotalDue: false,
      capitalizedCostReduction: false,
      totalFundsExpansion: false,
    };
    this.pricingItems = {
      vehicleDealer: [
        "baseMSRP",
        "totalOptions",
        "destinationDeliveryCharges",
        "totalMSRP",
        "dealerAdjustmentPrice",
        "totalAXZPlanPricing",
      ],
      additions: [
        "chargingPriceTotal",
        "accessoriesPriceTotal",
        "protectionPlanTotal",
        "purchaseDeposit",
        "downPayment",
        "tradeInPrice",
        "totalIncentivesPrice",
        "totalPreTaxPrice",
      ],
      listOfFees: [
        { name: "totalEstimatedTaxAndFees", list: "taxesAndFees" },
        { name: "estTotalDue", list: "vlcTaxesAndFees" },
      ],
      fees: [
        { name: "regLicTitleFeesTotal", list: "regLicTitleFees" },
        { name: "otherTaxesTotal", list: "otherFees" },
      ],
    };

    this.index = 1;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ pricingSummaryExpansion: this.props.expandAccordian });
    }
  }

  flipIcon() {
    this.setState({
      pricingSummaryExpansion: !this.state.pricingSummaryExpansion,
    });
  }

  expandDealerPrice() {
    this.setState({ dealerPriceExpansion: !this.state.dealerPriceExpansion });
  }

  expandAccordion(name) {
    this.setState({ [name]: !this.state[name] });
  }

  getValue(value, type) {
    const data = this.props.pricingStack;
    if (data && data[type]) return data[type][value];
  }

  getCommonPricingDetails = (data) => {
    const allFeatures =
      data &&
      data.userSelectedFeatures &&
      data.userSelectedFeatures.concat(data.includedFeatures);

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    return (
      <div className={styles.specsContainer}>
        <StyledTableRow
          text={this.props.t("OrderDetailsPricingSummary.baseMSRP")}
          data={formatPriceValue(data.baseMSRP)}
          index={++this.index}
        />
        <Accordion
          defaultExpanded={true}
          onChange={() => this.expandAccordion("dealerPriceExpansion")}
        >
          <AccordionSummary>
            <div className={styles.headerBar}>
              <div className={styles.regularHeader}>
                {this.props.t("OrderDetailsPricingSummary.totalOptions")}
              </div>
              <div className={styles.acceptRejectDiv}>
                <div className={styles.regularHeaderValue}>
                  {formatPriceValue(data && data.totalOptions)}
                </div>
                {!this.state.dealerPriceExpansion ? (
                  <ExpandLessIcon data-testid="more" />
                ) : (
                  <ExpandMoreIcon data-testid="less" />
                )}
              </div>
            </div>
          </AccordionSummary>
          {allFeatures &&
            allFeatures
              .filter((feature) => !!feature)
              .map((feature, index) => (
                <StyledTableRow
                  key={index}
                  text={feature.type}
                  indent
                  data={formatPriceValue(feature.value)}
                  index={++this.index}
                />
              ))}
        </Accordion>
        <StyledTableRow
          text={this.props.t(
            "OrderDetailsPricingSummary.destinationDeliveryCharges"
          )}
          data={formatPriceValue(data.destinationDeliveryCharges)}
          index={++this.index}
        />
        <StyledTableRow
          text={
            this.props.isPurchaseRequest
              ? this.props.t("OrderDetailsPricingSummary.totalMsrp")
              : this.props.t("OrderDetailsPaymentMethod.estimatedTotalMsrp")
          }
          data={formatPriceValue(data.totalMSRP)}
          index={++this.index}
        />
      </div>
    );
  };

  getDownPayment = () => {
    let downPayment = this.props.t("PurchaseDetails.paymentMethod.downPayment");

    if (this.props.isDownPaymentEnabled) {
      downPayment += this.props.t(
        "PurchaseDetails.paymentMethod.authorizedBelow"
      );
    } else {
      downPayment += this.props.t("PurchaseDetails.paymentMethod.notCollected");
    }

    return downPayment;
  };

  getFeesLists = (item, data, paymentType) => {
    const docFeeInTaxesAndFees =
      data && data.taxesAndFees.docFeeName && data.taxesAndFees.docFee;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    return (
      <Accordion
        defaultExpanded={false}
        onChange={() => this.expandAccordion(item.name)}
        elevation={0}
      >
        <AccordionSummary>
          <div className={styles.headerBar}>
            <div>
              <Typography
                variant="h5"
                display="inline"
                sx={{ mr: 2 }}
                color="text.primary"
              >
                {this.props.t(`OrderDetailsPricingSummary.${item.name}`)}
              </Typography>

              {item.name === "estTotalDue" && (
                <SharedToolTip
                  {...this.props}
                  openTestid={item.name}
                  closeTestid={item.name}
                  body={this.props.t(
                    `OrderDetailsConsumerDeposit.${item.name}TooltipBody`
                  )}
                />
              )}
            </div>
            <div className={styles.acceptRejectDiv}>
              <Typography variant="h5" color="text.primary">
                {formatPriceValue(
                  this.getValue("totalEstimatedTaxAndFees", item.list)
                )}
              </Typography>
              {this.state[item.name] ? (
                <ExpandLessIcon data-testid="less" />
              ) : (
                <ExpandMoreIcon data-testid="more" />
              )}
            </div>
          </div>
        </AccordionSummary>

        <StyledTableRow
          text={this.props.t("OrderDetailsPricingSummary.taxes")}
          indent
          data={formatPriceValue(this.getValue("taxes", item.list))}
          index={++this.index}
        />

        {LowerCaseText(paymentType) === FINANCE_PRODUCT_TYPE.LEASE &&
          item.list === "taxesAndFees" && (
            <StyledTableRow
              text={this.props.t("OrderDetailsPricingSummary.acquisitionFee")}
              indent
              data={formatPriceValue(data && data["acquisitionFee"])}
              index={++this.index}
            />
          )}

        {item.name === "totalEstimatedTaxAndFees" &&
          docFeeInTaxesAndFees &&
          this.getValue("docFee", item.list) && (
            <StyledTableRow
              text={this.getValue("docFeeName", item.list)}
              indent
              data={formatPriceValue(this.getValue("docFee", item.list))}
              index={++this.index}
            />
          )}

        {this.pricingItems.fees.map((specificFees, index) => {
          return (
            <Accordion
              key={index}
              onChange={() => this.expandAccordion(specificFees.name)}
              elevation={0}
            >
              <AccordionSummary>
                <div className={styles.headerBar}>
                  <Typography variant="h5" color="text.primary">
                    {this.props.t(
                      `OrderDetailsPricingSummary.${specificFees.name}`
                    )}
                  </Typography>
                  <div className={styles.acceptRejectDiv}>
                    <Typography variant="h5" color="text.primary">
                      {formatPriceValue(data[item.list][specificFees.name])}
                    </Typography>
                    {this.state[specificFees.name] ? (
                      <ExpandLessIcon data-testid="less" />
                    ) : (
                      <ExpandMoreIcon data-testid="more" />
                    )}
                  </div>
                </div>
              </AccordionSummary>

              {data[item.list][specificFees.list] &&
                data[item.list][specificFees.list].map((fee, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      text={fee.name}
                      indent
                      data={formatPriceValue(fee.amount, item.list)}
                      index={++this.index}
                    />
                  );
                })}
            </Accordion>
          );
        })}
      </Accordion>
    );
  };

  getCapitalizedCostReductionAccordion = (data) => {
    const { customerReservation } = this.props;

    const styles = fordStyles;

    const orderDeposit =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.depositAmount;

    const orderDepositRefund =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.refundAmount;

    const orderDepositRefundDealerAction =
      customerReservation && customerReservation.orderDepositRefundDealerAction;

    const reservationDeposit =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.depositAmount;

    const reservationDepositRefund =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.refundAmount;

    const purchaseDeposit =
      customerReservation &&
      customerReservation.vehicleHoldDepositInfo &&
      customerReservation.vehicleHoldDepositInfo.depositAmount;

    const getReservationDepositInfo = () => {
      let amt = "";
      if (reservationDeposit) {
        amt = reservationDeposit;
      }

      if (!reservationDeposit && reservationDepositRefund) {
        amt = "$0.00";
      }

      return amt;
    };

    return (
      <Accordion
        elevation={0}
        onChange={() => this.expandAccordion("capitalizedCostReduction")}
      >
        <AccordionSummary>
          <div className={styles.headerBar}>
            <Typography variant="h5" color="text.primary">
              {this.props.t(
                `OrderDetailsPricingSummary.capitalizedCostReduction`
              )}
            </Typography>
            <div className={styles.acceptRejectDiv}>
              <Typography variant="h5" color="text.primary">
                {formatPriceValue(data && data["capitalizedCostReduction"])}
              </Typography>
              {this.state.capitalizedCostReduction ? (
                <ExpandLessIcon data-testid="less" />
              ) : (
                <ExpandMoreIcon data-testid="more" />
              )}
            </div>
          </div>
        </AccordionSummary>
        {getReservationDepositInfo() !== "" && (
          <StyledTableRow
            text={this.props.t("OrderDetailsPricingSummary.reservationDeposit")}
            indent
            data={getReservationDepositInfo()}
            index={++this.index}
          />
        )}
        {!orderDeposit && orderDepositRefundDealerAction && (
          <StyledTableRow
            text={this.props.t("OrderDetailsPaymentMethod.orderDeposit")}
            indent
            data={
              orderDepositRefund
                ? "$0.00"
                : orderDeposit
                ? orderDeposit
                : "$0.00"
            }
            index={++this.index}
          />
        )}
        {orderDeposit && (
          <StyledTableRow
            text={this.props.t("OrderDetailsPaymentMethod.orderDeposit")}
            indent
            data={
              orderDepositRefund
                ? "$0.00"
                : orderDeposit
                ? orderDeposit
                : "$0.00"
            }
            index={++this.index}
          />
        )}
        {!this.props.isModelEOrder && (
          <StyledTableRow
            text={this.getDownPayment()}
            indent
            data={formatPriceValue(
              customerReservation &&
                customerReservation.financeDataQuote &&
                customerReservation.financeDataQuote["deposit"]
            )}
            index={++this.index}
          />
        )}

        {this.props.isModelEOrder && (
          <>
            <StyledTableRow
              text={this.props.t("OrderDetailsPricingSummary.purchaseDeposit")}
              indent
              data={purchaseDeposit}
              index={++this.index}
            />
            <StyledTableRow
              text={this.props.t("PurchaseDetails.paymentMethod.downPayment")}
              indent
              data={formatPriceValue(
                customerReservation &&
                  customerReservation.financeDataQuote &&
                  customerReservation.financeDataQuote["deposit"]
              )}
              index={++this.index}
            />
          </>
        )}
        <StyledTableRow
          text={this.props.t("OrderDetailsPricingSummary.totalIncentivesPrice")}
          indent
          data={formatPriceValue(data && data["totalIncentivesPrice"])}
          index={++this.index}
        />
        <StyledTableRow
          text={this.props.t(
            "OrderDetailsPricingSummary.estimatedNetTradeInAmount"
          )}
          indent
          data={formatPriceValue(data && data["tradeInPrice"])}
          index={++this.index}
        />
      </Accordion>
    );
  };

  render() {
    this.index = 0;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const data = this.props.pricingStack;
    const amountFinanced = this.props.amountFinanced;
    const paymentType = this.props.paymentType;
    const totalEstimatedTaxesAndFees = this.pricingItems.listOfFees[0];
    const estTotalDue = this.pricingItems.listOfFees[1];

    const { customerReservation } = this.props;

    const orderDeposit =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.depositAmount;

    const orderDepositRefund =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.refundAmount;

    const reservationDeposit =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.depositAmount;

    const reservationDepositRefund =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.refundAmount;

    const isOffline =
      customerReservation &&
      customerReservation.orderChannel &&
      customerReservation.orderChannel === "InStore" ? (
        <span className={styles.headerStatus}>
          {this.props.t(
            "OrderDetailsPricingSummary.offlineOrderPricingMessage"
          )}
        </span>
      ) : null;

    let totalFundsHeld = 0;
    if (orderDeposit) {
      totalFundsHeld += customerReservation.orderPaymentInfo.depositAmountInt;
    }
    if (orderDepositRefund) {
      totalFundsHeld -= customerReservation.orderPaymentInfo.refundAmountInt;
    }
    if (reservationDeposit) {
      totalFundsHeld +=
        customerReservation.reservationPaymentInfo.depositAmountInt;
    }
    if (reservationDepositRefund) {
      totalFundsHeld -=
        customerReservation.reservationPaymentInfo.refundAmountInt;
    }

    const purchaseTypeIsValid =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType;

    const purchaseTypeIsSignAtDealer =
      purchaseTypeIsValid &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.SIGN_AT_DEALER;

    const purchaseTypeIsSignOnline =
      purchaseTypeIsValid &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.SIGN_ONLINE;

    const showEstimatedTaxesAndFees = !(
      this.props.isModelEOrder &&
      (purchaseTypeIsSignAtDealer || purchaseTypeIsSignOnline) &&
      (this.props.customerReservation.financeDataQuote.financeProductType ===
        FINANCE_PRODUCT_TYPE.FINANCE ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.OTHER ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.FORD_OPTIONS ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.CASH)
    );

    const showPreTaxPrice = !(
      this.props.isModelEOrder && purchaseTypeIsSignOnline
    );

    const docFeeInDealerSellingPrice =
      data &&
      data.dealerSellingPriceDocFeeName &&
      data.dealerSellingPriceDocFee;

    const hasDownPayment =
      this.props.customerReservation &&
      this.props.customerReservation.financeDataQuote &&
      this.props.customerReservation.financeDataQuote.deposit;

    const hasPurchaseDeposit =
      this.props.customerReservation &&
      this.props.customerReservation.vehicleHoldDepositInfo &&
      this.props.customerReservation.vehicleHoldDepositInfo.depositAmount;

    const showFederalTaxCredit =
      this.props.isModelEOrder &&
      data &&
      data.federalTaxCreditAmount !== null &&
      this.props.paymentType !== FINANCE_PRODUCT_TYPE.LEASE;

    return (
      <EMPAccordion
        heading={this.props.t("OrderDetailsPricingSummary.title")}
        subHeading={isOffline ? isOffline : ""}
        onChange={() => this.flipIcon()}
        expanded={this.state.pricingSummaryExpansion}
        data-testid="pricingDropDown"
        elevation={0}
      >
        {!this.props.isPurchaseRequest ? (
          this.props.isOrder ? (
            <div>
              {this.getCommonPricingDetails(data)}
              <StyledTableRow
                text={this.props.t(
                  "OrderDetailsPricingSummary.dealerAdjustmentPrice"
                )}
                data={formatPriceValue(data.dealerAdjustmentPrice)}
                index={++this.index}
              />
              {data.dealerSellingPriceDocFee && (
                <StyledTableRow
                  text={data.dealerSellingPriceDocFeeName}
                  data={formatPriceValue(data.dealerSellingPriceDocFee)}
                  index={++this.index}
                />
              )}
              <StyledTableRow
                text={this.props.t(
                  "OrderDetailsPricingSummary.accessoriesPriceTotal"
                )}
                data={formatPriceValue(data.accessoriesPriceTotal)}
                index={++this.index}
              />
              <StyledTableRow
                text={this.props.t(
                  "OrderDetailsPricingSummary.totalIncentivesPrice"
                )}
                data={formatPriceValue(data.totalIncentivesPrice)}
                index={++this.index}
              />

              <StyledBigTableHeader
                text={this.props.t(
                  "OrderDetailsPricingSummary.dealerSellingPrice"
                )}
                data={formatPriceValue(data.promotionalDealerPrice)}
              />
              {(orderDeposit || reservationDeposit) && (
                <Accordion
                  defaultExpanded
                  onChange={() => this.expandAccordion("totalFundsExpansion")}
                  elevation={0}
                >
                  <AccordionSummary>
                    <div className={styles.headerBar}>
                      <div className={styles.regularHeader}>
                        {this.props.t(
                          "OrderDetailsPricingSummary.totalFundsHeldByDealer"
                        )}
                      </div>
                      <div className={styles.acceptRejectDiv}>
                        <div className={styles.regularHeaderValue}>
                          {formatPriceValue(totalFundsHeld)}
                        </div>
                        {!this.state.totalFundsExpansion ? (
                          <ExpandLessIcon data-testid="more" />
                        ) : (
                          <ExpandMoreIcon data-testid="less" />
                        )}
                      </div>
                    </div>
                  </AccordionSummary>

                  {reservationDeposit && (
                    <StyledTableRow
                      text={this.props.t(
                        "OrderDetailsPaymentMethod.reservationDeposit"
                      )}
                      indent
                      data={
                        reservationDepositRefund ? "$0.00" : reservationDeposit
                      }
                      index={++this.index}
                    />
                  )}

                  {orderDeposit && (
                    <StyledTableRow
                      text={this.props.t(
                        "OrderDetailsPricingSummary.orderDeposit"
                      )}
                      indent
                      data={orderDepositRefund ? "$0.00" : orderDeposit}
                      index={++this.index}
                    />
                  )}
                </Accordion>
              )}
            </div>
          ) : (
            <>{this.getCommonPricingDetails(data)}</>
          )
        ) : (
          <>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {this.props.t("OrderDetailsPricingSummary.totalDealerPrice")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {formatPriceValue(data && data.totalDealerPrice)}
                </Typography>
              </Grid>
            </Grid>

            {this.pricingItems.vehicleDealer.map((item, index) => {
              return (
                <StyledTableRow
                  text={this.props.t(`OrderDetailsPricingSummary.${item}`)}
                  indent
                  data={formatPriceValue(data && data[item])}
                  index={++this.index}
                  key={index}
                />
              );
            })}

            {data && docFeeInDealerSellingPrice && (
              <StyledTableRow
                text={data["dealerSellingPriceDocFeeName"]}
                indent
                data={formatPriceValue(
                  data && data["dealerSellingPriceDocFee"]
                )}
                index={++this.index}
              />
            )}

            <StyledTableRow
              text={this.props.t("OrderDetailsPricingSummary.totalAddOnsPrice")}
              indent
              data={formatPriceValue(data && data["totalAddOnsPrice"])}
              index={++this.index}
            />

            {this.pricingItems.additions.map((item, index) => {
              return (
                (showPreTaxPrice ||
                  (!showPreTaxPrice && !(item === "totalPreTaxPrice"))) && (
                  <StyledTableRow
                    text={this.props.t(`OrderDetailsPricingSummary.${item}`)}
                    data={
                      data && Object.hasOwn(data, item) ? (
                        formatPriceValue(data && data[item])
                      ) : item === "downPayment" && hasDownPayment ? (
                        formatPriceValue(
                          this.props.customerReservation.financeDataQuote
                            .deposit
                        )
                      ) : item === "purchaseDeposit" && hasPurchaseDeposit ? (
                        this.props.customerReservation.vehicleHoldDepositInfo
                          .depositAmount
                      ) : (
                        <EMPEmptyState />
                      )
                    }
                    index={++this.index}
                    key={index}
                  />
                )
              );
            })}

            <div>
              {LowerCaseText(paymentType) === FINANCE_PRODUCT_TYPE.LEASE && (
                <>
                  {data &&
                    data[totalEstimatedTaxesAndFees.list] &&
                    showEstimatedTaxesAndFees &&
                    Boolean(
                      data[totalEstimatedTaxesAndFees.list]
                        .totalEstimatedTaxAndFees
                    ) &&
                    this.getFeesLists(
                      totalEstimatedTaxesAndFees,
                      data,
                      paymentType
                    )}
                  <StyledTableHeader
                    text={this.props.t(
                      "OrderDetailsPricingSummary.estimatedGrossCapitalizedCost"
                    )}
                    data={
                      this.props.isModelEOrder &&
                      UpperCaseText(customerReservation.subJourneyType) ===
                        PIPELINE
                        ? formatPriceValue(
                            customerReservation?.financeDataQuote
                              ?.estimatedGrossCapitalizedCost
                          )
                        : formatPriceValue(
                            data && data["estimatedGrossCapitalizedCost"]
                          )
                    }
                  />

                  {this.getCapitalizedCostReductionAccordion(data)}
                  <StyledTableHeader
                    text={this.props.t(
                      "OrderDetailsPricingSummary.estimatedAdjustedCapitalizedCost"
                    )}
                    data={
                      this.props.isModelEOrder &&
                      UpperCaseText(customerReservation.subJourneyType) ===
                        PIPELINE
                        ? formatPriceValue(
                            customerReservation?.financeDataQuote
                              ?.estimatedAdjustedCapitalizedCost
                          )
                        : formatPriceValue(
                            data && data["estimatedAdjustedCapitalizedCost"]
                          )
                    }
                  />
                  {data &&
                    data[estTotalDue.list] &&
                    showEstimatedTaxesAndFees &&
                    Boolean(data[estTotalDue.list].totalEstimatedTaxAndFees) &&
                    this.getFeesLists(estTotalDue, data, paymentType)}
                </>
              )}

              {LowerCaseText(paymentType) !== FINANCE_PRODUCT_TYPE.LEASE && (
                <>
                  {this.pricingItems.listOfFees.map((item, index) => {
                    return (
                      <div key={index}>
                        {data &&
                          data[item.list] &&
                          showEstimatedTaxesAndFees &&
                          Boolean(data[item.list].totalEstimatedTaxAndFees) &&
                          this.getFeesLists(item, data, paymentType)}
                      </div>
                    );
                  })}
                  {showFederalTaxCredit && (
                    <StyledTableRow
                      text={this.props.t(
                        "OrderDetailsPricingSummary.federalTaxCredit"
                      )}
                      data={formatPriceValue(data.federalTaxCreditAmount)}
                      index={0}
                    />
                  )}
                  {LowerCaseText(paymentType) !== FINANCE_PRODUCT_TYPE.CASH &&
                    Boolean(amountFinanced) && (
                      <StyledBigTableHeader
                        text={this.props.t(
                          "OrderDetailsPricingSummary.estAmtFinanced"
                        )}
                        data={formatPriceValue(amountFinanced)}
                      />
                    )}
                </>
              )}

              {LowerCaseText(paymentType) === FINANCE_PRODUCT_TYPE.CASH && (
                <Box sx={{ borderTop: "2px solid black", mt: 4, pt: 4 }}>
                  <StyledBigTableHeader
                    text={this.props.t(
                      "OrderDetailsPricingSummary.estimatedCashDueAtDealer"
                    )}
                    data={
                      data && data.totalPriceWithAddOns ? (
                        formatPriceValue(data.totalPriceWithAddOns)
                      ) : (
                        <EMPEmptyState />
                      )
                    }
                  />
                </Box>
              )}
            </div>
          </>
        )}
      </EMPAccordion>
    );
  }
}

export default withTranslation("emp")(PricingSummary);
