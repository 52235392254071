import React from "react";
import { Typography, Box } from "@mui/material";
import EMPDataCard from "../../shared/components/EMPDataCard/EMPDataCard";
import { formatPriceValue } from "../../utils/FormatPrice";
import { useTranslation } from "react-i18next";

const ProtectionPlan = ({ protectionPlansData }) => {
  const { t } = useTranslation();

  const formatDeductibleString = (deductibleStr) => {
    if (deductibleStr) {
      const cleanedStr = deductibleStr.replace(/[$,]/g, "");
      const number = parseFloat(cleanedStr);
      return `$${number}`;
    }
  };

  const getDeductibleDisplay = (item) => {
    if (item.deductible !== null) {
      return formatPriceValue(item.deductible, "--");
    } else if (item.deductibleStr !== null) {
      return formatDeductibleString(item.deductibleStr);
    } else {
      return "--";
    }
  };

  return (
    <>
      <Typography variant="caption" component="p" sx={{ mb: 2 }}>
        {t("vehicle.protectionPlans.fields.0.heading")}
      </Typography>
      <EMPDataCard
        data={[
          {
            heading: t("vehicle.protectionPlans.fields.0.fields.0"),
            content: "New Vehicle Limited Warranty",
          },
        ]}
      />
      {protectionPlansData && protectionPlansData.protectionPlans && (
        <>
          <Typography variant="caption" component="p" sx={{ mt: 6, mb: 2 }}>
            {t("vehicle.protectionPlans.fields.1.heading")}
          </Typography>
          {protectionPlansData.protectionPlans.map((item) => (
            <Box sx={{ mb: 4 }} key={item.planName}>
              <EMPDataCard
                data={[
                  {
                    heading: t("vehicle.protectionPlans.fields.1.fields.0"),
                    content: item.planName,
                  },
                  {
                    heading: t("vehicle.protectionPlans.fields.1.fields.1"),
                    content: formatPriceValue(item.dealerSellingPrice, "--"),
                  },
                  {
                    heading: t("vehicle.protectionPlans.fields.1.fields.2"),
                    content: `${item.term ? item.term + " MO" : "--"}`,
                  },
                  {
                    heading: t("vehicle.protectionPlans.fields.1.fields.3"),
                    content: `${
                      item.annualMileage
                        ? parseFloat(item.annualMileage).toLocaleString() +
                          " Miles"
                        : "--"
                    }`,
                  },
                  {
                    heading: t("vehicle.protectionPlans.fields.1.fields.4"),
                    content: getDeductibleDisplay(item),
                  },
                ]}
              />
            </Box>
          ))}
        </>
      )}
    </>
  );
};

export default ProtectionPlan;
