import { useState } from "react";

export default () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerHeading, setDrawerHeading] = useState("Heading");
  const [drawerContent, setDrawerContent] = useState("Content");

  const handleDrawer = (heading = false, content = false) => {
    setOpenDrawer(!openDrawer);
    if (heading) {
      setDrawerHeading(heading);
    }
    if (content) {
      setDrawerContent(content);
    }
  };

  return {
    openDrawer,
    handleDrawer,
    drawerHeading,
    drawerContent,
  };
};
