import React from "react";
import fordStyles from "./NACustomerOrders.module.scss";
import lincolnStyles from "./NACustomerOrdersLincoln.module.scss";
import HasPermissionTo from "../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";
import ModelESearchOrders from "./tabs/ModelESearchOrders";
import { Typography } from "@mui/material";

const SearchResults = (props) => {
  const styles = IS_FORD_BRAND(props.user.brand) ? fordStyles : lincolnStyles;

  return (
    <HasPermissionTo
      perform={["modelETab"]}
      permissions={props.user.permissions.rolePermissions}
      render={() => {
        return (
          <div id="Modele" className={styles.resultsWrapper}>
            <ModelESearchOrders {...props} noContentText="noResults" />
          </div>
        );
      }}
      noRender={() => {
        return (
          <Typography variant="body1" component="p">
            {props.t("CustomerOrders.noResultsFound")}
          </Typography>
        );
      }}
    />
  );
};

export default withTranslation("emp")(SearchResults);
