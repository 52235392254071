import { useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";
import { UserContext } from "../context/userContext";

export async function usePricingStack(orderId) {
  const { market, dealerId, salesCode, dealerBrand } = useContext(UserContext);
  const userCommonId = `${market}|${dealerId}|${salesCode}|${dealerBrand}`;
  const queryClient = useQueryClient();
  // Ensure pricing stack data is fetched and available
  // if not, it will call async and put the data in cache
  const pricingStack = await queryClient.ensureQueryData({
    queryKey: ["pricingStack", { orderId }],
    queryFn: () =>
      CustomerReservationsClient.getPricingStack(
        sessionStorage.getItem("adfs.token"),
        orderId,
        market,
        userCommonId,
        "en"
      ),
    staleTime: 60 * 1000,
  });

  return pricingStack;
}
