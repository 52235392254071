import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import SharedToolTip from "../../../shared/sharedToolTip/SharedToolTip";

const PriceList = ({ amounts, netEquity, t }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        {amounts.map((item) => (
          <React.Fragment key={item.heading}>
            <Grid item xs={7}>
              <Typography variant="body1" sx={{ mr: 1 }} display="inline">
                {item.heading}
              </Typography>
              {item.tooltip && <SharedToolTip body={item.tooltip.body} />}
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1" align="right">
                {typeof item.amount === "number"
                  ? `$${item.amount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : "--"}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {netEquity && (
        <>
          <Divider color="black" sx={{ my: 2, borderBottomWidth: 2 }} />
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6">{t("tradeIn.netEquity")}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" align="right">
                {`$${netEquity}`}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default PriceList;
