const fileDownload = (fileContent, fileType, fileName, id) => {
  const blob = new Blob([fileContent], { type: fileType });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      if (id) link.setAttribute("id", id);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const base64FileDownload = (data, fileType, fileName) => {
  const binaryString = atob(
    data.replace("data:application/pdf;base64,", "").replace(/\s/g, "")
  );
  const binaryLen = binaryString.length;
  const buffer = new ArrayBuffer(binaryLen);
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  fileDownload(bytes, fileType, fileName);
};

const base64OpenInNewTab = (data, fileType) => {
  const binaryString = atob(
    data.replace("data:application/pdf;base64,", "").replace(/\s/g, "")
  );
  const binaryLen = binaryString.length;
  const buffer = new ArrayBuffer(binaryLen);
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  openInNewTab(bytes, fileType);
};

const openInNewTab = (data, fileType) => {
  const file = new Blob([data], { type: fileType });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, "windowsticker", "noopener,noreferrer");
};

export { fileDownload, base64FileDownload, openInNewTab, base64OpenInNewTab };
