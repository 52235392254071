import React from "react";
import { SvgIcon } from "@mui/material";

const MotionChevronRight = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M16.8 12L9.6 4.79999L8.76 5.63999L15.12 12L8.76 18.36L9.6 19.2L16.8 12Z" />
    </SvgIcon>
  );
};

export default MotionChevronRight;
