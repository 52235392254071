import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogActions, Grid, Typography, Box } from "@mui/material";
import EMPButton from "../../EMPButton/EMPButton";
import AcceptPurchaseDeposit from "./AcceptPurchaseDeposit";
import RejectPurchaseDeposit from "./RejectPurchaseDeposit";

const PurchaseDepositIntro = ({ handleDecision }) => {
  const handleClick = (decision) => {
    return handleDecision(decision);
  };
  return (
    <div>
      <Box>
        <Typography variant="body1" sx={{ mb: 6 }}>
          Accept or reject this customers purchase deposit. You can modify this
          later in the Financing tab.
        </Typography>
      </Box>

      <DialogActions sx={{ padding: "16px 0" }}>
        <Grid container justifyContent="flex-end" spacing={4}>
          <Grid item>
            <EMPButton variant="outlined" onClick={() => handleClick("cancel")}>
              Reject Purchase Deposit
            </EMPButton>
          </Grid>
          <Grid item>
            <EMPButton
              variant="contained"
              onClick={() => handleClick("accept")}
            >
              Accept Purchase Deposit
            </EMPButton>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

const PurchaseDepositDecision = ({ orderId }) => {
  const [decision, setDecision] = useState(null);
  const { t } = useTranslation();

  if (decision === "accept") {
    return (
      <AcceptPurchaseDeposit
        orderId={orderId}
        backButton
        handleBack={() => setDecision(null)}
      />
    );
  } else if (decision === "cancel") {
    return (
      <RejectPurchaseDeposit
        orderId={orderId}
        backButton
        handleBack={() => setDecision(null)}
      />
    );
  } else {
    return (
      <PurchaseDepositIntro
        t={t}
        handleDecision={(decision) => setDecision(decision)}
      />
    );
  }
};

export default PurchaseDepositDecision;
