import React from "react";
import useDrawer from "./UseDrawer";
import EMPDrawer from "./EMPDrawer";

let DrawerContext;
const { Provider } = (DrawerContext = React.createContext());

const DrawerProvider = ({ children }) => {
  const { openDrawer, handleDrawer, drawerContent, drawerHeading } =
    useDrawer();
  return (
    <Provider
      value={{
        openDrawer,
        handleDrawer,
        drawerContent,
        drawerHeading,
      }}
    >
      <EMPDrawer />
      {children}
    </Provider>
  );
};

export { DrawerContext, DrawerProvider };
