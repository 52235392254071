import React from "react";
import { TextField } from "@mui/material";
import UpperCaseText from "../../../utils/UpperCaseText";

const EMPInputField = React.forwardRef((props, ref) => {
  const {
    type,
    placeholder,
    validationState,
    value,
    testId,
    disabled,
    readOnly,
    error,
    onChange,
    onClick,
    name,
    multiline,
    helperText,
    startAdornment,
  } = props;

  return (
    <TextField
      InputProps={{
        readOnly,
        disableUnderline: true,
        startAdornment,
      }}
      inputProps={{
        "data-testid": testId,
      }}
      type={type}
      value={value}
      error={UpperCaseText(validationState) === "ERROR" || error}
      color={
        UpperCaseText(validationState) === "SUCCESS" ? "secondary" : "primary"
      }
      FormHelperTextProps={{
        error: UpperCaseText(validationState) === "ERROR" || error,
      }}
      placeholder={placeholder}
      fullWidth
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
      ref={ref}
      name={name}
      multiline={multiline}
      helperText={helperText}
      variant="standard"
    ></TextField>
  );
});
export default EMPInputField;
