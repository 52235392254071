import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubNav from "../../shared/subnav/SubNav";
import BrandSettings from "./brandSettings/BrandSettings";
import styles from "./AccessoriesWrapper.module.scss";
import { BRANDS } from "./Brands";
import ConfirmDialog from "../../shared/confirmDialog/ConfirmDialog";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";
import NamePlateSettings from "./namePlateSettings/NamePlateSettings";
import HasPermissionTo from "../../common/HasPermissionTo";
import AccessoriesSearch from "./search/AccessoriesSearch";
import CategorySettings from "./categorySettings/CategorySettings";

class AccessoriesWrapper extends Component {
  constructor(props) {
    super(props);

    const selectedTab = this.getSelectedTab(props);
    this.state = {
      selectedTab: selectedTab,
      isDataLoaded: false,
      enableTabs: selectedTab > 0,
      showWarning: false,
      selectedDisabledTab: null,
      confirmDialog: false,
    };
    this.restrictDropdown = this.props.user.dealerGroup.length < 2;
    this.isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    this.newTabChange = null;
    this.confirmDialogProps = {
      title: this.props.t("BrandSettings.dialog.confirm.warningTitle"),
      bodyText: this.props.t("BrandSettings.dialog.confirm.warning"),
      confirm: this.isFordBrand
        ? this.props.t("BrandSettings.dialog.confirm.F.continue")
        : this.props.t("BrandSettings.dialog.confirm.L.confirm"),
      cancel: this.isFordBrand
        ? this.props.t("BrandSettings.dialog.confirm.F.cancel")
        : this.props.t("BrandSettings.dialog.confirm.L.cancel"),
    };
  }

  componentDidMount() {
    this.setState({
      isDataLoaded: true,
    });
  }

  handleTabChange = (event, selectedTab) => {
    if (this.props.user.displayWarning) {
      this.newTabChange = selectedTab;
      this.setState({ confirmDialog: true });
    } else {
      this.changeTabTo(selectedTab);
    }
  };

  handleClose = () => {
    this.newTabChange = null;
    this.setState({ confirmDialog: false });
  };

  handleConfirm = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.user.showWarning(false);
    if (this.newTabChange !== null) {
      this.changeTabTo(this.newTabChange);
    } else {
      this.changeBrandSettings();
    }
  };

  getSelectedTab = (props) => {
    return props.history &&
      props.history.location &&
      props.history.location.state &&
      props.history.location.state.redirectToAccSearch
      ? 3
      : 0;
  };

  changeTabTo = (selectedTab) => {
    if (this.state.enableTabs) {
      this.newTabChange = null;
      this.setState({ selectedTab, confirmDialog: false });
    } else {
      this.setState({
        showWarning:
          selectedTab === 1 || selectedTab === 2 || selectedTab === 3,
        selectedDisabledTab: selectedTab,
      });
    }
  };

  getTabs = () => {
    return [
      {
        menuName: this.props.t("ProgressBar.brandSettings"),
        enabled: true,
        id: "brandSettings",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "brandSettingsTabUS"
        ),
      },
      {
        menuName: this.props.t("ProgressBar.namePlateSettings"),
        enabled: true,
        disabledStyle: !this.state.enableTabs,
        id: "namePlateSettings",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "namePlateSettingsTabUS"
        ),
      },
      {
        menuName: this.props.t("ProgressBar.categorySettings"),
        enabled: true,
        disabledStyle: !this.state.enableTabs,
        id: "categorySettings",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "categorySettingsTabUS"
        ),
      },
      {
        menuName: this.props.t("ProgressBar.search"),
        enabled: true,
        disabledStyle: !this.state.enableTabs,
        id: "accessoriesSearch",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "accessoriesSearchTabUS"
        ),
      },
    ];
  };

  enableOtherTabs = (value) => {
    this.setState({ enableTabs: value, showWarning: false });
  };

  changeBrand = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.brandCode = event.target.value;
    if (this.props.user.displayWarning) {
      this.setState({ confirmDialog: true });
    } else {
      this.changeBrandSettings();
    }
  };

  changeBrandSettings = () => {
    this.props.changeBrand(this.brandCode);
    document.body.style.backgroundColor = IS_FORD_BRAND(this.brandCode)
      ? "#ffffff"
      : "#f6f7f7";
  };

  render() {
    return (
      this.state.isDataLoaded && (
        <div className="pageWrapper">
          <SubNav
            selectedTab={this.state.selectedTab}
            restrictDropdown={true}
            showCustomDropdown={true}
            title={this.props.t("GlobalNavBar.accessoriesPricing")}
            action={this.handleTabChange}
            value={this.props.user.brand}
            onChange={this.changeBrand}
            dropDownValues={BRANDS}
            tabs={this.getTabs()}
            {...this.props}
          />
          {this.state.showWarning && (
            <div className={styles.dialogTitle}>
              <span className={styles.warning} />
              {this.state.selectedDisabledTab === 2 ? (
                <div>{this.props.t("CategorySettings.warningMessage")}</div>
              ) : (
                <div>{this.props.t("BrandSettings.warningMessage")}</div>
              )}
              <span
                className={styles.delete}
                onClick={() => {
                  this.setState({ showWarning: false });
                }}
              />
            </div>
          )}
          <div className="pageWrapper">
            <HasPermissionTo
              perform={["brandSettingsTabUS"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 0}
              render={() => {
                return (
                  <BrandSettings
                    {...this.props}
                    handleTabChange={this.handleTabChange}
                    enableOtherTabs={this.enableOtherTabs}
                  />
                );
              }}
            />
            <HasPermissionTo
              perform={["namePlateSettingsTabUS"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 1}
              render={() => {
                return (
                  <NamePlateSettings
                    {...this.props}
                    handleTabChange={this.handleTabChange}
                  />
                );
              }}
            />
            <HasPermissionTo
              perform={["categorySettingsTabUS"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 2}
              render={() => {
                return (
                  <CategorySettings
                    {...this.props}
                    confirmDialogProps={this.confirmDialogProps}
                    handleTabChange={this.handleTabChange}
                  />
                );
              }}
            />
            <HasPermissionTo
              perform={["accessoriesSearchTabUS"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 3}
              render={() => {
                return (
                  <AccessoriesSearch
                    {...this.props}
                    handleTabChange={this.handleTabChange}
                  />
                );
              }}
            />
          </div>

          <ConfirmDialog
            open={this.state.confirmDialog}
            dialogProps={this.confirmDialogProps}
            onConfirm={this.handleConfirm}
            onCancel={this.handleClose}
            boldTitle={true}
            showWarningIcon={true}
            onClose={this.handleClose}
            {...this.props}
          />
        </div>
      )
    );
  }
}

export default withTranslation("emp")(AccessoriesWrapper);
