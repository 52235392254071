import React, { Component } from "react";
import style from "./ErrorProcessingDialog.module.scss";
import { withTranslation } from "react-i18next";
import FordLogo from "../common/FordLogo";
import EMPButton from "../shared/components/EMPButton/EMPButton";
import { Typography } from "@mui/material";
class PageError extends Component {
  goBack() {
    window.history.back();
    this.props.close();
  }
  render() {
    return (
      <div className="pageWrapper">
        <div className={style.center}>
          <FordLogo className={style.brandLogo} />
          <Typography variant="h4" sx={{ mt: 4, mb: 1 }}>
            {this.props.code}
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {this.props.body}
          </Typography>
          <EMPButton variant="contained" onClick={() => this.goBack()}>
            {this.props.t("Error.goBack")}
          </EMPButton>
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(PageError);
