import { Grid, Typography } from "@mui/material";
import styles from "./Search.module.scss";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import React from "react";
import { withTranslation } from "react-i18next";
import stylesOverride from "./CrcSearchStylesOvverride";
import { IS_CANADA_MARKET } from "../../utils/EmpUtil";
import EMPButton from "../../shared/components/EMPButton/EMPButton";

const CustomerEmailOrFullNameAndZipSearch = (props) => {
  return (
    <>
      <Grid container>
        <Grid item>
          <div className={styles.subheaderPadding}>
            <p className={styles.subheader}>
              {props.t("CrcOrderSearch.subNav")}
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={styles.searchFormWrapper}>
            <Typography variant="body1">
              {props.t("CrcOrderSearch.searchBy")}
            </Typography>
            <div>
              <ValidatorForm
                className={styles.form}
                onSubmit={props.searchOrders}
              >
                <div className={styles.formSection}>
                  <div
                    className={
                      props.orderSearchMethod === "searchByEmail"
                        ? styles.inputLabel
                        : styles.inputLabelInactive
                    }
                  >
                    {props.t("CrcOrderSearch.customerEmailOrOrderId")}
                  </div>
                  <TextValidator
                    onClick={props.toggleRadio}
                    value={props.searchQuery}
                    id="crcEmailInput"
                    name="searchByEmail"
                    autoComplete="off"
                    placeholder="Customer email or order ID"
                    onChange={(e) => props.updateSearchQuery("searchQuery", e)}
                    required
                    fullWidth
                  />
                </div>
                <div>
                  <EMPButton
                    variant="contained"
                    disabled={props.orderSearchMethod !== "searchByEmail"}
                    id="emailSearchBtn"
                    testId="searchBtn"
                    buttonElement="button"
                    submitButton={true}
                  >
                    {props.t("CrcOrderSearch.search")}
                  </EMPButton>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg="auto">
          <Typography variant="subtitle1">
            {props.t("CrcOrderSearch.or")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md="auto" lg="auto">
          <div className={styles.searchFormWrapper}>
            <Typography variant="body1">
              {props.t("CrcOrderSearch.searchBy")}
            </Typography>
            <div style={stylesOverride.textInputGridItem}>
              <ValidatorForm
                className={styles.form}
                onSubmit={props.searchOrdersWithLastNameZipCode}
              >
                <div className={styles.formSection}>
                  <div
                    className={
                      props.orderSearchMethod === "searchByNameAndZip"
                        ? styles.inputLabel
                        : styles.inputLabelInactive
                    }
                  >
                    {props.t("CrcOrderSearch.firstAndLastName")}
                  </div>
                  <TextValidator
                    onClick={props.toggleRadio}
                    value={props.searchFullNameQuery}
                    id="crcFullNameInput"
                    name="searchByNameAndZip"
                    autoComplete="off"
                    placeholder="John Smith"
                    onChange={(e) =>
                      props.updateSearchQuery("searchFullNameQuery", e)
                    }
                    validators={[
                      "required",
                      "matchRegexp:^[a-zA-Z \\'\\,\\.\\-]+$",
                    ]}
                    errorMessages={[
                      props.t("CrcOrderSearch.invalid"),
                      props.t("CrcOrderSearch.invalid"),
                    ]}
                    required
                    fullWidth
                  />
                </div>
                <div className={styles.formSection}>
                  <div
                    className={
                      props.orderSearchMethod === "searchByNameAndZip"
                        ? styles.inputLabel
                        : styles.inputLabelInactive
                    }
                  >
                    {!IS_CANADA_MARKET(props.user.market)
                      ? props.t("CrcOrderSearch.zip")
                      : props.t("CrcOrderSearch.postalCode")}
                  </div>
                  {!IS_CANADA_MARKET(props.user.market) ? (
                    <TextValidator
                      onClick={props.toggleRadio}
                      value={props.searchZipQuery}
                      id="crcZipInput"
                      name="searchByNameAndZip"
                      autoComplete="off"
                      placeholder="48326"
                      onChange={(e) =>
                        props.handleZipCodeFormat("searchZipQuery", e)
                      }
                      validators={[
                        "minNumber:0",
                        "isNumber",
                        "matchRegexp:^\\d{5}(?:[- ]?\\d{4})?$",
                      ]}
                      errorMessages={[
                        props.t("CrcOrderSearch.invalid"),
                        props.t("CrcOrderSearch.invalid"),
                        props.t("CrcOrderSearch.invalid"),
                      ]}
                      fullWidth
                    />
                  ) : (
                    <TextValidator
                      onClick={props.toggleRadio}
                      value={props.searchZipQuery}
                      id="crcZipInput"
                      name="searchByNameAndZip"
                      autoComplete="off"
                      placeholder="A1A 1A1"
                      onChange={(e) =>
                        props.handleZipCodeFormat("searchZipQuery", e, true)
                      }
                      validators={[
                        "required",
                        "matchRegexp:^[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]\\d[ABCEGHJ-NPRSTV-Zabceghj-nnprstv-z][ -]?\\d[ABCEGHJ-NPRSTV-Zabcegh-nprstv-z]\\d$",
                      ]}
                      errorMessages={[
                        props.t("CrcOrderSearch.invalid"),
                        props.t("CrcOrderSearch.invalid"),
                        props.t("CrcOrderSearch.invalid"),
                      ]}
                      required
                      fullWidth
                    />
                  )}
                </div>
                <div className={styles.buttonContainer}>
                  <EMPButton
                    variant="contained"
                    disabled={props.orderSearchMethod !== "searchByNameAndZip"}
                    id="fullNameAndZipSearchBtn"
                    testId="fullNameAndZipSearchBtn"
                    buttonElement="button"
                    submitButton={true}
                  >
                    {props.t("CrcOrderSearch.search")}
                  </EMPButton>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default withTranslation("emp")(CustomerEmailOrFullNameAndZipSearch);
