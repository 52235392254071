import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const EMPBlankSection = ({ message1, message2 }) => {
  return (
    <Box
      sx={{
        border: "1px solid #B2B2B2",
        borderRadius: 2,
      }}
      gridColumn={{ xs: "span 12" }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: "240px" }}
      >
        <Grid item xs={8}>
          <Typography variant="subtitle1" align="center">
            {message1}
          </Typography>
          <Typography variant="body1" align="center">
            {message2}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EMPBlankSection;
