import EMPDataCard from "../../shared/components/EMPDataCard/EMPDataCard";
import EMPBanner from "../../shared/components/EMPBanner/EMPBanner";
import React, { useContext } from "react";
import { Box, Typography, Grid } from "@mui/material";
import CancelPurchaseRequest from "../../shared/components/EMPModal/ModalFragments/CancelPurchaseRequest";
import { formatDate, capitalizeText } from "../../utils/EmpUtil";
import PricingSummary from "../../components/customerHandling/purchaseDetails/addOns/PricingSummary";
import { useQuery } from "@tanstack/react-query";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";
import { UserContext } from "../../shared/context/userContext";
import { ModalContext } from "../../shared/components/EMPModal/ModalContext";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import EMPLoader from "../../shared/components/EMPLoader/EMPLoader";
import {
  canCancelPurchaseRequest,
  isPurchaseRequestAccepted,
} from "../../shared/rules/PurchaseRequestRules";
import { useTranslation } from "react-i18next";
import { formatPriceValue } from "../../utils/FormatPrice";
import { FINANCE_PRODUCT_TYPE } from "../../common/Constants";

const extractPurchaseInfo = (content) => {
  const {
    acceptRejectStatus,
    purchaseType,
    purchaseRequestDealerAction,
    soldDate,
    stockId,
  } = content.customerReservation.purchaseInfo || {};

  return {
    acceptRejectStatus: capitalizeText(acceptRejectStatus) || "--",
    purchaseType: capitalizeText(purchaseType) || "--",
    dealerAcceptedDate:
      formatDate(purchaseRequestDealerAction?.dealerAcceptedDate) || "--",
    dealerActionUserId: purchaseRequestDealerAction?.dealerActionUserId || "--",
    soldDate: formatDate(soldDate) || "--",
    stockId: stockId || "--",
    dealerRejectedReason:
      purchaseRequestDealerAction?.dealerRejectedReason || "--",
  };
};

const extractIncentivePrograms = (content) => {
  const { activeMilitaryFlag, purchaseInfo } = content.customerReservation;

  return {
    activeMilitary: activeMilitaryFlag ? activeMilitaryFlag : null,
    axzPlan: purchaseInfo ? purchaseInfo.axzPlanPinValidity : null,
  };
};

const Pricing = ({ content, orderId }) => {
  const user = useContext(UserContext);
  const userCommonId = `${user.market}|${user.dealerId}|${user.salesCode}|${user.dealerBrand}`;
  const { handleModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const { isLoading: pricingStackLoading, data: pricingStack } = useQuery({
    queryKey: ["pricingStack", { orderId }],
    queryFn: () =>
      CustomerReservationsClient.getPricingStack(
        sessionStorage.getItem("adfs.token"),
        orderId,
        user.market,
        userCommonId,
        "en"
      ),
    enabled: !!orderId,
    select: ({ data }) => data,
    staleTime: 60 * 1000,
  });

  const getOffers = () => {
    if (pricingStack?.offersList && pricingStack?.offersList.length > 0) {
      const offersData = pricingStack.offersList.map((offer) => {
        return [
          {
            heading: "Incentive",
            content: offer.name,
          },
          {
            heading: "Program Number",
            content: offer.programId,
          },
          {
            heading: "Incentive Amount",
            content: formatPriceValue(offer.amount),
          },
        ];
      });

      return offersData;
    } else {
      return [];
    }
  };

  const purchaseInfo = extractPurchaseInfo(content);
  const affinityPrograms = extractIncentivePrograms(content);
  const isTaxCreditEligible =
    content?.customerReservation?.isFederalTaxCreditEligible;
  const isThirdPartyFinancing =
    content?.customerReservation?.paymentType ===
    FINANCE_PRODUCT_TYPE.THIRD_PARTY;

  let statusInfo;

  if (purchaseInfo.acceptRejectStatus === "Accepted") {
    statusInfo = {
      heading: t("pricing.purchaseRequestDetails.fields.0"),
      content: purchaseInfo.acceptRejectStatus,
      highlight: true,
      color: "success",
    };
  } else if (purchaseInfo.acceptRejectStatus === "Canceled") {
    statusInfo = {
      heading: t("pricing.purchaseRequestDetails.fields.0"),
      content: purchaseInfo.acceptRejectStatus,
      highlight: true,
      color: "warning",
    };
  } else {
    statusInfo = {
      heading: t("pricing.purchaseRequestDetails.fields.0"),
      content: purchaseInfo.acceptRejectStatus,
      highlight: true,
      color: "info",
    };
  }

  const purchaseRequestDetails = [
    ...(statusInfo ? [statusInfo] : []),
    {
      heading: t("pricing.purchaseRequestDetails.fields.1"),
      content: isThirdPartyFinancing
        ? "Third Party Financing"
        : purchaseInfo.purchaseType,
    },
    ...(purchaseInfo.acceptRejectStatus === "accepted"
      ? [
          {
            heading: t("pricing.purchaseRequestAccepted.fields.0"),
            content: purchaseInfo.dealerAcceptedDate,
          },
        ]
      : purchaseInfo.acceptRejectStatus === "canceled"
      ? [
          {
            heading: t("pricing.purchaseRequestAccepted.fields.1"),
            content: purchaseInfo.dealerAcceptedDate,
          },
        ]
      : []),
    {
      heading: t("pricing.purchaseRequestDetails.fields.2"),
      content: purchaseInfo.soldDate,
    },
    {
      heading: t("pricing.purchaseRequestDetails.fields.3"),
      content: purchaseInfo.stockId,
    },
    {
      heading: t("pricing.purchaseRequestDetails.fields.4"),
      content: purchaseInfo.dealerActionUserId,
    },
    ...(purchaseInfo.acceptRejectStatus === "canceled"
      ? [
          {
            heading: t("pricing.purchaseRequestCanceled.fields.0"),
            content: purchaseInfo.dealerRejectedReason,
          },
        ]
      : []),
  ];

  const affinityProgramDetails = [
    {
      heading: "Active Military",
      content: affinityPrograms.activeMilitary ? "Yes" : "No",
    },
    {
      heading: "AXZ Plan",
      content: affinityPrograms.axzPlan === "Valid" ? "Yes" : "No",
    },
  ];

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      columnGap={4}
      rowGap={4}
      sx={{ mt: 5, mb: 11 }}
    >
      {isTaxCreditEligible && (
        <Box
          gridColumn={{
            xs: "span 12",
          }}
        >
          <EMPBanner
            title={t("common.banners.evTaxCredit.title")}
            content={t("common.banners.evTaxCredit.content")}
            hasLink
            linkText={t("common.banners.evTaxCredit.linkText")}
            href="https://www.irs.gov/credits-deductions/credits-for-new-clean-vehicles-purchased-in-2023-or-after"
          />
        </Box>
      )}

      {purchaseRequestDetails && purchaseRequestDetails.length > 0 && (
        <>
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h3" sx={{ color: "text.primary" }}>
                  {t("pricing.purchaseRequestDetails.heading")}
                </Typography>
              </Grid>
              <Grid item>
                {canCancelPurchaseRequest() &&
                  isPurchaseRequestAccepted(purchaseInfo) && (
                    <Box>
                      <EMPButton
                        testId="cancel-purchase-request-button"
                        onClick={() => {
                          handleModal(
                            t("common.buttonCtas.cancelPurchaseRequest"),
                            <CancelPurchaseRequest orderId={orderId} />
                          );
                        }}
                      >
                        {t("common.buttonCtas.cancelPurchaseRequest")}
                      </EMPButton>
                    </Box>
                  )}
              </Grid>
            </Grid>
          </Box>
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <EMPDataCard data={purchaseRequestDetails} />
          </Box>
        </>
      )}
      <Box
        gridColumn={{
          xs: "span 12",
        }}
        sx={{ mt: 2 }}
      >
        <Typography variant="h3" sx={{ color: "text.primary" }}>
          Affinity Programs
        </Typography>
      </Box>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <EMPDataCard data={affinityProgramDetails} />
      </Box>
      {pricingStackLoading && (
        <Box gridColumn={{ xs: "span 12" }} sx={{ height: "120px" }}>
          <Grid container alignItems="center" sx={{ height: "100%" }}>
            <Grid item>
              <EMPLoader loadingMessage="Loading Pricing Stack..." />
            </Grid>
          </Grid>
        </Box>
      )}
      {pricingStack && (
        <>
          {getOffers().length > 0 && (
            <>
              <Box gridColumn={{ xs: "span 12" }} sx={{ mt: 2 }}>
                <Typography variant="h3" sx={{ color: "text.primary" }}>
                  Offers & Incentives
                </Typography>
              </Box>

              {getOffers().map((offer) => {
                return (
                  <Box
                    key={offer[1].content}
                    gridColumn={{
                      xs: "span 12",
                    }}
                  >
                    <EMPDataCard data={offer} />
                  </Box>
                );
              })}
            </>
          )}

          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <PricingSummary
              isDownPaymentEnabled={
                content.customerReservation?.purchaseInfo?.isDownPaymentEnabled
              }
              paymentType={content.customerReservation.paymentType}
              customerReservation={content.customerReservation}
              pricingStack={pricingStack}
              isOrder={content.customerReservation.orderType}
              isPurchaseRequest
              amountFinanced={
                content.customerReservation &&
                content.customerReservation.financeDataQuote &&
                (content.customerReservation.financeDataQuote.amountFinanced ||
                  content.customerReservation.financeDataQuote
                    .totalAmountFinancedStandard)
              }
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Pricing;
