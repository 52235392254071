import React from "react";
import EMPGroupedHeading from "../../../shared/components/EMPGroupedHeading/EMPGroupedHeading";
import UpperCaseText from "../../../utils/UpperCaseText";
import {
  PIPELINE,
  VENDOR_CATEGORY_DEALER,
  VENDOR_CATEGORY_RRC,
} from "../../../common/Constants";
import { Link } from "react-router-dom";

// scenarios
// is Canadian field user who cannot see PII
// is cancelled reservation in Canada who cannot see PII - hasLabel and labelText is Cancelled

const DetailsHeader = (props) => {
  const { customerReservation, customerInfo, user } = props;

  // get details from user object
  const { market, userType } = user;
  const isCANUser = market === "CAN";
  const isFieldUser = userType && userType === "field_view";
  const isCANFieldViewUser = isFieldUser && isCANUser;

  // get details from customerReservation
  const {
    orderType,
    businessStatus,
    spotBuy,
    journeyType,
    vendorCategory,
    subJourneyType,
  } = customerReservation;
  const isReservation = orderType.toLowerCase() === "reservation";
  const isCancelled = businessStatus.toLowerCase() === "cancelled";
  const isFromPlant = UpperCaseText(subJourneyType) === PIPELINE;
  const isModelE = journeyType === "Model-E" || journeyType === "Model-e";
  const isSpotBuy = spotBuy;
  const isRRC = UpperCaseText(vendorCategory) === VENDOR_CATEGORY_RRC;
  const isInStock = UpperCaseText(vendorCategory) === VENDOR_CATEGORY_DEALER;
  const modelEFromPlant = isModelE && isFromPlant;

  // business logic
  const cancelledReservationInCanada =
    isReservation && isCancelled && isCANUser;
  const modelESpotBuy = isModelE && isSpotBuy;
  const modelERRC = isModelE && isRRC;
  const modelEInStock = isModelE && isInStock;

  const formatCustomerName = (firstName, lastName) => {
    if (firstName && lastName) {
      const first = firstName.charAt(0) + firstName.slice(1).toLowerCase();
      const last = lastName.charAt(0) + lastName.slice(1).toLowerCase();

      return `${first} ${last}`;
    } else {
      return "--";
    }
  };

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  const dynamicCustomerInfo = () => {
    if (customerInfo !== null) {
      return !isCANFieldViewUser
        ? [
            formatCustomerName(customerInfo.firstName, customerInfo.lastName),
            formatPhoneNumber(customerInfo.phoneNumber),
            customerInfo.email ? customerInfo.email.toLowerCase() : "",
          ]
        : [formatCustomerName(customerInfo.firstName, customerInfo.lastName)];
    } else {
      return [];
    }
  };

  const displayPurchaseType = (customerReservation) => {
    const { orderType } = customerReservation;

    const types = {
      reservation: "Reservation#",
      order: "Order#",
      purchase: "Purchase#",
    };

    if (customerReservation.purchaseInfo) {
      return types["purchase"];
    } else {
      return types[orderType.toLowerCase()];
    }
  };

  const displayLabel = () => {
    const labelMap = {
      inStore: {
        text: "In store",
        color: "primary",
      },
      rrc: {
        text: "RRC",
        color: "primary",
      },
      inStock: {
        text: "In stock",
        color: "primary",
      },
      fromPlant: {
        text: "From plant",
        color: "primary",
      },
    };

    if (modelESpotBuy) {
      return labelMap["inStore"];
    } else if (modelERRC) {
      return labelMap["rrc"];
    } else if (modelEInStock) {
      return labelMap["inStock"];
    } else if (modelEFromPlant) {
      return labelMap["fromPlant"];
    } else {
      return undefined;
    }
  };

  return (
    <>
      <EMPGroupedHeading
        brand={props.user.brand}
        headingText={`${displayPurchaseType(customerReservation)} ${
          customerReservation.id
        }`}
        label={displayLabel()}
        subItems={dynamicCustomerInfo()}
        hideSubItems={cancelledReservationInCanada}
      ></EMPGroupedHeading>
      <Link to={"/customer-handling/" + customerReservation.id}>
        See this order in the new design
      </Link>
    </>
  );
};

export default DetailsHeader;
