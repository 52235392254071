import React, { Component } from "react";
import fordStyles from "./SubNav.module.scss";
import lincolnStyles from "./SubNav-Lincoln.module.scss";
import Tabs from "@mui/material/Tabs";
import subNavFordStyles from "./subNavStyles";
import subNavLincolnStyles from "./subNavStyles-Lincoln";
import Tab from "@mui/material/Tab";
import { withTranslation } from "react-i18next";
import { MenuItem, Popover } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { handleBack } from "../../utils/GoBack";
import { withRouter } from "react-router-dom";
import CapitalizeFirstWord from "../../utils/CapitalizeFirstWord";

class SubNavTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handlePopOverClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopOverClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (event) => {
    this.handlePopOverClose();
    this.props.action(event, event.currentTarget.value);
  };

  clearSearch = () => {
    handleBack(false, this.props.history);
  };

  render() {
    const styles = this.props.isFordBrand ? fordStyles : lincolnStyles;
    const subNavStyles = this.props.isFordBrand
      ? subNavFordStyles
      : subNavLincolnStyles;
    const open = Boolean(this.state.anchorEl);
    const arrow = {
      position: "absolute",
      paddingTop: "1px",
      display: "inline-block",
    };

    return (
      <div className={styles.root}>
        {this.props.tabs && this.props.tabs.length ? (
          <>
            <Tabs
              id="tabs"
              data-testid="tabs"
              value={
                this.props.selectedTab < this.props.visibleTabsCount
                  ? this.props.selectedTab
                  : false
              }
              onChange={this.props.action}
              TabIndicatorProps={{ style: subNavStyles.highlighted }}
              textColor="primary"
              variant={
                this.props.visibleTabsCount === 100 ? "scrollable" : "standard"
              }
              scrollButtons={false}
              classes={{
                flexContainer: styles.flexContainer,
                scrollable: styles.scrollable,
              }}
            >
              {this.props.tabs.map((item, index) => {
                function getStyle() {
                  if (item.hide) return subNavStyles.hide;
                  else if (item.disabledStyle) return subNavStyles.disabledTab;
                  else if (!item.enabled) return subNavStyles.disabledTab;
                  else if (this.props.selectedTab === index)
                    return subNavStyles.highlighted;
                  else return subNavStyles.tab;
                }

                return (
                  index < this.props.visibleTabsCount && (
                    <Tab
                      sx={{ minWidth: "20px", fontSize: 14, px: 4 }}
                      style={getStyle.call(this)}
                      id={"tab" + item.id}
                      key={item.id}
                      value={index}
                      data-testid={"tab" + item.id}
                      label={CapitalizeFirstWord(item.menuName)}
                      disabled={!item.enabled}
                    />
                  )
                );
              })}
            </Tabs>
            {this.props.tabs.length > this.props.visibleTabsCount && (
              <>
                <div
                  data-testid="moreTab"
                  onClick={this.handlePopOverClick}
                  onMouseEnter={this.handlePopOverClick}
                  className={styles.moreTab}
                  style={
                    this.props.selectedTab >= this.props.visibleTabsCount
                      ? subNavStyles.highlighted
                      : subNavStyles.tab
                  }
                >
                  <span>
                    {this.props.t("SubNav.more")}
                    {this.state.anchorEl === null ? (
                      <KeyboardArrowDown style={arrow} />
                    ) : (
                      <KeyboardArrowUp style={arrow} />
                    )}
                  </span>
                </div>

                <Popover
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handlePopOverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  id="subNavMenuDropdown"
                  PaperProps={{ square: true }}
                >
                  {this.props.tabs.map((item, index) => {
                    return (
                      index >= this.props.visibleTabsCount && (
                        <MenuItem
                          onClick={this.handleMenuItemClick}
                          value={index}
                          key={index}
                          data-testid={"tab" + item.id}
                          id={"tab" + item.id}
                          style={
                            item.hide
                              ? subNavStyles.hide
                              : this.props.selectedTab === index
                              ? subNavStyles.highlightedMenuItem
                              : subNavStyles.menuItem
                          }
                          disabled={!item.enabled}
                        >
                          {item.menuName}
                        </MenuItem>
                      )
                    );
                  })}
                </Popover>
              </>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation("emp")(SubNavTabs));
