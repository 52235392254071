import React from "react";
import { Typography } from "@mui/material";
import PriceList from "./PriceList";

const LeasedTradeInTable = ({ appraisalDetails, t }) => {
  return (
    <>
      <Typography variant="h3" sx={{ color: "text.primary" }}>
        {t("tradeIn.tradeIn", { context: "obligation" })}
      </Typography>

      <Typography variant="body1" sx={{ mt: 2 }}>
        {t("tradeIn.leaseObligations")}
      </Typography>

      <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>
        {appraisalDetails.appraisalVehicle}
      </Typography>

      <PriceList amounts={appraisalDetails.amounts} />
    </>
  );
};

export default LeasedTradeInTable;
