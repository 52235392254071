import React from "react";
import { Box, Typography } from "@mui/material";
import { formatPhoneNumber } from "../../utils/EmpUtil";
import { useTranslation } from "react-i18next";
import EMPObjectCard from "../../shared/components/EMPDataCard/EMPObjectCard";

const extractCustomerDetails = (content) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const registrationMonth = content.customerReservation.registrationMonth
    ? monthNames[content.customerReservation.registrationMonth - 1]
    : "";

  return {
    name: `${content?.customerInfo?.firstName || "--"} ${
      content?.customerInfo?.lastName || "--"
    }`.trim(),
    address: `${content?.customerInfo?.address1 || "--"} ${
      content?.customerInfo?.city || "--"
    }, ${content?.customerInfo?.state || "--"} ${
      content?.customerInfo?.zip || "--"
    }`.trim(),
    phone:
      content?.customerInfo?.phoneNumber ||
      content?.customerInfo?.mobilePhoneNumber ||
      "",
    email: content?.customerInfo?.email || "--",
    registrationMonth: registrationMonth || "--",
    licensePlateDecision: content?.customerReservation?.keepLicensePlateFlag
      ? "Keep my plates"
      : "No license plate decision on file",
  };
};

const CustomerInfo = ({ content }) => {
  const { t } = useTranslation();
  const customerDetails = extractCustomerDetails(content);

  const contactDetails = {
    name: {
      heading: t("customerInfo.contactDetails.fields.0"),
      content: customerDetails.name,
    },
    address: {
      heading: t("customerInfo.contactDetails.fields.1"),
      content: customerDetails.address,
    },
    phone: {
      heading: t("customerInfo.contactDetails.fields.2"),
      content: customerDetails.phone
        ? formatPhoneNumber(customerDetails.phone)
        : "--",
    },
    email: {
      heading: t("customerInfo.contactDetails.fields.3"),
      content: customerDetails.email,
    },
  };

  const licenseAndRegistration = {
    registrationMonth: {
      heading: t("customerInfo.licenseAndRegistration.fields.0"),
      content: customerDetails.registrationMonth,
    },
    licensePlateDecision: {
      heading: t("customerInfo.licenseAndRegistration.fields.1"),
      content: customerDetails.licensePlateDecision,
    },
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      columnGap={4}
      rowGap={4}
    >
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <Typography variant="h3" sx={{ color: "text.primary" }}>
          {t("customerInfo.contactDetails.heading")}
        </Typography>
      </Box>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <EMPObjectCard data={contactDetails} />
      </Box>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
        sx={{ mt: 2 }}
      >
        <Typography variant="h3" sx={{ color: "text.primary" }}>
          {t("customerInfo.licenseAndRegistration.heading")}
        </Typography>
      </Box>
      <Box
        gridColumn={{
          xs: "span 12",
        }}
      >
        <EMPObjectCard data={licenseAndRegistration} />
      </Box>
    </Box>
  );
};

export default CustomerInfo;
