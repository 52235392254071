import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import fordStyles from "./AccessoriesSearch-Ford.module.scss";
import lincolnStyles from "./AccessoriesSearch-Lincoln.module.scss";
import FormControl from "@mui/material/FormControl";
import AccessoriesSearchClient from "../../../clients/AccessoriesSearchClient";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import stylesOverride from "./stylesOverride.js";
import CheckCurrencyValue from "../../../utils/CheckCurrencyValue";
import { fileDownload } from "../../../utils/FileDownloadUtil";
import {
  displayError,
  ERROR_PROCESSING,
  is404,
  isProcessingError,
  PAGE_ERROR,
} from "../../../errors/ErrorConstants";
import { logMsg } from "../../../common/Logger";
import SharedToolTip from "../../../shared/sharedToolTip/SharedToolTip";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import { SortAccessoriesData } from "../../../utils/SortUtil";
import SortingIcon from "../../../shared/sortingIcon/SortingIcon";
import { BRAND_NAMES } from "../../../common/Constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import HasPermissionTo from "../../../common/HasPermissionTo";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EMPSelectField from "../../../shared/components/EMPSelectField/EMPSelectField";
import CapitalizeEachWord from "../../../utils/CapitalizeEachWord";

const select = "select";

let isFordBrand = true;
let styles = fordStyles;

class AccessoriesSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModel: select,
      selectedYear: select,
      modelDropDownValues: [],
      yearDropDownValues: [],
      selectedCategory: select,
      categoryDropDownValues: [],
      disableSearch: true,
      accessoriesData: [],
      displayModelYearResults: false,
      displayDateRangeResults: false,
      hasError: [],
      sortKey: null,
      sortDirection: null,
      modelYearGrouping: null,
      isDataLoaded: false,
      searchMethod: "searchByModelYear",
      selectedDateRange: select,
      disableDateRangeSearch: true,
    };
    this.currencySymbol =
      this.props.localizedInfo && this.props.localizedInfo.defaultCurrency
        ? this.props.localizedInfo.defaultCurrency.symbol
        : this.props.t("CurrentPricing.na");
    this.dateRangeDropDownValues = [
      {
        translationKey: "last7Days",
        fieldKey: 7,
      },
      {
        translationKey: "last14Days",
        fieldKey: 14,
      },
      {
        translationKey: "last30Days",
        fieldKey: 30,
      },
    ];
  }

  componentDidMount() {
    AnalyticsService.trackPageLoadEvent(
      "fv:emp:accessories pricing:search",
      this.props.user
    );
    this.getAllVehicleLines();
  }

  getAllVehicleLines() {
    this.props.hideOrShow(true);
    AccessoriesSearchClient.getAllVehicleLinesByModelAndYear(
      this.props.user,
      IS_FORD_BRAND(this.props.user.brand)
        ? BRAND_NAMES.FORD
        : BRAND_NAMES.LINCOLN
    )
      .then((data) => {
        this.setState({
          modelYearGrouping: data,
          modelDropDownValues: Object.keys(data),
        });
      })
      .catch((error) => {
        this.processError(error);
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({
          isDataLoaded: true,
        });
      });
  }

  getCategories = () => {
    this.props.hideOrShow(true);
    AccessoriesSearchClient.getCategories(
      this.props.user,
      this.state.selectedModel,
      this.state.selectedYear
    )
      .then((data) => {
        this.setState({ categoryDropDownValues: data });
      })
      .catch((error) => {
        this.processError(error);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  processError(error) {
    logMsg("the error is: ", error);
    if (is404(error)) {
      this.setState({
        hasError: [PAGE_ERROR],
      });
    } else if (isProcessingError(error)) {
      this.setState({
        hasError: [ERROR_PROCESSING],
      });
    }
  }

  updateModel = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      selectedModel: event.target.value,
      selectedYear: select,
      selectedCategory: select,
      yearDropDownValues: this.state.modelYearGrouping[event.target.value],
      disableSearch: true,
      categoryDropDownValues: [],
      searchMethod: "searchByModelYear",
      disableDateRangeSearch: true,
      selectedDateRange: select,
    });
  };

  updateYear = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState(
      {
        selectedYear: event.target.value,
        selectedCategory: select,
        disableSearch: true,
      },
      () => {
        this.getCategories();
      }
    );
  };

  updateCategory = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      selectedCategory: event.target.value,
      disableSearch: false,
    });
  };

  updateDateRange = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      selectedDateRange: event.target.value,
      disableDateRangeSearch: false,
      searchMethod: "searchByDateRange",
      disableSearch: true,
      selectedModel: select,
      selectedYear: select,
      selectedCategory: select,
      categoryDropDownValues: [],
      yearDropDownValues: [],
    });
  };

  searchAccessoriesByModelYear = () => {
    this.props.hideOrShow(true);
    AccessoriesSearchClient.searchAccessories(
      this.props.user,
      this.state.selectedModel,
      this.state.selectedYear,
      this.state.selectedCategory
    )
      .then((data) => {
        this.setState({
          accessoriesData: data,
          displayModelYearResults: true,
          displayDateRangeResults: false,
          displayModel: this.state.selectedModel,
          displayYear: this.state.selectedYear,
          displayCategory: this.state.selectedCategory,
        });
      })
      .catch((error) => {
        this.processError(error);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  searchAccessoriesByDateRange = () => {
    this.props.hideOrShow(true);
    AccessoriesSearchClient.searchAccessoriesByDateRange(
      this.props.user,
      this.state.selectedDateRange
    )
      .then((data) => {
        this.setState({
          accessoriesData: Object.entries(data),
          displayDateRangeResults: true,
          displayModelYearResults: false,
          displayDateRange: this.dateRangeDropDownValues.find((value) => {
            return value.fieldKey === Number(this.state.selectedDateRange);
          }),
        });
      })
      .catch((error) => {
        this.processError(error);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  downloadReport = () => {
    const data = this.state.accessoriesData;
    const replacer = function (key, value) {
      return value === null ? "--" : value;
    };

    const reportAndValues = {
      desc: this.props.t("AccessoriesSearch.partName"),
      partNumber: this.props.t("AccessoriesSearch.partNumber"),
      model: this.props.t("AccessoriesSearch.model"),
      grossRetail: this.props.t("AccessoriesSearch.srp"),
      netRetail: this.props.t("AccessoriesSearch.netPricing"),
      markup: this.props.t("AccessoriesSearch.markup"),
      installTime: this.props.t("AccessoriesSearch.installTime"),
      laborRate: this.props.t("AccessoriesSearch.laborRate"),
      dealerSellingPrice: this.props.t("AccessoriesSearch.dealerSellingPrice"),
    };
    const fields = Object.keys(reportAndValues);
    const that = this;
    let csv = data.map(function (row) {
      return fields
        .map(function (fieldName) {
          let value = row[fieldName];
          if (fieldName === "model") {
            value = that.state.selectedModel;
          } else if (fieldName === "markup") {
            value = value + "%";
          } else if (
            fieldName === "grossRetail" ||
            fieldName === "laborRate" ||
            fieldName === "dealerSellingPrice" ||
            fieldName === "netRetail"
          ) {
            value = CheckCurrencyValue(that.currencySymbol, value);
          }
          return JSON.stringify(value, replacer);
        })
        .join(",");
    });
    csv.unshift(Object.values(reportAndValues).join(",")); // add header column
    csv = csv.join("\r\n");
    const filename = this.props.t("AccessoriesSearch.reportName") + ".csv";
    fileDownload(
      csv,
      "text/csv;charset=utf-8;",
      filename,
      "accessories-search-report"
    );
  };

  toggleToolTip = (tooltip) => {
    this.setState((prevState) => ({
      [tooltip]: !prevState[tooltip],
    }));
  };

  updateStyles = (isFordBrand) => {
    styles = isFordBrand ? fordStyles : lincolnStyles;
  };

  sortColumns = (sortByCol) => {
    let sortDir = "DSC";
    if (sortByCol === this.state.sortKey) {
      sortDir = this.state.sortDirection === "ASC" ? "DSC" : "ASC";
    }
    const that = this;
    SortAccessoriesData(
      sortByCol,
      sortDir,
      "alphanumeric",
      this.state.accessoriesData,
      function (sortedData) {
        that.setState({
          sortDirection: sortDir,
          sortKey: sortByCol,
          accessoriesData: sortedData,
        });
      }
    );
  };

  toggleRadio = (e) => {
    this.setState({
      searchMethod: e.target.name || e.target.value,
      selectedModel: select,
      selectedYear: select,
      selectedCategory: select,
      selectedDateRange: select,
      disableSearch: true,
      disableDateRangeSearch: true,
      categoryDropDownValues: [],
    });
  };

  render() {
    isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    this.updateStyles(isFordBrand);

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    return (
      this.state.isDataLoaded && (
        <>
          <div className={styles.pagePadding}>
            <HasPermissionTo
              perform={["dateRangeAccessoriesSearch"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => {
                return (
                  <>
                    <Typography variant="h1" sx={{ my: 4 }}>
                      Accessories Search
                    </Typography>
                    <Typography variant="body1" sx={{ my: 4 }}>
                      {this.props.t("AccessoriesSearch.subHeader")}
                    </Typography>

                    <Grid container wrap="nowrap">
                      <Grid item xs={1} sm="auto">
                        <RadioGroup
                          value={this.state.searchMethod}
                          onClick={this.toggleRadio}
                        >
                          <FormControlLabel
                            value="searchByModelYear"
                            control={<Radio />}
                            data-testid="searchByModelYear"
                            checked={
                              this.state.searchMethod === "searchByModelYear"
                            }
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid
                        item
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        alignContent="center"
                      >
                        <Grid item xs={12} sm={3} md={3}>
                          <ModelDropDown
                            {...this.props}
                            selectedModel={this.state.selectedModel}
                            modelDropDownValues={this.state.modelDropDownValues}
                            updateModel={this.updateModel}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                          <YearDropDown
                            {...this.props}
                            selectedYear={this.state.selectedYear}
                            yearDropDownValues={this.state.yearDropDownValues}
                            updateYear={this.updateYear}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} md={4}>
                          <CategoryDropDown
                            {...this.props}
                            selectedCategory={this.state.selectedCategory}
                            categoryDropDownValues={
                              this.state.categoryDropDownValues
                            }
                            updateCategory={this.updateCategory}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2} md={3}>
                          <SearchButton
                            {...this.props}
                            disabled={this.state.disableSearch}
                            onClick={this.searchAccessoriesByModelYear}
                            dataTestId="modelYearSearchButton"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              }}
              noRender={() => {
                return (
                  <>
                    <Typography variant="body1" sx={{ my: 4 }}>
                      {this.props.t("AccessoriesSearch.subHeader")}
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item>
                        <ModelDropDown
                          {...this.props}
                          selectedModel={this.state.selectedModel}
                          modelDropDownValues={this.state.modelDropDownValues}
                          updateModel={this.updateModel}
                        />
                      </Grid>
                      <Grid item>
                        <YearDropDown
                          {...this.props}
                          selectedYear={this.state.selectedYear}
                          yearDropDownValues={this.state.yearDropDownValues}
                          updateYear={this.updateYear}
                        />
                      </Grid>
                      <Grid item>
                        <CategoryDropDown
                          {...this.props}
                          selectedCategory={this.state.selectedCategory}
                          categoryDropDownValues={
                            this.state.categoryDropDownValues
                          }
                          updateCategory={this.updateCategory}
                        />
                      </Grid>
                      <Grid item>
                        <SearchButton
                          {...this.props}
                          disabled={this.state.disableSearch}
                          onClick={this.searchAccessoriesByModelYear}
                          dataTestId="modelYearSearchButton"
                        />
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            />

            <HasPermissionTo
              perform={["dateRangeAccessoriesSearch"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => {
                return (
                  <>
                    <Typography variant="body1" sx={{ my: 4 }}>
                      {this.props.t("AccessoriesSearch.timeFrameSubTitle")}
                    </Typography>

                    <Grid container wrap="nowrap">
                      <Grid item>
                        <RadioGroup
                          value={this.state.searchMethod}
                          onClick={this.toggleRadio}
                        >
                          <FormControlLabel
                            value="searchByDateRange"
                            control={<Radio />}
                            data-testid="searchByDateRange"
                            checked={
                              this.state.searchMethod === "searchByDateRange"
                            }
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid
                        item
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        alignContent="center"
                      >
                        <Grid item xs={12} sm={6} md={4}>
                          <DateRangeDropDown
                            {...this.props}
                            selectedDateRange={this.state.selectedDateRange}
                            dataRangeValues={this.dateRangeDropDownValues}
                            updateDateRange={this.updateDateRange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <SearchButton
                            {...this.props}
                            disabled={this.state.disableDateRangeSearch}
                            onClick={this.searchAccessoriesByDateRange}
                            dataTestId="dateRangeSearchButton"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            />
          </div>
          {this.state.displayModelYearResults && (
            <>
              <DisplayResultsFor
                {...this.props}
                selectedModel={this.state.displayModel}
                selectedYear={this.state.displayYear}
                selectedCategory={this.state.displayCategory}
                downloadReport={this.downloadReport}
              />

              <div>
                <Table>
                  <TableHeader
                    {...this.props}
                    openDisplayTooltip={this.state.openDisplayTooltip}
                    toggleToolTip={(event) => this.toggleToolTip(event)}
                    sortKey={this.state.sortKey}
                    sortDirection={this.state.sortDirection}
                    sortColumns={this.sortColumns}
                  />
                  <TableBody>
                    {this.state.accessoriesData.map((accessory, index) => {
                      return (
                        <RowData
                          accessory={accessory}
                          index={index}
                          key={accessory.id}
                          model={this.state.displayModel}
                          currencySymbol={this.currencySymbol}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
          {this.state.displayDateRangeResults && (
            <>
              <DisplayDateRangeResultsHeader
                {...this.props}
                selectedDateRange={this.state.displayDateRange}
                downloadReport={this.downloadReport}
                accessoriesData={this.state.accessoriesData}
              />

              {this.state.accessoriesData.length > 0 ? (
                <Table>
                  <TableHeader
                    {...this.props}
                    openDisplayTooltip={this.state.openDisplayTooltip}
                    toggleToolTip={(event) => this.toggleToolTip(event)}
                    sortKey={this.state.sortKey}
                    sortDirection={this.state.sortDirection}
                    sortColumns={this.sortColumns}
                  />
                  <TableBody>
                    {this.state.accessoriesData.map((accessory, index) => {
                      return (
                        <DateSearchRowData
                          accessory={accessory}
                          index={index}
                          accessoryGroupName={accessory[0]}
                          key={accessory[0]}
                          currencySymbol={this.currencySymbol}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <div className={styles.emptyResults}>
                  {this.props.t("AccessoriesSearch.dateRangeEmptyResultSet")}
                </div>
              )}
            </>
          )}
        </>
      )
    );
  }
}

const ModelDropDown = (props) => {
  const menuItems = [
    {
      text: props.t("AccessoriesSearch.select"),
      value: select,
      disabled: true,
    },
  ];
  if (props.modelDropDownValues) {
    props.modelDropDownValues.forEach((model) => {
      menuItems.push({
        text: CapitalizeEachWord(model).replace("Mach-e", "Mach-E"),
        value: model,
      });
    });
  }
  return (
    <>
      <div className={styles.subTitle}>
        {props.t("AccessoriesSearch.model")}
      </div>
      <div data-testid="modelDropdown" className={styles.dropdownDiv}>
        <FormControl className={styles.modelFormControl}>
          <EMPSelectField
            testId="modelSelect"
            value={props.selectedModel}
            onChange={(event) => props.updateModel(event)}
            menuItems={menuItems}
          />
        </FormControl>
      </div>
    </>
  );
};

const YearDropDown = (props) => {
  const menuItems = [
    {
      text: props.t("AccessoriesSearch.select"),
      value: select,
      disabled: true,
    },
  ];
  if (props.yearDropDownValues) {
    props.yearDropDownValues.forEach((year) => {
      menuItems.push({
        text: year,
        value: year,
      });
    });
  }
  return (
    <>
      <div className={styles.subTitle}>{props.t("AccessoriesSearch.year")}</div>
      <div data-testid="yearDropdown" className={styles.dropdownDiv}>
        <FormControl className={styles.modelFormControl}>
          <EMPSelectField
            testId="yearSelect"
            value={props.selectedYear}
            onChange={(event) => props.updateYear(event)}
            menuItems={menuItems}
          />
        </FormControl>
      </div>
    </>
  );
};

const CategoryDropDown = (props) => {
  const menuItems = [
    {
      text: props.t("AccessoriesSearch.select"),
      value: select,
      disabled: true,
    },
  ];
  if (props.categoryDropDownValues) {
    props.categoryDropDownValues.forEach((category) => {
      menuItems.push({
        text: category.groupName,
        value: category.groupName,
      });
    });
  }
  return (
    <>
      <div className={styles.subTitle}>
        {props.t("AccessoriesSearch.category")}
      </div>
      <div data-testid="categoryDropdown" className={styles.dropdownDiv}>
        <FormControl className={styles.modelFormControl}>
          <EMPSelectField
            testId="categorySelect"
            value={props.selectedCategory}
            onChange={(event) => props.updateCategory(event)}
            menuItems={menuItems}
          />
        </FormControl>
      </div>
    </>
  );
};

const DateRangeDropDown = (props) => {
  const menuItems = [
    {
      text: props.t("AccessoriesSearch.select"),
      value: select,
      disabled: true,
    },
  ];
  if (props.dataRangeValues) {
    props.dataRangeValues.forEach((range) => {
      menuItems.push({
        text: props.t(`AccessoriesSearch.${range.translationKey}`),
        value: range.fieldKey,
      });
    });
  }
  return (
    <>
      <div className={styles.subTitle}>
        {props.t("AccessoriesSearch.dateRange")}
      </div>
      <div data-testid="dateRangeDropdown">
        <FormControl className={styles.modelFormControl}>
          <EMPSelectField
            testId="dateRangeSelect"
            value={props.selectedDateRange}
            onChange={(event) => props.updateDateRange(event)}
            menuItems={menuItems}
          />
        </FormControl>
      </div>
    </>
  );
};

const SearchButton = (props) => {
  const { dataTestId, disabled, onClick, t } = props;
  return (
    <div className={styles.searchButton}>
      <EMPButton
        testId={dataTestId}
        disabled={disabled}
        onClick={onClick}
        variant="contained"
        buttonElement="button"
      >
        {t("AccessoriesSearch.search")}
      </EMPButton>
    </div>
  );
};

const DisplayResultsFor = (props) => {
  return (
    <div
      className={`${styles.displayResultsContainer} pagePaddingLeft pagePaddingRight`}
    >
      <div className={styles.displayResults} data-testid="displayResultsFor">
        <span>{props.t("AccessoriesSearch.displayResultsFor")}</span>
        <span className={styles.text}>{props.selectedModel}</span>
        <span className={styles.text}>{props.selectedYear}</span>
        <span className={styles.text}>{props.selectedCategory}</span>
        <span className={styles.download}>
          <EMPButton
            onClick={() => props.downloadReport()}
            variant="text"
            leftIcon={<CloudDownloadIcon />}
          >
            {props.t("AccessoriesSearch.download")}
          </EMPButton>
        </span>
      </div>
      <div className={styles.noteWrapper}>
        <span className={styles.bold}>{props.t("AccessoriesSearch.note")}</span>
        <span>{props.t("AccessoriesSearch.noteInfo")}</span>
      </div>
    </div>
  );
};

const DisplayDateRangeResultsHeader = (props) => {
  return (
    <div className={styles.pagePadding}>
      <Box my={8} data-testid="displayResultsForDateRange">
        <Typography variant="subtitle1">
          {props.t("AccessoriesSearch.displayResultsFor")}
        </Typography>
        <Typography variant="body1" display="inline">
          {props.t("AccessoriesSearch.newAccessories")}{" "}
          {props.t(`AccessoriesSearch.${props.selectedDateRange}`)}
        </Typography>
        {props.accessoriesData.length > 0 && (
          <span className={styles.download}>
            <EMPButton
              onClick={() => props.downloadReport()}
              variant="text"
              leftIcon={<CloudDownloadIcon />}
            >
              {props.t("AccessoriesSearch.download")}
            </EMPButton>
          </span>
        )}
      </Box>
    </div>
  );
};

const TableHeader = (props) => {
  return (
    <TableHead className={styles.tableHead}>
      <TableRow style={stylesOverride.tableRow}>
        <TableCell onClick={() => props.sortColumns("desc")}>
          <span className={styles.alignSortIconStart}>
            <div className={styles.rowHeader}>
              {props.t("AccessoriesSearch.partName")}
            </div>
            <span className={styles.sorting}>
              <SortingIcon
                className={styles.sorting}
                currentKey="desc"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </span>
        </TableCell>
        <TableCell
          style={stylesOverride.partNumberHeader}
          onClick={() => props.sortColumns("partNumber")}
        >
          <span className={styles.alignSortIcon}>
            <div className={styles.rowHeader}>
              {props.t("AccessoriesSearch.partNumber")}
            </div>
            <span className={styles.sorting}>
              <SortingIcon
                currentKey="partNumber"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </span>
        </TableCell>
        <TableCell
          style={stylesOverride.accessorySearchHeader}
          onClick={() => props.sortColumns("model")}
        >
          <span className={styles.alignSortIcon}>
            <div className={styles.rowHeader}>
              {props.t("AccessoriesSearch.model")}
            </div>
            <span className={styles.sorting}>
              <SortingIcon
                className={styles.sorting}
                currentKey="model"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </span>
        </TableCell>
        <TableCell
          style={stylesOverride.srpHeader}
          onClick={() => props.sortColumns("grossRetail")}
        >
          <span className={styles.alignSortIcon}>
            <div className={styles.rowHeader}>
              {props.t("AccessoriesSearch.srp")}
            </div>
            <span className={styles.sorting}>
              <SortingIcon
                className={styles.sorting}
                currentKey="grossRetail"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </span>
        </TableCell>
        <TableCell
          style={stylesOverride.accessorySearchHeader}
          onClick={() => props.sortColumns("netRetail")}
        >
          <span className={styles.alignSortIcon}>
            <div className={styles.rowHeader}>
              {props.t("AccessoriesSearch.netPricing")}
            </div>
            <span className={styles.sorting}>
              <SortingIcon
                className={styles.sorting}
                currentKey="netRetail"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </span>
        </TableCell>
        <TableCell
          style={stylesOverride.accessorySearchHeader}
          onClick={() => props.sortColumns("markup")}
        >
          <span className={styles.alignSortIcon}>
            <div className={styles.rowHeader}>
              {props.t("AccessoriesSearch.markup")}
            </div>
            <span className={styles.sorting}>
              <SortingIcon
                className={styles.sorting}
                currentKey="markup"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </span>
        </TableCell>
        <TableCell
          style={stylesOverride.accessorySearchHeader}
          onClick={() => props.sortColumns("installTime")}
        >
          <span className={styles.alignSortIcon}>
            <div className={styles.rowHeader}>
              {props.t("AccessoriesSearch.installTime")}
            </div>
            <span className={styles.sorting}>
              <SortingIcon
                className={styles.sorting}
                currentKey="installTime"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </span>
        </TableCell>
        <TableCell
          style={stylesOverride.accessorySearchHeader}
          onClick={() => props.sortColumns("laborRate")}
        >
          <span className={styles.alignSortIcon}>
            <div className={styles.rowHeader}>
              {props.t("AccessoriesSearch.laborRate")}
            </div>
            <span className={styles.sorting}>
              <SortingIcon
                className={styles.sorting}
                currentKey="laborRate"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </span>
        </TableCell>
        <TableCell
          style={stylesOverride.dealerSellingPriceHeader}
          onClick={() => props.sortColumns("dealerSellingPrice")}
        >
          <span className={styles.alignSortIcon}>
            <div className={styles.specialRowHeader}>
              {props.t("AccessoriesSearch.dealerSellingPrice")}
            </div>
            <span className={styles.sorting}>
              <SharedToolTip
                {...props}
                title={props.t("AccessoriesSearch.dealerSellingPrice")}
                body={props.t("AccessoriesSearch.dealerSellingPriceTooltip")}
                closeTestid="closeDealerSellingPrice"
                openTestid="openDealerSellingPrice"
                isLincolnBrand={!isFordBrand}
                className={styles.toolTipPadding}
              />
              <SortingIcon
                className={styles.sorting}
                currentKey="dealerSellingPrice"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </span>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const RowData = (props) => {
  const index = props.index;
  const accessory = props.accessory;
  return (
    <TableRow className={styles.tableRow} id={"row" + index} key={accessory.id}>
      <TableCell>
        <div className={`${styles.rowData} ${styles.partName}`}>
          {accessory.desc}
        </div>
      </TableCell>
      <TableCell style={stylesOverride.partNumber}>
        <div className={styles.rowData}>{accessory.partNumber}</div>
      </TableCell>
      <TableCell>
        <div className={styles.rowData}>
          {CapitalizeEachWord(props.model).replace("Mach-e", "Mach-E")}
        </div>
      </TableCell>
      <TableCell style={stylesOverride.srp}>
        <div className={styles.rowData}>
          {CheckCurrencyValue(props.currencySymbol, accessory.grossRetail)}
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.rowData}>
          {CheckCurrencyValue(props.currencySymbol, accessory.netRetail)}
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.rowData}>{`${accessory.markup}%`}</div>
      </TableCell>
      <TableCell>
        <div className={styles.rowData}>{accessory.installTime}</div>
      </TableCell>
      <TableCell>
        <div className={styles.rowData}>
          {CheckCurrencyValue(props.currencySymbol, accessory.laborRate)}
        </div>
      </TableCell>
      <TableCell style={stylesOverride.dealerSellingPrice}>
        <div className={styles.rowData}>
          {CheckCurrencyValue(
            props.currencySymbol,
            accessory.dealerSellingPrice
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
const DateSearchRowData = (props) => {
  const index = props.index;
  const accessory = props.accessory[1];
  const accessoryGroupName = props.accessory[1].map(
    (accessory) => accessory.groupName
  )[0];
  return (
    <>
      <TableRow
        className={styles.tableRow}
        id={"row" + index}
        key={accessoryGroupName}
        style={stylesOverride.accessoriesHeaderBarBg}
      >
        <TableCell>
          <div className={`${styles.rowData} ${styles.partName}`}>
            {accessoryGroupName}
          </div>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
      {accessory.map((subGroup, index) => {
        return (
          <TableRow
            className={styles.tableRow}
            id={"row" + index}
            key={subGroup.id}
          >
            <TableCell>
              <div className={`${styles.rowData} ${styles.partName}`}>
                {subGroup.desc}
              </div>
            </TableCell>
            <TableCell style={stylesOverride.partNumber}>
              <div className={styles.rowData}>{subGroup.partNumber}</div>
            </TableCell>
            <TableCell>
              <div className={styles.rowData}>
                {subGroup.model || subGroup.nameplate}
              </div>
            </TableCell>
            <TableCell style={stylesOverride.srp}>
              <div className={styles.rowData}>
                {CheckCurrencyValue(props.currencySymbol, subGroup.grossRetail)}
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.rowData}>
                {CheckCurrencyValue(props.currencySymbol, subGroup.netRetail)}
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.rowData}>{`${subGroup.markup}%`}</div>
            </TableCell>
            <TableCell>
              <div className={styles.rowData}>{subGroup.installTime}</div>
            </TableCell>
            <TableCell>
              <div className={styles.rowData}>
                {CheckCurrencyValue(props.currencySymbol, subGroup.laborRate)}
              </div>
            </TableCell>
            <TableCell style={stylesOverride.dealerSellingPrice}>
              <div className={styles.rowData}>
                {CheckCurrencyValue(
                  props.currencySymbol,
                  subGroup.dealerSellingPrice
                )}
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default withTranslation("emp")(AccessoriesSearch);
