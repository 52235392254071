import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import UiDateUtils from "../../../utils/UiDateUtils";
import MotionChevronRight from "../../Icons/MotionChevronRight";
import UpperCaseText from "../../../utils/UpperCaseText";
import TitleCase from "../../../utils/TitleCase";
import MotionPlus from "../../Icons/MotionPlus";
import MotionMinus from "../../Icons/MotionMinus";
import EMPButton from "../EMPButton/EMPButton";
import EMPEmptyState from "../EMPEmptyState/EMPEmptyState";

const EMPExpandableTableRow = (props) => {
  const { appointmentDate, order, status, orderLabel, customerName } = props;
  const {
    modelYear,
    nameplate,
    vinNumber,
    id,
    purchaseRequestDate,
    purchaseRequestStatus,
    subJourneyType,
  } = order;
  const [color, setColor] = useState("text.primary");
  const updateColor = (statusColorProp) => {
    setColor(statusColorProp);
  };

  useEffect(() => {
    updateColor(status.statusColor);
  }, [status]);

  const showVin = !(UpperCaseText(subJourneyType) === "PIPELINE" && !vinNumber);

  return (
    <>
      <TableRow sx={{ borderBottom: "none" }}>
        <TableCell>{orderLabel}</TableCell>
        <TableCell>
          {customerName ? (
            <Typography variant="body1">{customerName}</Typography>
          ) : (
            <EMPEmptyState />
          )}
          {showVin && (
            <Typography data-testid="vin-display" variant="body2">{`${props.t(
              "ModelEOrders.vin"
            )}: ${vinNumber}`}</Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            sx={{ color: color }}
            data-testid="table-row-message"
          >
            {status.message}
          </Typography>
          {appointmentDate &&
            !["CANCELED", "COMPLETED"].includes(
              UpperCaseText(status.status)
            ) && (
              <Typography variant="body2">
                {UiDateUtils.formatToDayDateTime(appointmentDate)}
              </Typography>
            )}
        </TableCell>

        <TableCell>
          <EMPButton
            testId="orderDetailLink"
            buttonElement={Link}
            variant="text"
            rightIcon={<MotionChevronRight />}
            link={props.rowLink}
          >
            {props.t("ModelEOrders.viewDetails")}
          </EMPButton>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={props.onShow}
            disableRipple
          >
            {props.isOpen ? <MotionMinus /> : <MotionPlus />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={7}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box
              py={4}
              sx={{
                paddingLeft: 24,
              }}
            >
              <Grid container justifyContent="flex-start">
                <Grid item xs={12}>
                  <Box mb={1}>
                    <Typography variant="subtitle2">
                      {props.t("ModelEOrders.additionalInfo")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box>
                      <Typography display="inline" variant="subtitle2">
                        {`${props.t("ModelEOrders.purchaseNumber")} `}
                      </Typography>
                      <Typography display="inline" variant="body1">
                        {id || <EMPEmptyState />}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box>
                      <Typography display="inline" variant="subtitle2">
                        {`${props.t("ModelEOrders.status")}: `}
                      </Typography>
                      <Typography display="inline" variant="body1">
                        {TitleCase(purchaseRequestStatus) || <EMPEmptyState />}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                spacing={1}
                alignContent="stretch"
                style={{ marginTop: "4px" }}
              >
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Box data-testid="date-placed-box">
                    <Typography display="inline" variant="subtitle2">
                      {`${props.t("ModelEOrders.datePlaced")}: `}
                    </Typography>
                    <Typography display="inline" variant="body1">
                      {purchaseRequestDate || <EMPEmptyState />}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Box>
                    <Typography display="inline" variant="subtitle2">
                      {`${props.t("ModelEOrders.vehicle")}: `}
                    </Typography>
                    <Typography noWrap display="inline" variant="body1">
                      {`${modelYear} ${nameplate}` || <EMPEmptyState />}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default withTranslation("emp")(EMPExpandableTableRow);
