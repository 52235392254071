import { useState } from "react";
import { useHistory } from "react-router-dom";

const EMPSearchFieldRouting = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");

  const routeChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (searchQuery !== "") {
      history.push(`/search/${searchQuery}`);
    }
  };

  const updateSearchQuery = (value) => {
    setSearchQuery(value);
  };

  return {
    searchQuery,
    routeChange,
    updateSearchQuery,
  };
};

export default EMPSearchFieldRouting;
